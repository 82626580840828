import React from "react";

import Customer from "../Customer"

import PayButton from "../button/PayButton"

import Separator from "../../common/Separator"

import PaymentMethodPortal from '../PaymentMethodPortal';

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General"
import AsyncStorage from "../../../../utils/AsyncStorage";


const config = {
  fields: {
    card: {
      selector: '[data-cc-card]',
    },

    exp: {
      selector: '[data-cc-exp]',
    },
    name: {
      selector: '[data-cc-name]',
      placeholder: 'Full Name',
    },
  },

  // css classes to be injected into the iframes.
  // the properties allowed are restricted via whitelist.
  // further, unrestricted styling can be applied to the div's in which the iframes are injected.
  styles: {
    input: {
      'font-size': '12px !important',
      color: '#464e5f',
      'font-family': 'Poppins, Helvetica, "sans-serif"',
      'background-color': 'transparent !important',
    },
    '.card': {
      'font-family': 'Poppins, Helvetica, "sans-serif"',
    },
    ':focus': {
      color: '#464e5f',
    },
    '.valid': {
      color: '#464e5f',
    },
    '.invalid': {
      color: '#464e5f',
    },
    '@media screen and (max-width: 700px)': {
      input: {
        'font-size': '18px',
      },
    },
    'input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 50px white inset',
    },
    'input:focus:-webkit-autofill': {
      '-webkit-text-fill-color': '#464E5F',
    },
    'input.valid:-webkit-autofill': {
      '-webkit-text-fill-color': '#464E5F',
    },
    'input.invalid:-webkit-autofill': {
      '-webkit-text-fill-color': '#464E5F',
    },
    'input::placeholder': {
      color: '#B5B5C3'
    },
  },

  // these values correspond to css class names defined above
  classes: {
    empty: 'empty',
    focus: 'focus',
    invalid: 'invalid',
    valid: 'valid',
  },
};


export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      loading: true
    }

    this.form = React.createRef()
    this.customer = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(nextProps){
    let {
      company,
      customer,
      paymentPlan
    } = nextProps

    let paymentMethod = company.payment_methods.card

    General.loadScript(paymentMethod.data.payment_js_url)

    return {
      company,
      customer,
      paymentPlan,
      paymentMethod
    }

  }

  componentDidMount() {
    this._createPaymentForm()
  }

  _createPaymentForm(){
    let layout = this.state.company.settings.layout

    if(layout === "modern"){
      config.styles.input['font-size']='13px !important';
      config.styles.input.color='white';
      config.styles[':focus'].color='white';
      config.styles['.valid'].color='white';
      config.styles['.invalid'].color='white';
    }

    if(window.firstdata){
      setTimeout(() => {
        window.firstdata.createPaymentForm(config, this._hooks(), this._setup.bind(this));
      }, 1000)

      return
    }
    setTimeout(() => this._createPaymentForm(), 100)
  }

  handleAction(response){
    if(response.method_form) {
      return this._validateMethodForm(response)
    }
    if(response.acs_url) {
      return this._validateChallengeForm(response)
    }
  }

  _getTermUrl(){
    return window.location.href
  }

  _validateMethodForm(response){
    return new Promise((resolve, reject) => {
      this.setState({ methodForm: response.method_form, transaction_id: response.transaction_id })

      // wait 12 seconds to allow method_form to communicate with aib/first data to
      setTimeout(() => {
        resolve({
          client_token: this.state.client_token,
          transaction_id: response.transaction_id,
          term_url: this._getTermUrl(),
          method_form: true,
        })
      }, 1000 * 12)
    })
  }

  _validateChallengeForm(response){
    response.term_url = this._getTermUrl()
    this.props.onSaveState({
      client_token: this.state.client_token,
      transaction_id: response.transaction_id,
      term_url: response.term_url,
    })

    return new Promise((resolve, reject) => {
      this.setState({ challengeForm: response, transaction_id: response.transaction_id })

      setTimeout(() => {

        this.form.current.submit()
      }, 300)
    })
  }


  _hooks(){
    let { company } = this.state
    return {
      preFlowHook: callback => {
        this.setState({ loading: true })
        let card = company.payment_methods.card
        this.props.onSaveState()

        this._getCardPaymentMethod(card)
        .then(card => {
          callback({
            clientToken: card.data.client_token,
            publicKeyBase64: card.data.public_key_base_64
          })
        })
      }
    }
  }

  _getCardPaymentMethod(card){
    // if the card doesn't have an id
    // it's a platform payment method
    if(card.id){
      return Backend.getPaymentMethod(card.id)
    }
    return Backend.getPlatformPaymentMethods()
    .then(paymentMethods => paymentMethods.card)
  }

  _setup(paymentForm){
    const ccFields = window.document.getElementsByClassName('payment-fields');
    for (let i = 0; i < ccFields.length; i++) {
      //DomUtils.removeClass(ccFields[i], 'disabled');
    }

    this.setState({ paymentForm, loading: false })

    //SubmitButton.enable();
  }

  isValid(scrollToError){
    if(this.customer.current && !this.customer.current.isValid(scrollToError)){
      return false
    }

    return true
  }

  getPaymentDetails(paymentMethod){
    let { paymentForm, cvv } = this.state
    return new Promise((resolve,reject) => {
      paymentForm.onSubmit(clientToken => {
        let { company, paymentForm } = this.state
        //paymentForm.reset(() => {});
        let termUrl = window.location.href
        this.setState({ loading: false, client_token: clientToken, term_url: termUrl })
        return resolve({
          paymentMethod: company.payment_methods.card,
          paymentDetails: {
            cvv,
            client_token: clientToken,
            term_url: termUrl,
          }
        })

      }, error => {
        let { paymentForm } = this.state
        //paymentForm.reset(() => {});
        return reject(error)
        this.setState({ loading: false })
      })
    })
  }

  render() {

    let {
      company,
      customer,
      loading,
      methodForm,
      challengeForm,
      paymentMethod
    } = this.state

    return (
      <PaymentMethodPortal type="card">
        <div className="components payment aib payment-methods">

          {methodForm &&
            <div dangerouslySetInnerHTML={{__html: methodForm }} className={"acs-method-form"}/>
          }

          {challengeForm &&
              <form
                  ref={this.form}
                  action={challengeForm.acs_url}
                  method={"post"}
              >

                <input name={"TermURL"} hidden value={challengeForm.term_url}/>

                { challengeForm.c_req &&
                  <input name={"creq"} hidden value={challengeForm.c_req}/>
                }

                { challengeForm.payer_authentication_request &&
                  <input name={"PaReq"} hidden value={challengeForm.payer_authentication_request}/>
                }

                { challengeForm.session_data !== undefined &&
                  <input name={"threeDSSessionData"} hidden value={challengeForm.session_data}/>
                }

                { challengeForm.merchant_data !== undefined &&
                  <input name={"MD"} hidden value={challengeForm.merchant_data}/>
                }
              </form>
          }

          <Customer
            ref={this.customer}
            customer={customer}
            collectEmail={this.props.collectEmail}
            collectName={false}
            onUpdated={customer => {
              this.props.onUpdatedCustomer(customer)
            }}
          />

          <form className="form" id="form">

            {/* Starts, Card Holder Name */}
            <div className="form-group mb-2">
              <div className="input-group with-append">
                <div id="cc-name" className="form-control h-auto border-0" data-cc-name />
                <div className="input-group-append input-group-pill">
                  <span className="input-group-text input-group-payment-form">
                    <span className="svg-icon svg-icon-white svg-icon-2x">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1">
                            <g stroke="none"
                               strokeWidth="1"
                               fill="none"
                               fillRule="evenodd">
                                <mask fill="white">
                                    <use xlinkHref="#path-1"/>
                                </mask>
                                <g/>
                                <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"/>
                            </g>
                      </svg>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/* end, Card Holder Name */}

            {/* Starts: Card Number, exp, cvv */}
            <div className="form-group mb-2">
              <div className="input-group with-append">

                  <div className="form-control h-auto border-0">

                    <div className="row">
                      <div id="cc-card" className="col-5 col-md-7 data cc-card" data-cc-card />
                      <div id="cc-exp" className="col-4 col-md-3 data cc-exp" data-cc-exp />
                      <div id="cc-cvv" className="col-3 col-md-2 data cc-cvv" style={{paddingRight: 0}}>
                        <input
                          className="border-0 w-100 px-0"
                          inputmode="numeric"
                          placeholder="CVV"
                          onChange={(e) => {
                            this.setState({ cvv: e.target.value })
                          }}
                        />
                      </div>
                    </div>

                  </div>

                  <div className="input-group-append input-group-pill">
                    <span className="input-group-text input-group-payment-form">
                      <span className="svg-icon svg-icon-white svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1">
                                <g stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd">
                                    <mask fill="white">
                                        <use xlinkHref="#path-1"/>
                                    </mask>
                                    <g/>
                                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"/>
                                </g>
                        </svg>
                      </span>
                    </span>
                  </div>

              </div>
            </div>
            {/* End: Card Number, exp, cvv */}

            <PayButton
              payButtonTitle={this.props.payButtonTitle}
              payButtonContainerId={this.props.payButtonContainerId}
              backgroundColor={company.settings.primary_color}
              paymentPlan={this.state.paymentPlan}
              onClick={() => {
                this.props.onPayPressed(paymentMethod)
              }}
            />

          </form>

        </div>
      </PaymentMethodPortal>
    )
  }

}
