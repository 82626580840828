import React from "react"

import Div from "../layouts/Div"

import BankAccountModal from "../modal/BankAccountModal"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class StripeStandardConnectSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount(){
    this._load()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const status = params.get('s')

    if(status === "t"){
      Notify.success("Stripe account successfully updated")
    }
    else if(status === "f"){
      Notify.error("Stripe account not updated")
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _load(){
    Backend.getConnectData(this.props.processorSlug)
    .then(data => {
      this.setState({ data, loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _goToStripe(){
    this.setState({ loading: true })
    Backend.getConnectData(this.props.processorSlug)
    .then(data => {
      if(data.activation_link){
        window.location = data.activation_link
      }
      this.setState({ data, loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }


  render() {
    let {
      data,
      loading,
    } = this.state

    if(!data){
      return null
    }

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <Div
                className="kt-wizard-v2__content"
                data-ktwizard-type="step-content"
                data-ktwizard-state="current"
                disabled={loading}
                spinner={true}
              >
                <h4 className="mb-10 font-weight-bold text-dark">Bank Details</h4>
                <div className="kt-form__section kt-form__section--first">
                  <div className="kt-wizard-v2__form">
                    { data.requirements.eventually_due.length > 0 &&
                      <p>
                        Our Payment processor, Stripe, requires more information about your business, please click below to provide it.
                      </p>
                    }
                    { data.requirements.eventually_due.length === 0 &&
                      <p>
                        Your account is fully connected to Stripe, click below to visit your Stripe dashboard
                      </p>
                    }
                  </div>
                </div>
              </Div>
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                { data.requirements.eventually_due.length > 0 &&
                  <button
                    className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    onClick={e => {
                      e.preventDefault()
                      this._goToStripe()
                    }}
                  >
                    Go to Stripe
                  </button>
                }
                { data.requirements.eventually_due.length === 0 &&
                  <a
                    href="https://dashboard.stripe.com"
                    target="_blank"
                    className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  >
                    Visit Stripe Dashboard
                  </a>
                }
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
}
