import React, { Component } from "react";

import Currency from "../../../utils/Currency";

import CustomerCell from "../tables/cells/CustomerCell";
import DateCell from "../tables/cells/DateCell";
import PillCell from "../tables/cells/PillCell";

import Utils from "./Utils";

import PaymentAttemptCell from "./PaymentAttemptCell";

export default class PaymentAttemptItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentAttempt: props.paymentAttempt,
    };
  }

  _renderType(paymentAttempt) {
    if (!paymentAttempt) {
      return "-";
    }

    let text = paymentAttempt.data?.payment_plan.product
      ? "Shop"
      : paymentAttempt.data.payment_plan?.payment_request
      ? "Request"
      : "Take";

    let className = paymentAttempt.data.payment_plan.product
      ? "label-light-info"
      : paymentAttempt.data.payment_plan.payment_request
      ? "label-light-danger"
      : "label-light-primary";

    let type = <PillCell text={text} className={className} />;

    return type;
  }

  _renderFrequency(paymentAttempt) {
    if (!paymentAttempt) {
      return "-";
    }

    let paymentPlan = paymentAttempt.data.payment_plan;

    if (paymentPlan.type === "one_time" && paymentPlan.frequency === null) {
      return "";
    } else if (paymentPlan.type === "recurring" && paymentPlan.frequency) {
      let interval = paymentPlan.frequency.charAt(0);
      return `p/${interval}`;
    }

    return "";
  }

  _renderAmount(paymentAttempt) {
    if (!paymentAttempt) {
      return "-";
    }

    let paymentPlan = paymentAttempt.data.payment_plan;

    let amount = Currency.format(
      paymentPlan._original.currency.symbol,
      paymentPlan.total
    );

    return amount;
  }

  render() {
    const { paymentAttempt } = this.state;

    let amount = this._renderAmount(paymentAttempt);

    let frequency = this._renderFrequency(paymentAttempt);

    let status = Utils.getStatus(paymentAttempt);

    let type = this._renderType(paymentAttempt);

    let customerDetails = paymentAttempt.data?.customer ? (
      <CustomerCell
        customer={paymentAttempt.data.customer}
        className="ml-0"
      />
    ) : (
      "-"
    );

    let date = paymentAttempt?.created_at && (
      <DateCell date={paymentAttempt.created_at} />
    );

    return (
      <div className="rt-tr-group" role="rowgroup">
        <div
          className="rt-tr -odd"
          role="row"
          style={{
            color: "black",
            paddingTop: 10,
            paddingBottom: 10,
            background:
              this.props.selectedPaymentAttempt.id == paymentAttempt.id &&
              "whitesmoke",
          }}
          onClick={() => {
            this.props.onClick(paymentAttempt);
          }}
        >
          <PaymentAttemptCell data={status} />
          <PaymentAttemptCell data={customerDetails} />
          <PaymentAttemptCell data={`${amount} ${frequency}`} />
          <PaymentAttemptCell data={type} />
          <PaymentAttemptCell data={date} />
        </div>
        <div className="separator separator-solid my-0" />
      </div>
    );
  }
}
