import AuthManager from './AuthManager'

export default class Permissions {
  static EXPORT = 'export'
  static PAYMENT = 'payment'
  static PAYMENT_LINK = 'payment_link'
  static PAYMENT_REQUEST = 'payment_request'
  static PRODUCT = 'product'
  static QR_CODE = 'qr_code'
  static ROLES = 'roles'
  static SETTINGS = 'settings'
  static STATS = 'stats'
  static TEAM_MEMBER = 'team_member'
  static RECURRING = 'subscription'


  static DEFAULT_ROLE = {
    name: null,
    default: false,
    permissions: {
      export: [],
      payment: [],
      payment_link: [],
      payment_request: [],
      product: [],
      qr_code: [],
      roles: [],
      settings: [],
      stats: [],
      team_member: [],
      subscription: [],
    },
  }

  static PERMISSION_TYPES = {
    none: null,
    view_owned: 'view_owned',
    view_all: 'view_all',
    edit_all: 'edit_all',
    edit_owned: 'edit_owned',
    delete_all: 'delete_all',
    create: 'create',
    view_all_create: 'view_all,create',
    view_owned_create: 'view_owned,create',
    view_all_create_delete: 'view_all,create,delete_all',
    view_create_edit_owned: 'view_owned,create,edit_owned',
    view_all_create_edit_delete: 'view_all,create,edit_all,delete_all',
  }

  static NONE_OPTION = {
    label: 'None',
    value: Permissions.PERMISSION_TYPES.none,
  }

  static OPTIONS = [
    {
      label: 'Take Payment',
      key: Permissions.PAYMENT,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View Owner',
          value: Permissions.PERMISSION_TYPES.view_owned,
        },
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View and Create (Owner)',
          value: Permissions.PERMISSION_TYPES.view_owned_create,
        },
        {
          label: 'View and Create (All)',
          value: Permissions.PERMISSION_TYPES.view_all_create,
        },
        {
          label: 'View, Create and Refund',
          value: Permissions.PERMISSION_TYPES.view_all_create_delete,
        },
      ],
    },
    {
      label: 'Payment Request',
      key: Permissions.PAYMENT_REQUEST,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View Owner',
          value: Permissions.PERMISSION_TYPES.view_owned,
        },
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View and Create (All)',
          value: Permissions.PERMISSION_TYPES.view_all_create,
        },
        {
          label: 'View and Create (Owner)',
          value: Permissions.PERMISSION_TYPES.view_create_edit_owned,
        },
        {
          label: 'View, Create, Manage and Archive',
          value: Permissions.PERMISSION_TYPES.view_all_create_edit_delete,
        },
      ],
    },
    {
      label: 'Shop Items',
      key: Permissions.PRODUCT,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View Owner',
          value: Permissions.PERMISSION_TYPES.view_owned,
        },
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View and Create (All)',
          value: Permissions.PERMISSION_TYPES.view_all_create,
        },
        {
          label: 'View, Create, Manage and Archive',
          value: Permissions.PERMISSION_TYPES.view_all_create_edit_delete,
        },
      ],
    },
    {
      label: 'Channel',
      key: Permissions.PAYMENT_LINK,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View Owner',
          value: Permissions.PERMISSION_TYPES.view_owned,
        },
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View and Create (All)',
          value: Permissions.PERMISSION_TYPES.view_all_create,
        },
        {
          label: 'View, Create, Manage and Archive',
          value: Permissions.PERMISSION_TYPES.view_all_create_edit_delete,
        },
      ],
    },
    {
      label: 'Recurring',
      key: Permissions.RECURRING,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View (Owner)',
          value: Permissions.PERMISSION_TYPES.view_owned,
        },
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View, Edit and Cancel (Owner)',
          value: "view_owned,edit_owned,delete_owned",
        },
        {
          label: 'View, Edit and Cancel (All)',
          value: "view_all,edit_all,delete_all",
        },
      ],
    },
    {
      label: 'Export',
      key: Permissions.EXPORT,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'Create',
          value: Permissions.PERMISSION_TYPES.create,
        },
      ],
    },
    {
      label: 'QR Code',
      key: Permissions.QR_CODE,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View Owner',
          value: Permissions.PERMISSION_TYPES.view_owned,
        },
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View and Create (All)',
          value: Permissions.PERMISSION_TYPES.view_all_create,
        },
        {
          label: 'View, Create, Manage and Archive',
          value: Permissions.PERMISSION_TYPES.view_all_create_edit_delete,
        },
      ],
    },
    {
      label: 'Settings',
      key: Permissions.SETTINGS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'Edit',
          value: Permissions.PERMISSION_TYPES.edit_all,
        },
      ],
    },
    {
      label: 'Team Member',
      key: Permissions.TEAM_MEMBER,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View and Manage',
          value: Permissions.PERMISSION_TYPES.view_all_create_edit_delete,
        },
      ],
    },
    {
      label: 'Stats',
      key: Permissions.STATS,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
      ],
    },
    {
      label: 'Role',
      key: Permissions.ROLES,
      options: [
        Permissions.NONE_OPTION,
        {
          label: 'View (All)',
          value: Permissions.PERMISSION_TYPES.view_all,
        },
        {
          label: 'View and Manage',
          value: Permissions.PERMISSION_TYPES.view_all_create_edit_delete,
        },
      ],
    },
  ]

  static hasViewPermission(key) {
    if (!key) {
      return false
    }
    let { permissions } = AuthManager.currentUser._role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    let hasPermission = false
    if (permission.includes('view_all') === true) {
      hasPermission = true
    }
    if (permission.includes('view_owned') === true) {
      hasPermission = true
    }
    return hasPermission
  }

  static hasCreatePermission(key) {
    let { permissions } = AuthManager.currentUser._role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    return permission.includes('create') === true
  }

  static hasEditPermission(key) {
    let { permissions } = AuthManager.currentUser._role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    let hasPermission = false
    if (permission.includes('edit_owned') === true) {
      hasPermission = true
    }
    if (permission.includes('edit_all') === true) {
      hasPermission = true
    }
    return hasPermission
  }

  static hasDeletePermission(key) {
    let { permissions } = AuthManager.currentUser._role
    let permission = permissions[key]
    if (!permission) {
      return false
    }
    return permission.includes('delete_all') === true
  }
}
