export default class Currency {
  static format(symbol, amount){
    if(!amount) return `${symbol ? symbol : ''}0`
    let value = parseFloat(amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    let last2 = value.slice(-2)
    value = last2 === '00' ? value.slice(0, -3) : value
    return `${symbol ? symbol : ''}${value ? value : ''}`
  }

  static parseNumber(value, locale = navigator.language) {
    if(!value){
      return 0
    }

    const example = Intl.NumberFormat(locale).format('1.1');
    const cleanPattern = new RegExp(`[^-+0-9${ example.charAt( 1 ) }]`, 'g');
    const cleaned = value.toString().replace(cleanPattern, '');
    const normalized = cleaned.replace(example.charAt(1), '.');

    return parseFloat(normalized);
  }
}
