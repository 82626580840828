import React from "react";

import { withRouter } from "react-router-dom";

import Alert from "./Alert";

import AuthManager from "../../../utils/AuthManager";

class BillingAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethodAdded: AuthManager.currentUser.company.subscription?.payment_instrument_data != null,
    };
  }

  render() {
    let { paymentMethodAdded } = this.state;
    if (paymentMethodAdded || window.location.pathname == "/billing") {
      return null;
    }

    let text = "Add a payment method to ensure your account remains active";
    let cta = "Go To Billing";
    let onClick = () => this.props.history.push("/billing");

    return <Alert text={text} cta={cta} closable={false} onClick={onClick} />;
  }
}

export default withRouter(BillingAlert);
