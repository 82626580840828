import React from "react"

import ImageEdit from "../common/ImageEdit";
import ColorPicker from "../common/ColorPicker";

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"
import Event from "../../../utils/Event"

export default class CustomerEmailsSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: props.loading,
      company: props.company,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  componentDidMount() {
    if(!AuthManager.currentUser.company.add_ons.custom_emails){
      Event.emit('ADD_ON_ALERT', '/settings/general')
    }
  }

  render() {
    let {
      company,
      loading
    } = this.state

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <h4 className="mb-10 font-weight-bold text-dark">Thank You Email</h4>

              <p>An automatic 'Thank You' email is sent to your customers when they make a payment to you. You can
                customise the content of the 'Thank You' email below. {window.General.Branding.Name} will automatically include the payment
                amount at the end of your 'Thank You' email.</p>
              <div className="form-group row">
                <label className="col-3 col-form-label">Subject</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder={`Thank you for your payment to ${company.name}`}
                    value={company.settings.payment_success_email_subject}
                    onChange={e => {
                      company.settings.payment_success_email_subject = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Email Body</label>
                <div className="col-9">
                  <textarea
                    className="form-control form-control-solid"
                    value={company.settings.payment_success_email_body}
                    onChange={e => {
                      company.settings.payment_success_email_body = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">Enter the content above for your customer's 'Thank You' email.</span>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-3 col-form-label">Custom Branding</label>
              <div className="col-9">
                <div className="select-wrapper">
                  <select
                    className="form-control form-control-solid field-choice"
                    value={company.settings.branded_emails}
                    onChange={e => {
                      company.settings.branded_emails = e.target.value == "true"
                      this.setState({ company })
                    }}
                  >
                    <option value="false">No, Use {window.General.Branding.Name} Branding</option>
                    <option value="true">Yes, Use My Company Branding</option>
                  </select>
                </div>
              </div>
            </div>

            { company.settings.branded_emails &&
              <>
                <div className="form-group row">
                  <label className="col-3 col-form-label">Email Banner Logo</label>
                  <div className="col-9 mb-4">
                    <ImageEdit
                      image={company.settings.logo_banner}
                      hidePreview={false}
                      deletable={true}
                      cropImage={true}
                      aspectRatio={4/1}
                      onUpdated={image => {
                        this.setState({loading: false})
                        company.settings.logo_banner = image
                        this.props.onUpdated(company)
                      }}
                      onDeleteClicked={() => {
                        company.settings.logo_banner = null
                        this.props.onUpdated(company)
                      }}
                    />
                    <span className="form-text text-muted">Your logo should be clearly visible when placed above your main colour</span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Button Text Colour</label>
                  <div className="col-9 mb-4">
                    <ColorPicker
                      color={company.settings.text_on_primary_color_for_mail}
                      onColorChange={color => {
                        company.settings.text_on_primary_color_for_mail = color.hex
                        this.props.onUpdated(company)
                      }}
                    />
                    <span className="form-text text-muted">The colour of text placed above your main colour</span>
                  </div>
                </div>
              </>
            }

            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    this.props.onSave()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
      </>
    )
  }
}
