import React from 'react'
import { withRouter } from 'react-router-dom'

import moment from "moment"

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'
import Notify from '../../../utils/Notify'
import CompanyProcessor from '../../../utils/CompanyProcessor'

const STRIPE_LOGO = require("../../../assets/media/icons/processors/stripe.png")

const PLAID_LOGO = require("../../../assets/media/icons/processors/plaid.png")

const PAYPAL_LOGO = require("../../../assets/media/icons/processors/paypal.png")

const AIB_LOGO = require("../../../assets/media/icons/processors/aib.png")

const STRIPE = {
  logo: STRIPE_LOGO,
  name: "Stripe",
  slug: "stripe_standard",
  url: "https://stripe.com",
  subline: "Take payments in minutes with the world's leading payment processor",
  setupTime: " 5 - 10 mins",
  paymentMethods: ["card", "apple", "google", "sepa"],
  enabled: true,
  getData: (currentUser) => {

    let { user, company } = currentUser
    let { address, date_of_birth } = user

    let data = {
      country_short: "ie",
      currency: company.settings.payment_plan.currency.code,
      business_type: 'individual',
      persons: [],
      representative: {
        first_name: user.first_name,
        last_name: user.last_name,
        // Stripe's phone validation is more strict than Api's currently so
        // we dont want to send phone in case it's invalid
        // phone: `${user.phone_country_code}${user.phone_number}`,
        relationship: {
          director: false,
          executive: false,
          owner: false,
        },
      },
    }

    if(address){
      data.country_short = address.country_short
      data.representative.address = {
        line1: address.line_1,
        line2: address.line_2,
        city: address.city,
        state: address.state,
        country: address.country_short,
        country_short: address.country_short,
        postal_code: address.postal_code
      }

      if(date_of_birth){
        let dob = moment(date_of_birth)
        data.representative.dob = {
          year: dob.format('YYYY'),
          day: dob.format('D'),
          month: dob.format('M')
        }
      }
    }

    return data
  }
}

const PAYPAL = {
  logo: PAYPAL_LOGO,
  name: "Paypal",
  slug: "paypal",
  url: "https://paypal.com",
  subline: "Accept payments from PayPal accounts",
  setupTime: "5 - 10 mins",
  paymentMethods: ["paypal"],
  enabled: false,
}

const PLAID = {
  logo: PLAID_LOGO,
  name: "Plaid",
  slug: "plaid",
  url: "https://plaid.com",
  subline: "Accept payments directly from your users bank accounts",
  setupTime: "5 - 10 mins",
  paymentMethods: ["bank"],
  enabled: false,
}

const AIB = {
  logo: AIB_LOGO,
  name: "AIB",
  slug: "aib",
  url: "https://aibms.com",
  subline: "Connect your AIB Merchant Services account to begin accepting card payments",
  setupTime: "5 - 10 mins",
  paymentMethods: ["card"],
  enabled: false,
}

const PROCESSORS = [
  STRIPE,
  PLAID,
  PAYPAL,
  AIB
]

class Progress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: props.company,
      progress: props.progress,
    }
  }

  _connect(processor){
    this.setState({ loading: true })
    let data = processor.getData(AuthManager.currentUser)

    Backend.connectProcessor(processor.slug, data)
    .then(data => {
      if(data.activation_link){
        window.location = data.activation_link
      }
      else{
        window.location.reload()
      }
      this.setState({ loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _renderProcessor(processor) {
    let {
      loading
    } = this.state
    return (
      <div className="d-flex todo-card align-items-center justify-content-between p-1 pl-5 pr-5 mb-10">
        <div className="d-flex align-items-center mr-2">
          <div className="symbol symbol-40 mr-3 flex-shrink-0">
            <div className="symbol-label">
              <img
                src={processor.logo}
                width="40px"
                height="40px"
              />
            </div>
          </div>
          <div>
            <a
              href={processor.url}
              target="_blank"
              rel="noopener noreferrer"
              className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
            >
              { processor.name }
            </a>
            <div className="font-size-sm text-muted font-weight-bold mt-1">
              { processor.subline }
            </div>
          </div>
        </div>
        { processor.enabled &&
          <button
            className="btn btn-primary w-150px font-weight-bold py-4 px-3 font-size-base"
            onClick={() => this._connect(processor)}
            disabled={loading}
          >
            Connect
          </button>
        }
        { !processor.enabled &&
          <button
            href="javascript:;"
            className="btn btn-secondary w-150px font-weight-bold py-4 px-3 font-size-base"
            onClick={() => window.Intercom("show")}
            disabled={loading}
          >
            Contact Us
          </button>
        }
      </div>
    )
  }

  render() {
    let { progress, data } = this.state

    return (
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body d-flex p-0 card-rounded">
          <div className="flex-grow-1 p-8 pt-5 card-rounded flex-grow-1 bgi-no-repeat">
            <h4 className="text-dark">
              You're{" "}
              <span
                id="progress-percent"
                className="text-primary font-weight-bolder"
              >
                80
              </span>
              <span className="text-primary font-weight-bolder">%</span> of the
              way there!
            </h4>
            <span className="text-muted font-size-lg font-weight-bold mb-2">
              Choose a processor to begin taking payments
            </span>
            <div className="progress mt-5">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                role="progressbar"
                style={{ width: '80%' }}
                aria-valuenow={80}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <div className="mt-15">
              <br/>
              { PROCESSORS.map(processor => this._renderProcessor(processor)) }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Progress)
