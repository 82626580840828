import React from 'react'
import StatCard from './components/common/StatCard'
import DateRange from './components/common/DateRange'
import PaymentsTable from './components/tables/PaymentsTable'
import Backend from '../utils/Backend'
import Currency from '../utils/Currency'
import PaymentRequestsTable from './components/tables/PaymentRequestsTable'
import AuthManager from '../utils/AuthManager'
import Event from '../utils/Event'
import Permissions from '../utils/Permissions'

export default class Payments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currency: 0,
      stats: undefined,
      dateFrom: null,
      dateTo: null,
    }
  }

  componentDidMount() {
    if (!AuthManager.currentUser.company.add_ons.payment_dashboard) {
      Event.emit('ADD_ON_ALERT', '/')
    }
    this._loadStats()
  }

  _loadStats() {
    let { dateFrom, dateTo } = this.state

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      this.setState({ stats: { } })
      return
    }

    Backend.getPaymentStats(
      dateFrom?.toISOString() || '',
      dateTo?.toISOString() || ''
    ).then((stats) => {
      this.setState({
        stats: stats.company,
      })
    })
  }

  _renderStatCards() {
    let { stats } = this.state

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      return null
    }

    let week = stats?.processed.week.reduce(
      (accum, item) => accum + item.count,
      0
    )
    let month = stats?.processed.week.reduce(
      (accum, item) => accum + item.count,
      0
    )
    let total = stats?.total_value_processed.reduce(
      (accum, item) => accum + item.count,
      0
    )

    return (
      <>
        <StatCard
          amount={week}
          title={'Last 7 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={month}
          title={'Last 28 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard amount={total} title={'Total'} icon={'chart'} plain={true} />
      </>
    )
  }

  render() {
    let { dateFrom, dateTo, stats } = this.state

    if (!stats) return null

    let showPayments = false
    if(stats?.total_value_processed){
      showPayments = stats?.total_value_processed.reduce(
        (accum, item) => accum + item.count,
        0
      ) || 0
    }
    return (
      <>
        {Permissions.hasViewPermission(Permissions.STATS) && (
          <DateRange
            dateFrom={dateFrom}
            dateTo={dateTo}
            onFromDateUpdated={(dateFrom) => {
              this.setState(
                {
                  dateFrom,
                },
                () => this._loadStats()
              )
            }}
            onToDateUpdated={(dateTo) => {
              this.setState(
                {
                  dateTo,
                },
                () => this._loadStats()
              )
            }}
          />
        )}
        <div className={'row'}>{this._renderStatCards()}</div>
        <PaymentsTable
          title={'All Payments'}
          dateFrom={dateFrom}
          dateTo={dateTo}
          paymentsProcessed={showPayments}
          loadStats={() => this._loadStats()}
        />
      </>
    )
  }
}
