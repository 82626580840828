import React from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from "moment";
import XLSX from 'xlsx'

import Flatpickr from "react-flatpickr";

import BulkUploadTable from "../bulkUpload/BulkUploadTable";
import AsyncSelect from "../common/AsyncSelect";

import Backend from "../../../utils/Backend"
import Notify from "../../../utils/Notify"
import BulkUpload from "../../../utils/BulkUpload";
import Event from "../../../utils/Event";
import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";

import { TAX } from '../../../constants/Variables';

export default class BulkUploadRequestsModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      company: AuthManager.currentUser.company,
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      data: {
        type: "one_time",
        frequency: null,
        installments: null,
        send_sms: true,
        send_email: true,
        vatEnabled: "on",
        vat_percentage: 0},
    }
  }

  _isFormValid(){
    let {
      data,
      invalidFixedSplinksData
    } = this.state

    let error = null

    if(invalidFixedSplinksData == null){
      error = "Please select a file"
    }
    else if(!data.currency){
      error = "Please select a currency"
    }
    else if(data.type === "recurring"){
      if(!data.frequency){
        error = "Please select a frequency"
      }
      else if(data.installments === "false"){
        error = "Please select the number of installments"
      }
      else if(data.installments < 2){
        error = "Installments must be 2 or more"
      }
    }

    if(!error){
      for(let i = 0; i < invalidFixedSplinksData.length; i++){
        let lineNumber = General.toOrdinal(i+1)
        console.log("SS", invalidFixedSplinksData[i])
        if(!invalidFixedSplinksData[i].amount){
          error = `Please include the amount on the ${lineNumber} item on the invalid payment requests`
        }else if(!invalidFixedSplinksData[i].customer){
          error = `Please include the customer on the ${lineNumber} item on the invalid payment requests`
        }else if(!invalidFixedSplinksData[i].email){
          error = `Please include the email on the ${lineNumber} item on the invalid payment requests`
        }else if(!invalidFixedSplinksData[i].dueDate){
          error = `Please include the due date on the ${lineNumber} item on the invalid payment requests`
        }else if(!invalidFixedSplinksData[i].description){
          error = `Please include the description on the ${lineNumber} item on the invalid payment requests`
        }
        if(error) break
      }
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleCreateBulkUpload(){
    let {
      data,
      validFixedSplinksData
    } = this.state

    data.requests = validFixedSplinksData

    this.setState({data})

    if(!this._isFormValid()){
      return
    }
    this._createBulkRequests()
  }

  _createBulkRequests(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.createBulkRequests(data)
    .then(requests => {
      Notify.success("Bulk requests created successfully")
      this.setState({ loading: false })
      this.props.onAdded(requests)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _handleFileUpload(e){
    let file  = e.target.files[0]

    let reader = new FileReader();
    reader.onload = (e) => {
      this._handleFileProcessing(e)
    }
    reader.readAsBinaryString(file)
  }

  _handleFileProcessing(e){
    let data = e.target.result
    let [headers, validFixedSplinksData, invalidFixedSplinksData] = BulkUpload.formatData(data, this.state.data.dueDate)

    this.setState({
      headers,
      validFixedSplinksData,
      invalidFixedSplinksData
    });
  }

  _checkValidRows(){
    let {
      validFixedSplinksData,
      invalidFixedSplinksData
    } = this.state

    const validTimer = setTimeout(() => {
      for(let i = validFixedSplinksData.length - 1; i >= 0; --i){
        if(!BulkUpload.isValid(validFixedSplinksData[i])){
          invalidFixedSplinksData.unshift(validFixedSplinksData[i])
          validFixedSplinksData.splice(i, 1)
        }
      }

      this.setState({
        validFixedSplinksData,
        invalidFixedSplinksData
      })
    }, 1500)
    return () => clearTimeout(validTimer)

  }

  _checkInvalidRows(){
    let {
      validFixedSplinksData,
      invalidFixedSplinksData
    } = this.state

    const invalidTimer = setTimeout(() => {
      for(let i = invalidFixedSplinksData.length - 1; i >= 0; --i){
        if(BulkUpload.isValid(invalidFixedSplinksData[i])){
          validFixedSplinksData.unshift(invalidFixedSplinksData[i])
          invalidFixedSplinksData.splice(i, 1)
        }
      }

      this.setState({
        validFixedSplinksData,
        invalidFixedSplinksData
      })
    }, 1500)
    return () => clearTimeout(invalidTimer)

  }

  render() {
    let {
      show,
      data,
      headers,
      company,
      loading,
      validFixedSplinksData,
      invalidFixedSplinksData
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-xl'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Upload Requests</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <div className="col-12">
              <div className="card card-custom mb-8 mb-lg-0">
                <div className="card-body">
                  <div className="d-flex align-items-center p-5">
                    <div className="mr-6">
                    <span className="svg-icon svg-icon-4x">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                           height="24px" viewBox="0 0 24 24" version="1.1">
                         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                             <rect x="0" y="0" width="24" height="24"></rect>
                             <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                             <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"></rect>
                             <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"></rect>
                         </g>
                     </svg>
                    </span>
                    </div>
                    <div className="d-flex flex-column">
                      <a href="#" className="text-dark font-weight-bold font-size-h4 mb-3">
                        How to bulk upload
                      </a>
                      <div className="text-dark-75">
                        <a href="assets/media/template.xlsx?v=1">Download the template</a> and fill in your list debtors in the format below. We'll automatically send out a payment
                        request to each of your debtors. We'll also send them gentle reminders if they don't pay
                        before your due date.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <img src="assets/media/excel-list.png" width="100%" style={{marginTop: 30}}
                           alt="Demo excel file example"/>
                    </div>
                    <div className="col-6">
                      <p className="text-dark font-weight-bold">Please upload an excel file with the following
                        columns:</p>
                      <ul>
                        <li><span className="text-dark font-weight-bold">Email:</span> Any valid email address.</li>
                        <li><span className="text-dark font-weight-bold">Customer:</span> Any value that identifies
                          the customer to you (optional).
                        </li>
                        <li><span className="text-dark font-weight-bold">Amount:</span> Any valid amount.</li>
                        <li><span className="text-dark font-weight-bold">Description:</span> Plain text description.
                        </li>
                        <li><span className="text-dark font-weight-bold">Due Date:</span> Any date in the
                          format dd/mm/yyyy.
                        </li>
                        <li><span className="text-dark font-weight-bold">SMS:</span> Mobile phone number (optional).
                        </li>
                      </ul>
                      <p>All other columns in your Excel file will be added as Additional Data.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            !headers && !validFixedSplinksData &&
            <div className="form-group row">
              <label className="col-3 col-form-label">Excel File*</label>
              <div className="col-9">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => {
                      this._handleFileUpload(e)
                    }}
                  />
                  <label className="custom-file-label">Choose File</label>
                </div>
                <span className="form-text text-muted">Accepted file types: .xls, .xlsx</span>
              </div>
            </div>
          }

          <div className="form-group row">
            <label className="col-3 col-form-label">Due Date</label>
            <div className="col-9">
              <div className="input-group date">

                <div className="input-group-prepend">
                  <span className="input-group-text border-0">
                    <i className="la la-calendar"></i>
                  </span>
                </div>

                <Flatpickr
                  className="form-control form-control-lg form-control-solid"
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    dateFormat: "Y-m-d"
                  }}
                  onChange={dates => {
                    let globalDate = moment(dates[0]).format('YYYY-MM-DDThh:mm')
                    data.dueDate = globalDate
                    if(validFixedSplinksData){
                      validFixedSplinksData.map(splink => {
                        splink.dueDate = globalDate
                      })
                    }
                    if(invalidFixedSplinksData){
                      invalidFixedSplinksData.map(splink => {
                        splink.dueDate = globalDate
                      })
                    }
                    this.setState({
                      validFixedSplinksData,
                      invalidFixedSplinksData,
                      data
                    })
                  }}
                />

              </div>
              <span className="form-text text-muted">We'll send each debtor gentle reminders if they forget to pay before the due date. If specified this will overwrite any individual due dates set</span>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Currency*</label>
            <div className="col-9">
              <AsyncSelect
                endpoint={window.Api.Currencies}
                classNamePrefix={'custom-async-select'}
                params={{ }}
                orderBy="name"
                onSelected={currency => {
                  data.currency = currency
                  this.setState({ data })
                }}
                getOptions={currencies => General.getAsyncOptions(currencies)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">{TAX}</label>
            <div className="col-9">
              <div className="radio-inline"
                   onChange={e => {
                     if(e.target.value === "off"){
                       data["vat_percentage"] = company.settings.vat_rate
                     }
                     data.vatEnabled = e.target.value
                     this.setState({ data })
                   }}
              >
                <label className="radio">
                  <input
                    type="radio"
                    name="vat"
                    value={"on"}
                    checked={data.vatEnabled === "on"}
                  />
                  {TAX} is already included
                  <span></span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="vat"
                    value={"off"}
                    checked={data.vatEnabled === "off"}
                  />
                  I need to add {TAX}
                  <span></span>
                </label>
              </div>
              <span className="form-text text-muted">Please set the correct {TAX} option. </span>
            </div>
          </div>

          {
            data.vatEnabled === "off" &&
            <>
              <div className="form-group row">
                <div className="col-3"></div>
                <div className="col-9">
                  <div className="row" id="vatOptions">
                    <div className="col-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{border: 'none'}}>%</span>
                        </div>
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder="0"
                          name={"vat"}
                          value={data.vat_percentage * 100}
                          onChange={(e) => {
                            data["vat_percentage"] = e.target.value / 100
                            this.setState({ data })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

          <div className="form-group row">
            <label className="col-3 col-form-label">Payment Type</label>
            <div className="col-9">
              <div className="radio-inline mt-2"
                   onChange={e => {
                     if(!AuthManager.currentUser.company.add_ons.recurring_payments && e.target.value === "recurring"){
                       Event.emit('ADD_ON_ALERT')
                       return
                     }
                     if(e.target.value === "one_time"){
                       data.type = "one_time"
                       data.frequency = null
                       data.installments = null
                     }else if(e.target.value === "recurring"){
                       data.type = "recurring"
                       data.frequency = null
                       data.installments = null
                     }else{
                       data.type = "optional"
                       data.frequency = null
                       data.installments = null
                     }
                     this.setState({ data })
                   }}
              >
                <label className="radio">
                  <input
                    type="radio"
                    name="paymentType"
                    value={"one_time"}
                    checked={data.type === "one_time"}
                  />
                  One Time Payment <span></span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="paymentType"
                    value={"recurring"}
                    checked={data.type === "recurring"}
                  />
                  Recurring / Installment Plan <span></span>
                </label>
              </div>
            </div>
          </div>

        {
          data.type === "recurring" &&
          <>
            <div className="form-group row">
              <label className="col-3 col-form-label">Period</label>
              <div className="col-9">
                <div className="select-wrapper">
                  <select
                    name="frequency"
                    className="form-control"
                    value={data.frequency}
                    onChange={e => {
                      data.frequency = e.target.value
                      this.setState({ data })
                    }}
                  >
                    <option>Select Frequency</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly" selected="">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-3 col-form-label">Installments</label>
              <div className="col-9">
                <input
                  className="form-control form-control-solid"
                  type="number"
                  min={2}
                  placeholder="Leave blank for continuous"
                  name={"installments"}
                  value={data.installments}
                  onChange={(e) => {
                    data.installments = e.target.value || null
                    this.setState({ data })
                  }}
                />
              </div>
            </div>
            </>
          }

          <div className="form-group row">
            <label className="col-3 col-form-label">Send SMS</label>
            <div className="col-9">
              <span className="switch switch-icon">
                <label>
                 <input
                   type="checkbox"
                   name="select"
                   checked={data.send_sms}
                   onChange={e => {
                     data.send_sms = e.target.checked
                     this.setState({data})
                   }}
                 />
                 <span></span>
                </label>
              </span>
              <span className="form-text text-muted">Send SMS to debtors when payment requests have been created. </span>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Send Email</label>
            <div className="col-9">
              <span className="switch switch-icon">
                <label>
                 <input
                   type="checkbox"
                   name="select"
                   checked={data.send_email}
                   onChange={e => {
                     data.send_email = e.target.checked
                     this.setState({data})
                   }}
                 />
                 <span></span>
                </label>
              </span>
              <span className="form-text text-muted">Send Email to debtors when payment requests have been created. </span>
            </div>
          </div>

          {
            headers && validFixedSplinksData &&
              <>
                <ul className={"nav nav-tabs nav-tabs-line"}>
                  <li className={"nav-item"}>
                    <a className={"nav-link active"} data-toggle={"tab"} href={"#validData"}>Valid ({validFixedSplinksData.filter((entry) => { return entry != null }).length})</a>
                  </li>
                  <li className={"nav-item"}>
                    <a className={"nav-link invalid"} data-toggle={"tab"} href={"#invalidData"}>Invalid ({invalidFixedSplinksData.filter((entry) => { return entry != null }).length})</a>
                  </li>
                </ul>
                <div className={"tab-content mt-5"}>
                  <div className={"tab-pane fade show active"} id={"validData"} role={"tabpanel"}>
                    <BulkUploadTable
                      headers={headers}
                      rows={validFixedSplinksData}
                      onUpdated={rows => {
                        this.setState({
                          validFixedSplinksData: rows
                        }, () => {
                          this._checkValidRows()
                        })
                      }}
                    />
                  </div>
                  <div className={"tab-pane fade"} id={"invalidData"} role={"tabpanel"}>
                    <BulkUploadTable
                      headers={headers}
                      rows={invalidFixedSplinksData}
                      onUpdated={rows => {
                        this.setState({
                          invalidFixedSplinksData: rows
                        }, () => {
                          this._checkInvalidRows()
                        })
                      }}
                    />
                  </div>
                </div>
              </>
          }

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleCreateBulkUpload()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            Create
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
