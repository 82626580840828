import React from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      width: props.width,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { dateFrom, dateTo, width } = this.state;

    let cssClass = "col-lg-3";
    if (width === "full") {
      cssClass = "col";
    }

    return (
      <div className="row mb-4">
        <div className={cssClass}>
          <div className="input-group date">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="la la-calendar"></i>
              </span>
            </div>
            <Flatpickr
              value={dateFrom ? dateFrom.format("DD-MM-YYYY") : null}
              className="form-control"
              placeholder="From"
              options={{
                enableTime: false,
                noCalendar: false,
                dateFormat: "d-m-Y",
              }}
              onChange={(dates) => {
                this.setState(
                  {
                    dateFrom: moment(dates[0]).startOf("day"),
                  },
                  () => this.props.onFromDateUpdated(moment(dates[0]))
                );
              }}
            />
          </div>
        </div>
        <div className={cssClass}>
          <div className="input-group date">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="la la-calendar"></i>
              </span>
            </div>
            <Flatpickr
              value={dateTo ? dateTo.format("DD-MM-YYYY") : null}
              className="form-control"
              placeholder="To"
              options={{
                minDate: dateFrom ? dateFrom.format("DD-MM-YYYY") : null,
                enableTime: false,
                noCalendar: false,
                dateFormat: "d-m-Y",
              }}
              onChange={(dates) => {
                this.setState(
                  {
                    dateTo: moment(dates[0]).endOf("day"),
                  },
                  () => this.props.onToDateUpdated(moment(dates[0]))
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
