import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';

export default class NudgeCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {

    } = this.state

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a className="btn btn-icon btn-light btn-hover-primary btn-sm"
           style={{margin: 3}}
           onClick={e => this.props.onNudgeClicked()}
        >
          <i className="la la-bell text-primary icon-md"></i>
        </a>
      </Tooltip>
    )
  }
}

NudgeCell.defaultProps = {
  tooltip: ''
}