import React from 'react'

import LazyLoadingList from './components/common/LazyLoadingList'

import General from '../utils/General'
import AuthManager from '../utils/AuthManager'
import TeamMemberModal from './components/modal/TeamMemberModal'
import ConfirmationModal from './components/modal/ConfirmationModal'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import Event from '../utils/Event'
import Permissions from '../utils/Permissions'

export default class TeamMembers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      endpoint: `${window.Api.CompanyMembers}?active=true&`,
    }
    this.list = React.createRef()
  }

  componentDidMount() {
    this._setTeamMemberCount()
  }

  _setTeamMemberCount() {
    this.setState({ isLoading: true })
    Backend.getCompanyMembers()
      .then((response) => {
        this.setState({
          currentTeamMembers: response.results.length,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        Notify.error(error.message)
      })
  }

  refresh() {
    let current = this.list.current
    if (current) {
      this.setState({
        endpoint: `${window.Api.CompanyMembers}?active=true&search_term=${
          this.state.searchTerm || ''
        }&role=${this.state.role || ''}`,
      })
    }
  }

  _addTeamMemberClicked() {
    let { currentTeamMembers } = this.state
    let add_ons = AuthManager.currentUser.company.add_ons
    if (
      !add_ons.team_members ||
      currentTeamMembers >= add_ons.additional_team_members
    ) {
      Event.emit('ADD_ON_ALERT')
      return
    }
    this.setState({ showTeamMemberModal: true, member: null })
  }

  _handleSearch = General.debounce(() => {
    let current = this.list.current
    this.refresh()
  }, 500)

  _deleteMember() {
    let { teamMember } = this.state

    Backend.deleteMember(teamMember)
      .then(() => {
        Notify.success('Member has been deleted successfully')
        this.setState({
          teamMember: null,
          showConfirmationModal: false,
        })
        this.list.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          teamMember: null,
          showConfirmationModal: false,
        })
      })
  }

  _renderUser(teamMember, index) {
    let role = General.snakeCaseToTitleCase(teamMember._role.name)
    let name = teamMember.user.first_name + ' ' + teamMember.user.last_name
    let initials = General.getInitials(name)

    let isCurrentUser = teamMember.user.id === AuthManager.currentUser.user.id

    let canEdit = Permissions.hasEditPermission(Permissions.TEAM_MEMBER)
    let canDelete = !isCurrentUser && Permissions.hasDeletePermission(Permissions.TEAM_MEMBER)

    let buttonClassName = 'info'
    if (teamMember._role.admin) {
      buttonClassName = 'danger'
    }

    return (
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className={'card card-custom gutter-b card-stretch'}>
          <div className={'card-body text-center pt-4'}>
            <div className="d-flex justify-content-end">
              {(canEdit || canDelete) && (
                <div className="dropdown dropdown-inline">
                  <a
                    href="javascript:;"
                    className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="ki ki-bold-more-hor"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                    <ul className="navi navi-hover">
                      <li className="py-4">
                        {canEdit && (
                          <a
                            className="btn btn-clean font-weight-bold btn-sm w-100 text-left"
                            onClick={() => {
                              this.setState({
                                teamMember,
                                showTeamMemberModal: true,
                              })
                            }}
                          >
                            <i className="la la-edit"></i>
                            Edit Team Member
                          </a>
                        )}
                        <br />
                        {canDelete && (
                          <a
                            className="btn btn-clean font-weight-bold btn-sm w-100 text-left"
                            onClick={() => {
                              this.setState({
                                teamMember,
                                showConfirmationModal: true,
                              })
                            }}
                          >
                            <i className="la la-close"></i>
                            Remove Team Member
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className={'mt-7'}>
              {this._renderAvatar(teamMember, buttonClassName, initials)}
            </div>
            <div className={'my-2'}>
              <a
                href="javascript:;"
                className="text-dark font-weight-bold text-hover-primary font-size-h4"
              >
                {name}
              </a>
              <br />
              <br />
              <span
                className={`label label-inline label-lg label-light-${buttonClassName} btn-sm font-weight-bold`}
              >
                {role}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderAvatar(teamMember, label, initials) {
    if (teamMember.user.avatar) {
      return (
        <div className="symbol symbol-circle symbol-lg-75">
          <img src={teamMember.user.avatar} alt="image" />
        </div>
      )
    }
    return (
      <div className={`symbol symbol-lg-75 symbol-circle symbol-${label}`}>
        <span className="font-size-h3 font-weight-boldest">{initials}</span>
      </div>
    )
  }

  render() {
    let {
      selectedUser,
      role,
      endpoint,
      teamMember,
      searchTerm,
      showTeamMemberModal,
      showConfirmationModal,
      showJobBoardAdminAddModal,
      showJobBoardAdminDeleteModal,
      selectedUserIndex,
    } = this.state
    let { permissions } = AuthManager.currentUser._role

    return (
      <>
        <div className="row">
          <div className={'col-xl-12'}>
            <div className="mb-7">
              <div className="row align-items-center">
                <div className="col-lg-8 col-xl-8">
                  <div className="row align-items-center">
                    <div className="col-md-6 my-2 my-md-0">
                      <div className="input-icon">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            this.setState(
                              {
                                searchTerm: e.target.value,
                              },
                              () => this._handleSearch()
                            )
                          }}
                        />
                        <span>
                          <i className="flaticon2-search-1 text-muted"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 my-2 my-md-0">
                      <div className="d-flex align-items-center">
                        <label className="mr-3 mb-0 d-none d-md-block">
                          Role:
                        </label>
                        <div className="select-wrapper w-100">
                          <select
                            className="form-control"
                            name="role"
                            onChange={(e) => {
                              this.setState(
                                {
                                  role: e.target.value,
                                },
                                () => this._handleSearch()
                              )
                            }}
                          >
                            <option value="">All</option>
                            <option value="admin">Admin</option>
                            <option value="staff">Staff</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 mt-5 mt-lg-0">
                  {Permissions.hasCreatePermission(
                      Permissions.TEAM_MEMBER
                    ) && (
                      <button
                        className="btn btn-primary font-weight-bolder float-right"
                        disabled={this.state.isLoading}
                        onClick={() => this._addTeamMemberClicked()}
                      >
                        <i className="flaticon2-add-1 icon-sm"></i>
                        Add Team Member
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>

          <LazyLoadingList
            ref={this.list}
            endpoint={endpoint}
            renderItem={(member, index) => this._renderUser(member, index)}
          />

          <TeamMemberModal
            show={showTeamMemberModal}
            member={teamMember}
            onHide={() =>
              this.setState({ showTeamMemberModal: false, teamMember: null })
            }
            onAdded={(teamMember) => {
              this.list.current.refresh()
              this.setState({ showTeamMemberModal: false, teamMember: null })
            }}
          />

          <ConfirmationModal
            show={showConfirmationModal}
            title={'Are you sure you want to delete this team member?'}
            onConfirm={() => this._deleteMember()}
            onHide={() => {
              this.setState({
                teamMember: null,
                showConfirmationModal: false,
              })
            }}
          />
        </div>
      </>
    )
  }
}
