import React from 'react'
import General from "../../../../utils/General";

export default class PaidCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      paid: props.paid
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      paid
    } = this.state

    let className = paid ? 'label-light-primary' : 'label-light-danger'
    let title = paid ? 'Paid' : 'Unpaid'

    return (
      <span style={{width: 110}}>
        <span className={`label label-lg font-weight-bold w-100 ${className} label-inline`}>
          {General.toTitleCase(title)}
        </span>
      </span>
    )
  }
}