import React from 'react'
import { Portal } from 'react-portal'
import { Link } from 'react-router-dom'

import BaseTable from './BaseTable'
import RequestPaymentModal from '../modal/RequestPaymentModal'
import BulkUploadRequestsModal from '../modal/BulkUploadRequestsModal'
import EngagementCard from '../common/EngagementCard'
import ConfirmationModal from '../modal/ConfirmationModal'
import PaymentsTable from './PaymentsTable'
import PaidCell from './cells/PaidCell'
import CustomerCell from './cells/CustomerCell'
import ViewCell from './cells/ViewCell'
import EditCell from './cells/EditCell'
import DeleteCell from './cells/DeleteCell'
import CheckCell from './cells/CheckCell'
import NudgeCell from './cells/NudgeCell'
import DownloadCell from './cells/DownloadCell'
import ExpanderCell from './cells/ExpanderCell'

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import Currency from '../../../utils/Currency'
import Event from '../../../utils/Event'
import General from '../../../utils/General'
import Notify from '../../../utils/Notify'
import Permissions from '../../../utils/Permissions'
import Splink from '../../../utils/Splink'

import moment from 'moment'

const TYPE_FILTERS = {
  name: {
    api: 'recurring',
    display: 'recurring',
  },
  values: [
    {
      label: 'All Payments',
      value: null,
    },
    {
      label: 'Recurring',
      value: "true",
    },
    {
      label: 'One-Off',
      value: "false",
    },
  ],
}

const PAID_FILTERS = {
  name: {
    api: 'paid',
    display: 'requests',
  },
  values: [
    {
      label: 'All Requests',
      value: null,
    },
    {
      label: 'Paid',
      value: "true",
    },
    {
      label: 'Unpaid',
      value: "false",
    },
  ],
}

const FILTERS = [TYPE_FILTERS, PAID_FILTERS]

export default class PaymentRequestsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      company: AuthManager.currentUser.company,
      paymentRequestsSent: props.paymentRequestsSent,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    let { paymentRequestsSent } = this.state

    if (paymentRequestsSent > 0) {
      if (this.table.current) {
        this.table.current.refresh()
      }
    }
  }

  _nudgeClicked(paymentRequest) {
    if (!AuthManager.currentUser.company.add_ons.payment_request_nudge) {
      Event.emit('ADD_ON_ALERT', '/requests')
      return
    }
    this.setState({
      paymentRequestToNudge: paymentRequest,
      showConfirmationModal: true,
    })
  }

  _getColumns() {
    let { permissions } = AuthManager.currentUser._role
    return [
      {
        expander: true,
        Expander: ({ isExpanded, ...props }) => (
          <ExpanderCell
            isExpanded={isExpanded}
            show={() => {
              let paymentRequest = props.original
              let showExpander = paymentRequest.description
              return showExpander
            }}
          />
        ),
      },
      {
        Header: 'Customer',
        id: 'customer.email',
        Cell: (rowInfo) => {
          let customer = rowInfo.original.customer
          return <CustomerCell customer={customer} />
        },
        width: 160,
      },
      {
        Header: 'Amount',
        id: 'payment_plans[0].total',
        sortable: false,
        Cell: (rowInfo) => {
          let paymentRequest = rowInfo.original
          return Currency.format(
            paymentRequest.payment_plans[0].currency.symbol,
            paymentRequest.payment_plans[0].total
          )
        },
      },
      {
        Header: 'Status',
        id: 'paid',
        Cell: (rowInfo) => {
          let paymentRequest = rowInfo.original
          return <PaidCell paid={paymentRequest.paid} />
        },
      },
      {
        Header: 'Views',
        id: 'views_no',
        accessor: 'views_no',
      },
      {
        Header: 'Created',
        id: 'created_at',
        accessor: (paymentRequest) =>
          moment(paymentRequest.created_at).format('DD MMM YYYY'),
      },
      {
        Header: 'Due',
        id: 'due_at',
        accessor: (paymentRequest) =>
          moment(paymentRequest.due_at).format('DD MMM YYYY'),
      },
      {
        Header: 'Paid',
        id: 'paid_at',
        accessor: (paymentRequest) =>
          paymentRequest.paid_at
            ? moment(paymentRequest.paid_at).format('DD MMM YYYY')
            : '-',
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 250,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let paymentRequest = rowInfo.original
          let url = Splink.getPaymentRequestUrl(
            this.state.company,
            paymentRequest
          )
          return (
            <span
              style={{ overflow: 'visible', position: 'relative', width: 150 }}
            >
              {!paymentRequest.deleted_at && (
                <ViewCell
                  tooltip={'View'}
                  onViewClicked={() => {
                    window.open(url, '_blank')
                  }}
                />
              )}
              {!paymentRequest.paid && !paymentRequest.deleted_at && (
                <>
                  {Permissions.hasEditPermission(
                    Permissions.PAYMENT_REQUEST
                  ) && (
                    <CheckCell
                      tooltip={'Mark Paid'}
                      onCheckClicked={() => {
                        this.setState({
                          paymentRequest: paymentRequest,
                          showConfirmationModal: true,
                        })
                      }}
                    />
                  )}
                  <NudgeCell
                    tooltip={'Nudge Customer'}
                    onNudgeClicked={() => this._nudgeClicked(paymentRequest)}
                  />
                </>
              )}
              {paymentRequest.file && (
                <DownloadCell
                  tooltip={'Download Invoice'}
                  onDownloadClicked={() => {
                    window.open(paymentRequest.file.url, '_blank')
                  }}
                />
              )}
              {!paymentRequest.paid &&
                !paymentRequest.deleted_at &&
                Permissions.hasDeletePermission(
                  Permissions.PAYMENT_REQUEST
                ) && (
                  <DeleteCell
                    tooltip={'Archive'}
                    onDeleteClicked={() => {
                      this.setState({
                        paymentRequestToDelete: paymentRequest,
                        showConfirmationModal: true,
                      })
                    }}
                  />
                )}
            </span>
          )
        },
      },
    ]
  }

  _verifyPayment() {
    Backend.verifyPaymentRequest(this.state.paymentRequest)
      .then(() => {
        Notify.success('Payment request marked as paid')
        this.setState({
          paymentRequest: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          paymentRequest: null,
          showConfirmationModal: false,
        })
      })
  }

  _deletePaymentRequest() {
    Backend.deletePaymentRequest(this.state.paymentRequestToDelete)
      .then(() => {
        Notify.success('Payment request deleted successfully')
        this.setState({
          paymentRequestToDelete: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          paymentRequestToDelete: null,
          showConfirmationModal: false,
        })
      })
  }

  _resendPaymentRequest() {
    Backend.resendPaymentRequest(this.state.paymentRequestToNudge)
      .then(() => {
        Notify.success('Payment request has been resent.')
        this.setState({
          paymentRequestToNudge: null,
          showConfirmationModal: false,
        })
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          paymentRequestToNudge: null,
          showConfirmationModal: false,
        })
      })
  }

  _renderConfirmationModal() {
    let {
      paymentRequest,
      paymentRequestToNudge,
      paymentRequestToDelete,
      showConfirmationModal,
    } = this.state

    let message = 'Are you sure you want to mark this payment request as paid?'
    if (paymentRequestToDelete) {
      message = 'Are you sure you want to archive this payment request?'
    } else if (paymentRequestToNudge) {
      message = 'Are you sure you want to resend this payment request?'
    }

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={message}
        onConfirm={() => {
          if (paymentRequestToDelete) {
            this._deletePaymentRequest()
          } else if (paymentRequest) {
            this._verifyPayment()
          } else if (paymentRequestToNudge) {
            this._resendPaymentRequest()
          }
        }}
        onHide={() => {
          this.setState({
            paymentRequest: null,
            deletedPaymentRequest: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  _renderToolBar() {
    let { permissions } = AuthManager.currentUser._role
    if (this.props.latestResults) {
      return (
        <Link
          to="/requests"
          className="btn btn-light-primary font-weight-bolder"
        >
          <span className="svg-icon svg-icon-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                  fill="#000000"
                ></path>
              </g>
            </svg>
          </span>
          View All Requests
        </Link>
      )
    }

    return (
      <>
        {Permissions.hasCreatePermission(Permissions.PAYMENT_REQUEST) && (
          <>
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder mr-4"
              onClick={() => {
                if (
                  !AuthManager.currentUser.company.add_ons.bulk_payment_requests
                ) {
                  Event.emit('ADD_ON_ALERT', '/requests')
                  return
                }
                this.setState({ showBulkRequestsModal: true })
              }}
            >
              <span className="svg-icon svg-icon-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <rect
                      fill="#000000"
                      opacity="0.3"
                      x="11"
                      y="7"
                      width="2"
                      height="14"
                      rx="1"
                    ></rect>
                    <path
                      d="M6.70710678,14.7071068 C6.31658249,15.0976311 5.68341751,15.0976311 5.29289322,14.7071068 C4.90236893,14.3165825 4.90236893,13.6834175 5.29289322,13.2928932 L11.2928932,7.29289322 C11.6714722,6.91431428 12.2810586,6.90106866 12.6757246,7.26284586 L18.6757246,12.7628459 C19.0828436,13.1360383 19.1103465,13.7686056 18.7371541,14.1757246 C18.3639617,14.5828436 17.7313944,14.6103465 17.3242754,14.2371541 L12.0300757,9.38413782 L6.70710678,14.7071068 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    ></path>
                    <rect
                      fill="#000000"
                      opacity="0.3"
                      x="3"
                      y="3"
                      width="18"
                      height="2"
                      rx="1"
                    ></rect>
                  </g>
                </svg>
              </span>
              Bulk Requests
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bolder"
              onClick={() => {
                this.setState({ showRequestPaymentModal: true })
              }}
            >
              <span className="svg-icon svg-icon-white svg-icon-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                      fill="#000000"
                    ></path>
                  </g>
                </svg>
              </span>
              Send Payment Request
            </button>
          </>
        )}
      </>
    )
  }

  _renderDescription(paymentRequest) {
    return (
      <>
        {paymentRequest.description && (
          <div
            style={{ backgroundColor: '#fff', paddingTop: 15, paddingLeft: 15 }}
          >
            <p>
              <b>Description:</b> {paymentRequest.description}
            </p>
          </div>
        )}
      </>
    )
  }

  _renderRequestPaymentModal() {
    let { company, showRequestPaymentModal } = this.state

    if (!showRequestPaymentModal) return null

    return (
      <Portal node={document && document.getElementById('request_portal')}>
        <RequestPaymentModal
          show={showRequestPaymentModal}
          showOptional={false}
          company={company}
          onHide={() => this.setState({ showRequestPaymentModal: false })}
          onAdded={() => {
            this.setState({ showRequestPaymentModal: false })
            if (this.table.current) {
              this.table.current.refresh()
            }
            this.props.loadStats()
          }}
        />
      </Portal>
    )
  }

  _renderBulkRequestPaymentModal() {
    let { showBulkRequestsModal } = this.state

    if (!showBulkRequestsModal) return null

    return (
      <Portal node={document && document.getElementById('request_portal')}>
        <BulkUploadRequestsModal
          show={showBulkRequestsModal}
          onHide={() => this.setState({ showBulkRequestsModal: false })}
          onAdded={() => {
            this.setState({ showBulkRequestsModal: false })
            if (this.table.current) {
              this.table.current.refresh()
            }
            this.props.loadStats()
          }}
        />
      </Portal>
    )
  }

  render() {
    let { paymentRequestsSent } = this.state

    const columns = this._getColumns()

    let showEngagementCard =
      paymentRequestsSent === 0 && this.props.canShowEngagementCard

    return (
      <div>
        {showEngagementCard && (
          <EngagementCard
            title={'Send Payment Request'}
            text={`
            Send a payment request for money
            owed or to collect payment upfront.
            Your customer will receive a text message
            and an email with a simple payment link.
          `}
            buttonTitle={'Send Payment Request'}
            onClick={() => {
              this.setState({ showRequestPaymentModal: true })
            }}
          />
        )}
        {!showEngagementCard && (
          <BaseTable
            ref={this.table}
            endpoint={`${window.Api.PaymentRequests}?min_date=${
              this.state.dateFrom?.format('YYYY-MM-DD') || ''
            }&max_date=${this.state.dateTo?.format('YYYY-MM-DD') || ''}`}
            noDataMessage={'No payment requests found'}
            title={this.props.title}
            columns={columns}
            showExport={false}
            filters={!this.props.latestResults && FILTERS}
            defaultSorted={[
              {
                id: 'created_at',
                desc: true,
              },
            ]}
            showSearch={!this.props.latestResults}
            showPaginationBottom={!this.props.latestResults}
            SubComponent={(row) => this._renderDescription(row.original)}
            renderToolbar={() => this._renderToolBar()}
          />
        )}
        {this._renderRequestPaymentModal()}
        {this._renderBulkRequestPaymentModal()}
        {this._renderConfirmationModal()}
      </div>
    )
  }
}

PaymentRequestsTable.defaultProps = {
  latestResults: false,
  canShowEngagementCard: true,
}
