import React from 'react'
import Modal from 'react-bootstrap/Modal'

import ImageEdit from "../common/ImageEdit";
import SmartList from "../common/SmartList";
import Gallery from "../common/Gallery";

import PaymentPlans from "../splink/PaymentPlans";

import Backend from "../../../utils/Backend"
import Notify from "../../../utils/Notify"
import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";

export default class ProductModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      product: props.product ? General.clone(props.product) : {
        collect_address: false,
        payment_plans: [{
          type: 'one_time',
          open: false,
          vat: 0,
          currency: AuthManager.currentUser.company.settings.payment_plan.currency,
          allow_discounts: false,
          discounts: []
        }],
        images: [],
        questions: [],
        srock: null
      },
    }
  }

  _handleChange(e) {
    let product = this.state.product
    product[e.target.name] = e.target.value
    this.setState({ product, error: null })
  }

  _isFormValid(){
    let {
      product
    } = this.state

    let error = null

    if(product.name == null || product.name.length === 0){
      error = "Please enter a product name"
    }else if(!product.payment_plans[0].currency){
      error = "Please select a currency"
    }else if(!product.payment_plans[0].subtotal){
      error = "Please enter the price"
    }else if(product.payment_plans[0].type == "recurring" && !product.payment_plans[0].frequency){
      error = "Please select a frequency"
    }else if(product.payment_plans[0].installments !== null && product.payment_plans[0].installments < 2){
      error = "Installments must be 2 or more"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddProduct(){
    if(!this._isFormValid()){
      return
    }
    if(this.state.product.id){
      this._updateProduct()
    }else{
      this._addProduct()
    }
  }

  _addProduct(){
    let {
      product
    } = this.state

    this.setState({ loading: true })
    Backend.createProduct(product)
    .then(product => {
      Notify.success("Product Added Successfully")
      this.setState({ loading: false })
      this.props.onAdded(product)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _updateProduct(){
    let {
      product
    } = this.state

    this.setState({ loading: true })
    Backend.updateProduct(product)
      .then(product => {
        Notify.success("Product Updated Successfully")
        this.setState({ loading: false })
        this.props.onAdded(product)
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderItem(item, index){
    let {
      product
    } = this.state

    return (
      <>
        <div className={"row mb-3"}>
          <div className={"col-4"}>
            <div className="select-wrapper">
              <select
                className="form-control form-control-solid field-choice"
                disabled={!!product.questions[index].id}
                value={product.questions[index].type}
                onChange={e => {
                  product.questions[index].type = e.target.value
                  this.setState({ product })
                }}
              >
                <option value="checkbox">Checkbox</option>
                <option value="text">Text Field</option>
              </select>
            </div>
          </div>
          <div className={"col-8"}>
            <input
              type="text"
              className="form-control form-control-solid"
              value={ product.questions[index]?.text || '' }
              disabled={!!product.questions[index].id}
              onChange={e => {
                product.questions[index].text = e.target.value
                this.setState({ product })
              }}
            />
          </div>
        </div>
        {
          product.questions[index].type === "text" &&
          <label className="checkbox c-checkbox mb-0 checkbox">
            <input
              type="checkbox"
              name="agree"
              className="mr-1"
              checked={product.questions[index].is_required}
              onClick={e => {
                product.questions[index].is_required = e.target.checked
                this.setState({ product })
              }}
            /> Required
            <span></span>
          </label>
        }
      </>
    )
  }

  _renderDelete(item, index){
    let {
      product
    } = this.state

    return (
      <a href="javascript:;" data-repeater-delete=""
         className="btn font-weight-bold btn-danger btn-icon"
         onClick={() => {
           product.questions.splice(index, 1)
           this.setState({ product })
         }}
      >
        <i className="la la-remove"></i>
      </a>
    )
  }

  render() {
    let {
      show,
      loading,
      product,
      company,
      phone,
      error,
      member,
      type
    } = this.state

    let title = product.id ? "Update Shop Item" : "Add Shop Item";
    let buttonTitle = product.id ? 'Update Item' : 'Add Item';

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-lg c-modal product'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group">
              <span className="error-message">{ error }</span>
            </div>
          }

          <div className="form-group row">
            <label className="col-3 col-form-label">Item Name*</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={product.name}
                placeholder="Name"
                name={"name"}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Description</label>
            <div className="col-9">
              <textarea
                className="form-control form-control-solid"
                value={product.description}
                onChange={e => {
                  product.description = e.target.value
                  this.setState({product, error: null})
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Stock</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={product.stock}
                placeholder="Stock"
                name={"stock"}
                onChange={e => {
                  product.stock = e.target.value || null
                  this.setState({product, error: null})
                }}
              />
              <span className="form-text text-muted">Leave blank for unlimited stock</span>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Image</label>
            <div className="col-9">
              <ImageEdit
                image={product.featured_image}
                hidePreview={false}
                cropImage={true}
                aspectRatio={1}
                onUpdated={image => {
                  this.setState({loading: false})
                  product.featured_image = image
                  this.setState({product, error: null})
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Additional Images</label>
            <div className="col-lg-9 my-auto">
              <Gallery
                images={product.images}
                cropImage={true}
                aspectRatio={1}
                onUpdated={images => {
                  console.log("uuuu", images)
                  product.images = images
                  this.setState({ product })
                }}
              />
            </div>
          </div>

          <PaymentPlans
            showOptional={this.props.showOptional}
            paymentPlans={product.payment_plans}
            updatePaymentPlan={(paymentPlan, index) => {
              product.payment_plans[index] = paymentPlan
              this.setState({product})
            }}
          />

          <div className="form-group row">
            <label className="col-3 col-form-label">Collect Address</label>
            <div className="col-9">
              <div className="radio-inline"
                 onChange={e => {
                   product.collect_address = e.target.value === "on"
                   this.setState({ product })
                 }}
              >
                <label className="radio">
                  <input type="radio" name="collect_address" value="off" checked={!product.collect_address}/>
                    No
                  <span></span>
                </label>
                <label className="radio">
                  <input type="radio" name="collect_address" value="on" checked={product.collect_address}/>
                    Yes
                  <span></span>
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Redirect Buyer</label>
            <div className="col-9">
              <div className="radio-inline"
                   onChange={e => {
                     product.payment_success_url = e.target.value === "on" ? 'http://' : null
                     this.setState({ product })
                   }}
              >
                <label className="radio">
                  <input type="radio" name="paymentRedirect" value={"off"} checked={!product.payment_success_url}/>
                  No, keep them on {window.General.Branding.LinkName} after purchase
                  <span></span>
                </label>
                <label className="radio">
                  <input type="radio" name="paymentRedirect" value={"on"} checked={product.payment_success_url}/>
                  Yes
                  <span></span>
                </label>
              </div>
            </div>
          </div>

          {
            product.payment_success_url &&
            <div className="form-group row redirectLink" id="redirectInput" style={{border: 'none'}}>
              <label className="col-3 col-form-label">Redirect Link</label>
              <div className="col-9">
                <input
                  type={"text"}
                  className="form-control form-control-solid"
                  placeholder="Add Redirect"
                  value={product.payment_success_url}
                  name={"payment_success_url"}
                  onChange={e => this._handleChange(e)}
                />
              </div>
            </div>
          }

          <div className="form-group row questionBox" id="questionInput" style={{border: 'none'}}>
            <label className="col-3 col-form-label">Questions</label>
            <div className="col-9">
              <SmartList
                items={product.questions}
                itemName="Question"
                showItemName={true}
                value={item => item.text}
                disabled={item => item.id != null}
                onChange={(value, item, index) => {
                  product.questions[index].text = value
                  this.setState({ product })
                }}
                onUpdated={questions => {
                  product.questions = questions
                  this.setState({ product })
                }}
                onAddItemPressed={() => {
                  product.questions.push({text: "", type: "checkbox"})
                  this.setState({ product })
                }}
                renderItem={(item, index) => this._renderItem(item, index)}
                renderDelete={(item, index) => this._renderDelete(item, index)}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleAddProduct()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
