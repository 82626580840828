import React from 'react'
import Modal from 'react-bootstrap/Modal'

import ColorPicker from "../common/ColorPicker";


import Backend from "../../../utils/Backend"
import Notify from "../../../utils/Notify"
import AuthManager from "../../../utils/AuthManager";
import Splink from "../../../utils/Splink";

export default class QRCodeModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    let company = AuthManager.currentUser.company
    return {
      ...props,
      qrCode: props.qrCode || {
        url: Splink.getSplinkUrl(company),
        amount: "",
        cta: null,
        color: company.settings.primary_color,
        deletable: true
      },
    }
  }

  _isFormValid(){
    let {
      qrCode
    } = this.state

    let error = null

    if(qrCode.deletable && !qrCode.amount){
      error = "Please enter an amount"
    }else if(!qrCode.color){
      error = "Please select a colour"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddQRCode(){
    if(!this._isFormValid()){
      return
    }
    if(this.state.qrCode.id){
      this._updateQRCode()
    }else{
      this._addQRCode()
    }
  }

  _addQRCode(){
    let {
      qrCode
    } = this.state

    this.setState({ loading: true })
    Backend.createQRCode(qrCode)
    .then(qrCode => {
      Notify.success("QR Code Added Successfully")
      this.setState({ loading: false })
      this.props.onUpdated(qrCode)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _updateQRCode(){
    let {
      qrCode
    } = this.state

    this.setState({ loading: true })
    Backend.updateQRCode(qrCode)
    .then(qrCode => {
      Notify.success("QR Code Updated Successfully")
      this.setState({ loading: false })
      this.props.onUpdated(qrCode)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }


  render() {
    let {
      show,
      loading,
      qrCode,
      error,
    } = this.state

    let title = qrCode.id ? "Update QR Code" : "Add QR Code";

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-lg'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { qrCode.deletable &&
            <div className="form-group row">
              <label className="col-3 col-form-label">Amount</label>
              <div className="col-9 mb-4">
                <input
                  className="form-control form-control-solid"
                  type="number"
                  defaultValue={qrCode.amount ? qrCode.amount / 100 : ""}
                  placeholder="0.00"
                  step={"0.01"}
                  onChange={(e) => {
                    qrCode.amount = Math.round(e.target.value * 100)
                    this.setState({ qrCode })
                  }}
                />
              </div>
            </div>
          }

          <div className="form-group row">
            <label className="col-3 col-form-label">Call To Action</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={qrCode.cta}
                placeholder="Call To Action"
                onChange={e => {
                  qrCode.cta = e.target.value || null
                  this.setState({ qrCode })
                }}
              />
              <span>Optional text displayed under QR Code</span>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">QR Colour</label>
            <div className="col-9 mb-4">
              <ColorPicker
                color={qrCode.color}
                onColorChange={color => {
                  qrCode.color = color.hex
                  this.setState({ qrCode })
                }}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            disabled={loading}
            onClick={() => this._handleAddQRCode()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            Save
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
