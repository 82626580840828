import React from "react"
import {
  isValidPhoneNumber
} from 'libphonenumber-js'

export default class BulkUploadTableRowInputCell extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      row: props.row,
      rowKey: props.rowKey,
      value: props.value,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      row,
      rowKey,
      value
    } = this.state

    let className = ''
    if(rowKey === "amount" || rowKey === "email" || rowKey === "due date" || rowKey === "description"){
      if(!value){
        className = 'error-outline'
      }
    }
    else if(rowKey === "sms" && value && !isValidPhoneNumber(value)){
      className = 'error-outline'
    }

    return (
      <input
        type="text"
        className={`form-control ${className}`}
        style={{width: 120}}
        value={value}
        onChange={e => {
          value = e.target.value
          this.setState({value})
        }}
        onBlur={e => {
          this.props.onUpdated(e.target.value)
        }}
      />
    )
  }
}
