import React from "react"

import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'

export default class FileUploader extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      file: props.file,
      params: props.params,
      endpoint: props.endpoint
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onFileChange(e) {
    let {
      endpoint,
      params
    } = this.state

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]
    if(file == null){
      return
    }
    this.setState({ loading: true })
    this.props.onUploading()
    if(!this.props.canUpload){
      return
    }
    Backend.uploadFile(file, endpoint, params)
      .then(file => {
        this.setState({
          file,
          loading: false
        })
        this.props.onUploaded(file)
      })
      .catch(error => {
        this.setState({ loading: false })
        alert(error.message)
      })

  }

  render() {

    let {
      file,
      loading
    } = this.state

    let label = file ? file.name : "Choose File"
    return (
      <Div disabled={loading}>
        <div className="input-group">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              accept={this.props.accept}
              onChange={e => this._onFileChange(e)}
            />
            <label className="custom-file-label">{label}</label>
          </div>
        </div>
      </Div>
    )
  }
}


FileUploader.defaultProps = {
  canUpload: true
}
