import React from 'react'
import Modal from 'react-bootstrap/Modal'

import {loadStripe} from '@stripe/stripe-js';
import {IbanElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import Div from "../layouts/Div"

import Select from '../common/CustomSelect'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"


export default class BankAccountSelectionModal extends React.Component {
  constructor(props){
    super(props)
    this.state = this._getState(props)

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      accounts: props.accounts || [],
    }
  }


  render() {
    let {
      show,
      accounts,
      selectedAccount
    } = this.state

    if(!show){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => {}}
        dialogClassName="w-70"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          {
            accounts.map(account => {
              let className = "token-bank-account border rounded-lg"
              if(account.id == selectedAccount?.id){
                className += " border-primary"
              }
              return (
                <div className="mb-10">
                  <div className={className}>
                    <a onClick={() => this.setState({ selectedAccount: account })}>
                      <div className="row p-5">
                        <div className="col-8">
                          <div className="text-dark-75 font-weight-bold line-height-sm">
                            {account.accountDetails.accountHolderName}
                          </div>
                          <p className="font-size-sm text-dark-50 mb-0">
                            {account.accountDetails.type || account.name }
                          </p>
                        </div>
                        { account.accountDetails.currency &&
                          <div className="col-4 text-right">
                            <span
                              className={`label label-inline label-lg label-light-info btn-sm font-weight-bold`}
                            >
                              {account.accountDetails.currency}
                            </span>
                          </div>
                        }
                      </div>
                    </a>
                  </div>
                </div>
              )
            })
          }

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={!selectedAccount}
            onClick={() => this.props.onSelected(selectedAccount)}
          >
            Select
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
