import React from "react"

import PhoneInput from "../common/PhoneInput"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"
import Event from "../../../utils/Event"

export default class NotificationsSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: props.loading,
      companyMember: props.companyMember,
      phone: props.phone
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  componentDidMount() {
    if(!AuthManager.currentUser.company.add_ons.payment_notifications){
      Event.emit('ADD_ON_ALERT', '/settings/general')
    }
  }

  _handlePhoneUpdate(value, data) {
    let {
      companyMember
    } = this.state

    if(!data || !data.dialCode|| !value) return

    companyMember.user.phone_country_code = `+${data.dialCode}`
    companyMember.user.phone_number = value.replace(/[^0-9]+/g,'').slice(data.dialCode.length)

    this.setState({
      phone: value
    })
    this.props.onUpdated(companyMember)
  }

  render() {
    let {
      phone,
      companyMember,
      loading
    } = this.state

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <h4 className="mb-10 font-weight-bold text-dark">Control when you will be notified</h4>
              <div className="form-group row">
                <label className="col-3 col-form-label">Phone Number</label>
                <div className="col-9">
                  <PhoneInput
                    value={phone}
                    onChange={(value, data) => this._handlePhoneUpdate(value, data)}
                    inputProps={{
                      autocomplete: 'off',
                      className: "form-control form-control-solid"
                    }}
                  />
                  <div className="row no-gutters mt-2">
                    <div className="col-auto mt-3">
                      <span className="switch switch-outline switch-icon switch-primary">
                        <label>
                        <input
                          type="checkbox"
                          name="smsSelect"
                          checked={companyMember.notify_payment_by_sms}
                          onChange={e => {
                            companyMember.notify_payment_by_sms = e.target.checked
                            this.props.onUpdated(companyMember)
                          }}
                        />
                        <span></span>
                        </label>
                      </span>
                    </div>
                    <label htmlFor="smsSelect" className="col col-form-label align-left pl-3 mt-3">
                      Notify me by SMS when a customer pays.
                    </label>
                  </div>


                  <div className="row no-gutters">
                    <div className="col-auto mt-3">
                      <span className="switch switch-outline switch-icon switch-primary">
                        <label>
                        <input
                          type="checkbox"
                          name="emailSelect"
                          checked={companyMember.notify_payment_by_email}
                          onChange={e => {
                            companyMember.notify_payment_by_email = e.target.checked
                            this.props.onUpdated(companyMember)
                          }}
                        />
                        <span></span>
                        </label>
                      </span>
                    </div>
                    <label htmlFor="emailSelect" className="col col-form-label align-left pl-3 mt-3">
                      Notify me by Email when a customer pays.
                    </label>
                  </div>

                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Weekly Report</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <select
                      className="form-control form-control-solid"
                      value={companyMember.notify_weekly_report}
                      onChange={e => {
                        companyMember.notify_weekly_report = e.target.value === "true"
                        this.props.onUpdated(companyMember)
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <span className="form-text text-muted">Do you want to receive weekly reports on payments?</span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    this.props.onSave()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
      </>
    )
  }
}
