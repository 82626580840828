import React from "react"

import StatCard from "./components/common/StatCard";
import DateRange from "./components/common/DateRange";
import PayoutsTable from "./components/tables/PayoutsTable";

import Backend from "../utils/Backend";
import AuthManager from "../utils/AuthManager";
import Event from "../utils/Event";

export default class Payouts extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      stats: undefined,
      dateFrom: null,
      dateTo: null,
    }
  }

  componentDidMount() {

  }

  render() {
    let {
      dateFrom,
      dateTo,
      stats
    } = this.state

    return (
      <>
        <PayoutsTable
          title={'Payouts'}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </>
    )
  }

}
