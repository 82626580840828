import React from 'react'

import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import PhoneInput from 'react-phone-input-2'

import Div from './components/layouts/Div'
import AsyncSelect from './components/common/AsyncSelect'
import PasswordInput from './components/common/PasswordInput'
import LocationInput from './components/common/LocationInput'
import ColorPicker from './components/ColorPicker'
import PackageView from './components/PackageView'
import ImagePickerModal from './components/modal/ImagePickerModal'

import AuthManager from '../utils/AuthManager'
import Intercom from '../utils/Intercom'
import Email from '../utils/Email'
import Backend from '../utils/Backend'
import Notify from '../utils/Notify'
import General from '../utils/General'
import MetaTags from '../utils/MetaTags'

const MODE_SIGNUP = 'signup'
const MODE_SIGNIN = 'signin'
const MODE_FORGOT_PASSWORD = 'forgot_password'
const MODE_RESET_PASSWORD = 'reset_password'
const MODE_MFA = "mfa";

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    const search = window.location.search
    const params = new URLSearchParams(search)
    const source = params.get('s') || 'organic'
    const u = params.get('u') || 's'
    const package_slug = params.get('p') || null
    const hashKey = params.get('h') || null

    this.state = {
      hashKey,
      source,
      package_slug,
      email: null,
      isLoading: false,
      mode: u === 'c' ? MODE_SIGNUP : MODE_SIGNIN,
      color: '#3d85c6',
      maxDate: moment().subtract(18, 'years').toDate(),
    }

    this.location = React.createRef()
    this.newPassword = React.createRef()
    Intercom.update()
  }

  componentDidMount() {
    if (this.state.package_slug) {
      this._getPackage()
    }
    this._getHashKey()
  }

  _getHashKey() {
    let { hashKey } = this.state

    if (!hashKey) {
      return
    }
    Backend.getHash(hashKey, 'email')
      .then((hash) => {
        this.setState({
          email: hash.value,
        })
      })
      .catch((error) => {
        console.log('error', error.message)
      })
  }

  _getPackage() {
    let { package_slug } = this.state
    this.setState({ isLoading: true })
    if (!package_slug) {
      return
    }
    Backend.getPackage(package_slug)
      .then((pkg) => {
        this.setState({
          pkg,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        console.log('error', error.message)
      })
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    if (!Email.isValid(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    if (mode == MODE_MFA && !code) {
      this.setState({ error: "Please enter a valid code" });
      return false;
    }


    if (mode === MODE_RESET_PASSWORD) {
      if (!code) {
        this.setState({ error: 'Please enter a valid code' })
        return false
      }
      if (!this.newPassword.current.isValid()) {
        this.setState({ error: "Your new password is not strong enough, please enter a stronger password" })
        return false
      }
    }

    return true
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password)
      .then(() => {
        let url = '/'
        window.location = url
      })
      .catch((response) => {
        if (response?.message?.ephemeral_token) {
          console.log("£££££", response)
          this.setState({
            isLoading: false,
            mode: MODE_MFA,
            mfaMethod: response.message.method,
            ephemeralToken: response.message.ephemeral_token,
            lastFourPhoneDigits: response.message.phone_number_last_4_digit
          });
        } else {
          this.setState({
            isLoading: false,
            error: response.message,
          });
        }
      })
  }

  _handleRequestResetPassword(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, confirmPassword } = this.state

    this.setState({ isLoading: true })
    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: '',
          confirmPassword: '',
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }

  _handleResetPassword(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, code } = this.state

    this.setState({ isLoading: true })
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: '',
          password: '',
          confirmPassword: '',
        })
        Notify.success('Password reset successfully!')
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }

  _isSignUpFormValid() {
    let { currency, company_name, email, color, image, agree } = this.state

    let error = null
    if (!currency) {
      error = 'Please select a currency'
    } else if (!company_name) {
      error = 'Please enter your company name'
    } else if (!email) {
      error = 'Please enter a valid email'
    } else if (!color) {
      error = 'Please select your brand colour'
    } else if (!image) {
      error = 'Please select an image'
    } else if (agree == null || !agree) {
      error = 'You must agree to the terms and conditions'
    }

    if (error) {
      Notify.error(error)
      return false
    }

    return true
  }

  _signUpPressed(e) {
    e.preventDefault()

    if (!this._isSignUpFormValid()) {
      return
    }

    let { currency, company_name, email, color, image, source, package_slug } =
      this.state

    this.setState({ isLoading: true })

    let data = {
      color,
      email,
      source,
      package_slug,
      image: image.id,
      name: company_name,
      currency: currency.id,
      association: window.General.AssociationId,
    }

    AuthManager.register(data)
      .then((response) => {
        window.location = response.activate_url
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
        Notify.error(error.message)
      })
  }

  _handleVerifyLogInClicked(e) {
    e.preventDefault();

    let { ephemeralToken, code } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });
    AuthManager.verifyLogin(ephemeralToken, code)
    .then(() => {
      let url = "/";
      window.location = url;
    })
    .catch((response) => {
      this.setState({
        isLoading: false,
        error: response.message,
      });
    });
  }

  _resendMFACode() {
    const { mfaMethod, ephemeralToken, email } = this.state;

    this.setState({ isLoading: true });

    AuthManager.resendCode(mfaMethod, email, ephemeralToken)
    .then(() => {
      this.setState({ isLoading: false });
    })
    .catch((error) => {
      Notify.error(error.message);
      this.setState({
        isLoading: false,
      });
    });
  }

  _renderMFASubtitle(mfaMethod) {
    let subtitle = "To verify it's you, ";

    if (mfaMethod === "sms_twilio") {
      let lastFourPhoneDigits = this.state.lastFourPhoneDigits
      subtitle += `we've sent a 6-digit code to your phone with a number ending in ${lastFourPhoneDigits}.`;
    } else if (mfaMethod === "sms_api") {
      subtitle += `we've sent a 6-digit code to your mobile.`;
    } else {
      subtitle += "enter a code generated by your authenticator app.";
    }
    return subtitle;
  }

  _renderError() {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    )
  }

  _renderSignUp() {
    let { mode, slug, image, color, email, imageLoading, isLoading, pkg } =
      this.state

    if (mode != MODE_SIGNUP) {
      return
    }

    let className = 'input-group-prepend-bg-dark '

    return (
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        novalidate="novalidate"
      >
        <div className="pb-13 pt-lg-0 pt-5">
          <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
            {pkg ? pkg.title : 'Get Started'}
          </h3>
          <span className="text-muted font-weight-bold font-size-h4">
            {pkg ? pkg.subtitle : 'Enter your details to create your account'}
          </span>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label className="mb-4">
            <span className="font-size-h6 font-weight-bolder text-dark">
              01 - Currency
            </span>{' '}
            (What Currency Do You Take Payments In?)
          </label>
          <div className="input-group">
            <AsyncSelect
              endpoint={window.Api.Currencies}
              placeholder={'Currency'}
              validateTokens={false}
              params={{}}
              orderBy="name"
              onSelected={(currency) => {
                this.setState({ currency })
              }}
              getOptions={(currencies) => General.getAsyncOptions(currencies)}
              styles={{}}
            />
          </div>
        </div>

        <div className="form-group fv-plugins-icon-container mt-10">
          <label className="mb-4">
            <span className="font-size-h6 font-weight-bolder text-dark">
              02 - Company
            </span>{' '}
            (What's Your Company Name?)
          </label>
          <input
            className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
            type="email"
            placeholder="Company Name"
            name="company_name"
            autoComplete="off"
            onChange={(e) => this.setState({ company_name: e.target.value })}
          />
          <div className="fv-plugins-message-container"></div>
        </div>

        <div className="mt-10">
          <ColorPicker
            title={
              <>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  03 - Brand Colour
                </span>{' '}
                (Choose A Colour, You Can Change Later)
              </>
            }
            color={color}
            onColorSelected={(color) => this.setState({ color })}
          />
        </div>

        <div className="form-group fv-plugins-icon-container mt-10">
          <label className="mb-4">
            <span className="font-size-h6 font-weight-bolder text-dark">
              04 - Image
            </span>{' '}
            (Pick A Random Image, You Can Change Later)
          </label>
          <div
            className="form-control form-control-solid text-center p-0 d-flex justify-content-center align-items-center cursor-pointer choose-image"
            style={{ height: 300, overflow: 'hidden' }}
            onClick={() => this.setState({ showImagePicker: true })}
          >
            {!image && (
              <>
                <div className="choose-image-overlay" />
                <div className="bg-text">
                  <p className="my-auto">Select Random Image</p>
                </div>
              </>
            )}
            {(image || imageLoading) && (
              <div className="w-100 shine shine-overlay">
                {image && (
                  <>
                    <div
                      className="uploaded-image"
                      style={{ backgroundImage: 'url(' + image.original + ')' }}
                    ></div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="form-group fv-plugins-icon-container mt-10">
          <label className="mb-4">
            <span className="font-size-h6 font-weight-bolder text-dark">
              05 - Email
            </span>{' '}
            (We'll Notify You When You Receive Payments)
          </label>
          <input
            className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
            type="email"
            placeholder="Email"
            value={email}
            name="email"
            autocomplete="off"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <div className="fv-plugins-message-container"></div>
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label className="checkbox c-checkbox mb-0 checkbox">
            <input
              type="checkbox"
              name="agree"
              className="mr-1"
              onClick={(e) => this.setState({ agree: e.target.checked })}
            />
            I Agree to the
            <a
              href={`${window.General.Branding.TermsUrl}`}
              target="_blank"
              className="text-primary ml-1"
            >
              terms and conditions
            </a>
            <span></span>
          </label>

          <div className="fv-plugins-message-container"></div>
        </div>

        <div className="form-group d-flex flex-wrap pb-lg-0 pb-3">
          <button
            type="button"
            className="btn btn-primary w-100 font-weight-bolder font-size-h6 px-8 py-4 my-3"
            onClick={(e) => this._signUpPressed(e)}
            disabled={isLoading}
          >
            {isLoading && (
              <span className="loading mr-2">
                <i className="fa fa-spinner fa-spin"></i>
              </span>
            )}
            Create My Account
          </button>
        </div>
        <div className="c-separator form-group row my-8 align-items-center text-center">
          <div className="col-3">
            <hr />
          </div>
          <div className="col-6">
            <span>
              <strong>Already Have An Account?</strong>
            </span>
          </div>
          <div className="col-3">
            <hr />
          </div>
        </div>

        <div className="pb-lg-0 pb-5">
          <button
            className="btn btn-outline-dark w-100 font-weight-bolder font-size-h6 px-8 py-4 my-3"
            onClick={(e) => {
              e.preventDefault()
              this.setState({ mode: MODE_SIGNIN, signUpStep: 1 })
              window.scrollTo(0, 0)
            }}
          >
            Sign In
          </button>
        </div>
      </form>
    )
  }

  _renderMFA() {
    const { mode, code, isLoading, response, mfaMethod } = this.state;

    return (
      <div
        className="login-form login-forgot"
        style={{
          display: mode === MODE_MFA ? "block" : "none",
        }}
      >
        <form
          className="form"
          noValidate="novalidate"
          id="kt_login_forgot_form"
        >
          <div className="pb-5 pt-lg-0 pt-5">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              Verify Your Identity
            </h3>
            <p className="text-muted font-weight-bold font-size-h6">
              {this._renderMFASubtitle(mfaMethod)}
            </p>
          </div>

          {this._renderError()}

          <div className="form-group">
            <span className="text-dark-75 ml-1">Code</span>
            <input
              className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              name="code"
              autoComplete="off"
              value={code}
              placeholder="Code (or Backup Code)"
              onChange={(e) =>
                this.setState({ code: e.target.value, error: null })
              }
            />
            <div>
              {mfaMethod !== "app" && (
                <>
                  <span className="text-muted ml-1">
                    Didn't receive a code ?
                    <a
                      href="javascript:;"
                      tabIndex="4"
                      className="text-primary font-weight-bold text-hover-primary pt-5 ml-1"
                      style={{ textDecoration: "underline" }}
                      onClick={() => this._resendMFACode()}
                    >
                      Resend Code
                    </a>
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="form-group d-flex flex-wrap pb-lg-0 pull-right">
            <button
              className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  code: "",
                  mode: MODE_SIGNIN,
                  error: null,
                });
              }}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              onClick={(e) => {
                this._handleVerifyLogInClicked(e);
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading... " : "Verify"}
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
      signUpStep,
      showImagePicker,
      pkg,
    } = this.state

    return (
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {MetaTags.render(
            pkg
              ? `${window.General.Branding.Name} | ${pkg.title}`
              : window.General.Branding.Name,
            pkg ? pkg.subtitle : 'The Fastest Way To Take Payment',
            null,
            window.location.href
          )}

          {/* begin : Login Aside */}

          <PackageView pkg={pkg} package_slug={this.state.package_slug} />
          {/* end : Login Aside */}

          {/* <!--begin::Content--> */}
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 pt-38 pb-38 mx-auto w-100">
            <div className="d-flex flex-column-fluid flex-center">
              {/* <!--begin::Signin--> */}
              <div
                className="login-form login-signin"
                style={{
                  display: mode === MODE_SIGNIN ? 'block' : 'none',
                }}
              >
                <form className="form" noValidate="novalidate">
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Welcome to {window.General.Branding.Name}
                    </h3>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Email
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value, error: null })
                      }
                      autoFocus
                      tabIndex="1"
                    />
                  </div>

                  <div className="form-group">
                    <PasswordInput
                      label={() => {
                        return (
                          <div className="d-flex justify-content-between mt-n5">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                              Password
                            </label>
                            <a
                              href="javascript:;"
                              tabIndex="5"
                              className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                              onClick={() =>
                                this.setState({
                                  email: '',
                                  mode: MODE_FORGOT_PASSWORD,
                                  error: null,
                                })
                              }
                            >
                              Forgot Password ?
                            </a>
                          </div>
                        )
                      }}
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      onChange={(password) => {
                        this.setState({ password, error: null })
                      }}
                      tabIndex={2}
                    />
                  </div>

                  <div className="pb-lg-0 pb-5">
                    <button
                      className="btn btn-primary w-100 font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => this._handleLogInClicked(e)}
                      disabled={isLoading}
                      tabIndex="3"
                    >
                      {isLoading ? 'Loading... ' : 'Sign In'}
                    </button>
                  </div>

                  <div className="c-separator form-group row my-8 align-items-center text-center">
                    <div className="col-3">
                      <hr />
                    </div>
                    <div className="col-6">
                      <span>
                        <strong>Don't Have An Account?</strong>
                      </span>
                    </div>
                    <div className="col-3">
                      <hr />
                    </div>
                  </div>

                  <div className="pb-lg-0 pb-5">
                    <button
                      className="btn btn-outline-dark w-100 font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({ mode: MODE_SIGNUP, signUpStep: 1 })
                        window.scrollTo(0, 0)
                      }}
                      tabIndex="4"
                    >
                      Create An Account
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::Signin-->
                {/* <!--begin::Signup--> */}
              <div
                className="login-form login-signup"
                style={{
                  display: mode === MODE_SIGNUP ? 'block' : 'none',
                }}
              >
                {this._renderSignUp()}
              </div>
              {/* <!--end::Signup-->
                <!--begin::Forgot--> */}
              <div
                className="login-form login-forgot"
                style={{
                  display: mode === MODE_FORGOT_PASSWORD ? 'block' : 'none',
                }}
              >
                <form
                  className="form"
                  noValidate="novalidate"
                  id="kt_login_forgot_form"
                >
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Forgotten Password ?
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      Enter your email to reset your password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                      type="email"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div className="form-group d-flex flex-wrap pb-lg-0 pull-right">
                    <button
                      className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          email: '',
                          password: '',
                          mode: MODE_SIGNIN,
                          error: null,
                        })
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => this._handleRequestResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading... ' : 'Request'}
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::Forgot--> */}

              {/* <!--begin::reset--> */}
              <div
                className="login-form login-reset"
                style={{
                  display: mode === MODE_RESET_PASSWORD ? 'block' : 'none',
                }}
              >
                <form className="form" noValidate="novalidate">
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Reset Password
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      A verification code was sent to your email, please enter
                      it below along with your new password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Code
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      placeholder="Code"
                      name="code"
                      autoComplete="off"
                      value={code}
                      onChange={(e) =>
                        this.setState({ code: e.target.value, error: null })
                      }
                    />
                  </div>
                  <PasswordInput
                    ref={this.newPassword}
                    onChange={(password) =>
                      this.setState({ password, error: null })
                    }
                    onConfirmChange={(confirmPassword) =>
                      this.setState({
                        confirmPassword,
                        error: null,
                      })
                    }
                    labelClassName="font-size-h6 font-weight-bolder text-dark"
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    autoComplete="off"
                    showValidationCheck
                  />
                  <div className="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          email: '',
                          password: '',
                          mode: MODE_SIGNIN,
                          error: null,
                        })
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => this._handleResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Loading... ' : 'Update Password'}
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::reset--> */}

              {/*}<!--begin::MFA--> */}
              {this._renderMFA()}
              {/* <!--end::MFA--> */}
            </div>
            {/* <!--end::Content body--> */}
          </div>
          {/* end::Content */}
          <ImagePickerModal
            show={showImagePicker}
            onSelected={(image) => {
              this.setState({ imageLoading: true, showImagePicker: false })
              Backend.addImage(image)
                .then((image) => {
                  this.setState({ image, imageLoading: false })
                })
                .catch((error) => {
                  Notify.error(error.message)
                  this.setState({ imageLoading: false })
                })
            }}
            onHide={() => this.setState({ showImagePicker: false })}
          />
        </div>
      </div>
    )
  }
}
