import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'

import Backend from '../../../utils/Backend'

import EditCell from './cells/EditCell'
import DeleteCell from './cells/DeleteCell'
import ConfirmationModal from '../modal/ConfirmationModal'
import Notify from '../../../utils/Notify'
import RoleModal from '../modal/RoleModal'

export default class RolesTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      live: props.live,
    }

    this.table = React.createRef()
  }

  _getColumns() {
    return [
      {
        Header: 'Name',
        id: 'name',
        accessor: (role) => role.name,
      },
      {
        Header: 'Permissions',
        id: 'permissions',

        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let permissionsNo = -2
          for (const [key, value] of Object.entries(rowInfo.original.permissions)) {
            if(value?.length > 0){
              permissionsNo += 1
            }
          }
          return permissionsNo
        },
      },
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: (role) => {
          return moment(role.created_at).format('DD MMM YYYY')
        },
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        exportable: false,
        width: 180,
        Cell: (rowInfo) => {
          let role = rowInfo.original
          return (
            <span
              style={{ overflow: 'visible', position: 'relative', width: 150 }}
            >
              { !role.admin &&
                <>
                  <EditCell
                    onEditClicked={() => {
                      this.setState({
                        showRoleModal: true,
                        role,
                      })
                    }}
                  />
                  { !role.deleted_at &&
                    <DeleteCell
                      onDeleteClicked={() => {
                        this.setState({
                          showConfirmationModal: true,
                          role,
                        })
                      }}
                    />
                  }
                </>
              }
            </span>
          )
        },
      },
    ]
  }

  _deleteRole() {
    let { role } = this.state
    Backend.deleteRole(role)
      .then((role) => {
        Notify.success('Role has been deleted')
        this.setState({
          loading: false,
          role: null,
          showConfirmationModal: false,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderToolBar() {
    return (
      <>
        <a
          className="btn btn-primary font-weight-bolder"
          onClick={() => {
            this.setState({
              showRoleModal: true,
            })
          }}
        >
          <span className="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                  fill="#000000"
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
              </g>
            </svg>
          </span>
          Add Role
        </a>
      </>
    )
  }

  _renderRoleModal() {
    let { role, showRoleModal } = this.state

    return (
      <RoleModal
        show={showRoleModal}
        role={role}
        onHide={() => this.setState({ showRoleModal: false, role: null })}
        onAdded={(role) => {
          this.table.current.refresh()
          this.setState({ showRoleModal: false, role: null })
        }}
      />
    )
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure? This action can't be undone."}
        onConfirm={() => {
          this._deleteRole()
        }}
        onHide={() => {
          this.setState({
            role: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  render() {
    let { live } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Roles}`}
          noDataMessage={'No roles found'}
          title={this.props.title}
          columns={columns}
          showExport={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          renderToolbar={() => this._renderToolBar()}
        />
        {this._renderRoleModal()}
        {this._renderConfirmationModal()}
      </div>
    )
  }
}

RolesTable.defaultProps = {
  latestResults: false,
}
