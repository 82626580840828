import React from 'react'

import { Portal } from 'react-portal'

import RequestPaymentModal from './modal/RequestPaymentModal'
import TakePaymentModal from './modal/TakePaymentModal'

import Backend from '../../utils/Backend'
import AuthManager from '../../utils/AuthManager'
import Notify from '../../utils/Notify'
import General from '../../utils/General'
import Permissions from '../../utils/Permissions'

import Collapsible from "react-collapsible";

export default class EasyMode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: props.company,
      forcePayByBank: false,
    }
  }

  componentDidMount() {

  }

  _binLookup(){
    let { binValue } = this.state

    if(!binValue || binValue.length != 6){
      Notify.error("Please enter a valid 6 digit bin")
      return
    }

    this.setState({ loading: true })
    Backend.binLookup(binValue)
    .then(bin => {
      this.setState({ bin, loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderCardOptions(){
    let { company } = this.state

    let cardPaymentMethod = company.payment_methods.card
    if(!cardPaymentMethod){
      return null
    }

    if(!Permissions.hasCreatePermission(Permissions.PAYMENT) && !Permissions.hasCreatePermission(Permissions.PAYMENT_REQUEST)){
      return null
    }

    let className = "varation-2 ";
    return (
      <Collapsible
        className={className}
        openedClassName={className}
        trigger={"Take Card Payment Now"}
      >
        <div className='buttons-grid'>
          { Permissions.hasCreatePermission(Permissions.PAYMENT) &&
            <button
              type="button"
              className="btn btn-primary font-weight-bolder"
              onClick={() => {
                this.setState({
                  showTakePaymentModal: true,
                  payByBank: false
                })
              }}
            >
              Take Payment Now
            </button>
          }
          { Permissions.hasCreatePermission(Permissions.PAYMENT_REQUEST) &&
            <button
              type="button"
              className="btn btn-primary font-weight-bolder"
              onClick={() => {
                this.setState({
                  showRequestPaymentModal: true,
                  payByBank: false
                })
              }}
            >
              Send Secure CARD Payment Link
            </button>
          }
        </div>
      </Collapsible>
    )
  }

  _renderBankOptions(){
    let { company } = this.state

    let tokenPaymentMethod = Object.keys(company.payment_methods).find(key => {
      let paymentMethod = company.payment_methods[key]
      if(!paymentMethod){
        return null
      }
      return paymentMethod.processor.indexOf("token") > -1
    })

    if(!tokenPaymentMethod || !Permissions.hasCreatePermission(Permissions.PAYMENT_REQUEST)){
      return null
    }
    let className = "varation-2 ";
    return (
      <Collapsible
        className={className}
        openedClassName={className}
        trigger={"Pay By Bank"}
      >
        <button
          type="button"
          className="btn btn-primary font-weight-bolder"
          onClick={() => {
            this.setState({
              showRequestPaymentModal: true,
              payByBank: true
            })
          }}
        >
          Send Payment Request
        </button>
      </Collapsible>
    )
  }

  _renderRequestPaymentModal() {
    let { company, showRequestPaymentModal, payByBank } = this.state

    if (!showRequestPaymentModal) return null

    return (
      <Portal node={document && document.getElementById('request_portal')}>
        <RequestPaymentModal
          show={showRequestPaymentModal}
          showOptional={false}
          company={company}
          payByBank={payByBank}
          onHide={() => this.setState({ showRequestPaymentModal: false })}
          onAdded={() => {
            this.setState({ showRequestPaymentModal: false })
          }}
        />
      </Portal>
    )
  }

  _renderTakePaymentModal() {
    let { showTakePaymentModal } = this.state

    if (!showTakePaymentModal) return null

    return (
      <Portal node={document && document.getElementById('payment_portal')}>
        <TakePaymentModal
          show={showTakePaymentModal}
          onHide={() => this.setState({ showTakePaymentModal: false })}
        />
      </Portal>
    )
  }

  render() {
    let { company, bin, loading } = this.state
    if (!company) {
      return null
    }

    let binLookupEnabled = company.add_ons.bin_lookups

    let paymentOptions = []
    let cardOptions = this._renderCardOptions()
    let bankOptions = this._renderBankOptions()
    if(cardOptions){
      paymentOptions.push(cardOptions)
    }
    if(bankOptions){
      paymentOptions.push(bankOptions)
    }


    return (
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body d-flex p-0 card-rounded">
          <div className="flex-grow-1 p-8 pt-5 card-rounded flex-grow-1 bgi-no-repeat">
            <h4 className="text-dark">
              Quick Payments
            </h4>
            { binLookupEnabled &&
              <>
                <span className="text-muted font-size-lg font-weight-bold mb-2">
                  Add the first six digits of a card to find out more about it.
                </span>
                <div className="mt-10">
                  <div className="form-group row">
                    <div className="col-6">
                      <label>First 6 Digits Of Card</label>
                      <input
                        className="form-control form-control-solid"
                        type="text"
                        name="bin"
                        placeholder="123456"
                        disabled={loading}
                        onChange={(e) => {
                          this.setState({
                            bin: null,
                            binValue: e.target.value || null
                          })
                        }}
                      />

                      { bin &&
                        <span className="text-muted">
                          Brand: <span className="text-primary">{General.toTitleCase(bin.card || "Unknown")}  </span>
                          Type: <span className="text-primary">{General.toTitleCase(bin.type || "Unknown")}  </span>
                          Level: <span className="text-primary">{General.toTitleCase(bin.level || "Unknown")}  </span>
                        </span>
                      }
                    </div>
                    <label className="col-3 col-form-label mt-5 pl-0">
                      <button
                        className="btn btn-light-primary font-weight-bolder"
                        disabled={loading}
                        onClick={() => this._binLookup()}
                      >
                        Check Card Type
                      </button>
                    </label>
                  </div>
                </div>
              </>
            }
            { paymentOptions && (
                <>
                  <div className="font-size-lg font-weight-bold mt-7 mb-5">Processing Options Available:</div>
                  {paymentOptions}
                </>
              )
            }
            { this._renderRequestPaymentModal() }
            { this._renderTakePaymentModal() }
          </div>
        </div>
      </div>
    )
  }
}
