import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword         = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken          = window.Api.User.Base + "/refresh-token"

window.Api.Companies                  = window.Api.Base + "/companies"
window.Api.Payments                   = window.Api.Base + "/payments"
window.Api.PaymentRequests            = window.Api.Base + "/payment-requests"
window.Api.Requests                   = window.Api.Base + "/requests"
window.Api.PaymentAttempts            = window.Api.Base + "/payment-attempts"
window.Api.CompanyMembers             = window.Api.Base + "/company-members"
window.Api.CompanySubscriptions       = window.Api.Base + "/company-subscriptions"
window.Api.Associations               = window.Api.Base + "/associations"
window.Api.Currencies                 = window.Api.Base + "/currencies"
window.Api.Products                   = window.Api.Base + "/products"
window.Api.PaymentLinks               = window.Api.Base + "/payment-links"
window.Api.Images                     = window.Api.Base + "/images"
window.Api.Stats                      = window.Api.Base + "/stats"
window.Api.Subscriptions              = window.Api.Base + "/subscriptions"
window.Api.Splinks                    = window.Api.Base + "/splinks"
window.Api.Invoices                   = window.Api.Base + "/invoices"
window.Api.BulkUploads                = window.Api.Base + "/bulk-uploads"
window.Api.StripePubishableKey        = window.Api.Base + "/bulk-uploads"
window.Api.BankAccounts               = window.Api.Base + "/bank-accounts"
window.Api.Questions                  = window.Api.Base + "/questions"
window.Api.ImagesSearch               = window.Api.Base + "/images-search"
window.Api.Reminders                  = window.Api.Base + "/reminders"
window.Api.Connect                    = window.Api.Base + "/connect"
window.Api.Receipts                   = window.Api.Base + "/receipts"
window.Api.Files                      = window.Api.Base + "/files"
window.Api.ReverseFeeCalc             = window.Api.Base + "/reverse-fee-calc"
window.Api.Hashes                     = window.Api.Base + "/hashes"
window.Api.Packages                   = window.Api.Base + "/packages"
window.Api.Payouts                    = window.Api.Base + "/payouts"
window.Api.Export                     = window.Api.Base + "/exports"
window.Api.PaymentMethods             = window.Api.Base + "/payment-methods"
window.Api.PlatformPaymentMethods     = window.Api.Base + "/platform-payment-methods"
window.Api.Branding                   = window.Api.Base + "/branding"
window.Api.QRCodes                    = window.Api.Base + "/qr-codes"
window.Api.Processors                 = window.Api.Base + "/processors"
window.Api.Roles                      = window.Api.Base + '/roles'
window.Api.SecretKey                  = window.Api.Base + '/secret-key'
window.Api.Webhooks                   = window.Api.Base + '/webhooks'
window.Api.MFA                        = window.Api.Base + '/mfa'
window.Api.Discounts                  = window.Api.Base + '/discounts'
window.Api.PaymentPlans               = window.Api.Base + '/payment-plans'
window.Api.CompanySubscriptions       = window.Api.Base + '/company-subscriptions'
window.Api.CompanyInvoices            = window.Api.Base + '/company-invoices'
window.Api.CompanyInvoiceLineItems    = window.Api.Base + '/company-invoice-line-items'
window.Api.Bins                       = window.Api.Base + '/bins'

window.Api.Keys = {}

window.Api.Keys.IntercomAppId         = env.REACT_APP_INTERCOM_ID

window.Api.RecaptchaSiteKey           = env.REACT_APP_RECAPTCHA_SITE_KEY

window.Api.FlinksLiveEnv              = env.REACT_APP_FLINKS_LIVE_ENV
