import React from "react"

import Div from "../layouts/Div"

import BankAccountModal from "../modal/BankAccountModal"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class BankAccountSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      createMode: false,
      bankAccount: null,
      stripeAccountId: props.stripeAccountId,
      stripePublicKey: props.stripePublicKey,
      stripeProcessorId: props.stripeProcessorId,
      showBankAccountModal: false
    }
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _load(){
    this.setState({ loadingAccount: true })
    Backend.getBankAccount()
    .then(bankAccount => {
      this.setState({
        bankAccount,
        loading: false,
      })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  _renderReadOnlyBankDetailsSection(){
    let { bankAccount } = this.state
    return (
      <>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Account Holder Name:</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              className="form-control"
              im-insert="true"
              value={bankAccount.account_holder_name}
              disabled
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Account Number:</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              className="form-control form-control-solid"
              im-insert="true"
              value={`**** **** ${bankAccount.last4}`}
              disabled
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Bank:</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              className="form-control form-control-solid"
              im-insert="true"
              value={bankAccount.bank_name}
              disabled
            />
          </div>
        </div>
      </>
    )
  }

  _renderContent(){
    let { bankAccount } = this.state

    if(bankAccount){
      return this._renderReadOnlyBankDetailsSection()
    }

    return (
      <div>
        <p>
          Add a Bank Account in order to enable payouts/payments.
        </p>
      </div>
    )
  }

  render() {
    let {
      loading,
      bankAccount,
      stripeAccountId,
      stripePublicKey,
      stripeProcessorId,
      showBankAccountModal
    } = this.state

    let buttonTitle = bankAccount ? "Update Bank Account" : "Add Bank Account"

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <Div
                className="kt-wizard-v2__content"
                data-ktwizard-type="step-content"
                data-ktwizard-state="current"
                disabled={loading}
                spinner={true}
              >
                <h4 className="mb-10 font-weight-bold text-dark">Bank Details</h4>
                <div className="kt-form__section kt-form__section--first">
                  <div className="kt-wizard-v2__form">
                    { this._renderContent() }
                  </div>
                </div>
              </Div>
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  onClick={e => {
                    e.preventDefault()
                    this.setState({ showBankAccountModal: true })
                  }}
                >
                  {buttonTitle}
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
        <BankAccountModal
          show={showBankAccountModal}
          stripeAccountId={stripeAccountId}
          stripePublicKey={stripePublicKey}
          stripeProcessorId={stripeProcessorId}
          onCancel={() => this.setState({ showBankAccountModal: false})}
          onAccountUpdated={bankAccount => {
            this.setState({
              bankAccount,
              showBankAccountModal: false
            })
            Notify.success("Bank Account Saved")
          }}
        />
      </>
    )
  }
}
