import React from 'react'

import LazyLoadingList from './components/common/LazyLoadingList'

import General from '../utils/General'
import AuthManager from '../utils/AuthManager'
import StatCard from './components/common/StatCard'
import ProductModal from './components/modal/ProductModal'
import SharePaymentLinkModal from './components/modal/SharePaymentLinkModal'
import Currency from '../utils/Currency'
import ShareProductModal from './components/modal/ShareProductModal'
import { Portal } from 'react-portal'
import RequestPaymentModal from './components/modal/RequestPaymentModal'
import Backend from '../utils/Backend'
import ConfirmationModal from './components/modal/ConfirmationModal'
import Notify from '../utils/Notify'
import Splink from '../utils/Splink'
import EngagementCard from './components/common/EngagementCard'
import Event from '../utils/Event'
import Permissions from '../utils/Permissions'

export default class Shop extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      objects: 'active',
      searchTerm: '',
      company: AuthManager.currentUser.company,
      currency: 0,
      stats: undefined,
      endpoint: `${window.Api.Products}?objects=active`,
    }
    this.list = React.createRef()
  }

  componentDidMount() {
    if (!AuthManager.currentUser.company.add_ons.shop) {
      Event.emit('ADD_ON_ALERT', '/')
    }
    this._loadStats()
  }

  _loadStats() {

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      this.setState({ stats: { } })
      return
    }

    Backend.getShopStats().then((stats) => {
      this.setState({
        stats: stats.company,
      })
    })
  }

  refresh() {
    let { objects, searchTerm } = this.state

    let current = this.list.current

    if (current) {
      this.setState(
        {
          endpoint: `${window.Api.Products}?objects=${objects}&search_term=${
            searchTerm || ''
          }`,
        },
        () => this._loadStats()
      )
    }
  }

  _handleSearch = General.debounce(() => {
    this.refresh()
  }, 500)

  _deleteProduct() {
    let { product } = this.state

    Backend.deleteProduct(product)
      .then((channel) => {
        Notify.success('Product has been deleted successfully')
        this.setState({
          product: null,
          showConfirmationModal: false,
        })
        this.list.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          product: null,
          showConfirmationModal: false,
        })
      })
  }

  _restoreProduct() {
    let { deletedProduct } = this.state

    Backend.restoreProduct(deletedProduct)
      .then((channel) => {
        Notify.success('Product has been restored successfully')
        this.setState({
          deletedProduct: null,
          showConfirmationModal: false,
        })
        this.list.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          deletedProduct: null,
          showConfirmationModal: false,
        })
      })
  }

  _renderProduct(product, index) {
    let { company } = this.state
    let status = product.deleted_at ? 'Archived' : 'Active'
    let name = product.name
    let initials = General.getInitials(name)

    let buttonClassName = product.deleted_at ? 'danger' : 'success'

    let url = Splink.getProductUrl(company, product)

    return (
      <div className="product col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className={'card card-custom gutter-b card-stretch'}>
          <div className={'card-body text-center pt-4'}>
            <div className="d-flex justify-content-end">
              <div className="dropdown dropdown-inline">
                <a
                  href="javascript:;"
                  className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="ki ki-bold-more-hor"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                  <ul className="navi navi-hover">
                    <li className="py-4">
                      {Permissions.hasEditPermission(Permissions.PRODUCT) && (
                        <a
                          className="btn btn-clean font-weight-bold btn-sm w-100 text-left"
                          onClick={() => {
                            this.setState({
                              product: product,
                              showProductModal: true,
                            })
                          }}
                        >
                          <i className="la la-edit"></i>
                          Edit Product
                        </a>
                      )}
                      <br />
                      {!product.deleted_at &&
                        Permissions.hasViewPermission(Permissions.PRODUCT) && (
                          <>
                            <a
                              className="btn btn-clean font-weight-bold btn-sm w-100 text-left"
                              href={url}
                              target="_blank"
                            >
                              <i className="la la-eye"></i>
                              View Product
                            </a>
                            <br />
                            <a
                              className="btn btn-clean font-weight-bold btn-sm w-100 text-left"
                              onClick={() => {
                                this.setState({
                                  product: product,
                                  showShareProductModal: true,
                                })
                              }}
                            >
                              <i className="la la-share"></i>
                              Share Product
                            </a>
                          </>
                        )}
                      {!product.deleted_at &&
                        Permissions.hasDeletePermission(
                          Permissions.PRODUCT
                        ) && (
                          <a
                            className="btn btn-clean font-weight-bold btn-sm w-100 text-left"
                            onClick={() => {
                              this.setState({
                                product: product,
                                showConfirmationModal: true,
                              })
                            }}
                          >
                            <i className="la la-trash"></i>
                            Archive Product
                          </a>
                        )}
                      {(product.deleted_at && Permissions.hasEditPermission(
                        Permissions.PRODUCT
                      )) && (
                        <a
                          className="btn btn-clean font-weight-bold btn-sm w-100 text-left"
                          onClick={() => {
                            this.setState({
                              deletedProduct: product,
                              showConfirmationModal: true,
                            })
                          }}
                        >
                          <i className="la la-recycle"></i>
                          Restore Product
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={'mt-7'}>
              {this._renderAvatar(product, buttonClassName, initials)}
            </div>
            <div className={'my-2'}>
              <a
                href="javascript:;"
                className="text-dark font-weight-bold text-hover-primary font-size-h4"
              >
                {name}
              </a>
              <br />
              <a
                href="javascript:;"
                className="text-dark font-weight-bold text-hover-primary"
              >
                {Currency.format(
                  product.payment_plans[0].currency.symbol,
                  product.payment_plans[0].total
                )}
              </a>
              <br />
              <span
                className={`label label-inline label-lg label-light-${buttonClassName} btn-sm font-weight-bold`}
              >
                {status}
              </span>
              <div className={'separator separator-solid my-7'} />
              <a href="javascript:;" className="text-dark text-hover-primary">
                {product.sales_no} Sales
              </a>
              <br />
              <span className="text-dark font-weight-bold">
                {Currency.format(
                  product.payment_plans[0].currency.symbol,
                  product.sales_amount
                )}{' '}
                Total
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderAvatar(product, label, initials) {
    if (product.featured_image) {
      return (
        <div className="img">
          <span
            data-name={product.name}
            style={{
              backgroundImage: 'url(' + product.featured_image.thumbnail + ')',
            }}
          />
        </div>
      )
    }
    return (
      <div className={`symbol symbol-lg-75 symbol-circle symbol-${label}`}>
        <span className="font-size-h3 font-weight-boldest">{initials}</span>
      </div>
    )
  }

  _renderStatCards() {
    let { stats } = this.state

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      return null
    }

    let activeProducts = stats?.shop.active_count || 0
    let productSalesMonth =
      stats?.shop.month.reduce((accum, item) => accum + item.count, 0) || 0
    let totalSales =
      stats?.shop.all.reduce((accum, item) => accum + item.count, 0) || 0

    return (
      <>
        <StatCard
          amount={activeProducts}
          title={'Active Products'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={productSalesMonth}
          title={'Last Month Sales'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={totalSales}
          title={'All Time Sales'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  _renderProductModal() {
    let { stats, product, showProductModal } = this.state

    let numberOfProducts = stats?.shop?.product_count || 0

    if (!showProductModal) return null

    return (
      <ProductModal
        show={showProductModal}
        showOptional={false}
        product={product}
        onHide={() => this.setState({ showProductModal: false, product: null })}
        onAdded={(product) => {
          if (numberOfProducts > 0) {
            this.list.current.refresh()
          } else if(stats.shop){
            stats.shop.product_count = 1
          }
          this.setState({ showProductModal: false, product: null, stats })
        }}
      />
    )
  }

  _renderShareProductModal() {
    let { product, showShareProductModal } = this.state

    if (!showShareProductModal) return null

    return (
      <ShareProductModal
        show={showShareProductModal}
        product={product}
        onHide={() =>
          this.setState({ showShareProductModal: false, product: null })
        }
      />
    )
  }

  _renderConfirmationModal() {
    let { product, deletedProduct, showConfirmationModal } = this.state

    let message = 'Are you sure you want to archive this product?'
    if (deletedProduct) {
      message = 'Are you sure you want to restore this product?'
    }

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={message}
        onConfirm={() => {
          if (product) {
            this._deleteProduct()
          } else if (deletedProduct) {
            this._restoreProduct()
          }
        }}
        onHide={() => {
          this.setState({
            product: null,
            deletedProduct: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  _renderEngagementCard() {
    return (
      <EngagementCard
        title={'Setup A Shop'}
        text={`
            Create simple one-click shop link to
            sell your products and services.
          `}
        buttonTitle={'Add A Product'}
        onClick={() => {
          this.setState({ showProductModal: true })
        }}
      />
    )
  }

  _renderAddButton() {
    if (!Permissions.hasCreatePermission(Permissions.PRODUCT)) {
      return null
    }
    return (
      <a
        href="javascript:;"
        className="btn btn-primary font-weight-bolder float-right"
        onClick={() => this.setState({ showProductModal: true, product: null })}
      >
        <i className="flaticon2-add-1 icon-sm"></i>
        Add Shop Item
      </a>
    )
  }

  render() {
    let { stats, endpoint } = this.state

    if (!stats) return null

    let hasProducts = true
    if(stats?.shop){
      hasProducts = stats?.shop.product_count > 0
    }

    return (
      <div className="page shop">
        <div className={'row'}>{this._renderStatCards()}</div>

        {!hasProducts && this._renderEngagementCard()}

        {hasProducts && (
          <>
            <div className="row">
              <div className={'col-xl-12'}>
                <div className="mb-7">
                  <div className="row align-items-center">
                    <div className="col-lg-8 col-xl-8">
                      <div className="row align-items-center">
                        <div className="col-md-6 my-2 my-md-0">
                          <div className="input-icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onChange={(e) => {
                                this.setState(
                                  {
                                    searchTerm: e.target.value,
                                  },
                                  () => this._handleSearch()
                                )
                              }}
                            />
                            <span>
                              <i className="flaticon2-search-1 text-muted"></i>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 my-2 my-md-0">
                          <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">
                              Status:
                            </label>
                            <div className="select-wrapper w-100">
                              <select
                                className="form-control"
                                name="status"
                                value={this.state.objects}
                                onChange={(e) => {
                                  this.setState(
                                    {
                                      objects: e.target.value,
                                    },
                                    () => this._handleSearch()
                                  )
                                }}
                              >
                                <option value="all">All</option>
                                <option value="active">Active</option>
                                <option value="deleted">Inactive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 mt-5 mt-lg-0">
                      {this._renderAddButton()}
                      <a
                        href={Splink.getShopUrl(
                          AuthManager.currentUser.company
                        )}
                        className="btn btn-light-primary font-weight-bolder float-right mr-4"
                        target={'_blank'}
                      >
                        <span className="svg-icon svg-icon-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              ></path>
                              <path
                                d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z"
                                fill="#000000"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        View Shop
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row products">
              <LazyLoadingList
                ref={this.list}
                endpoint={endpoint}
                renderItem={(product, index) =>
                  this._renderProduct(product, index)
                }
              />
            </div>
          </>
        )}

        {this._renderProductModal()}
        {this._renderShareProductModal()}
        {this._renderConfirmationModal()}
      </div>
    )
  }
}
