import React from 'react'

export default class BankAccountCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      bankAccount: props.bankAccount
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      bankAccount
    } = this.state

    return (
      <div className="d-flex align-items-center">
        <div className="ml-2">
          <div className="text-dark-75 font-weight-bold line-height-sm">{bankAccount.bank_name}</div>
          <a href="javascript:;" className="font-size-sm text-dark-50 text-hover-primary">**** {bankAccount.last4}</a>
        </div>
      </div>
    )
  }
}
