import React from "react"
import moment from "moment";
import Flatpickr from "react-flatpickr";

export default class BulkUploadTableRowDateCell extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      row: props.row,
      rowKey: props.rowKey,
      value: props.value,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      row,
      rowKey,
      value
    } = this.state

    let className = ''
    if(!value){
      className = 'error-outline'
    }

    return (
      <>
        <Flatpickr
          className={`form-control dueDate ${className}`}
          value={value}
          options={{
            enableTime: false,
            noCalendar: false,
            dateFormat: "Y-m-d",
            static: true
          }}
          onChange={dates => {
            let globalDate = moment(dates[0]).format('YYYY-MM-DD')
            this.props.onUpdated(globalDate)
          }}
          style={{width: 100}}
        />
      </>
    )
  }
}
