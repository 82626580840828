import React from 'react'
import StatCard from './components/common/StatCard'
import SubscriptionsTable from './components/tables/SubscriptionsTable'
import Backend from '../utils/Backend'
import DateRange from './components/common/DateRange'
import PaymentRequestsTable from './components/tables/PaymentRequestsTable'
import Event from '../utils/Event'
import Stats from '../utils/Stats'
import AuthManager from '../utils/AuthManager'
import Permissions from '../utils/Permissions'

export default class Recurring extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dateFrom: null,
      dateTo: null,
    }
  }

  componentDidMount() {
    if (!AuthManager.currentUser.company.add_ons.recurring_payments) {
      Event.emit('ADD_ON_ALERT', '/')
    }
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if (window.General.DefaultCurrency) {
      this._loadStats()
    }
  }

  _loadStats() {
    let { dateFrom, dateTo } = this.state

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      this.setState({ stats: { } })
      return
    }

    Backend.getRecurringStats(
      dateFrom?.toISOString() || '',
      dateTo?.toISOString() || ''
    ).then((stats) => {
      this.setState({
        stats: stats.company,
      })
    })
  }

  _renderStatCards() {
    let { stats } = this.state

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      return null
    }

    let noRecurring = stats?.recurring_payments.active_count
    let week = Stats.getRecurringDueThisWeek(stats?.recurring_payments)
    let total = Stats.getAllTimeRecurring(stats?.recurring_payments)

    return (
      <>
        <StatCard
          amount={noRecurring}
          title={'No. of Recurring'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={week}
          title={'Next Week'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={total}
          title={'Amount All time'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let { dateFrom, dateTo, stats } = this.state
    if (!stats) return null

    let recurringPayments = true

    if(stats?.recurring_payments){
      recurringPayments = Stats.getAllTimeRecurringNo(
        stats.recurring_payments
      )
    }

    return (
      <>
        {Permissions.hasViewPermission(Permissions.STATS) && (
          <DateRange
            dateFrom={dateFrom}
            dateTo={dateTo}
            onFromDateUpdated={(dateFrom) => {
              this.setState(
                {
                  dateFrom,
                },
                () => this._loadStats()
              )
            }}
            onToDateUpdated={(dateTo) => {
              this.setState(
                {
                  dateTo,
                },
                () => this._loadStats()
              )
            }}
          />
        )}

        <div className={'row'}>{this._renderStatCards()}</div>
        <SubscriptionsTable
          title={'All Recurring Payments'}
          recurringPayments={recurringPayments}
          dateFrom={dateFrom}
          dateTo={dateTo}
          loadStats={() => this._loadStats()}
        />
      </>
    )
  }
}
