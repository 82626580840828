import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import PaymentForm from "../payment/Form";
import PaymentAttempt from "../payment/PaymentAttempt"

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import AuthManager from "../../../utils/AuthManager"

export default class TakePaymentModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      show: false,
      company: AuthManager.currentUser.company
    }
  }

  componentDidMount() {
    let { company } = this.state
    this.setState({ show: true })
    let savedState = PaymentAttempt.getState()

    Backend.getSplink(company.slug, savedState?.paymentAttemptId)
    .then(company => {
      let paymentPlan = company.settings.payment_plan
      let paymentPlans = [paymentPlan]

      this.setState({
        company,
        savedState,
        paymentPlan,
        paymentPlans,
        loading: false,
      })
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderContent(){
    let {
      loading,
      company,
      savedState,
      paymentPlan,
      paymentPlans
    } = this.state

    if(loading){
      return <p className="text-center">Loading...</p>
    }

    let description = company.settings.collect_note === "description" ? company.description : null

    if(company.payment_methods){
      company.payment_methods.digital_wallet = null
    }

    if(this.props.recurring && paymentPlan.type !== "one_time"){
      paymentPlan.type = "recurring"
    }

    company.settings.primary_color = "#3699FF"
    company.settings.layout = "dashboard"

    return (
      <PaymentForm
        company={company}
        savedState={savedState}
        paymentPlan={paymentPlan}
        paymentPlans={paymentPlans}
        paymentAttemptId={company.payment_attempt_id}
        questions={company.settings.questions}
        description={description}
        collectAddress={company.settings.collect_address}
        collectDescription={company.settings.collect_note === "yes"}
        collectEmail={company.settings.collect_email}
        onPaid={() => {
          if(window._aibACSResponse){
            window.location = "/"
          }
          else{
            this.props.onHide()
          }
        }}
        paymentSuccessModalClassName="sub-modal"
        errorModalClassName="sub-modal"
        dynamicCurrency={true}
      />
    )
  }

  render() {
    let {
      show,
      loading,
    } = this.state


    let title = this.props.recurring ? "Take Payment" : "Create Recurring Payment"

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={this.props.modalClassName}
        dialogClassName={'c-modal take-payment'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Take Payment</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { this._renderContent() }

        </Modal.Body>

      </Modal>
    )
  }
}
