import React, { Component } from "react";

import Event from "../../../utils/Event";
import Currency from "../../../utils/Currency";
import AuthManager from "../../../utils/AuthManager";

export default class Discount extends Component {
  constructor(props) {
    super(props);

    let company = AuthManager.currentUser.company;
    let paymentMethod = company.payment_methods.ach;

    this.state = {
      paymentMethod,
      paymentPlan: props.paymentPlan,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }


  render() {

    let { paymentPlan, paymentMethod } = this.state;

    if(!paymentMethod || paymentMethod.processor.indexOf("tsys") === -1){
      return null
    }

    let discounts = paymentPlan.discounts

    let discount = discounts[0]

    let discountAmount = ""
    if(discount && paymentPlan.total && discount.percentage_off){
      let discountAmountCents = (paymentPlan.total * discount.percentage_off)
      discountAmount = parseFloat(discountAmountCents).toFixed(2);
    }

    return (
      <div className="form-group row" style={{marginTop: -20}}>
        <label className="col-3 col-form-label"></label>
        <div className="col-9">
          <div className="row no-gutters">
            <div className="col-auto mt-3">
              <span className="switch switch-outline switch-icon switch-primary">
                <label>
                  <input
                    type="checkbox"
                    id="allow_discount"
                    checked={paymentPlan.allow_discounts}
                    onChange={(e) => {
                      if(!AuthManager.currentUser.company.add_ons.discounts){
                        Event.emit('ADD_ON_ALERT')
                        return
                      }

                      paymentPlan.allow_discounts = e.target.checked;
                      if(paymentPlan.allow_discounts){
                        paymentPlan.discounts = [{
                          percentage_off: "",
                          conditions: [
                            {
                              payment_method: paymentMethod.id
                            }
                          ]
                        }];
                      }

                      this.props.onUpdated(paymentPlan);
                    }}
                  />
                  <span></span>
                </label>
              </span>
            </div>
            <label
              htmlFor="discount"
              className="col col-form-label align-left pl-3 mt-3"
            >
              Enable Cash Discount
            </label>
          </div>
        </div>
        {(discount && paymentPlan.allow_discounts) && (
          <>
            <div className="col-3"></div>
            <div className="col-9">
              <div className="row" id="vatOptions">
                <div className="col-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        style={{ border: "none" }}
                      >
                        %
                      </span>
                    </div>
                    <input
                      className="form-control form-control-solid"
                      type="number"
                      min={0}
                      defaultValue={discount.percentage_off > 0 ? parseFloat(discount.percentage_off * 100).toFixed(2) : ""}
                      onChange={(e) => {
                        discount.percentage_off = parseFloat(
                          e.target.value / 100
                        ).toFixed(4);

                        paymentPlan.discounts = [discount]
                        this.props.onUpdated(paymentPlan);
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        {paymentPlan.currency.symbol}
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Total"
                      value={Currency.format("", discountAmount)}
                      readOnly
                    />
                  </div>
                  <span className="text-muted">Value of discount</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
