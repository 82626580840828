import React from 'react'

import CompanyInvoicesTable from './components/tables/CompanyInvoicesTable'

import CompanySubscriptionPaymentInstrumentModal from './components/modal/CompanySubscriptionPaymentInstrumentModal'

import PaymentAttempt from './components/payment/PaymentAttempt'

import Currency from "../utils/Currency";
import AuthManager from "../utils/AuthManager";


export default class Billing extends React.Component {
  constructor(props) {
    super(props)

    let company = AuthManager.currentUser.company
    let { settings, subscription } = company
    company.settings.primary_color = "#3699FF"
    company.settings.layout = "dashboard"
    let paymentInstrumentModalCSS = "layout-" + company.settings.layout;

    this.state = {
      company,
      settings,
      subscription,
      showAddOns: false,
      showPaymentInstrumentModal: false,
      paymentInstrumentModalCSS
    }

    this.paymentInstrumentModal = React.createRef()
  }

  componentDidMount(){
    let savedState = localStorage.getItem("payment_instrument_state")
    if(!savedState){
      return
    }

    localStorage.removeItem("payment_instrument_state")
    savedState = JSON.parse(savedState)

    this.setState({
      showPaymentInstrumentModal: true
    }, () => this.paymentInstrumentModal.current.resume(savedState))
  }

  _getPrice(){
    let { subscription } = this.state

    let price = "Free"
    if(subscription.total){
      let frequency = null
      if(subscription.frequency != "one_time"){
        frequency = `per ${subscription.frequency.replace("ly", "")}`
      }
      price = (
        <>
          <b>
            {Currency.format(subscription.currency?.symbol, subscription.total)}
          </b>
          { " "+frequency }
        </>
      )
    }
    return price
  }

  _getPaymentInstrumentTitle(){
    let { subscription } = this.state

    let paymentInstrumentTitle = "None"
    if(subscription.payment_instrument_identifier){
      paymentInstrumentTitle = `**** ${subscription.payment_instrument_identifier}`
    }
    else if(subscription.payment_instrument_data){
      paymentInstrumentTitle = `****`
    }
    return paymentInstrumentTitle
  }

  _getTransactionCharge(){
    let { settings } = this.state

    let transactionFlatCharge = (settings.platform_flat_charge + settings.association_flat_charge)
    let transactionRate = (parseFloat(settings.platform_rate) + parseFloat(settings.association_rate)) * 100
    transactionRate = parseFloat(transactionRate.toFixed(4))

    let transactionCharge = ""
    if(transactionRate == 0 && transactionFlatCharge == 0){
      transactionCharge = "Free"
    }
    if(transactionRate > 0){
      transactionCharge = `${transactionRate}%`
    }
    if(transactionFlatCharge > 0){
      if(transactionRate > 0){
        transactionCharge += ` + `
      }
      let formattedTransactionFlatCharge = ""
      if(transactionFlatCharge >= 100){
        formattedTransactionFlatCharge = Currency.format("", transactionFlatCharge)
      }
      else{
        formattedTransactionFlatCharge = `${transactionFlatCharge}c`
      }
      transactionCharge += formattedTransactionFlatCharge
    }

    return transactionCharge
  }

  _renderSection(label, content){
    return (
      <>
        {label}: <b>{ content }</b>
        <br/>
      </>
    )
  }

  render() {
    let {
      company,
      subscription,
      showAddOns,
      showPaymentInstrumentModal,
      paymentInstrumentModalCSS
    } = this.state

    let pkg = subscription.package

    let price = this._getPrice()

    let transactionCharge = this._getTransactionCharge()

    let paymentInstrumentTitle = this._getPaymentInstrumentTitle()

    let hasPaymentInstrument = subscription.payment_instrument_data != null
    let updatePaymentInstrumentTitle = hasPaymentInstrument ? "Change Payment Method" : "Add Payment Method"
    let updatePaymentInstrumentClassName = hasPaymentInstrument ? "btn-outline-primary" : "btn-primary"
    return (
      <>
        <div className="page payment-attempts">
          <div className="row">
            <div className="col-md-12 c-table-detailss">
              <div className="card card-custom mb-5">
                <div className="card-header flex-wrap border-0 pt-6 pb-0">
                  <div class="card-title mb-0">
                    <h3 class="card-label">
                      Billing
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <a className={`btn ${updatePaymentInstrumentClassName} font-weight-bolder ml-4`}
                      onClick={() => {
                        this.setState({ showPaymentInstrumentModal: true })
                      }}
                    >
                      { updatePaymentInstrumentTitle }
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3 my-auto">
                      { this._renderSection("Package", pkg.title)}
                    </div>
                    <div className="col-3 my-auto">
                      { this._renderSection("Base Price", price)}
                    </div>
                    <div className="col-3 my-auto">
                      { this._renderSection("Payment Method", paymentInstrumentTitle)}
                    </div>
                    <div className="col-3 my-auto">
                      { this._renderSection("Per Transaction Charge", transactionCharge)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 c-table-attemptss">
              <CompanyInvoicesTable
                title={'Invoices'}
              />
            </div>
          </div>
        </div>

        <CompanySubscriptionPaymentInstrumentModal
          ref={this.paymentInstrumentModal}
          show={showPaymentInstrumentModal}
          subscription={subscription}
          onCancel={() => this.setState({ showPaymentInstrumentModal: false})}
          onUpdated={subscription => {
            AuthManager.currentUser.company.subscription = subscription
            this.setState({ subscription, showPaymentInstrumentModal: false })
          }}
          modalClassName={ paymentInstrumentModalCSS }
        />
      </>
    )
  }
}


const ADD_ONS = [
  {
    label: "Payment Dashboard",
    key: "payment_dashboard",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Reverse Fees",
    key: "reverse_fee",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Requests",
    key: "payment_requests",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom URL",
    key: "custom_url",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Take Payment Link",
    key: "payment_link",
    type: "checkbox",
    disabled: false
  },
  {
    label: "IBAN/SEPA Payments",
    key: "sepa",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Channels",
    key: "payment_links",
    type: "checkbox",
    disabled: false
  },
  {
    label: "API Access",
    key: "api",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Attempts",
    key: "payment_attempts",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Notifications",
    key: "payment_notifications",
    type: "checkbox",
    disabled: false
  },
  {
    label: "CRM Integrations",
    key: "crm",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Recurring Payments",
    key: "recurring_payments",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Methods",
    key: "digital_wallet",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom Reminders",
    key: "reminder_schedule",
    type: "checkbox",
    disabled: true
  },
  {
    label: "NFC",
    key: "nfc",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Mobile App",
    key: "mobile_app",
    type: "checkbox",
    disabled: false
  },

  {
    label: "Payment Redirect",
    key: "payment_redirect",
    type: "checkbox",
    disabled: false
  },
  {
    label: "e-Commerce Checkout Links",
    key: "shop",
    type: "checkbox",
    disabled: false
  },
  {
    label: "e-Commerce Checkout Layout",
    key: "shop_layout",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom Email Reply",
    key: "custom_emails",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Website Pay Widget",
    key: "website_pay_widget",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Additional Page Design Templates",
    key: "splink_layouts",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Bulk Payment Requests",
    key: "bulk_payment_requests",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Attach Invoice To PR",
    key: "payment_request_attachment",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom Data Capture",
    key: "questions",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Nudge",
    key: "payment_request_nudge",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Fundraising Progress Bar",
    key: "payment_progress_bar",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Export",
    key: "export",
    type: "checkbox",
    disabled: false
  },
  {
    label: "QR Codes",
    key: "qr_codes",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Cash Discounting",
    key: "discounts",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Team Members",
    key: "team_members",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Additional Team Members",
    key: "additional_team_members",
    type: "number",
    disabled: false
  },
  {
    label: "Team Roles",
    key: "roles",
    type: "checkbox",
    disabled: false
  },
  {
    label: "SMS Notifications",
    key: "sms_limit",
    type: "number",
    disabled: false
  },
]
