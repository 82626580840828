import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {CopyToClipboard} from 'react-copy-to-clipboard';

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'
import moment from "moment";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import Notify from "../../../utils/Notify"
import AuthManager from "../../../utils/AuthManager";
import Splink from "../../../utils/Splink";
import Backend from "../../../utils/Backend";

export default class ResumeSubscriptionModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      company: AuthManager.currentUser.company,
      loading: false,
      error: null,
      nextInvoiceDueDate: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      title: props.title,
      show: props.show,
      subscription: props.subscription
    }
  }

  _isFormValid(){
    let {
      nextInvoiceDueDate
    } = this.state

    let error = null
    if(!nextInvoiceDueDate){
      error = "Please specify a 'Next Invoice Due Date'"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _resume(){
    let {
      subscription,
      nextInvoiceDueDate
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ isLoading: true })
    Backend.resumeSubscription(subscription, nextInvoiceDueDate)
    .then(subscription => {
      this.setState({ isLoading: false })
      Notify.success('Subscription has been resumed successfully')
      this.props.onResumed(subscription)
    })
    .catch(error => {
      this.setState({ isLoading: true })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      title,
      body,
      company,
      subscription,
      nextInvoiceDueDate
    } = this.state

    return (
      <Modal
        show={show}
        dialogClassName={'modal-dialog-centered modal-md'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Resume Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Next Invoice Due Date*</label>
            <div className="input-group date">

              <div className="input-group-prepend">
                <span className="input-group-text border-0">
                  <i className="la la-calendar"></i>
                </span>
              </div>

              <Flatpickr
                className="form-control form-control-solid"
                options={{
                  enableTime: false,
                  noCalendar: false,
                  dateFormat: "Y-m-d"
                }}
                onChange={dates => {
                  nextInvoiceDueDate = moment(dates[0]).format('YYYY-MM-DDThh:mm')
                  this.setState({ nextInvoiceDueDate })
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button type="button" className={`btn btn-light-${this.props.theme} font-weight-bold`}
                  onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button type="button" className={`btn btn-${this.props.theme} font-weight-bold`}
                  onClick={() => this._resume()}
          >
            Resume Subscription
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

ResumeSubscriptionModal.defaultProps = {
  confirmTitleText: "Confirm",
  theme: "primary",
  dismissTitleText: "Dismiss"
}
