import React from "react"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import Splink from "../../../utils/Splink";
import Event from "../../../utils/Event"

export default class ShopSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: props.loading,
      company: props.company,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      company,
      loading
    } = this.state

    let currency = General.getSingleAsyncOption(company.settings.payment_plan?.currency) || null

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <h4 className="mb-10 font-weight-bold text-dark">Control your shop settings</h4>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Shop Type</label>
                  <div className="col-9">
                    <div className="select-wrapper">
                      <select
                        className="form-control form-control-solid"
                        value={company.settings.shop_layout}
                        onChange={e => {
                          if(!AuthManager.currentUser.company.add_ons.shop_layout){
                            Event.emit('ADD_ON_ALERT', '/settings/shop')
                            return
                          }
                          company.settings.shop_layout = e.target.value
                          this.props.onUpdated(company)
                        }}
                      >
                        <option value="express">Drop Down View</option>
                        <option value="standard">Product Tile View</option>
                      </select>
                    </div>
                    <span className="form-text text-muted">Do you want to display your products in traditional tile view, or if you don't have product images, just use a simple drop down view</span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Show Out Of Stock Products</label>
                  <div className="col-9">
                    <div className="select-wrapper">
                      <select
                        className="form-control form-control-solid"
                        value={company.settings.shop_show_out_of_stock}
                        onChange={e => {
                          company.settings.shop_show_out_of_stock = e.target.value
                          this.props.onUpdated(company)
                        }}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                </div>

            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    this.props.onSave()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
}
