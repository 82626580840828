import React from "react"

import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'

export default class FileUpload extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      fileId: props.fileId,
      name: props.name,
      status: props.status,
      params: props.params,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onFileChange(e) {
    let {
      endpoint,
      params
    } = this.state

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]
    if(file == null){
      return
    }
    this.setState({ loading: true })

    let slug = AuthManager.currentUser.company.settings.live ? "stripe" : "stripe_test"
    Backend.uploadFile(file, `${window.Api.Connect}/${slug}/files`, params)
      .then(response => {
        this.setState({
          fileId: response.id,
          loading: false
        })
        this.props.onUploaded(response.id)
      })
      .catch(error => {
        this.setState({ loading: false })
        alert(error.message)
      })

  }

  _renderFileInput(){
    let {
      fileId,
      name,
      status,
      loading
    } = this.state

    if(fileId == null){
      return (
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            accept=".pdf,.png,.jpg"
            onChange={e => this._onFileChange(e)}
          />
          <label className="custom-file-label">Choose file</label>
        </div>
      )
    }

    return (
      <>
        <div className="my-auto">
          { status }
        </div>
        <a
          href="javascript:;"
          className="btn btn-sm btn-label-danger btn-bold ml-auto"
          onClick={() => {
            this.setState({ fileId: null })
            this.props.onRemoved()
          }}
        >
          <i className="la la-trash-o align-select-centers"
             style={{
               marginRight: 0,
               color: 'white'
             }}
          />
        </a>
      </>
    )
  }

  render() {

    let {
      fileId,
      name,
      loading
    } = this.state

    let label = fileId ? name : "Choose file"
    return (
      <Div disabled={this.state.loading}>
        <div className="input-group">
          { this._renderFileInput() }
        </div>
      </Div>
    )
  }
}
