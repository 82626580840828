import React from "react"
import PackageShimmerView from './shimmer/PackageShimmerView'

const LOGO = require('../../assets/media/logos/logo-light.png')
const LOGIN_VISUAL = require('../../assets/media/icons/svg/illustrations/login-visual-1.svg')

export default class PackageView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      pkg: props.pkg,
      package_slug: props.package_slug
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderBenefits() {
    let {pkg} = this.state
    if (!pkg) {
      return null
    }
    return (
      <ul className="list-unstyled">
        {pkg.benefits.map((benefit, index) => {
          return (
            <li className="d-flex p-2 pl-10 pr-10" style={{ color: 'white' }}>
              <span className="d-flex flex-column flex-center">
                <i
                  className="flaticon2-check-mark"
                  style={{ color: 'white' }}
                />
              </span>
              <div className="ml-10">
                <h5 className={index > 5 ? "font-weight-bolder" : 'font-weight-normal'}>{benefit.title}</h5>
                { benefit.subtitle &&
                  <p>{benefit.subtitle}</p>
                }
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  _renderDefaultPackageView() {
    return (
      <div
        className="login-aside d-flex flex-column flex-row-auto"
        style={{ backgroundColor: '#2d97fb' }}
      >
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 pl-25 pr-25 text-center">
          <a href="#" className="mb-10">
            {this._renderLogo()}
          </a>
          <h3
            className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10"
            style={{ color: '#FFFFFF' }}
          >
            Take payment in
            <br /> a variety of different ways
          </h3>
        </div>
        <div
          className="d-none aside-img d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          style={{ backgroundImage: `url(${LOGIN_VISUAL})` }}
        ></div>
      </div>
    )
  }

  _renderLogo(url) {
    if (!url) {
      return (
        <img
          src={ window.General.Branding.Banner }
          alt="Logo"
          className="max-h-70px max-w-80per"
          alt=""
        />
      )
    }
    return (
      <img
        src={url}
        alt="foreground Logo"
        className="max-h-70px img-fluid"
        alt=""
      />
    )
  }

  _renderPackageView() {
    let {pkg} = this.state
    if(!pkg){
      return null
    }
    let background = pkg.background ? pkg.background.original : null
    return (
      <div
        className="login-aside d-flex flex-column flex-row-auto"
        style={{
          backgroundColor: '#2d97fb',
          backgroundImage: 'url(' + background + ')',
          height: '100vh',
          width: '100%',
          backgroundSize: 'cover',
        }}
      >
        <div
          style={{
            height: '100vh',
            width: '100%',
            backgroundColor: background ? 'rgba(0,0,0,0.5)' : 'transparent',
          }}
        >
          <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 pl-25 pr-25 text-center">
            <a href="#" className="mb-10">
              {this._renderLogo(
                pkg.foreground ? pkg.foreground.original : null
              )}
            </a>
            <h3
              className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10"
              style={{ color: '#FFFFFF' }}
            >
              Take payment in
              <br /> a variety of different ways
            </h3>
          </div>
          { pkg.description &&
            <h4
              className="text-center font-size-h4 ml-10 mr-10"
              style={{ color: '#FFFFFF' }}
            >
              {pkg.description}
            </h4>
          }
          <div className="mt-5 d-flex flex-center">
            {this._renderBenefits()}
          </div>
          {this._renderBackgroundImage()}
        </div>
      </div>
    )
  }

  _renderBackgroundImage() {
    let {pkg} = this.state
    if (pkg) {
      return null
    }
    return (
      <div
        className="d-none aside-img d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
        style={{ backgroundImage: `url(${LOGIN_VISUAL})` }}
      ></div>
    )
  }

  _renderShimmerView() {
    return <PackageShimmerView />
  }

  _renderPackageContainer() {
    let { pkg, isLoading, package_slug } = this.state
    if (package_slug && !pkg) {
      return this._renderShimmerView()
    }
    if (pkg && !isLoading) {
      return this._renderPackageView()
    }
    return this._renderDefaultPackageView()
  }

  render(){
    let { pkg, isLoading, package_slug } = this.state
    if (package_slug && !pkg) {
      return this._renderShimmerView()
    }
    if (pkg && !isLoading) {
      return this._renderPackageView()
    }
    return this._renderDefaultPackageView()
  }
}
