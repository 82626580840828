import React from 'react'

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'
import Email from '../../../utils/Email'
import Notify from '../../../utils/Notify'
import General from '../../../utils/General'
import AsyncSelect from '../common/AsyncSelect'
import Select from '../common/CustomSelect'
import Splink from '../../../utils/Splink'
import Event from '../../../utils/Event'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import 'flatpickr/dist/flatpickr.min.css'

const DEFAULT_DATA = {
  payments: true,
  payment_requests: true,
  payment_links: true,
  shop: true,
  open: true,
  paid_out: null,
  refunded: null,
  subscriptions: true,
  payouts: true,
  company_members: true,
  payment_requests_paid: null,
}
const PAYMENT_REQUEST_PAID_TYPES = [
  {
    label: "Export Payment Requests that have and haven't been paid",
    value: null,
  },
  {
    label: 'Only export Payment Requests that have been paid',
    value: true,
  },
  {
    label: 'Only export Payment Requests that have not been paid',
    value: false,
  },
]

const CHANNEL_TYPES = [
  {
    label: 'Include payments from channels',
    value: true,
  },
  {
    label: 'Exclude payments from channels',
    value: false,
  },
]

const SHOP_TYPES = [
  {
    label: 'Include payments from shop products',
    value: true,
  },
  {
    label: 'Exclude payments from shop products',
    value: false,
  },
]

let OPEN_TYPES = [
  {
    label: `Include payments from your 'open'/main ${window.General.Branding.LinkName}`,
    value: true,
  },
  {
    label: `Exclude payments from your 'open'/main ${window.General.Branding.LinkName}`,
    value: false,
  },
]

const PAID_OUT_TYPES = [
  {
    label: "Export payments that have and haven't been paid out",
    value: null,
  },
  {
    label: 'Only export payments that have been paid out',
    value: true,
  },
  {
    label: 'Only export payments that have not been paid out',
    value: false,
  },
]

const REFUNDED_TYPES = [
  {
    label: "Export payments that have and haven't been refunded",
    value: null,
  },
  {
    label: 'Only export payments that have been refunded',
    value: true,
  },
  {
    label: 'Only export payments that have not been refunded',
    value: false,
  },
]

const COMPANY_MEMBER_TYPES = [
  {
    label: 'Include team members',
    value: true,
  },
  {
    label: 'Exclude team members',
    value: false,
  },
]

export default class ExportSettings extends React.Component {
  constructor(props) {
    super(props)

    OPEN_TYPES = [
      {
        label: `Include payments from your 'open'/main ${window.General.Branding.LinkName}`,
        value: true,
      },
      {
        label: `Exclude payments from your 'open'/main ${window.General.Branding.LinkName}`,
        value: false,
      },
    ]

    this.state = {
      data: { ...DEFAULT_DATA },
      defaultMaxDate: moment()
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59),
      loading: props.loading,
      company: props.company,
    }
  }

  componentDidMount() {
    if (!AuthManager.currentUser.company.add_ons.export) {
      Event.emit('ADD_ON_ALERT', '/settings/general')
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _resetData() {
    this.setState({ data: { ...DEFAULT_DATA } })
  }

  _exportClicked() {
    let { data } = this.state
    Backend.export(data)
      .then(() => {
        this.props.onExported()
        this._resetData()
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  render() {
    let { data, company, loading } = this.state
    let starts_at = moment(data.starts_at).format('YYYY-MM-DD')
    let minDate = moment(starts_at + ' 23:59:59').format('DD-MM-YYYY HH:mm:ss')
    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h4 className="mb-10 font-weight-bold text-dark">Export</h4>

              <div className="form-group row">
                <label className="col-3 col-form-label">Min Date</label>
                <div className="col-9">
                  <div className="input-group date">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="la la-calendar"></i>
                      </span>
                    </div>

                    <Flatpickr
                      value={
                        data.starts_at
                          ? moment(data.starts_at).format('DD-MM-YYYY HH:mm:ss')
                          : null
                      }
                      className="form-control"
                      placeholder="From"
                      options={{
                        enableTime: true,
                        noCalendar: false,
                        dateFormat: 'd-m-Y H:i:s',
                        minuteIncrement: 1,
                        enableSeconds: true,
                        time_24hr: true,
                        defaultHour: 0,
                        defaultMinute: 0,
                        defaultSeconds: 0,
                      }}
                      onChange={(dates) => {
                        data.starts_at = moment(dates[0]).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                        this.setState({ data })
                      }}
                    />
                  </div>
                  <span className="form-text text-muted">
                    The minimum date of a payment
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Max Date</label>
                <div className="col-9">
                  <div className="input-group date">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="la la-calendar"></i>
                      </span>
                    </div>

                    <Flatpickr
                      value={
                        data.ends_at
                          ? moment(data.ends_at).format('DD-MM-YYYY HH:mm:ss')
                          : null
                      }
                      className="form-control"
                      placeholder="To"
                      options={{
                        minDate: data.starts_at ? minDate : null,
                        enableTime: true,
                        noCalendar: false,
                        dateFormat: 'd-m-Y H:i:s',
                        minuteIncrement: 1,
                        enableSeconds: true,
                        time_24hr: true,
                        defaultHour: 23,
                        defaultMinute: 59,
                        defaultSeconds: 59,
                      }}
                      onChange={(dates) => {
                        data.ends_at = moment(dates[0]).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                        this.setState({ data })
                      }}
                    />
                  </div>
                  <span className="form-text text-muted">
                    The maximum date of a payment
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Payment Requests</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <Select
                      value={PAYMENT_REQUEST_PAID_TYPES.find(
                        (payment_requests) =>
                          payment_requests.value === data?.payment_requests_paid
                      )}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      name="account_holder_type"
                      placeholder="Please Select An Option"
                      onChange={(option) => {
                        data.payment_requests_paid = option.value
                        this.setState({ data })
                      }}
                      options={PAYMENT_REQUEST_PAID_TYPES}
                    />
                  </div>
                  <span className="form-text text-muted">
                    Include/Exclude payments from payment requests
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Channels</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <Select
                      value={CHANNEL_TYPES.find(
                        (payment_links) =>
                          payment_links.value === data?.payment_links
                      )}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      name="account_holder_type"
                      placeholder="Please Select An Option"
                      onChange={(option) => {
                        data.payment_links = option.value
                        this.setState({ data })
                      }}
                      options={CHANNEL_TYPES}
                    />
                  </div>
                  <span className="form-text text-muted">
                    Include/Exclude payments from channels
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Shop</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <Select
                      value={SHOP_TYPES.find(
                        (shop) => shop.value === data?.shop
                      )}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      name="account_holder_type"
                      placeholder="Please Select An Option"
                      onChange={(option) => {
                        data.shop = option.value
                        this.setState({ data })
                      }}
                      options={SHOP_TYPES}
                    />
                  </div>
                  <span className="form-text text-muted">
                    Include/Exclude payments from shop products
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Open</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <Select
                      value={OPEN_TYPES.find(
                        (open) => open.value === data?.open
                      )}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      name="account_holder_type"
                      placeholder="Please Select An Option"
                      onChange={(option) => {
                        data.open = option.value
                        this.setState({ data })
                      }}
                      options={OPEN_TYPES}
                    />
                  </div>
                  <span className="form-text text-muted">
                    Include/Exclude from your 'open'/main{' '}
                    {window.General.Branding.LinkName}
                  </span>
                </div>
              </div>

              <div
                className="accordion accordion-light accordion-light-borderless accordion-svg-toggle"
                id="accordionExample7"
              >
                <div className="card">
                  <div className="card-header" id="headingOne7">
                    <div
                      className="card-title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseOne7"
                      aria-expanded="false"
                    >
                      <span className="svg-icon svg-icon-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                              d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            ></path>
                            <path
                              d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                              transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      <div className="card-label pl-4">Advanced Options</div>
                    </div>
                  </div>
                  <div
                    id="collapseOne7"
                    className="collapse"
                    data-parent="#accordionExample7"
                  >
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Paid Out</label>
                      <div className="col-9">
                        <div className="select-wrapper">
                          <Select
                            value={PAID_OUT_TYPES.find(
                              (paid_out) => paid_out.value === data?.paid_out
                            )}
                            className="async-select-paginate"
                            classNamePrefix="async-select-paginate"
                            name="account_holder_type"
                            placeholder="Please Select An Option"
                            onChange={(option) => {
                              data.paid_out = option.value
                              this.setState({ data })
                            }}
                            options={PAID_OUT_TYPES}
                          />
                        </div>
                        <span className="form-text text-muted">
                          Export payments that have and haven't been paid out
                        </span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Refunded</label>
                      <div className="col-9">
                        <div className="select-wrapper">
                          <Select
                            value={REFUNDED_TYPES.find(
                              (refunded) => refunded.value === data?.refunded
                            )}
                            className="async-select-paginate"
                            classNamePrefix="async-select-paginate"
                            name="account_holder_type"
                            placeholder="Please Select An Option"
                            onChange={(option) => {
                              data.refunded = option.value
                              this.setState({ data })
                            }}
                            options={REFUNDED_TYPES}
                          />
                        </div>
                        <span className="form-text text-muted">
                          Export payments that have and haven't been refunded
                        </span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Team Members
                      </label>
                      <div className="col-9">
                        <div className="select-wrapper">
                          <Select
                            value={COMPANY_MEMBER_TYPES.find(
                              (companyMemberType) =>
                                companyMemberType.value ===
                                data?.company_members
                            )}
                            className="async-select-paginate"
                            classNamePrefix="async-select-paginate"
                            name="account_holder_type"
                            placeholder="Please Select An Option"
                            onChange={(option) => {
                              data.company_members = option.value
                              this.setState({ data })
                            }}
                            options={COMPANY_MEMBER_TYPES}
                          />
                        </div>
                        <span className="form-text text-muted">
                          Export team members
                        </span>
                      </div>
                    </div>
                    { false &&
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Filter</label>
                      <div className="col-9">
                        <div className="select-wrapper">
                          <AsyncSelect
                            endpoint={window.Api.CompanyMembers}
                            placeholder={'Select A Team Member'}
                            isClearable={true}
                            onSelected={(value) => {
                              data.company_member = value
                              this.setState({ data })
                            }}
                            getOptions={(company_members) => {
                              return company_members.map((company_member) => ({
                                value: company_member.user.id,
                                label:
                                  company_member.user.first_name +
                                  ' ' +
                                  company_member.user.last_name,
                                data: company_member,
                              }))
                            }}
                          />
                        </div>
                        <span className="form-text text-muted">
                          Filter exported data by team member
                        </span>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{ margin: '0 auto' }}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault()
                    this._exportClicked()
                  }}
                >
                  Export
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
      </>
    )
  }
}
