import React from 'react'
import { withRouter } from 'react-router-dom'

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'
import Notify from '../../../utils/Notify'

const VERIFIED = 'verified'
const PENDING = 'pending'
const UNVERIFIED = 'unverified'
class Progress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: props.company,
      progress: props.progress,
    }
  }

  componentDidMount() {
    this._setProgressData()
  }

  _setProgressData() {
    let { company } = this.state
    let companyProcessor = company.company_processors.find(
      (companyProcessor) => companyProcessor.default
    )
    let data = {
      verification_status: companyProcessor.data.verification_status,
      bank_connected: companyProcessor.bank_connected,
    }
    this.setState({ data })
  }

  _getVerificationStatus(data) {
    switch (data.verification_status) {
      case VERIFIED:
        return 'success'
        break
      case PENDING:
        return 'info'
        break
      case UNVERIFIED:
        return 'danger'
        break
      default:
    }
  }

  _renderCompletedIcon() {
    return (
      <div className="symbol symbol-40 symbol-light-success mr-3 flex-shrink-0">
        <div className="symbol-label">
          <span className="svg-icon svg-icon-lg svg-icon-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                />
              </g>
            </svg>
          </span>
        </div>
      </div>
    )
  }

  _renderPendingIcon() {
    return (
      <div className="symbol symbol-40 symbol-light-info mr-3 flex-shrink-0">
        <div className="symbol-label">
          <span className="svg-icon svg-icon-lg svg-icon-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <path
                  d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <path
                  d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </span>
        </div>
      </div>
    )
  }

  _renderIncompleteIcon() {
    return (
      <div className="symbol symbol-40 symbol-light-danger mr-3 flex-shrink-0">
        <div className="symbol-label">
          <span className="svg-icon svg-icon-lg svg-icon-danger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  fill="#000000"
                >
                  <rect x={0} y={7} width={16} height={2} rx={1} />
                  <rect
                    transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                    x={0}
                    y={7}
                    width={16}
                    height={2}
                    rx={1}
                  />
                </g>
              </g>
            </svg>
          </span>
        </div>
      </div>
    )
  }

  _renderStatusIcon(status) {
    switch (status) {
      case 'success':
        return this._renderCompletedIcon()
        break
      case 'danger':
        return this._renderIncompleteIcon()
        break
      case 'info':
        return this._renderPendingIcon()
        break
      default:
    }
  }

  _renderCompletedSubtitle() {
    return (
      <div className="font-size-sm text-muted font-weight-bold mt-1">
        You've already completed this step
      </div>
    )
  }

  _renderPendingSubtitle() {
    return (
      <div className="font-size-sm text-muted font-weight-bold mt-1">
        This step is currently pending
      </div>
    )
  }

  _renderCompleteButton() {
    return (
      <a
        href="javascript:;"
        className="btn btn-secondary w-150px font-weight-bold py-4 px-3 font-size-base"
      >
        Complete
      </a>
    )
  }

  _renderPendingButton() {
    return (
      <a
        href="javascript:;"
        className="btn btn-info w-150px font-weight-bold py-4 px-3 font-size-base"
      >
        Pending
      </a>
    )
  }

  _renderActionButton(url, text) {
    return (
      <a
        onClick={() => {
          this.props.history.push(url)
        }}
        className="btn btn-primary w-150px font-weight-bold py-4 px-3 font-size-base"
      >
        {text}
      </a>
    )
  }

  _renderCreateSplink() {
    return (
      <div className="d-flex todo-card align-items-center justify-content-between p-1 pl-5 pr-5">
        <div className="d-flex align-items-center mr-2">
          {this._renderStatusIcon('success')}
          <div>
            <a
              href="javascript:;"
              className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
            >
              Create Your {window.General.Branding.LinkName}
            </a>
            {this._renderCompletedSubtitle()}
          </div>
        </div>
        {this._renderCompleteButton()}
      </div>
    )
  }

  _renderConnectBankAccount() {
    let { data } = this.state
    return (
      <div className="d-flex todo-card align-items-center justify-content-between p-1 pl-5 pr-5">
        <div className="d-flex align-items-center mr-2">
          {this._renderStatusIcon(data.bank_connected ? 'success' : 'danger')}
          <div>
            <a
              onClick={() => {
                this.props.history.push('/settings/bank-account')
              }}
              className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
            >
              Connect Your Bank Account
            </a>
            {!data.bank_connected && (
              <div className="font-size-sm text-muted font-weight-bold mt-1">
                To connect your bank account we need your account name, <br />{' '}
                type, currency and IBAN
              </div>
            )}
            {data.bank_connected && this._renderCompletedSubtitle()}
          </div>
        </div>
        {data.bank_connected
          ? this._renderCompleteButton()
          : this._renderActionButton('/settings/bank-account', 'Connect')}
      </div>
    )
  }

  _renderVerifyIdentification() {
    let { data } = this.state
    let status = this._getVerificationStatus(data)
    return (
      <div className="d-flex todo-card align-items-center justify-content-between p-1 pl-5 pr-5">
        <div className="d-flex align-items-center mr-2">
          {this._renderStatusIcon(status)}
          <div>
            <a
              onClick={() => {
                this.props.history.push('/settings/kyc')
              }}
              className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
            >
              Verify Your Identification
            </a>
            {data.verification_status == UNVERIFIED && (
              <div className="font-size-sm text-muted font-weight-bold mt-1">
                For ID verification we need your Passport or Drivers License,{' '}
                <br />
                as well as proof of address in the form of a Utility Bill.
              </div>
            )}
            {data.verification_status == PENDING &&
              this._renderPendingSubtitle()}
            {data.verification_status == VERIFIED &&
              this._renderCompletedSubtitle()}
          </div>
        </div>
        {data.verification_status == UNVERIFIED && this._renderActionButton('/settings/kyc', 'Verify')}
        {data.verification_status == PENDING && this._renderPendingButton()}
        {data.verification_status == VERIFIED && this._renderCompleteButton()}
      </div>
    )
  }

  render() {
    let { progress, data } = this.state
    if (!data) {
      return null
    }
    return (
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body d-flex p-0 card-rounded">
          <div className="flex-grow-1 p-8 pt-5 card-rounded flex-grow-1 bgi-no-repeat">
            <h4 className="text-dark">
              You're{' '}
              <span
                id="progress-percent"
                className="text-primary font-weight-bolder"
              >
                {Math.floor(progress)}
              </span>
              <span className="text-primary font-weight-bolder">%</span> of the
              way there!
            </h4>
            <span className="text-muted font-size-lg font-weight-bold mb-2">
              Make sure your {window.General.Branding.LinkName} account is fully activated to receive your
              payouts
            </span>
            <div className="progress mt-5">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                role="progressbar"
                style={{ width: progress.toString() + '%' }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <div className="mt-15">
              {this._renderCreateSplink()}
              <hr />
              {this._renderConnectBankAccount()}
              <hr />
              {this._renderVerifyIdentification()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Progress)
