import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Notify from '../../../utils/Notify'
import AuthManager from '../../../utils/AuthManager'
import Splink from '../../../utils/Splink'

export default class AlertModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      loading: false,
      error: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      title: props.title,
      subtitle: props.subtitle,
      show: props.show,
    }
  }

  render() {
    let { title, subtitle, show } = this.state

    return (
      <Modal show={show} dialogClassName={'modal-dialog-centered modal-md'}>
        <Modal.Body>
          <div style={{ position: 'absolute', top: 20, right: 20 }}>
            <a
              onClick={() => this.props.onConfirm()}
              href="javascript:;"
              className="btn btn-clean btn-hover-light-warning btn-sm btn-icon"
            >
              <i className="ki ki-bold-close"></i>
            </a>
          </div>
          <div
            className="swal2-icon swal2-warning swal2-icon-show"
            style={{ display: 'flex' }}
          >
            <div className="swal2-icon-content">i</div>
          </div>
          <h3 className="text-center">
            This 'add-on' is not in your current package
          </h3>
          <h4 className="text-center pt-5">
            Please chat with us if you would like to trial this feature!
          </h4>
        </Modal.Body>
        <Modal.Footer>
          { (!window.General.AssociationId || window.General.Branding.AddOnsUrl) &&
            <a
              href={window.General.Branding.AddOnsUrl || window.General.WebsiteUrl + "/#add-ons"}
              target="_blank"
              style={{ width: '48%' }}
              className="btn btn-outline-primary font-weight-bold btn-lg"
            >
              View Add-On's
            </a>
          }
          {
            (!window.General.AssociationId || window.General.Branding.SupportUrl) &&
            <button
              type="button"
              style={{ width: '48%' }}
              className="btn btn-primary font-weight-bold btn-lg "
              onClick={() => this.props.onChatClicked()}
            >
              Chat with Support
            </button>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}
