import $ from 'jquery'

export default class General {
  static uuid() {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
    return uuid
  }

  static debounce(func, wait, immediate) {
    var timeout
    return function () {
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  static snakeCaseToTitleCase(str) {
    let words = str.replace(/_/g, ' ')
    return General.toTitleCase(words)
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  static getInitials(str) {
    var initials = str.match(/\b\w/g) || []
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    return initials
  }

  static getRandomColorState() {
    let colors = [
      'info',
      'success',
      'warning',
      'danger',
      'dark',
      'primary',
      'brand',
    ]
    let color = colors[Math.floor(Math.random() * colors.length)]
    return color
  }

  static formatTimeToAmPm(hours) {
    let AmOrPm = hours * 1 >= 12 ? 'pm' : 'am'
    hours = hours % 12 || 12
    return `${hours}${AmOrPm}`
  }

  static getSingleAsyncOption(value) {
    if (!value || typeof value !== 'object') return ''
    return {
      value: value.id,
      label: `${value.name}`,
      data: value,
    }
  }

  static getAsyncOptions(values) {
    return values.map((value) => ({
      value: value.id,
      label: `${value.name}`,
      data: value,
    }))
  }

  static toOrdinal(number) {
    let x = number % 10
    let y = number % 100

    if (x === 1 && y !== 11) {
      return `${number}st`
    }
    if (x === 2 && y !== 13) {
      return `${number}nd`
    }
    if (x === 3 && y !== 13) {
      return `${number}rd`
    }
    return `${number}th`
  }

  static scrollTo(selector) {
    $('html, body').animate({ scrollTop: $(selector).position().top }, 'slow')
  }

  static getQueryVariable(variable) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (pair[0] == variable) {
        return pair[1]
      }
    }
    return false
  }

  static preparePaymentPlan(paymentPlan, zero = false) {
    // store the 'origina;' plan so we can always reference the original values
    // if/where needed while allowing us to edit and send the plan easily
    paymentPlan = JSON.parse(JSON.stringify(paymentPlan))
    if (!paymentPlan._original) {
      paymentPlan._original = JSON.parse(JSON.stringify(paymentPlan))
    }

    if (paymentPlan.type === 'optional') {
      paymentPlan.type = 'one_time'
    }

    if (paymentPlan.type === 'recurring' && !paymentPlan.frequency) {
      paymentPlan.frequency = 'weekly'
    }

    if (paymentPlan.reverse_fee === 'optional') {
      paymentPlan.reverse_fee = 'no'
    }

    if (!paymentPlan.selected_products) {
      paymentPlan.selected_products = []
    }

    if (zero) {
      if (paymentPlan.id) {
        delete paymentPlan.id
      }
      paymentPlan.vat = 0
      paymentPlan.subtotal = 0
      paymentPlan.total = 0
    }

    return paymentPlan
  }

  static clone(object) {
    if (object == null) {
      return object
    }

    return JSON.parse(JSON.stringify(object))
  }

  static loadScript(url){
    const script = document.createElement('script')

    script.src = url

    document.body.appendChild(script)
  }
}
