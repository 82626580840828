import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'

import { Link } from 'react-router-dom'
import { Portal } from 'react-portal'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from '../../../utils/Currency'
import Notify from '../../../utils/Notify'
import Permissions from '../../../utils/Permissions'

import DeleteCell from './cells/DeleteCell'
import PaidCell from './cells/PaidCell'
import RecurringCell from './cells/RecurringCell'
import PillCell from './cells/PillCell'
import CustomerCell from './cells/CustomerCell'
import PaymentProcessedCell from './cells/PaymentProcessedCell'
import PaymentFeeCell from './cells/PaymentFeeCell'
import PaymentNetCell from './cells/PaymentNetCell'

import ConfirmationModal from '../modal/ConfirmationModal'
import SharePaymentLinkModal from '../modal/SharePaymentLinkModal'
import TakePaymentModal from '../modal/TakePaymentModal'

import DownloadCell from './cells/DownloadCell'
import SendCell from './cells/SendCell'
import DisplayCDC from '../splink/DisplayCDC'
import EngagementCard from '../common/EngagementCard'
import AuthManager from '../../../utils/AuthManager'
import ExpanderCell from './cells/ExpanderCell'

const TYPE_FILTERS = {
  name: {
    api: 'recurring',
    display: 'recurring',
  },
  values: [
    {
      label: 'All Payments',
      value: null,
    },
    {
      label: 'Recurring',
      value: 'true',
    },
    {
      label: 'One-Off',
      value: 'false',
    },
  ],
}

const STATUS_FILTERS = {
  name: {
    api: 'type',
    display: 'type',
  },
  values: [
    {
      label: 'All Add-Ons',
      value: null,
    },
    {
      label: 'Take',
      value: 'take',
    },
    {
      label: 'Request',
      value: 'payment_request',
    },
    {
      label: 'Shop',
      value: 'shop',
    },
  ],
}

const FILTERS = [TYPE_FILTERS, STATUS_FILTERS]

export default class PaymentsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      company: AuthManager.currentUser.company,
      paymentsProcessed: props.paymentsProcessed,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      payout: props.payout,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    let { company } = this.state

    if (company.live_payments_no > 0) {
      this.table.current.refresh()
    }
  }

  _getColumns() {
    let columns = [
      {
        expander: true,
        Expander: ({ isExpanded, ...props }) => (
          <ExpanderCell
            isExpanded={isExpanded}
            show={() => {
              let payment = props.original
              let customer = payment.customer
              let showExpander =
                this.props.payout ||
                customer.shipping_address ||
                customer.note ||
                payment.answers.length > 0
              return showExpander || true // always show expander due to charge id
            }}
          />
        ),
      },
      {
        Header: 'Customer',
        id: 'customer.email',
        Cell: (rowInfo) => {
          let payment = rowInfo.original
          return <CustomerCell customer={payment.customer} />
        },
        width: 160,
      },
    ]

    if (!this.props.payout) {
      columns = [
        ...columns,
        {
          Header: 'Amount',
          sortable: false,
          id: 'payment_plan.total',
          accessor: (payment) =>
            Currency.format(
              payment.payment_plan.currency.symbol,
              payment.payment_plan.total
            ),
        },
      ]
    }

    if (this.props.payout) {
      columns = [
        ...columns,
        {
          Header: 'Processed',
          sortable: false,
          id: 'payment_plan.total',
          Cell: (rowInfo) => {
            let payment = rowInfo.original

            return (
              <PaymentProcessedCell
                payment={payment}
                payout={this.props.payout}
              />
            )
          },
        },
        {
          Header: 'Fees',
          sortable: false,
          id: 'fee',
          Cell: (rowInfo) => {
            let payment = rowInfo.original

            return (
              <PaymentFeeCell payment={payment} payout={this.props.payout} />
            )
          },
        },
        {
          Header: 'Deposited',
          sortable: false,
          id: 'commission',
          Cell: (rowInfo) => {
            let payment = rowInfo.original

            return (
              <PaymentNetCell payment={payment} payout={this.props.payout} />
            )
          },
        },
      ]
    }

    columns = [
      ...columns,
      {
        Header: 'Type',
        id: 'type',
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let payment = rowInfo.original
          let text = payment.payment_plan.product
            ? 'Shop'
            : payment.payment_plan.payment_request
            ? 'Request'
            : 'Take'
          let className = payment.payment_plan.product
            ? 'label-light-info'
            : payment.payment_plan.payment_request
            ? 'label-light-danger'
            : 'label-light-primary'
          return <PillCell text={text} className={className} />
        },
      },
      {
        Header: 'Recurring',
        id: 'recurring',
        sortable: false,
        Cell: (rowInfo) => {
          let payment = rowInfo.original
          if (
            payment.subscription ||
            payment.processor_payment_data.subscription_id
          ) {
            return <RecurringCell />
          }
          return null
        },
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: (rowInfo) => {
          let payment = rowInfo.original
          let className = 'label-light-success'
          let text = General.snakeCaseToTitleCase(payment.status)
          if(payment.status == "pending"){
            className = 'label-light-info'
          }
          else if(payment.status == "failed"){
            className = 'label-light-danger'
          }
          if(payment.refunded_at){
            text = "Refunded"
            className = 'label-light-warning'
          }
          return <PillCell text={text} className={className} />
        },
      },
      {
        Header: 'Date Paid',
        id: 'paid_at',
        accessor: (payment) =>
          moment(payment.paid_at).format('DD MMM YYYY') || '-',
      },
      {
        Header: 'Refund Date',
        id: 'deleted_at',
        accessor: (payment) =>
          payment.refunded_at
            ? moment(payment.refunded_at).format('DD MMM YYYY')
            : '-',
      },
    ]

    if (!this.props.payout) {
      columns = [
        ...columns,
        {
          Header: 'Actions',
          id: 'actions',
          width: 150,
          sortable: false,
          exportable: false,
          Cell: (rowInfo) => {
            let payment = rowInfo.original
            return (
              <span
                style={{
                  overflow: 'visible',
                  position: 'relative',
                  width: 150,
                }}
              >
                {payment.file && (
                  <DownloadCell
                    tooltip={'Download Receipt'}
                    onDownloadClicked={() => {
                      window.open(payment.file.url, '_blank')
                    }}
                  />
                )}
                <SendCell
                  tooltip={'Resend Receipt'}
                  onSendClicked={() => {
                    this.setState({
                      paymentToResend: payment,
                      showConfirmationModal: true,
                    })
                  }}
                />
                {this._renderDeleteCell(payment)}
              </span>
            )
          },
        },
      ]
    }

    return columns
  }

  _renderDeleteCell(payment) {
    let { permissions } = AuthManager.currentUser._role
    if (
      payment.deleted_at ||
      !Permissions.hasDeletePermission(Permissions.PAYMENT)
    ) {
      return null
    }
    if(payment.payment_instrument?.payment_method.processor.indexOf("token") > -1){
      return null
    }
    return (
      <DeleteCell
        tooltip={'Refund'}
        onDeleteClicked={() => {
          this.setState({
            payment,
            paymentToResend: null,
            showConfirmationModal: true,
          })
        }}
      />
    )
  }

  _deletePayment() {
    let { payment } = this.state

    Backend.deletePayment(payment)
      .then((payment) => {
        Notify.success('Payment has been refunded successfully')
        this.setState({
          payment: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          payment: null,
          showConfirmationModal: false,
        })
      })
  }

  _resendReceipt() {
    let { paymentToResend } = this.state

    Backend.resendPaymentReceipt(paymentToResend)
      .then(() => {
        Notify.success('Receipt has been resent successfully')
        this.setState({
          paymentToResend: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          paymentToResend: null,
          showConfirmationModal: false,
        })
      })
  }

  _renderToolBar() {
    let { permissions } = AuthManager.currentUser._role
    if (this.props.payout) {
      return null
    }
    if (!Permissions.hasViewPermission(Permissions.PAYMENT)) {
      return null
    }
    if (this.props.latestResults) {
      return (
        <Link
          to="/payments"
          className="btn btn-light-primary font-weight-bolder ml-4"
        >
          <span className="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="2"
                  y="5"
                  width="20"
                  height="14"
                  rx="2"
                ></rect>
                <rect fill="#000000" x="2" y="8" width="20" height="3"></rect>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="16"
                  y="14"
                  width="4"
                  height="2"
                  rx="1"
                ></rect>
              </g>
            </svg>
          </span>
          View All Payments
        </Link>
      )
    }

    return (
      <>
        <a
          className="btn btn-primary font-weight-bolder mr-4"
          onClick={() => {
            this.setState({ showSharePaymentLinkModal: true })
          }}
        >
          <span className="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M10.9,2 C11.4522847,2 11.9,2.44771525 11.9,3 C11.9,3.55228475 11.4522847,4 10.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,16 C20,15.4477153 20.4477153,15 21,15 C21.5522847,15 22,15.4477153 22,16 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L10.9,2 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  opacity="0.3"
                ></path>
                <path
                  d="M24.0690576,13.8973499 C24.0690576,13.1346331 24.2324969,10.1246259 21.8580869,7.73659596 C20.2600137,6.12944276 17.8683518,5.85068794 15.0081639,5.72356847 L15.0081639,1.83791555 C15.0081639,1.42370199 14.6723775,1.08791555 14.2581639,1.08791555 C14.0718537,1.08791555 13.892213,1.15726043 13.7542266,1.28244533 L7.24606818,7.18681951 C6.93929045,7.46513642 6.9162184,7.93944934 7.1945353,8.24622707 C7.20914339,8.26232899 7.22444472,8.27778811 7.24039592,8.29256062 L13.7485543,14.3198102 C14.0524605,14.6012598 14.5269852,14.5830551 14.8084348,14.2791489 C14.9368329,14.140506 15.0081639,13.9585047 15.0081639,13.7695393 L15.0081639,9.90761477 C16.8241562,9.95755456 18.1177196,10.0730665 19.2929978,10.4469645 C20.9778605,10.9829796 22.2816185,12.4994368 23.2042718,14.996336 L23.2043032,14.9963244 C23.313119,15.2908036 23.5938372,15.4863432 23.9077781,15.4863432 L24.0735976,15.4863432 C24.0735976,15.0278051 24.0690576,14.3014082 24.0690576,13.8973499 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  transform="translate(15.536799, 8.287129) scale(-1, 1) translate(-15.536799, -8.287129) "
                ></path>
              </g>
            </svg>
          </span>
          Share My Payment Link
        </a>
        {Permissions.hasCreatePermission(Permissions.PAYMENT) && (
          <a
            className="btn btn-primary font-weight-bolder"
            onClick={() => {
              this.setState({ showTakePaymentModal: true })
            }}
          >
            <span className="svg-icon svg-icon-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    x="2"
                    y="5"
                    width="20"
                    height="14"
                    rx="2"
                  ></rect>
                  <rect fill="#000000" x="2" y="8" width="20" height="3"></rect>
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    x="16"
                    y="14"
                    width="4"
                    height="2"
                    rx="1"
                  ></rect>
                </g>
              </svg>
            </span>
            Take Payment
          </a>
        )}
      </>
    )
  }

  _renderTakePaymentModal() {
    let { showTakePaymentModal } = this.state

    if (!showTakePaymentModal) return null

    return (
      <Portal node={document && document.getElementById('payment_portal')}>
        <TakePaymentModal
          show={showTakePaymentModal}
          onHide={() => this.setState({ showTakePaymentModal: false })}
        />
      </Portal>
    )
  }

  _renderSharePaymentLinkModal() {
    let { showSharePaymentLinkModal } = this.state

    if (!showSharePaymentLinkModal) return null

    return (
      <Portal node={document && document.getElementById('payment_link_portal')}>
        <SharePaymentLinkModal
          show={showSharePaymentLinkModal}
          onHide={() => this.setState({ showSharePaymentLinkModal: false })}
          onAdded={() => this.setState({ showSharePaymentLinkModal: false })}
        />
      </Portal>
    )
  }

  _renderConfirmationModal() {
    let { payment, paymentToResend, showConfirmationModal } = this.state

    let message = 'Are you sure you want to refund this payment?'
    if (paymentToResend) {
      message = 'Are you sure you want to resend the receipt for this payment?'
    }

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={message}
        onConfirm={() => {
          if (payment) {
            this._deletePayment()
          } else if (paymentToResend) {
            this._resendReceipt()
          }
        }}
        onHide={() => {
          this.setState({
            payment: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  _renderDescription(payment) {
    return (
      <div style={{ backgroundColor: '#fff', paddingTop: 15, paddingLeft: 15 }}>
        {payment.processor_payment_data && (
          <p className={'mb-3'} style={{ whiteSpace: 'pre-wrap' }}>
            <b>ID: </b>
            {payment.processor_payment_data.charge_id ||
              payment.processor_payment_data.transaction_id ||
              payment.processor_payment_data.ipg_transaction_id ||
              payment.processor_payment_data.retref ||
              payment.processor_payment_data.transaction_number ||
              payment.processor_payment_data.AchResponse?.transactionID ||
              payment.processor_payment_data.SaleResponse?.transactionID ||
              payment.processor_payment_data.merchantTxId ||
              payment.processor_payment_data.id
            }
            <br />
          </p>
        )}
        {payment.status != "completed" && (
          <p className={'mb-3'} style={{ whiteSpace: 'pre-wrap' }}>
            <b>Processor Payment Status: </b>
            {payment.processor_payment_status}
            <br />
          </p>
        )}
        {payment.customer.shipping_address && (
          <p className={'mb-3'}>
            <b>Customer Address: </b>
            {`
            ${payment.customer.shipping_address.line_1},
            ${payment.customer.shipping_address.line_2},
            ${payment.customer.shipping_address.city},
            ${payment.customer.shipping_address.state},
            ${payment.customer.shipping_address.country},
            `}
          </p>
        )}
        {payment.customer.note && (
          <p className={'mb-3'} style={{ whiteSpace: 'pre-wrap' }}>
            <b>Customer Note: </b>
            {payment.customer.note}
            <br />
          </p>
        )}
        {payment.payment_plan.payment_link && (
          <p className={'mb-3'} style={{ whiteSpace: 'pre-wrap' }}>
            <b>Channel: </b>
            {payment.payment_plan.payment_link.title}
            <br />
          </p>
        )}
        {payment.products.length == 0 &&
          payment.answers.length > 0 && (
            <>
              <DisplayCDC answers={payment.answers} />
            </>
          )}
        <br />
        {payment.products.length > 0 && <h5>Products</h5>}
        {payment.products.map((product, index) => {
          let name = product.name
          let answers = product.answers

          let price = Currency.format(
            payment.payment_plan.currency.symbol,
            product.product_total * product.quantity
          )

          if(product.quantity > 1){
            name = `${product.quantity}x ${product.name}`
            price += ` (${Currency.format(
              payment.payment_plan.currency.symbol,
              product.product_total
            )} each)`
          }

          return (
            <>
              <hr />
              <p>
                <b>Product: </b>
                { name }
              </p>
              <p>
                <b>Price: </b>
                { price }
              </p>
              <DisplayCDC answers={answers} />
            </>
          )
        })}
      </div>
    )
  }

  render() {
    let { dateFrom, dateTo, payout, paymentsProcessed } = this.state

    const columns = this._getColumns()

    let showEngagementCard =
      paymentsProcessed === 0 && this.props.canShowEngagementCard

    return (
      <div>
        {showEngagementCard && (
          <EngagementCard
            title={'Take Payment'}
            text={`
            Use your ${window.General.Branding.LinkName} to take payment from your customer,
            or send your ${window.General.Branding.LinkName} to your customer
            so they can process the payment.
          `}
            buttonTitle={'Take Payment'}
            onClick={() => {
              this.setState({ showTakePaymentModal: true })
            }}
          />
        )}
        {!showEngagementCard && (
          <BaseTable
            ref={this.table}
            endpoint={window.Api.Payments}
            deletedObjectLabel="Refunded"
            noDataMessage={'No payments found'}
            title={this.props.title}
            columns={columns}
            params={{
              min_date: dateFrom?.format('YYYY-MM-DD') || '',
              max_date: dateTo?.format('YYYY-MM-DD') || '',
              payout_id: payout?.id || '',
            }}
            showExport={false}
            filters={!this.props.latestResults && FILTERS}
            defaultSorted={[
              {
                id: 'paid_at',
                desc: true,
              },
            ]}
            showFilter={!this.props.payout}
            showSearch={!this.props.latestResults}
            showPaginationBottom={!this.props.latestResults}
            SubComponent={(row) => this._renderDescription(row.original)}
            renderToolbar={() => this._renderToolBar()}
          />
        )}
        {this._renderTakePaymentModal()}
        {this._renderSharePaymentLinkModal()}
        {this._renderConfirmationModal()}
      </div>
    )
  }
}

PaymentsTable.defaultProps = {
  latestResults: false,
  canShowEngagementCard: true,
}
