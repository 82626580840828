import React from 'react'

import BaseTable from './BaseTable'
import InvoicesTable from './InvoicesTable'
import moment from 'moment'

import CustomerCell from './cells/CustomerCell'
import PaidCell from './cells/PaidCell'
import ViewCell from './cells/ViewCell'
import CheckCell from './cells/CheckCell'
import DeleteCell from './cells/DeleteCell'
import NudgeCell from './cells/NudgeCell'
import PillCell from './cells/PillCell'
import PauseCell from './cells/PauseCell'
import ResumeCell from './cells/ResumeCell'
import EditCell from './cells/EditCell'

import ConfirmationModal from '../modal/ConfirmationModal'
import ResumeSubscriptionModal from '../modal/ResumeSubscriptionModal'
import SubscriptionModal from '../modal/SubscriptionModal'

import { Portal } from 'react-portal'
import RequestPaymentModal from '../modal/RequestPaymentModal'
import TakePaymentModal from '../modal/TakePaymentModal'
import EngagementCard from '../common/EngagementCard'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from '../../../utils/Currency'
import Notify from '../../../utils/Notify'
import Permissions from '../../../utils/Permissions'
import AuthManager from '../../../utils/AuthManager'
import Splink from '../../../utils/Splink'

const STATUS_FILTERS = {
  name: {
    api: 'type',
    display: 'type',
  },
  values: [
    {
      label: 'All Add-Ons',
      value: null,
    },
    {
      label: 'Take',
      value: 'take',
    },
    {
      label: 'Request',
      value: 'request',
    },
    {
      label: 'Shop',
      value: 'shop',
    },
  ],
}

const FILTERS = [STATUS_FILTERS]

export default class SubscriptionsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      recurringPayments: props.recurringPayments,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    let { recurringPayments } = this.state

    if (recurringPayments > 0) {
      this.table.current.refresh()
    }
  }

  _getColumns() {
    let { permissions } = AuthManager.currentUser._role
    return [
      {
        Header: 'Customer',
        id: 'initial_payment.customer.email',
        Cell: (rowInfo) => {
          let customer = rowInfo.original.initial_payment.customer
          return <CustomerCell customer={customer} />
        },
      },
      {
        Header: 'Amount',
        id: 'payment_plan.total',
        width: 100,
        Cell: (rowInfo) => {
          let recurringPayment = rowInfo.original
          let paymentPlan = recurringPayment.payment_plan || recurringPayment.initial_payment.payment_plan

          return Currency.format(
            paymentPlan.currency.symbol,
            paymentPlan.total
          )
        },
      },
      {
        Header: 'Status',
        id: 'status',
        width: 100,
        Cell: (rowInfo) => {
          let recurringPayment = rowInfo.original
          let className =
            recurringPayment.status === 'active'
              ? 'label-light-success'
              : 'label-light-danger'

          if (recurringPayment.status === 'complete') {
            className = 'label-light-primary'
          }
          else if (recurringPayment.status === 'paused') {
            className = 'label-light-warning'
          }

          return (
            <PillCell
              text={General.toTitleCase(recurringPayment.status)}
              className={className}
            />
          )
        },
      },
      {
        Header: 'Type',
        id: 'initial_payment.payment_plan.product',
        width: 90,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let recurringPayment = rowInfo.original
          let text = recurringPayment.initial_payment.payment_plan.product
            ? 'Shop'
            : recurringPayment.initial_payment.payment_plan.payment_request
            ? 'Request'
            : 'Take'
          let className = recurringPayment.initial_payment.payment_plan.product
            ? 'label-light-info'
            : recurringPayment.initial_payment.payment_plan.payment_request
            ? 'label-light-danger'
            : 'label-light-primary'
          return <PillCell text={text} className={className} />
        },
      },
      {
        Header: 'Frequency',
        id: 'initial_payment.payment_plan.frequency',
        width: 110,
        accessor: (recurringPayment) =>
          General.toTitleCase(
            recurringPayment.initial_payment.payment_plan.frequency
          ),
      },
      {
        Header: 'Installments',
        id: 'installments_paid',
        width: 180,
        accessor: 'installments_paid',
        Cell: (rowInfo) => {
          let subscription = rowInfo.original

          let paymentPlan = subscription.payment_plan || subscription.initial_payment.payment_plan
          let installmentsNo = paymentPlan.installments
          if(installmentsNo){
            return `${subscription.installments_paid} paid out of ${installmentsNo}`
          }
          return "Continuous"
        }
      },
      {
        Header: 'Paid',
        id: 'initial_payment.paid_at',
        accessor: (recurringPayment) =>
          moment(recurringPayment.initial_payment.paid_at).format(
            'DD MMM YYYY'
          ) || '-',
      },
      {
        Header: 'Next Payment',
        id: 'next_invoice_date_at',
        accessor: (recurringPayment) =>
          recurringPayment.next_invoice_date_at
            ? moment(recurringPayment.next_invoice_date_at).format(
                'DD MMM YYYY'
              )
            : '-',
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 250,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let subscription = rowInfo.original

          let status = subscription.status
          let cancellable = status === 'active' || status === 'paused'

          let url = Splink.getSplinkUrl(AuthManager.currentUser.company)
          let initialPaymentId = subscription.initial_payment.id
          let authToken = subscription.auth_token
          url += `/subscriptions/${initialPaymentId}/${authToken}`

          return (
            <span
              style={{ overflow: 'visible', position: 'relative', width: 150 }}
            >
              {Permissions.hasDeletePermission(Permissions.RECURRING) && (
                <>
                { !subscription.deleted_at &&
                    <ViewCell
                      tooltip={'View'}
                      onViewClicked={() => {
                        window.open(url)
                      }}
                    />
                  }
                  { status == "active" &&
                    <>
                      <EditCell
                        tooltip={'Edit'}
                        onEditClicked={() => {
                          this.setState({
                            subscription,
                            showEditModal: true,
                          })
                        }}
                      />
                      <PauseCell
                        tooltip={'Pause'}
                        onPauseClicked={() => {
                          this.setState({
                            subscription,
                            showPauseConfirmationModal: true,
                          })
                        }}
                      />
                    </>
                  }
                  { status == "paused" &&
                    <ResumeCell
                      tooltip={'Resume'}
                      onResumeClicked={() => {
                        this.setState({
                          subscription,
                          showResumeModal: true,
                        })
                      }}
                    />
                  }
                  {cancellable &&
                    <DeleteCell
                      tooltip={'Cancel'}
                      onDeleteClicked={() => {
                        this.setState({
                          subscription,
                          showConfirmationModal: true,
                        })
                      }}
                    />
                  }
                </>
              )}
            </span>
          )
        },
      },
    ]
  }

  _cancelSubscription() {
    let { subscription } = this.state

    Backend.cancelSubscription(subscription)
      .then(() => {
        Notify.success('Subscription has been canceled successfully')
        this.setState({
          subscription: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          subscription: null,
          showConfirmationModal: false,
        })
      })
  }

  _pauseSubscription() {
    let { subscription } = this.state

    Backend.pauseSubscription(subscription)
    .then(() => {
      Notify.success('Subscription has been paused successfully')
      this.setState({
        subscription: null,
        showPauseConfirmationModal: false,
      })
      this.table.current.refresh()
    })
    .catch((e) => {
      Notify.error(e.message)
      this.setState({
        subscription: null,
        showPauseConfirmationModal: false,
      })
    })
  }

  _renderToolBar() {
    let { permissions } = AuthManager.currentUser._role
    if (!Permissions.hasCreatePermission(Permissions.PAYMENT)) {
      return null
    }
    return (
      <>
        <button
          type="button"
          className="btn btn-primary font-weight-bolder"
          onClick={() => {
            this.setState({ showTakePaymentModal: true })
          }}
        >
          <span className="svg-icon svg-icon-white svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                  fill="#000000"
                ></path>
              </g>
            </svg>
          </span>
          Create Recurring Payment
        </button>
      </>
    )
  }

  _renderTakePaymentModal() {
    let { showTakePaymentModal } = this.state

    if (!showTakePaymentModal) return null

    return (
      <Portal node={document && document.getElementById('payment_portal')}>
        <TakePaymentModal
          show={showTakePaymentModal}
          recurring={true}
          onHide={() => this.setState({ showTakePaymentModal: false })}
        />
      </Portal>
    )
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={'Are you sure you want to cancel this subscription?'}
        onConfirm={() => this._cancelSubscription()}
        onHide={() => {
          this.setState({
            subscription: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  _renderPauseConfirmationModal() {
    let { showPauseConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showPauseConfirmationModal}
        title={'Are you sure you want to pause this subscription?'}
        body={'Invoices will no longer be issued and you will need to manually restart it to resume issuing invoices.'}
        onConfirm={() => this._pauseSubscription()}
        onHide={() => {
          this.setState({
            subscription: null,
            showPauseConfirmationModal: false,
          })
        }}
      />
    )
  }

  _renderResumeModal(){
    let { showResumeModal, subscription } = this.state

    if(!showResumeModal){
      return null
    }

    return (
      <ResumeSubscriptionModal
        show={showResumeModal}
        subscription={subscription}
        onHide={() => {
          this.setState({
            subscription: null,
            showResumeModal: false,
          })
        }}
        onResumed={() => {
          this.setState({
            subscription: null,
            showResumeModal: false,
          })
          this.table.current.refresh()
        }}
      />
    )
  }

  _renderEditModal(){
    let { showEditModal, subscription } = this.state

    if(!showEditModal){
      return null
    }

    return (
      <SubscriptionModal
        show={showEditModal}
        subscription={subscription}
        onHide={() => {
          this.setState({
            subscription: null,
            showEditModal: false,
          })
        }}
        onUpdated={() => {
          this.setState({
            subscription: null,
            showEditModal: false,
          })
          this.table.current.refresh()
        }}
      />
    )
  }

  _renderPayments(subscription) {
    return (
      <InvoicesTable
        subscription={subscription}
        title={`${subscription.customer?.email || ''}`}
        showExport={false}
      />
    )
  }

  render() {
    let { recurringPayments } = this.state
    let { permissions } = AuthManager.currentUser._role
    const columns = this._getColumns()

    return (
      <div>
        {recurringPayments === 0 &&
          Permissions.hasCreatePermission(Permissions.PAYMENT) && (
            <EngagementCard
              title={'Create A Recurring Payment'}
              text={`
            Setup your customers on a recurring
            payment or a installment plan.
            Choose from weekly, monthly or annually re-payments.
          `}
              buttonTitle={'Create A Recurring Payment'}
              onClick={() => {
                this.setState({ showTakePaymentModal: true })
              }}
            />
          )}
        {recurringPayments > 0 && (
          <BaseTable
            ref={this.table}
            endpoint={`${window.Api.Subscriptions}?min_date=${
              this.state.dateFrom?.format('YYYY-MM-DD') || ''
            }&max_date=${this.state.dateTo?.format('YYYY-MM-DD') || ''}`}
            noDataMessage={'No subscriptions found'}
            title={this.props.title}
            columns={columns}
            showExport={false}
            filters={FILTERS}
            defaultSorted={[
              {
                id: 'initial_payment.paid_at',
                desc: true,
              },
            ]}
            renderToolbar={() => this._renderToolBar()}
            SubComponent={(row) => this._renderPayments(row.original)}
          />
        )}
        {this._renderTakePaymentModal()}
        {this._renderConfirmationModal()}
        {this._renderPauseConfirmationModal()}
        {this._renderResumeModal()}
        {this._renderEditModal()}
      </div>
    )
  }
}
