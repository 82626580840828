import React from "react"

import QRCodesTable from "./components/tables/QRCodesTable";

import AuthManager from "../utils/AuthManager";
import Event from "../utils/Event";

export default class Channels extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    if(!AuthManager.currentUser.company.add_ons.qr_codes){
      Event.emit('ADD_ON_ALERT', '/')
    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <QRCodesTable
          title={'QR Codes'}
        />
      </>
    )
  }

}
