import React from "react"

import ChannelsTable from "./components/tables/ChannelsTable";

import AuthManager from "../utils/AuthManager";
import Event from "../utils/Event";

export default class Channels extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    if(!AuthManager.currentUser.company.add_ons.payment_links){
      Event.emit('ADD_ON_ALERT', '/')
    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <ChannelsTable
          title={'Payment Channels'}
        />
      </>
    )
  }

}
