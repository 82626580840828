import React from 'react'
import Modal from 'react-bootstrap/Modal'

import LoadingOverlay from 'react-loading-overlay';

import moment from 'moment'

import PaymentAttempt from "../payment/PaymentAttempt"
import PaymentMethods from "../payment/PaymentMethods"
import PaymentMethodsContainer from "../payment/PaymentMethodsContainer"

import AuthManager from '../../../utils/AuthManager'
import Currency from '../../../utils/Currency'
import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import General from '../../../utils/General'
import AsyncStorage from '../../../utils/AsyncStorage'


export default class AccountPaymentModal extends React.Component {
  constructor(props) {
    super(props)
    let company = AuthManager.currentUser.company
    let subscription = company.subscription
    this.state = {
      company,
      customer: {
        first_name: "",
        last_name: "",
        email: null
      },
      paymentPlan: {
        vat: 0,
        total: subscription.package.total,
        subtotal: subscription.package.total,
        currency: subscription.package.currency,
        frequency: subscription.package.frequency,
        type: subscription.package.frequency === "one_time" ? "one_time" : "recurring"
      }
    }

    this.paymentMethods = React.createRef()

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount() {
    if (!this._shouldShow()) {
      return null
    }

    let data = PaymentAttempt.getState()
    if(data){
      this.setState(data, () => {
        if(data.paymentDetails){
          this._pay(window.Api.PlatformPaymentMethods.card, data.paymentDetails)
        }
      })
    }
  }

  _shouldShow(){
    let { company } = this.state

    if (!company || !company.subscription) {
      return false
    }

    if (!company.subscription || company.subscription.active || company.subscription.package.total === 0) {
      return false
    }

    return true
  }

  _goTo(url) {
    this.props.history.push(url)
  }

  _onLogoutPressed() {
    AuthManager.logout().then(() => {
      this._goTo('/account')
    })
  }

  _isValid(paymentMethod){
    let isValid = true
    if(!this.paymentMethods.current.isValid(isValid, paymentMethod)){
      isValid = false
    }

    return isValid
  }

  _handlePay(paymentMethod){
    if(!this._isValid(paymentMethod)){
      return false
    }

    this.setState({ isLoading: true })
    this.paymentMethods.current.getPaymentDetails(paymentMethod)
    .then(({paymentMethod, paymentDetails}) => {
      this._pay(paymentMethod, paymentDetails)
    })
    .catch(error => {
      this.setState({isLoading: false})
      Notify.error(error.message)
    })
  }

  _pay(paymentMethod, paymentDetails){
    let {
      company,
      customer,
    } = this.state


    paymentDetails.customer = customer
    let data = {
      package: company.subscription.package.id,
      payment_details: paymentDetails
    }

    this.setState({isLoading: true})
    Backend.purchaseSubscription(company, data)
    .then(response => {
      if(response.requires_action){
        return this.paymentMethods.current.handleAction(response)
        .then(paymentDetails => {
          return this._pay(paymentMethod, paymentDetails)
        })
      }

      window.location.href = '/'
    })
    .catch(error => {
      this.setState({isLoading: false})
      Notify.error(error.message)
    })
  }



  _renderPaymentConfirmationContainer() {
    if(!this.state.showPaymentConfirmation){
      return null
    }
    return (
      <>
        <div
          className="swal2-icon swal2-success swal2-icon-show"
          style={{ display: 'flex' }}
        >
          <div className="swal2-success-circular-line-left"></div>
          <span className="swal2-success-line-tip"></span>
          <span className="swal2-success-line-long"></span>
          <div className="swal2-success-ring"></div>
          <div className="swal2-success-fix"></div>
          <div className="swal2-success-circular-line-right"></div>
        </div>

        <h1 className="text-center">Thank You</h1>
        <p className="text-center">Your package is now active</p>
      </>
    )
  }

  render() {
    let { isLoading, company, customer, paymentPlan } = this.state
    if (!this._shouldShow()) {
      return null
    }

    let pkg = company.subscription.package
    let payButtonTitle = `Pay ${Currency.format(pkg.currency.symbol, pkg.total)}`
    payButtonTitle += ` (per ${pkg.frequency.replace('ly', '')})`

    return (
      <Modal
        show={true}
        onHide={() => {}}
        centered
        className={this.props.modalClassName}
        dialogClassName={'c-modal account-payment'}
      >

        <Modal.Header closeButton>
          <Modal.Title>Pay For Your Package</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this._renderPaymentConfirmationContainer()}
          {!this.state.showPaymentConfirmation && (
            <div
              className="form payment-form"

            >
              <div className="pb-5 pt-lg-0 pt-5">
                <p className="text-mute">
                  Enter your card details to pay for your package and start
                  processing payments instantly.
                </p>
              </div>

              <PaymentMethods
                ref={this.paymentMethods}
                customer={customer}
                company={company}
                products={[]}
                product={null}
                payButtonTitle={payButtonTitle}
                payButtonContainerId="sub-payment-form-footer"
                paymentMethods={window.Api.PlatformPaymentMethods}
                paymentPlan={paymentPlan}
                collectEmail={false}
                collectAddress={false}
                subscriptionPayment={true}
                onUpdated={paymentDetails => {
                  this.setState({ paymentDetails })
                }}
                onUpdatedCustomer={customer => {
                  this.setState({ customer })
                }}
                onPayPressed={(paymentMethod, paymentDetails) => {
                  if(paymentMethod.type !== "digital_wallet"){
                    this._handlePay(paymentMethod)
                    return
                  }

                  if(!this._isValid(paymentMethod)){
                    return
                  }

                  this._pay(paymentMethod, paymentDetails)
                }}
                onSaveState={(paymentDetails) => {
                  this.setState({ paymentDetails}, () => {
                    AsyncStorage.setItem("payment_data", JSON.stringify(this.state))
                  })
                }}
              />

              {!this.state.showPaymentConfirmation && (
                <PaymentMethodsContainer
                  paymentMethods={window.Api.PlatformPaymentMethods}
                  showAlternativePaymentMethods={true}
                  payButtonContainerId="sub-payment-form-footer"
                />
              )}

            </div>
          )}
        </Modal.Body>

        <LoadingOverlay
            active={isLoading}
            className={isLoading ? "payment-form-loading-overlay" : ""}
            spinner
            text={<p>Loading... <br/>please do not refresh</p>}
        />


      </Modal>
    )
  }
}

AccountPaymentModal.defaultProps = {
  modalClassName: ''
}

const styles = {
  cardInput: {
    base: {
      marginTop: '20px',
      fontSize: '13px',
      color: '#000',
      fontFamily: 'Poppins, Helvetica, "sans-serif"',
      '::placeholder': {
        color: '#B5B5C3',
        textTransform: 'capitalize',
        fontSize: '13px',
        fontFamily: 'Poppins, Helvetica, "sans-serif"',
      },
      ':-webkit-autofill': {
        color: '#464E5F',
      },
    },
    invalid: {
      color: '#fa6160',
    },
  },
}
