import React from 'react'
import StatCard from './components/common/StatCard'
import DateRange from './components/common/DateRange'
import PaymentRequestsTable from './components/tables/PaymentRequestsTable'
import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'
import Event from '../utils/Event'
import Permissions from '../utils/Permissions'

export default class Requests extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stats: undefined,
      dateFrom: null,
      dateTo: null,
    }
  }

  componentDidMount() {
    if (!AuthManager.currentUser.company.add_ons.payment_requests) {
      Event.emit('ADD_ON_ALERT', '/')
    }
    this._loadStats()
  }

  _loadStats() {
    let { dateFrom, dateTo } = this.state

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      this.setState({ stats: { } })
      return
    }

    Backend.getPaymentRequestStats(
      dateFrom?.format('YYYY-MM-DD') || '',
      dateTo?.format('YYYY-MM-DD') || ''
    ).then((stats) => {
      this.setState({
        stats: stats.company,
      })
    })
  }

  _renderStatCards() {
    let { stats } = this.state

    if(!Permissions.hasViewPermission(Permissions.STATS)){
      return null
    }

    let sent = stats?.payment_requests.sent || 0
    let paid = stats?.payment_requests.paid.reduce(
      (accum, item) => accum + item.count,
      0
    )
    let unpaid = sent - paid

    return (
      <>
        <StatCard
          amount={sent}
          title={'Requests Sent'}
          icon={'send'}
          plain={true}
        />
        <StatCard
          amount={paid}
          title={'Requests Paid'}
          icon={'check'}
          plain={true}
        />
        <StatCard
          amount={unpaid}
          title={'Requests Unpaid'}
          icon={'warning'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let { dateFrom, dateTo, stats } = this.state

    if (!stats) return null

    let paymentRequestsSent = 1
    if(stats?.payment_requests){
      paymentRequestsSent = stats.payment_requests.sent || 0
    }
    return (
      <>
        {Permissions.hasViewPermission(Permissions.STATS) && (
          <DateRange
            dateFrom={dateFrom}
            dateTo={dateTo}
            onFromDateUpdated={(dateFrom) => {
              this.setState(
                {
                  dateFrom,
                },
                () => this._loadStats()
              )
            }}
            onToDateUpdated={(dateTo) => {
              this.setState(
                {
                  dateTo,
                },
                () => this._loadStats()
              )
            }}
          />
        )}
        <div className={'row'}>{this._renderStatCards()}</div>
        <PaymentRequestsTable
          title={'All Requests'}
          dateFrom={dateFrom}
          dateTo={dateTo}
          paymentRequestsSent={paymentRequestsSent}
          loadStats={() => this._loadStats()}
        />
      </>
    )
  }
}
