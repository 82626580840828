import React from 'react'

import Currency from "../../../../utils/Currency";

export default class PaymentNetCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      payment: props.payment,
      payout: props.payout
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      payment,
      payout
    } = this.state


    let net = "-"
    if(payment.processor_payout_data){
      net = Currency.format(payout.currency.symbol, payment.processor_payout_data.net)
    }

    return (
      <div className="d-flex align-items-center">
        <div className="ml-2">
          <div className="text-dark-75 line-height-sm">{net}</div>
        </div>
      </div>
    )
  }
}
