import React from "react"
import BulkUploadTableRow from "./BulkUploadTableRow";
import BulkUpload from "../../../utils/BulkUpload";

export default class BulkUploadTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      rows: props.rows,
      headers: props.headers,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      rows,
      headers
    } = this.state

    return (
      <div className="table-responsive">
        <table className="table table-hover table-scroll" id="expense-receipt-entry-records">
          <thead>
          <tr>
            {
              headers.map(col => {
                return (
                  <th>{col}</th>
                )
              })
            }
          </tr>
          </thead>
          <tbody data-repeater-list="">
          {
            rows.map((row, index) => {
              let className = ''
              return (
                <tr data-repeater-item={""} className={className}>
                  <BulkUploadTableRow
                    row={row}
                    rowIndex={index}
                    headers={headers}
                    onUpdated={(row, rowIndex) => {
                      rows[rowIndex] = row
                      this.props.onUpdated(rows)
                    }}
                  />
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    )
  }
}
