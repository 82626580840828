import React from "react"

// import ImageEdit from "../common/ImageEdit"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import Splink from "../../../utils/Splink";

import { TAX } from "../../../constants/Variables"

export default class GeneralSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: props.loading,
      company: props.company,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      company,
      loading
    } = this.state

    let currency = General.getSingleAsyncOption(company.settings.payment_plan?.currency) || null

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <h4 className="mb-10 font-weight-bold text-dark">Control your basic default settings</h4>

              <div className="form-group row">
                <label className="col-3 col-form-label">Currency</label>
                <div className="col-9">
                  <AsyncSelect
                    endpoint={window.Api.Currencies}
                    classNamePrefix={'custom-async-select'}
                    params={{ }}
                    orderBy="name"
                    value={currency}
                    onSelected={currency => {
                      company.settings.payment_plan.currency = currency
                      this.props.onUpdated(company)
                    }}
                    getOptions={currencies => General.getAsyncOptions(currencies)}
                  />
                  <span className="form-text text-muted">Select the currency that you want to accept payments in.</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Payment Type</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <select
                      className="form-control form-control-solid"
                      value={company.settings.payment_plan.type}
                      onChange={e => {
                        company.settings.payment_plan.type = e.target.value
                        if(e.target.value === "optional"){
                          company.settings.payment_plan.vat = null
                          company.settings.payment_plan.subtotal = null
                          company.settings.payment_plan.total = null
                          company.settings.payment_plan.frequency = null
                          company.settings.payment_plan.installments = null
                          company.settings.payment_plan.open = true
                        }
                        this.props.onUpdated(company)
                      }}
                    >
                      <option value="one_time">One Time</option>
                      <option value="recurring">Recurring</option>
                      <option value="optional">Optional</option>
                    </select>
                    </div>
                    <span
                      className="form-text text-muted">What payment types do you want to provide on your {window.General.Branding.LinkName}?</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Capture Emails</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <select
                      className="form-control form-control-solid"
                      value={company.settings.collect_email}
                      onChange={e => {
                        company.settings.collect_email = e.target.value
                        this.props.onUpdated(company)
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <span className="form-text text-muted">Do you want to capture your customer’s email address and send an automatic receipt?</span>
                </div>
              </div>

              { company.add_ons.reverse_fee &&
                <div className="form-group row">
                  <label className="col-3 col-form-label">Reverse Your Fees</label>
                  <div className="col-9">
                    <div className="select-wrapper">
                      <select
                        className="form-control form-control-solid"
                        value={company.settings.payment_plan.reverse_fee}
                        onChange={e => {
                          company.settings.payment_plan.reverse_fee = e.target.value
                          this.props.onUpdated(company)
                        }}
                      >
                        <option value="optional">Optional</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <span className="form-text text-muted">Select whether the handling/admin fees are reversed (paid by your customers). Optional setting means your customers decide whether to reverse the fees or not when making a payment.</span>
                  </div>
                </div>
              }


              <div className="form-group row">
                <label className="col-3 col-form-label">Enable {TAX}</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <select
                      className="form-control form-control-solid"
                      value={company.settings.vat_enabled}
                      onChange={e => {
                        company.settings.vat_enabled = e.target.value
                        this.props.onUpdated(company)
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <span className="form-text text-muted">Enable {TAX} by default? You can change this for each individual payment request.</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Add {TAX} %</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={company.settings.vat_rate * 100}
                    onChange={e => {
                      company.settings.vat_rate = e.target.value / 100
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">Enter the default {TAX} %. You can change this for each individual payment request.</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Add Your {TAX} No.</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={company.settings.vat_no}
                    onChange={e => {
                      company.settings.vat_no = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">Enter your {TAX} number. This will appear at the bottom of your customer's payment requests.</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label text-capitalize">{window.General.Branding.LinkName} URL</label>
                <div className="col-9">
                  <input className="form-control" type="text" disabled value={Splink.getSplinkUrl(company)}/>
                    <span
                      className="form-text text-muted">Open for you or your customers to use for open amounts.</span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label text-capitalize">Shop {window.General.Branding.LinkName} URL</label>
                <div className="col-9">
                  <input className="form-control" disabled type="text" value={Splink.getShopUrl(company)}/>
                  <span className="form-text text-muted">Send this link to your customers so they can buy items from your shop.</span>
                </div>
              </div>

            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    this.props.onSave()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
      </>
    )
  }
}
