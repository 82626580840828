import React from 'react'

import BaseTable from './BaseTable'
import moment from 'moment'

import PillCell from './cells/PillCell'
import CustomerCell from './cells/CustomerCell'
import DownloadCell from './cells/DownloadCell'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from '../../../utils/Currency'
import Notify from '../../../utils/Notify'

const FILTERS = []

export default class CompanyInvoiceLineItemsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      invoice: props.invoice
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    this.table.current.refresh()
  }

  _getColumns() {
    let { invoice } = this.state
    return [
      {
        Header: 'Paid At',
        id: 'payment.paid_at',
        Cell: (rowInfo) => {
          let lineItem = rowInfo.original

          return moment(lineItem.payment.paid_at).format('DD MMM YYYY')
        },
      },
      {
        Header: 'Customer',
        id: 'payment.customer.email',
        Cell: (rowInfo) => {
          let payment = rowInfo.original.payment
          return <CustomerCell customer={payment.customer} />
        },
        width: 160,
      },
      {
        Header: 'Amount',
        id: 'total',
        Cell: (rowInfo) => {
          let payment = rowInfo.original.payment

          return Currency.format(
            payment.payment_plan.currency?.symbol,
            payment.payment_plan.total
          )
        },
      },
      {
        Header: 'Flat Fee',
        id: 'platform_flat_charge',
        Cell: (rowInfo) => {
          let lineItem = rowInfo.original

          return Currency.format(
            invoice.currency.symbol,
            lineItem.platform_flat_charge + lineItem.association_flat_charge
          )
        },
      },
      {
        Header: '% Fee',
        id: 'platform_flat_charge',
        Cell: (rowInfo) => {
          let lineItem = rowInfo.original

          return Currency.format(
            invoice.currency.symbol,
            lineItem.platform_rate_charge + lineItem.association_rate_charge
          )
        },
      },
      {
        Header: 'Total Fees',
        id: 'total_charge',
        Cell: (rowInfo) => {
          let lineItem = rowInfo.original
          let payment = lineItem.payment

          return Currency.format(
            invoice?.currency?.symbol,
            lineItem.total_charge
          )
        },
      },
    ]
  }

  _renderLineItems(invoice) {
    return null
  }

  render() {
    let { invoice } = this.state
    const columns = this._getColumns()

    let endpoint = `${window.Api.CompanyInvoiceLineItems}?invoice_id=${invoice.id}`
    return (
      <div>
        <BaseTable
          ref={this.table}
          showHeader={false}
          showSearch={false}
          endpoint={endpoint}
          noDataMessage={'No Line Items Found'}
          title={this.props.title}
          columns={columns}
          showExport={false}
          filters={FILTERS}
          showObjectFilter={false}
          defaultSorted={[
            {
              id: 'payment.paid_at',
              desc: true,
            },
          ]}
        />
      </div>
    )
  }
}
