import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import LeftMenu from "./components/LeftMenu";
import MobileHeader from "./components/MobileHeader";
import Footer from "./components/Footer";
import Header from "./components/Header";

import AuthManager from "../utils/AuthManager";
import Intercom from "../utils/Intercom";

import Dashboard from "./Dashboard";
import Payments from "./Payments";
import PaymentAttempts from "./PaymentAttempts";
import Requests from "./Requests";
import Payouts from "./Payouts";
import Recurring from "./Recurring";
import Shop from "./Shop";
import Channels from "./Channels";
import TeamMembers from "./TeamMembers";
import Settings from "./Settings";
import QRCodes from "./QRCodes";
import Roles from "./Roles";
import Billing from "./Billing";

import QuickPanel from "./components/QuickPanel";
import PaymentPortal from "./components/portals/PaymentPortal";
import PaymentRequestPortal from "./components/portals/PaymentRequestPortal";
import DemoPanelsPortal from "./components/portals/DemoPanelsPortal";
import AccountPaymentModal from "./components/modal/AccountPaymentModal";
import AlertModal from "./components/modal/AlertModal";

import Event from "../utils/Event";
import MetaTags from "../utils/MetaTags";
import Permissions from "../utils/Permissions";

import BillingAlert from "./components/alert/BillingAlert";

import MFAAlert from "./components/alert/MFAAlert";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertModal: false,
      isItPaymentAttemptsPage: false,
    };
    this._setupEvents();
    Intercom.update();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      Intercom.update();
    }
  }

  _setupEvents() {
    Event.on("ADD_ON_ALERT", (url) => {
      this.setState({ showAlertModal: true }, () => {
        if (!url) {
          return;
        }
        this.props.history.push(url);
      });
    });
  }

  _renderRoutes() {
    let routes = [];
    if (Permissions.hasViewPermission(Permissions.PAYMENT_LINK)) {
      routes.push(<Route exact path="/channels" component={Channels} />);
    }
    if (Permissions.hasViewPermission(Permissions.QR_CODE)) {
      routes.push(<Route exact path="/qr-codes" component={QRCodes} />);
    }
    if (Permissions.hasViewPermission(Permissions.PAYMENT)) {
      routes.push(<Route exact path="/payments" component={Payments} />);
    }

    if (Permissions.hasViewPermission(Permissions.RECURRING)) {
      routes.push(<Route exact path="/recurring" component={Recurring} />);
    }

    if (Permissions.hasViewPermission(Permissions.PAYMENT)) {
      routes.push(<Route exact path="/payouts" component={Payouts} />);
      routes.push(
        <Route
          exact
          path="/settings/payment-attempts"
          component={PaymentAttempts}
        />
      );
    }
    if (Permissions.hasViewPermission(Permissions.PAYMENT_REQUEST)) {
      routes.push(<Route exact path="/requests" component={Requests} />);
    }
    if (Permissions.hasViewPermission(Permissions.PRODUCT)) {
      routes.push(<Route exact path="/shop" component={Shop} />);
    }
    if (Permissions.hasEditPermission(Permissions.SETTINGS)) {
      routes.push(<Route path="/settings" component={Settings} />);
    }
    if (Permissions.hasViewPermission(Permissions.TEAM_MEMBER)) {
      routes.push(<Route exact path="/team-members" component={TeamMembers} />);
    }

    if (Permissions.hasViewPermission(Permissions.ROLES)) {
      routes.push(<Route exact path="/roles" component={Roles} />);
    }

    routes.push(<Route exact path="/" component={Dashboard} />);
    routes.push(<Route exact path="/billing" component={Billing} />);
    return (
      <Switch>
        {routes.map((route) => {
          return route;
        })}
        <Redirect to="/404" />
      </Switch>
    );
  }

  render() {
    let { isItPaymentAttemptsPage } = this.state;

    if (window.location.pathname.indexOf("payment-attempts") > -1) {
      isItPaymentAttemptsPage = true;
    }

    return (
      <>
        {MetaTags.render(
          window.General.Branding.Name,
          "The Fastest Way To Take Payment",
          null,
          window.location.href
        )}

        <MobileHeader />

        <div
          className="d-flex flex-column flex-root"
          style={{ minHeight: "100%" }}
        >
          <div className="d-flex flex-row flex-column-fluid page">
            <LeftMenu />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div
                className={`content d-flex flex-column flex-column-fluid ${
                  isItPaymentAttemptsPage ? " py-0 " : ""
                }`}
                id="kt_content"
              >
                <div className={"d-flex flex-column-fluid"}>
                  <div className={"container-fluid"}>
                    <BillingAlert />
                    <MFAAlert />

                    {this._renderRoutes()}
                    <PaymentPortal />
                    <PaymentRequestPortal />
                    <DemoPanelsPortal />
                  </div>
                </div>
              </div>
              <Footer hideFooter={isItPaymentAttemptsPage} />
            </div>
          </div>
          {/*  begin::Quick Panel  */}
          <QuickPanel />
          {/*  end::Quick Panel  */}
          <div id="kt_scrolltop" className="scrolltop">
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    x="11"
                    y="10"
                    width="2"
                    height="10"
                    rx="1"
                  ></rect>
                  <path
                    d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <AccountPaymentModal />
        <AlertModal
          title="This 'add-on' is not in your current package, please chat with us if you would like to trial this feature!"
          show={this.state.showAlertModal}
          onConfirm={() => this.setState({ showAlertModal: false })}
          onChatClicked={() => {
            this.setState(
              {
                showAlertModal: false,
              },
              () => {
                if (
                  window.General.AssociationId &&
                  !window.Api.Keys.IntercomAppId
                ) {
                  window.open(window.General.Branding.SupportUrl);
                } else {
                  window.Intercom("show");
                }
              }
            );
          }}
        />
      </>
    );
  }
}
