import React, {Component} from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

import CountrySelect from "./CountrySelect"

import General from "../../../utils/General";

export default class LocationInput extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        search: "",
        value: this._getValue(props.location),
        manualInput: this.props.manualInput,
        data: {},
        errors: []
    }

    this.googlePlacesSuggest = React.createRef()
  }

  _getValue(location){
    return location ? this._getRaw(location) : ""
  }

  componentWillReceiveProps(nextProps){
    let data = {
      ...nextProps,
      data: this.state.data || {},
      value: this._getValue(nextProps.location) || this.state.value,
      manualInput: this.state.manualInput,
    }

    let errors = this.state.errors || nextProps.errors
    if(errors){
      data.errors = errors
    }

    return data
  }

  handleInputChange = e => {
      this.setState({
        search: e.target.value,
        value: e.target.value,
        errors: [],
      })
  }

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
      this.setState({
        search: "",
        value: geocodedPrediction.formatted_address
      }, () => {
        this._updateLocation(geocodedPrediction)
      }
    )
  }

  _handleManualInputChange(e){
    let {
      data,
      errors
    } = this.state

    data[e.target.name] = e.target.value

    errors = errors.filter(error => {
      return error.key !== e.target.name
    })

    data.raw = this._getRaw(data)
    data.longitude = 0
    data.latitude = 0

    this.setState({ data, errors }, () => {
      this.props.onUpdated(data)
    })
  }

  isValid(scrollToError){
    let {
      manualInput
    } = this.state
    let errors = this._getErrors(this.state.data)
    this.setState({ errors })
    if(errors.length > 0){
      if(scrollToError){
        let selector = manualInput ? errors[0].selector : "#input-address"
        General.scrollTo(selector)
      }
      return false
    }

    return true
  }

  _getErrors(data){

    let defaultError = "This field is required"
    let errors = []

    let requiredKeys = [
      "line_1",
      "city",
      "state",
      "country"
    ]

    if(this.props.requirePostalCode){
      requiredKeys = [
        ...requiredKeys,
        "postal_code"
      ]
    }

    for(var i=0; i<requiredKeys.length; i++){
      let key = requiredKeys[i]
      if(!data[key]){
        let fieldTitle = General.snakeCaseToTitleCase(key)
        errors.push({
          key,
          selector: `#input-address-${key}`,
          message: fieldTitle + ' is required'
        })
      }
    }

    return errors
  }

  _getRaw(data){
    let raw = ""

    let orderedKeys = [
      "line_1",
      "line_2",
      "line_3",
      "city",
      "state",
      "country"
    ]

    for(var i = 0; i < orderedKeys.length; i++){
      let key = orderedKeys[i]
      let value = data[key]

      if(value){
        if(i > 0 && i < orderedKeys.length){
          raw += ", "
        }
        raw += value
      }
    }

    return raw
  }

  _updateLocation(details){
    let data = {}
    if(details.geometry && details.geometry.location){
      const location = details.geometry.location;
      data["longitude"] = location.lng();
      data["latitude"] = location.lat();
    }

    data["raw"] = details.formatted_address;
    let lines = data.raw.split(", ")
    data["line_1"] = lines[0]
    if(lines.length > 1){
      data["line_2"] = lines[1]
    }
    if(lines.length > 2){
      data["line_3"] = lines[2]
    }

    details.address_components.forEach(function(address_component){
        var type = address_component.types[0];
        if(type === "country"){
          data["country"] = address_component.long_name;
          data["country_short"] = address_component.short_name;
        }
        if(type === "locality" || type === "postal_town") {
            data["city"] = address_component.long_name;
        }
        else if(type === "administrative_area_level_1") {
            data["state"] = address_component.long_name;
        }
        else if(type === "postal_code"){
          data["postal_code"] = address_component.long_name;
        }
    });

    if(!data.line_1 || !data.city || !data.state || !data.country ||
      (this.props.requirePostalCode && !data.postal_code)){
      let errors = this._getErrors(data)
      if(errors){
        this.setState({data, errors, manualInput:true })
        return
      }
    }

    this.setState({ data, errors: [] }, () => {
      this.props.onUpdated(data)
    })
  }



  handleNoResult = () => {

  }

  handleStatusUpdate = (status) => {

  }

  _renderManualInputFields(){
    return (
      <>
        {this._renderManualInputField("line_1", 'place', 'Address Line 1')}
        {/* {this._renderManualInputField("line_2", 'place', 'Address Line 2')} */}
        {this._renderManualInputField("city", 'location_city', 'City / Town')}

        {this._renderManualInputField("state", 'pin_drop', 'State / Province')}

        {this.props.requirePostalCode && this._renderManualInputField("postal_code", 'pin_drop', 'Post Code')}

        {this._renderManualCountrySelectField('pin_drop', 'Country')}

      </>
    )
  }

  _renderManualInputField(name, icon, placeholder){
    let {
      data,
      errors
    } = this.state

    let className = "input-group"

    let error = null
    for(var i=0; i<errors.length; i++){
      if(errors[i].key === name){
        error = errors[i]
      }
    }

    if(error){
      className += " validation-error"
    }

    return (
      <div id={`input-address-${name}`} className="form-group mb-2">
        <div className={className}>
          <input
                type="text"
                name={name}
                className="form-control h-auto border-0"
                value={data[name]}
                placeholder={placeholder}
                onChange={e => this._handleManualInputChange(e)}
            />
        </div>
        { error &&
          <span className="validation-error-message">{ error.message }</span>
        }
      </div>
    )
  }

  _renderManualCountrySelectField(icon, placeholder){
    let {
      data,
      errors
    } = this.state

    let className = "input-group"
    let error = null
    for(var i=0; i<errors.length; i++){
      if(errors[i].key === "country"){
        error = errors[i]
      }
    }

    if(error){
      className += " validation-error"
    }

    return (
      <div id="input-address-country" className="form-group mb-2">
        <div className={className}>
          <CountrySelect
            value={data.country_short}
            className={className}
            onSelected={country => {
              data.country = country.label
              data.country_short = country.value
              data.raw = this._getRaw(data)

              errors = errors.filter(error => error.key !== "country")
              this.setState({ data, errors }, () => {
                this.props.onUpdated(data)
              })
            }}
          />
        </div>
        { error &&
          <span className="validation-error-message">{ error.message }</span>
        }
      </div>
    )
  }

  render() {
    const {search, value, manualInput, errors} = this.state

    if(manualInput){
      return this._renderManualInputFields()
    }

    let className = "input-group position-relative"
    if(errors.length > 0){
      className += " validation-error"
    }

    let inputAddressClassName = "form-group"
    if(window.ReactNativeWebView){
      inputAddressClassName += " mb-2"
    }

    return (
      <div id="input-address" className={inputAddressClassName}>
        <div className={className}>
          <ReactGoogleMapLoader
            params={{
                key: process.env.REACT_APP_GMAPS_KEY,
                libraries: "places,geocode",
            }}
            render={googleMaps =>
                googleMaps && (
                    <ReactGooglePlacesSuggest
                        ref={this.googlePlacesSuggest}
                        googleMaps={googleMaps}
                        autocompletionRequest={{
                            input: search,
                            // Optional options
                            // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                        }}
                        // Optional props
                        onNoResult={this.handleNoResult}
                        onSelectSuggest={this.handleSelectSuggest}
                        onStatusUpdate={this.handleStatusUpdate}
                        textNoResults="No resultst" // null or "" if you want to disable the no results item
                        customContainerRender={items => {
                          return (
                            <div className="google-suggestion-list position-absolute">
                              { items.map(item => {
                                  return (
                                    <div
                                      className="google-suggestion-item"
                                      onClick={() => {
                                        this.googlePlacesSuggest.current.handleSelectPrediction(item)
                                      }}
                                    >
                                      {item.description}
                                    </div>
                                  )
                                })
                              }
                              { this.props.allowManualInput &&
                                <p
                                  className="google-suggestion-item google-suggestion-item-manual"
                                  onClick={() => this.setState({ manualInput: true })}
                                >
                                  ENTER MY ADDRESS MANUALLY
                                </p>
                              }
                            </div>
                          )
                        }}
                    >
                    <input
                        type="text"
                        value={value}
                        className={this.props.className}
                        autoComplete="none"
                        placeholder={this.props.placeholder}
                        onChange={this.handleInputChange}
                    />
                  </ReactGooglePlacesSuggest>
              )
          }
        />
      </div>
      { errors.length > 0 &&
        <span className="validation-error-message">Please enter a more specific address</span>
      }
    </div>
    )
  }
}

LocationInput.defaultProps = {
  showIcon: false,
  manualInput: false,
  allowManualInput: false,
  placeholder: "Type your address",
}
