import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';

import General from '../../../../utils/General'

export default class PayCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      className: props.className
    }
  }


  render() {
    let {

    } = this.state

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a className="btn btn-icon btn-light btn-hover-primary btn-sm"
           style={{margin: 3}}
           onClick={e => this.props.onPayClicked()}
        >
          <i className="la la-credit-card text-primary icon-md"></i>
        </a>
      </Tooltip>
    )
  }
}

PayCell.defaultProps = {
  className: 'ml-2'
}
