import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class QRCodePrintModal extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      qrCode,
    } = this.props

    if(!qrCode){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Qr Code</Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <img
            src={qrCode.image.original}
            height="200px"
            style={{display: "block", margin: "0 auto"}}
          />
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onHide()}
          >
            Close
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            onClick={() => this.props.onPrint()}
          >
            Print Code
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

QRCodePrintModal.defaultProps = {

}
