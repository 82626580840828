import React from 'react'

import { Link } from 'react-router-dom'
import { Portal } from 'react-portal'

import moment from 'moment'

import BaseTable from './BaseTable'


import SharePaymentLinkModal from '../modal/SharePaymentLinkModal'
import TakePaymentModal from '../modal/TakePaymentModal'
import ConfirmationModal from '../modal/ConfirmationModal'
import ResendInvoiceModal from '../modal/ResendInvoiceModal'


import ViewCell from './cells/ViewCell'
import EditCell from './cells/EditCell'
import CheckCell from './cells/CheckCell'
import DownloadCell from './cells/DownloadCell'
import SendCell from './cells/SendCell'
import CustomerCell from './cells/CustomerCell'
import PaymentInstrumentCell from './cells/PaymentInstrumentCell'
import PaidCell from './cells/PaidCell'
import RecurringCell from './cells/RecurringCell'
import PillCell from './cells/PillCell'
import DeleteCell from './cells/DeleteCell'


import Currency from '../../../utils/Currency'
import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Permissions from '../../../utils/Permissions'
import Notify from '../../../utils/Notify'
import Splink from '../../../utils/Splink'


export default class InvoicesTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      subscription: props.subscription,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._refresh())
  }

  _refresh() {
    this.table.current.refresh()
  }

  _getColumns() {
    let { subscription } = this.state
    let { permissions } = AuthManager.currentUser._role
    return [
      {
        Header: 'Created Date',
        id: 'created_at',
        accessor: (invoice) =>
          moment(invoice.created_at).format('DD MMM YYYY') || '-',
      },
      {
        Header: 'Customer',
        id: 'payment.customer.email',
        Cell: (rowInfo) => {
          let invoice = rowInfo.original
          let customer = invoice.payment?.payment_instrument?.customer_data || invoice.payment?.customer
          if(!customer){
            customer = subscription.payment_instrument?.customer_data || subscription.initial_payment.customer
          }
          return <CustomerCell customer={customer} />
        },
        width: 160,
      },
      {
        Header: 'Amount',
        id: 'payment.payment_plan.total',
        width: 100,
        Cell: (rowInfo) => {
          let invoice = rowInfo.original
          let paymentPlan = invoice.payment?.payment_plan
          if(!paymentPlan){
            paymentPlan = subscription.payment_plan || subscription.initial_payment.payment_plan
          }

          return Currency.format(
            paymentPlan.currency.symbol,
            paymentPlan.total
          )
        },
      },
      {
        Header: 'Paid',
        id: 'payment.paid_at',
        Cell: (rowInfo) => {
          let invoice = rowInfo.original
          if (!invoice.payment) {
            return null
          }
          return <PaidCell paid={invoice.payment.paid_at} />
        },
      },
      {
        Header: 'Payment Method',
        id: 'payment.payment_instrument',
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let invoice = rowInfo.original
          return <PaymentInstrumentCell paymentInstrument={invoice.payment?.payment_instrument}/>
        },
        width: 160,
      },
      {
        Header: 'Paid At',
        id: 'payment.paid_at',
        accessor: (invoice) =>
          invoice.payment
            ? moment(invoice.payment.paid_at).format('DD MMM YYYY')
            : '-',
      },
      {
        Header: 'Refund Date',
        id: 'payment.refunded_at',
        accessor: (invoice) =>
          invoice.payment && invoice.payment.refunded_at
            ? moment(invoice.payment.refunded_at).format('DD MMM YYYY')
            : '-',
      },
      {
        Header: 'Installment #',
        id: 'installment_no',
        accessor: 'installment_no',
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let invoice = rowInfo.original
          return (
            <span
              style={{ overflow: 'visible', position: 'relative', width: 150 }}
            >
              {invoice.payment && invoice.payment.file && (
                <DownloadCell
                  tooltip={'Download Receipt'}
                  onDownloadClicked={() => {
                    window.open(invoice.payment.file.url, '_blank')
                  }}
                />
              )}
              {invoice.payment && (
                <SendCell
                  tooltip={'Resend Receipt'}
                  onSendClicked={() => {
                    this.setState({
                      invoiceToResend: invoice,
                      showResendReceiptModal: true,
                    })
                  }}
                />
              )}
              {invoice.payment &&
                !invoice.payment.deleted_at &&
                Permissions.hasDeletePermission(Permissions.RECURRING) && (
                  <DeleteCell
                    tooltip={'Refund'}
                    onDeleteClicked={() => {
                      this.setState({
                        invoice: invoice,
                        showConfirmationModal: true,
                      })
                    }}
                  />
                )}
            </span>
          )
        },
      },
    ]
  }

  _deletePayment() {
    let { invoice } = this.state

    Backend.deletePayment(invoice.payment)
      .then(() => {
        Notify.success('Payment has been refunded successfully')
        this.setState({
          invoice: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          invoice: null,
          showConfirmationModal: false,
        })
      })
  }

  _renderConfirmationModal() {
    let { invoice, invoiceToResend, showConfirmationModal } = this.state

    let message = 'Are you sure you want to refund this payment?'
    if (invoiceToResend) {
      message = 'Are you sure you want to resend the receipt for this payment?'
    }

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={message}
        onConfirm={() => {
          if (invoice) {
            this._deletePayment()
          } else if (invoiceToResend) {
            this._resendReceipt()
          }
        }}
        onHide={() => {
          this.setState({
            invoice: null,
            invoiceToResend: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  _renderResendModal(){
    let { showResendReceiptModal, invoiceToResend, subscription } = this.state

    if(!showResendReceiptModal){
      return null
    }

    return (
      <ResendInvoiceModal
        show={showResendReceiptModal}
        invoice={invoiceToResend}
        subscription={subscription}
        onResent={() => {
          this.table.current.refresh()
          Notify.success("Receipt successfully resent")
          this.setState({
            invoiceToResend: null,
            showResendReceiptModal: false,
          })
        }}
        onHide={() => {
          this.setState({
            invoiceToResend: null,
            showResendReceiptModal: false,
          })
        }}
      />
    )
  }

  _renderToolBar() {
    return <></>
  }

  render() {
    let { subscription } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Invoices}?subscription_id=${subscription.initial_payment.id}`}
          noDataMessage={'No invoices found'}
          title={this.props.title}
          columns={columns}
          showExport={false}
          showHeader={false}
          defaultSorted={[
            {
              id: 'installment_no',
              desc: true,
            },
          ]}
          showSearch={false}
          showPaginationBottom={!this.props.latestResults}
          renderToolbar={() => this._renderToolBar()}
        />
        {this._renderConfirmationModal()}
        {this._renderResendModal()}
      </div>
    )
  }
}

InvoicesTable.defaultProps = {
  latestResults: false,
}
