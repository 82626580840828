import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class SuccessModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      company: props.company,
      error: props.error
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      company,
      error
    } = this.props

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Body>
        <div
          className="swal2-icon swal2-success swal2-icon-show"
          style={{ display: 'flex' }}
        >
          <div className="swal2-success-circular-line-left"></div>
          <span className="swal2-success-line-tip"></span>
          <span className="swal2-success-line-long"></span>
          <div className="swal2-success-ring"></div>
          <div className="swal2-success-fix"></div>
          <div className="swal2-success-circular-line-right"></div>
        </div>

        <h1 className="text-center">{this.props.title}</h1>
        <p className="text-center">{this.props.message}</p>
        </Modal.Body>
      </Modal>
    )
  }
}

SuccessModal.defaultProps = {
  title: 'TITLE',
  message: 'Message required',
  modalClassName: ''
}
