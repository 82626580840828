import React from "react";

import { withRouter } from "react-router-dom";

import Alert from "./Alert";

import AuthManager from "../../../utils/AuthManager";

class MFAAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mfaEnabled: AuthManager.currentUser.user.mfa_enabled,
    };
  }

  render() {
    let { mfaEnabled } = this.state;

    // todo remove - temporarily hide warning
    return null

    if (mfaEnabled) {
      return null;
    }

    let text = "Add an extra layer of security to your account. Enable Multi Factor Authentication.";
    let cta = "Enable";
    let onClick = () => this.props.history.push("/settings/multi-factor-authentication");

    return <Alert text={text} cta={cta} closable={false} onClick={onClick} />;
  }
}

export default withRouter(MFAAlert);
