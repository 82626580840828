import React from 'react'
import Modal from 'react-bootstrap/Modal'

import {loadStripe} from '@stripe/stripe-js';
import {IbanElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import Div from "../layouts/Div"

import Select from '../common/CustomSelect'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"


export default class BankAccountNameModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _isFormValid(){
    let {
      accountHolderName,
      statementDescriptor,
    } = this.state

    let error = null

    if(!accountHolderName){
      error = "Please enter a valid 'Account Holder Name'"
    }

    else if(!statementDescriptor || statementDescriptor.length != 4){
      error = "Please enter a valid 'Statement Description'"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _onConfirm(){
    if(!this._isFormValid()){
      return
    }
    let { accountHolderName, statementDescriptor } = this.state
    this.props.onConfirm(accountHolderName, statementDescriptor)
  }

  render() {
    let {
      show,
      accountHolderName,
      statementDescriptor
    } = this.state


    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        dialogClassName="modal-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bank Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p className="my-7">Please enter the following details to complete setup</p>
            <div className="form-group row">
              <label className="col-lg-5 col-form-label">Account Holder Name:</label>
              <div className="col-lg-7 my-auto">
                <input
                  type="text"
                  className="form-control form-control-solid"
                  value={accountHolderName}
                  onChange={e => this.setState({ accountHolderName: e.target.value || null})}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-5 col-form-label">Statement Descriptor</label>
              <div className="col-lg-7 my-auto">
                <input
                  className="form-control form-control-solid"
                  type="text"
                  value={statementDescriptor}
                  placeholder="Statement Descriptor"
                  maxLength={4}
                  onChange={e => {
                    this.setState({ statementDescriptor: e.target.value || null })
                  }}
               />
               <span className="text-muted">4 Characters that identify your business on a customer's bank statement</span>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            onClick={() => this._onConfirm()}
          >
            Save
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

BankAccountNameModal.defaultProps = {

}
