import React from 'react'

import BaseTable from './BaseTable'
import CompanyInvoiceLineItemsTable from "./CompanyInvoiceLineItemsTable"

import moment from 'moment'

import PayCell from './cells/PayCell'
import PillCell from './cells/PillCell'
import DownloadCell from './cells/DownloadCell'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from '../../../utils/Currency'
import Notify from '../../../utils/Notify'

const STATUS_FILTERS = {
  name: {
    api: 'paid',
    display: 'Status',
  },
  values: [
    {
      label: 'All',
      value: null,
    },
    {
      label: 'Paid',
      value: true,
    },
    {
      label: 'Unpaid',
      value: false,
    },
  ],
}

const FILTERS = [STATUS_FILTERS]

export default class CompanyInvoicesTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _refresh() {
    this.table.current.refresh()
  }

  _getColumns() {
    return [
      {
        Header: 'Due At',
        id: 'due_at',
        Cell: (rowInfo) => {
          let invoice = rowInfo.original

          return moment(invoice.due_at).format('DD MMM YYYY')
        },
      },
      {
        Header: 'Amount',
        id: 'total',
        Cell: (rowInfo) => {
          let invoice = rowInfo.original

          let now = moment()
          let endDate = moment(invoice.end_date)

          if(endDate >= now && invoice.total){
            let key = invoice.status == "paid" ? "total_paid" : "total_due"
            return Currency.format(
              invoice.currency?.symbol,
              invoice[key]
            )
          }

          return "-"
        },
      },
      {
        Header: 'Status',
        id: 'paid',
        Cell: (rowInfo) => {
          let invoice = rowInfo.original

          let status = invoice.status
          let className = ""
          if(status == "draft"){
            className = 'label-light-dark'
          }
          else if(status == "pending"){
            className = 'label-light-primary'
          }
          else if(status == "paid"){
            className = "label-light-success"
          }
          else{
            className = "label-light-danger"
          }

          return <PillCell text={General.toTitleCase(status)} className={className} />
        },
      },
      {
        Header: 'From',
        id: 'transaction_start_date',
        Cell: (rowInfo) => {
          let invoice = rowInfo.original

          if(!invoice.transaction_start_date){
            return "-"
          }

          return moment(invoice.transaction_start_date).format('DD MMM YYYY')
        },
      },
      {
        Header: 'To',
        id: 'transaction_end_date',
        Cell: (rowInfo) => {
          let invoice = rowInfo.original
          if(!invoice.transaction_end_date){
            return "-"
          }
          return moment(invoice.transaction_end_date).format('DD MMM YYYY')
        },
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let invoice = rowInfo.original

          let status = invoice.status

          return (
            <span
              style={{ overflow: 'visible', position: 'relative', width: 150 }}
            >
              { invoice.file &&
                  <DownloadCell
                    tooltip={'Download'}
                    onDownloadClicked={() => {
                      window.open(invoice.file.url, '_blank')
                    }}
                  />
              }
              { (status == "overdue") &&
                  <PayCell
                    tooltip={'Pay'}
                    onPayClicked={() => {
                      this._pay(invoice)
                    }}
                  />
              }
            </span>
          )
        },
      },
    ]
  }

  _pay(invoice){
    if(this.state.payingInvoice){
      return
    }
    this.setState({ payingInvoice: true })
    Backend.payCompanyInvoice(invoice)
    .then(() => {
      this._refresh()
      Notify.success("Invoice paid successfully")
      this.setState({ payingInvoice: false })
    })
    .catch(e => {
      this._refresh()
      Notify.error(e.message)
      this.setState({ payingInvoice: false })
    })
  }

  _renderLineItems(invoice) {
    return <CompanyInvoiceLineItemsTable invoice={invoice}/>
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.CompanyInvoices}
          noDataMessage={'No Invoices found'}
          title={this.props.title}
          columns={columns}
          showExport={false}
          filters={FILTERS}
          showSearch={false}
          showObjectFilter={false}
          defaultSorted={[
            {
              id: 'due_at',
              desc: true,
            },
          ]}
          SubComponent={(row) => this._renderLineItems(row.original)}
        />
      </div>
    )
  }
}
