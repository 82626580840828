import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import SubsplinkModal from '../modal/SubsplinkModal'
import Currency from '../../../utils/Currency'
import AuthManager from '../../../utils/AuthManager'
import Permissions from '../../../utils/Permissions'

import DeleteCell from './cells/DeleteCell'
import ViewCell from './cells/ViewCell'
import EditCell from './cells/EditCell'
import Splink from '../../../utils/Splink'
import Notify from '../../../utils/Notify'
import ConfirmationModal from '../modal/ConfirmationModal'
import CheckCell from './cells/CheckCell'

export default class ChannelsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      company: AuthManager.currentUser.company,
    }

    this.table = React.createRef()
  }

  _getColumns() {
    let { permissions } = AuthManager.currentUser._role
    return [
      {
        Header: 'Title',
        id: 'title',
        accessor: (channel) => General.toTitleCase(channel.title),
        width: 150,
      },
      {
        Header: 'Channel',
        id: 'slug',
        accessor: (channel) =>
          Splink.getPaymentLinkUrl(this.state.company, channel),
        width: 280,
      },
      {
        Header: 'Total Amount',
        id: 'sales_amount',
        accessor: (channel) => {
          let symbol = ''
          if (channel.payment_plans.length > 0) {
            symbol = channel.payment_plans[0].currency.symbol
          }
          return Currency.format(symbol, channel.sales_amount)
        },
      },
      {
        Header: 'Total Payments',
        id: 'sales_no',
        accessor: 'sales_no',
      },
      {
        Header: 'Views',
        id: 'views_no',
        accessor: 'views_no',
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 150,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let channel = rowInfo.original
          let url = Splink.getPaymentLinkUrl(this.state.company, channel)
          return (
            <span
              style={{ overflow: 'visible', position: 'relative', width: 150 }}
            >
              {!channel.deleted_at && (
                <>
                  <ViewCell
                    tooltip={'View'}
                    onViewClicked={() => {
                      window.open(url, '_blank')
                    }}
                  />
                  {Permissions.hasEditPermission(Permissions.PAYMENT_LINK) && (
                    <>
                      <EditCell
                        tooltip={'Edit'}
                        onEditClicked={() => {
                          this.setState({
                            channel,
                            showSubSplinkModal: true,
                          })
                        }}
                      />
                      <DeleteCell
                        tooltip={'Archive'}
                        onDeleteClicked={() => {
                          this.setState({
                            channel,
                            showConfirmationModal: true,
                          })
                        }}
                      />
                    </>
                  )}
                </>
              )}
              {channel.deleted_at && (
                <CheckCell
                  tooltip={'Restore'}
                  onCheckClicked={() => {
                    this.setState({
                      deletedChannel: channel,
                      showConfirmationModal: true,
                    })
                  }}
                />
              )}
            </span>
          )
        },
      },
    ]
  }

  _deleteChannel() {
    let { channel } = this.state

    Backend.deleteChannel(channel)
      .then((channel) => {
        Notify.success('Channel has been deleted successfully')
        this.setState({
          channel: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          channel: null,
          showConfirmationModal: false,
        })
      })
  }

  _restoreChannel() {
    let { deletedChannel } = this.state

    deletedChannel.deleted_at = null

    Backend.updateChannel(deletedChannel)
      .then((channel) => {
        Notify.success('Channel has been restored successfully')
        this.setState({
          deletedChannel: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          deletedChannel: null,
          showConfirmationModal: false,
        })
      })
  }

  render() {
    let { channel, deletedChannel, showSubSplinkModal, showConfirmationModal } =
      this.state
    let { permissions } = AuthManager.currentUser._role
    const columns = this._getColumns()

    let message = 'Are you sure you want to hide this channel?'
    if (deletedChannel) {
      message = 'Are you sure you want to restore this channel?'
    }

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.PaymentLinks}`}
          noDataMessage={'No payment links found'}
          title={this.props.title}
          columns={columns}
          showExport={false}
          searchFilter={'active'}
          filterValue={{ objects: 'active' }}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          renderToolbar={() => {
            return (
              <>
                <a
                  href={Splink.getPaymentLinksUrl(
                    AuthManager.currentUser.company
                  )}
                  className="btn btn-light-primary font-weight-bolder"
                  target={'_blank'}
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M18,15 L18,13.4774152 C18,13.3560358 18.0441534,13.2388009 18.1242243,13.147578 C18.3063883,12.9400428 18.622302,12.9194754 18.8298372,13.1016395 L21.7647988,15.6778026 C21.7814819,15.6924462 21.7971714,15.7081846 21.811763,15.7249133 C21.9932797,15.933015 21.9717282,16.2488631 21.7636265,16.4303797 L18.828665,18.9903994 C18.7375973,19.0698331 18.6208431,19.1135979 18.5,19.1135979 C18.2238576,19.1135979 18,18.8897403 18,18.6135979 L18,17 L16.445419,17 C14.5938764,17 12.8460429,16.1451629 11.7093057,14.6836437 L7.71198984,9.54423755 C6.95416504,8.56989138 5.7889427,8 4.55458097,8 L2,8 L2,6 L4.55458097,6 C6.40612357,6 8.15395708,6.85483706 9.29069428,8.31635632 L13.2880102,13.4557625 C14.045835,14.4301086 15.2110573,15 16.445419,15 L18,15 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M18,6 L18,4.4774157 C18,4.3560363 18.0441534,4.23880134 18.1242243,4.14757848 C18.3063883,3.94004327 18.622302,3.9194759 18.8298372,4.10163997 L21.7647988,6.67780304 C21.7814819,6.69244668 21.7971714,6.70818509 21.811763,6.72491379 C21.9932797,6.93301548 21.9717282,7.24886356 21.7636265,7.43038021 L18.828665,9.99039986 C18.7375973,10.0698336 18.6208431,10.1135984 18.5,10.1135984 C18.2238576,10.1135984 18,9.88974079 18,9.61359842 L18,8 L16.445419,8 C15.2110573,8 14.045835,8.56989138 13.2880102,9.54423755 L9.29069428,14.6836437 C8.15395708,16.1451629 6.40612357,17 4.55458097,17 L2,17 L2,15 L4.55458097,15 C5.7889427,15 6.95416504,14.4301086 7.71198984,13.4557625 L11.7093057,8.31635632 C12.8460429,6.85483706 14.5938764,6 16.445419,6 L18,6 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  View Channels
                </a>
                {Permissions.hasCreatePermission(Permissions.PAYMENT_LINK) && (
                  <a
                    className="btn btn-primary font-weight-bolder ml-4"
                    onClick={() => {
                      this.setState({ showSubSplinkModal: true })
                    }}
                  >
                    <i className="flaticon2-add-1 icon-sm"></i>
                    Create A Channel
                  </a>
                )}
              </>
            )
          }}
        />

        <SubsplinkModal
          show={showSubSplinkModal}
          subsplink={channel}
          onAdded={() => {
            this.table.current.refresh()
            this.setState({
              channel: null,
              showSubSplinkModal: false,
            })
          }}
          onHide={() => {
            this.setState({
              channel: null,
              showSubSplinkModal: false,
            })
          }}
        />

        <ConfirmationModal
          show={showConfirmationModal}
          title={message}
          onConfirm={() => {
            if (channel) {
              this._deleteChannel()
            } else if (deletedChannel) {
              this._restoreChannel()
            }
          }}
          onHide={() => {
            this.setState({
              channel: null,
              showConfirmationModal: false,
            })
          }}
        />
      </div>
    )
  }
}
