import React from 'react'

// import ImageEdit from "../common/ImageEdit"
import PhoneInput from '../common/PhoneInput'
import LocationInput from '../common/LocationInput'

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'
import Email from '../../../utils/Email'
import Notify from '../../../utils/Notify'
import General from '../../../utils/General'
import AsyncSelect from '../common/AsyncSelect'
import Splink from '../../../utils/Splink'
import Event from '../../../utils/Event'

import { TAX } from '../../../constants/Variables'

export default class GeneralSettings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: props.loading,
      company: props.company,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let { company, loading } = this.state

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <p className="my-10">
                Your Account/Contact Name and Business Info are used in your{' '}
                {window.General.Branding.Name}, emails, invoices and any other
                communications with your customers. As such ensure these reflect
                your brand and are kept up to date.
              </p>
              <div className="form-group row">
                <label className="col-3 col-form-label">Account Name</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={company.name}
                    onChange={(e) => {
                      company.name = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Contact Name</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={company.contact_name}
                    onChange={(e) => {
                      company.contact_name = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Email</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="email"
                    value={company.settings?.email}
                    onChange={(e) => {
                      company.settings.email = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Phone Number</label>
                <div className="col-9">
                  <PhoneInput
                    value={company.settings?.phone_number}
                    onChange={(value, data) => {
                      company.settings.phone_number = '+' + value
                      this.props.onUpdated(company)
                    }}
                    inputProps={{
                      className: 'form-control form-control-solid',
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Address</label>
                <div className="col-9">
                  <LocationInput
                    className="form-control form-control-solid"
                    requirePostalCode={true}
                    allowManualInput={true}
                    placeholder="Address"
                    location={company.settings?.address}
                    onUpdated={address => {
                      company.settings.address = address
                      this.props.onUpdated(company)
                    }}
                  />
                  { company.settings?.address &&
                    <div className="w-100 text-right">
                      <span
                        className="cursor-pointer text-right text-warning"
                        onClick={() => {
                          company.settings.address = null
                          this.props.onUpdated(company)
                        }}
                      >
                        Delete Address
                      </span>
                    </div>
                  }
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Business Info</label>
                <div className="col-9">
                  <textarea
                    className="form-control form-control-solid"
                    value={company.business_info}
                    onChange={(e) => {
                      company.business_info = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">
                    This information will be displayed at the bottom of your PDF
                    invoices and receipts. (e.g - business name | address |
                    contact number | {TAX.toLowerCase()} number)
                  </span>
                </div>
              </div>

              <p className="my-10">
                Customise your customer's payment confirmation pop up. You can
                also redirect your user to your website or social media channel
                after payment.
              </p>
              <div className="form-group row">
                <label className="col-3 col-form-label">Title</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={company.settings.payment_success_title}
                    onChange={(e) => {
                      company.settings.payment_success_title = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">
                    The title used in the pop up.
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Message</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={company.settings.payment_success_message}
                    onChange={(e) => {
                      company.settings.payment_success_message = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">
                    The message used in the pop up.
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Button</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={company.settings.payment_success_button}
                    onChange={(e) => {
                      company.settings.payment_success_button = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">
                    The text to display on the button that closes the pop up.
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Url Redirect</label>
                <div className="col-9">
                  <input
                    ref={(input) => {
                      this.urlInput = input
                    }}
                    className="form-control form-control-solid"
                    type="text"
                    value={company.settings.payment_success_url || 'http://'}
                    onFocus={() => {
                      if (
                        !AuthManager.currentUser.company.add_ons
                          .payment_redirect
                      ) {
                        Event.emit('ADD_ON_ALERT')
                        this.urlInput.blur()
                        return
                      }
                    }}
                    onChange={(e) => {
                      company.settings.payment_success_url = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">
                    The url to send your customers to after payment.
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{ margin: '0 auto' }}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.onSave()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
      </>
    )
  }
}
