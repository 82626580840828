
export default class CompanyProcessor {
  static getDefault(company){
    return company.company_processors.find(companyProcessor => companyProcessor.default)
  }

  static isDefaultStripeCustomConnect(company){
    let companyProcessor = CompanyProcessor.getDefault(company)
    return companyProcessor?.processor_slug === 'stripe' || companyProcessor?.processor_slug === 'stripe_test'
  }

  static isDefaultStripeStandardConnect(company){
    let companyProcessor = CompanyProcessor.getDefault(company)
    return companyProcessor?.processor_slug === 'stripe_standard' || companyProcessor?.processor_slug === 'stripe_standard_test'
  }

  static isSingleCurrencyProcessor(companyProcessor) {
    switch (companyProcessor.processor_slug) {
      case 'apaylo_test':
        return true
      case 'apaylo':
        return true
      case 'card_connect_test':
        return true
      case 'card_connect':
        return true
      default:
        return false
        break
    }
  }
}
