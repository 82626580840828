import React from "react"

export default class QuickPanel extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      tabs: props.tabs,
      activeTabIndex: props.activeTabIndex
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      tabs,
      activeTabIndex,
    } = this.state

    return (
      <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
             kt-hidden-height="44">
          <ul className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
              role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#kt_quick_panel_notifications">Activity Feed</a>
            </li>
          </ul>
          <div className="offcanvas-close mt-n1 pr-5">
            <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close">
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
        </div>
        <div className="offcanvas-content px-10">
          <div className="tab-content">
            <div className="tab-pane fade show pt-2 pr-5 mr-n5 active scroll ps ps--active-y"
                 id="kt_quick_panel_notifications" role="tabpanel" style={{height: 423, overflow: 'hidden'}}>
              <div className="navi navi-icon-circle navi-spacer-x-0">
                <a href="#" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-primary svg-icon-2x">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                               height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
                                fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                              <path
                                d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
                                fill="#000000" opacity="0.3"></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold font-size-lg">Payment Request Viewed</div>
                      <div className="text-muted">Sean | €235 | 2 Views</div>
                    </div>
                  </div>
                </a>
                <a href="dash/payments.php" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-info svg-icon-2x">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                               height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <rect fill="#000000" opacity="0.3" x="2" y="5" width="20" height="14" rx="2"></rect>
                              <rect fill="#000000" x="2" y="8" width="20" height="3"></rect>
                              <rect fill="#000000" opacity="0.3" x="16" y="14" width="4" height="2" rx="1"></rect>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold font-size-lg">Payment Received (Take)</div>
                      <div className="text-muted">Jane | €540</div>
                    </div>
                  </div>
                </a>
                <a href="dash/requests.php" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-warning svg-icon-2x">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                               height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <rect fill="#000000" opacity="0.3" x="2" y="5" width="20" height="14" rx="2"></rect>
                              <rect fill="#000000" x="2" y="8" width="20" height="3"></rect>
                              <rect fill="#000000" opacity="0.3" x="16" y="14" width="4" height="2" rx="1"></rect>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold font-size-lg">Payment Received (Request)</div>
                      <div className="text-muted">James | €1,070 | 1 View</div>
                    </div>
                  </div>
                </a>
                <a href="dash/requests.php" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-primary svg-icon-2x">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                               height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                                fill="#000000"></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold font-size-lg">Payment Request Sent</div>
                      <div className="text-muted">Sylvia | €750</div>
                    </div>
                  </div>
                </a>
                <a href="dash/payments.php" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-info svg-icon-2x">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                               height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z"
                                fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                              <path
                                d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z"
                                fill="#000000"></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold font-size-lg">Payment Received (Shop)</div>
                      <div className="text-muted"> Jane | €540</div>
                    </div>
                  </div>
                </a>
                <a href="dash/payments.php" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-danger svg-icon-2x">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                               height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <rect fill="#000000" opacity="0.3" x="2" y="5" width="20" height="14" rx="2"></rect>
                              <rect fill="#000000" x="2" y="8" width="20" height="3"></rect>
                              <rect fill="#000000" opacity="0.3" x="16" y="14" width="4" height="2" rx="1"></rect>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold font-size-lg">Payment Received (Take)</div>
                      <div className="text-muted">Andy | €380</div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="ps__rail-x" style={{left: 0, bottom: 0}}>
                <div className="ps__thumb-x" tabIndex="0" style={{left: 0, width: 0}}></div>
              </div>
              <div className="ps__rail-y" style={{top: 0, height: 423, right: 0}}>
                <div className="ps__thumb-y" tabIndex="0" style={{top: 0, height: 300}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
