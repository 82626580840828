import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Notify from "../../../utils/Notify"
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";

export default class WebhookModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show:props.show,
      webhook: props.webhook ? General.clone(props.webhook) : {
        url: null,
        payment_received: false,
        payment_failed: false,
        payment_refunded: false,
        payment_request_viewed: false,
        payment_request_marked_as_paid: false,
        invoice_payment_succeeded: false,
        invoice_payment_failed: false,
        subscription_completed: false,
        subscription_cancelled: false,
        company_created: false,
        company_updated: false,
        company_member_created: false,
        company_member_updated: false,
        company_member_deleted: false,
      }
    }
  }

  _isFormValid(){
    let { webhook } = this.state

    let error = null
    if(!webhook.url){
      error = "Please specify a url"
    }
    else if(webhook.url.indexOf("https://") == -1 && webhook.url.indexOf("http://") == -1){
      error = "Please specify an absolute url"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _saveWebhook(){

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    this._handleBackend(this.state.webhook)
    .then(webhook => {
      this.props.onUpdated(webhook)
      this.setState({
        webhook,
        loading: false
      })
      Notify.success("Webhook successfully updated!")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _handleBackend(webhook){
    if(!webhook.id){
      return Backend.createWebhook(webhook)
    }
    else{
      return Backend.updateWebhook(webhook)
    }
  }

  render() {
    let {
      show,
      webhook,
      loading
    } = this.state

    if(!show){
      return null
    }

    let title = webhook.id ? "Update Webhook" : "Create Webhook"
    return (
      <Modal
        show={show}
        dialogClassName={'modal-dialog-centered modal-lg'}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <label className="col-3 col-form-label">URL</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                placeholder="https://"
                value={webhook.url}
                onChange={(e) => {
                  webhook.url = e.target.value
                  this.setState({webhook})
                }}
              />
              <span className="text-muted">Must start with https:// or http://</span>
            </div>
          </div>

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr />
            </div>
            <div className="col-6">
              <span>
                <strong>Events</strong>
              </span>
            </div>
            <div className="col-3">
              <hr />
            </div>
          </div>

          <div className="row">

            { EVENTS.map(event => {
                let { label, key, description } = event
                if(event.partner_only){
                  return null
                }
                return (
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col-8 col-form-label">
                        {label}
                        <br/>
                        <span className="text-muted">{description}</span>
                      </label>
                      <div className="col-4">
                        <span className="switch switch-icon mt-4">
                          <label>
                            <input
                              type="checkbox"
                              checked={webhook[key]}
                              onChange={e => {
                                webhook[key] = e.target.checked
                                this.setState({webhook})
                              }}
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>

        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className={`btn btn-secondary font-weight-bold`}
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className={`btn btn-primary font-weight-bold`}
              onClick={() => this._saveWebhook()}
              disabled={loading}
            >
              Save
            </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

WebhookModal.defaultProps = {

}


const EVENTS = [
  {
    label: "Payment Received",
    key: "payment_received",
    description: "Sent when a payment is taken/made"
  },
  {
    label: "Payment Failed",
    key: "payment_failed",
    description: "Sent when a payment fails due to a processor related setup/kyc issue"
  },
  {
    label: "Payment Refunded",
    key: "payment_refunded",
    description: "Sent when a payment is refunded"
  },
  {
    label: "Payment Request Sent",
    key: "payment_request_sent",
    description: "Sent when a payment request is sent/created"
  },
  {
    label: "Payment Request Viewed",
    key: "payment_request_viewed",
    description: "Sent when a request is viewed"
  },
  {
    label: "Payment Request Marked As Paid",
    key: "payment_request_marked_as_paid",
    description: "Sent when a payment request is marked as paid"
  },
  {
    label: "Invoice Payment Succeeded",
    key: "invoice_payment_succeeded",
    description: "Sent when a subsequent invoice is paid successfully"
  },
  {
    label: "Invoice Payment Failed",
    key: "invoice_payment_failed",
    description: "Sent when an invoice payment fails"
  },
  {
    label: "Subscription Completed",
    key: "subscription_completed",
    description: "Sent when a subscription is completed"
  },
  {
    label: "Subscription Cancelled",
    key: "subscription_cancelled",
    description: "Sent when a subscription is cancelled"
  },
  {
    label: "Company Created",
    key: "company_created",
    partner_only: true,
    description: "Sent when a company is created"
  },
  {
    label: "Company Updated",
    key: "company_updated",
    description: "Sent when a company's settings are updated"
  },

  {
    label: "Team Member Created",
    key: "company_member_created",
    description: "Sent when a Team Member is created"
  },
  {
    label: "Team Member Updated",
    key: "company_member_updated",
    description: "Sent when a Team Member is updated"
  },
  {
    label: "Team Member Deleted",
    key: "company_member_deleted",
    description: "Sent when a Team Member is deleted"
  },
]
