import React from "react"
import General from "../../../utils/General";

const LAYOUTS = [
  {
    type: "retro",
    image: "/assets/media/splink-layouts/layout-5.png"
  },
  {
    type: "classic",
    image: "/assets/media/splink-layouts/layout-1.png"
  },
  {
    type: "modern",
    image: "/assets/media/splink-layouts/layout-2.png"
  }
]

export default class PaymentLinkStyle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: props.loading,
      company: props.company
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      company,
      loading,
      selectedLayout,
    } = this.state

    if(!selectedLayout){
      selectedLayout=company.settings.layout
    }

    return (

      <div className="col payment-link-style">
        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <h4 className="mb-10 font-weight-bold text-dark text-capitalize">Choose A {window.General.Branding.LinkName} Layout</h4>

            <div className='choose'>
              {LAYOUTS.map((layout) => (
                <div
                  key={layout.type}
                  className={`graphic ${ selectedLayout === layout.type ? ' selected flaticon2-check-mark' : ' '}`}
                  onClick={() => {
                    company.settings.layout = layout.type
                    this.props.onUpdated(company)
                    this.setState({selectedLayout: layout.type})
                  }}
                >
                    <div className='text text-capitalize'>{layout.type} Layout</div>
                    <div className='image'>
                      <img src={layout.image} alt="" className="w-100" loading="lazy" />
                    </div>
                </div>
              ))}
            </div>

          </div>
          <div className="d-flex justify-content-between border-top mt-5 pt-10">
            <div style={{ margin: '0 auto' }}>
              <button
                className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                disabled={loading}
                onClick={e => {
                  e.preventDefault()
                  this.props.onSave()
                }}
              >
                Save Changes
              </button>
            </div>
          </div>

        </form>
      </div>
    )
  }
}
