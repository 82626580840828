import React from "react"

import ImageEdit from "../common/ImageEdit";
import ColorPicker from "../common/ColorPicker";

import Event from "../../../utils/Event"
import AuthManager from "../../../utils/AuthManager"

export default class BrandingSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: props.loading,
      company: props.company
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      company,
      loading
    } = this.state

    return (
      <>
        <div className="col c-branding-settings">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <h4 className="mb-10 font-weight-bold text-dark">Add Your Business Branding To Your Account</h4>
              <p>You have the option to further customise your {window.General.Branding.LinkName} with your own branded background image, logo and
                colours. If you need any help with this, just reach out to us and we'll help you.</p>

              <div className="form-group row background-image">
                <label className="col-3 col-form-label">Choose A Background Image</label>
                <div className="col-9 mb-4">
                  <ImageEdit
                    image={company.settings.background}
                    hidePreview={false}
                    deletable={true}
                    imagePicker={true}
                    type={"banner"}
                    onUpdated={image => {
                      this.setState({loading: false})
                      company.settings.background = image
                      this.props.onUpdated(company)
                    }}
                    onDeleteClicked={() => {
                      company.settings.background = null
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Add A Logo</label>
                <div className="col-9 mb-4">
                  <ImageEdit
                    image={company.settings.logo}
                    hidePreview={false}
                    deletable={true}
                    cropImage={true}
                    onUpdated={image => {
                      this.setState({loading: false})
                      company.settings.logo = image
                      this.props.onUpdated(company)
                    }}
                    onDeleteClicked={() => {
                      company.settings.logo = null
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Main Colour</label>
                <div className="col-9 mb-4">
                  <ColorPicker
                    color={company.settings.primary_color}
                    onColorChange={color => {
                      company.settings.primary_color = color.hex
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>

              {/*
              <div className="form-group row">
                <label className="col-3 col-form-label">Portal Colour</label>
                <div className="col-9 mb-4">
                  <ColorPicker
                    color={company.settings.secondary_color}
                    onColorChange={color => {
                      company.settings.secondary_color = color.hex
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>
              */}

              <div className="form-group row">
                <label className="col-3 col-form-label">Add A Caption</label>
                <div className="col-9">
                  <textarea
                    className="form-control form-control-solid"
                    value={company.caption}
                    onChange={e => {
                      company.caption = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Choose Title Size</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <select
                      className="form-control form-control-solid"
                      value={company.settings.text_size}
                      onChange={e => {
                        company.settings.text_size = e.target.value
                        this.props.onUpdated(company)
                      }}
                    >
                      <option value="small">Small</option>
                      <option value="medium">Medium</option>
                      <option value="large">Large</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Description</label>
                <div className="col-9">

                    <div className="select-wrapper">
                      <select
                        className="form-control form-control-solid"
                        value={company.settings.collect_note}
                        onChange={e => {
                          company.settings.collect_note = e.target.value
                          this.props.onUpdated(company)
                        }}
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                        <option value="description">Text</option>
                      </select>
                    </div>

                  <span className="form-text text-muted">Do you want to enable a description field on your {window.General.Branding.LinkName}?</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Description (Text)</label>
                <div className="col-9">
                  <textarea
                    className="form-control form-control-solid"
                    value={company.description}
                    onChange={e => {
                      company.description = e.target.value
                      this.props.onUpdated(company)
                    }}
                  />
                  <span className="form-text text-muted">This text will be displayed on your open {window.General.Branding.LinkName} if 'Text' option is selected above.</span>
                </div>
              </div>

            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    this.props.onSave()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
      </>
    )
  }
}
