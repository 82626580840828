import React from "react"
import BulkUploadTableRowInputCell from "./BulkUploadTableRowInputCell";
import BulkUploadTableRowSelectCell from "./BulkUploadTableRowSelectCell";
import BulkUploadTableRowDateCell from "./BulkUploadTableRowDateCell";

const RECURRING_CELL_ENABLED = false
export default class BulkUploadTableRowCell extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      row: props.row,
      rowIndex: props.rowIndex,
      headers: props.headers,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      row,
      headers,
      rowIndex
    } = this.state

    let values = new Array(headers.length)

    let rowKeys = Object.keys(row)

    rowKeys.map(
      key => {
        let index = headers.findIndex(heading => {
          if(key === "mobile"){
            return "SMS" === heading
          }else if(key === "dueDate"){
            return "Due Date" === heading
          }
          return String(key).toLowerCase() === String(heading).toLowerCase()
        })
        values[index] = row[key]
      }
    )

    return values.map((value, index) => {
      if(RECURRING_CELL_ENABLED && String(headers[index]).toLowerCase() === "recurring"){
        return (
          <>
            <td>
              <BulkUploadTableRowSelectCell
                row={row}
                value={value}
                rowKey={String(headers[index]).toLowerCase()}
                onUpdated={(value) => {
                  row[String(headers[index]).toLowerCase()] = value
                  this.props.onUpdated(row, rowIndex)
                }}
              />
            </td>
          </>
        )
      }else if(String(headers[index]).toLowerCase() === "due date"){
        return (
          <>
            <td>
              <BulkUploadTableRowDateCell
                row={row}
                value={value}
                rowKey={String(headers[index]).toLowerCase()}
                onUpdated={(value) => {
                  row['dueDate'] = value
                  this.props.onUpdated(row, rowIndex)
                }}
              />
            </td>
          </>
        )
      }
      return (
        <td>
          <BulkUploadTableRowInputCell
            value={value}
            rowKey={String(headers[index]).toLowerCase()}
            onUpdated={(value) => {
              let key = ''
              if(String(headers[index]).toLowerCase() === "sms"){
                key = 'mobile'
              }else{
                key = String(headers[index]).toLowerCase()
              }
              row[key] = value
              this.props.onUpdated(row, rowIndex)
            }}
          />
        </td>
      )
    })
  }
}
