import React from "react"

export default class BulkUploadTableRowSelectCell extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      row: props.row,
      rowKey: props.rowKey,
      value: props.value,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      row,
      rowKey,
      value
    } = this.state

    if(rowKey === "recurring"){
      return (
        <select
          className="form-control"
          style={{width: 80}}
          value={value}
          onChange={e => {
            this.props.onUpdated(e.target.value === "true")
          }}
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      )
    }
    return null
  }
}
