import React from "react"

import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

import Backend from '../../../utils/Backend'

import ImageEdit from "./ImageEdit"

const ReorderableImage = SortableElement(({image, onUpdated, onDeleteClicked}) =>
  <div 
    className="col-sm-6 col-md-4 reorderable-image cursor-move" 
    style = {{zIndex: 100000}}
  >
    <ImageEdit
        key={image.id}
        image={image}
        type="photo"
        onUpdated={image => onUpdated(image)}
        deletable={true}
        onDeleteClicked={() => {
          console.log("DEL")
          onDeleteClicked()
        }}
      />
  </div>
)

const SortableList = SortableContainer((
  {images, onUpdated, onDeleteClicked, onAdded, imagePicker, cropImage, aspectRatio, maxImages}) => {
  return (
    <div key={images.length} className="row">
      { images.map((image, index) => {
        //console.log("A", index)
        return  <ReorderableImage
            key={`item-${image.id}`}
            index={index}
            value={image}
            image={image}
            onUpdated={image => onUpdated(image, index)}
            onDeleteClicked={() => {
              console.log("ETE")
              onDeleteClicked(index)
            }}
          />
        })
      }
      { images.length < maxImages &&
        <div className="col-lg-4 col-md-4 col-sm-6">
          <ImageEdit
            key={images.length}
            image={null}
            type="photo"
            imagePicker={imagePicker}
            cropImage={cropImage}
            aspectRatio={aspectRatio}
            onUpdated={image => onAdded(image)}
          />
        </div>
      }
    </div>
  )
})


export default class Gallery extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      images: props.images,
    }
  }

  _renderInputs(){
    let {
      images
    } = this.state

    if(!images){
      return <div className='mb-4'/>
    }

    return (
      <div key={images.length}>
      <SortableList
        key={images.length}
        axis="xy"
        images={images}
        onSortEnd={({oldIndex, newIndex}) => {
          images = arrayMove(images, oldIndex, newIndex)
          this.setState({
            images
          })
        }}
        onUpdated={(image, index) => {
          images[index] = image
          this.setState({ images })
          this.props.onUpdated(images)
        }}
        onDeleteClicked={index => {
          images.splice(index, 1)
          this.setState({ images })
          console.log("MEEEE")
          this.props.onUpdated(images)
        }}
        onAdded={image => {
          images.push(image)
          this.setState({ images })
          this.props.onUpdated(images)
        }}
        imagePicker={this.props.imagePicker}
        cropImage={this.props.cropImage}
        aspectRatio={this.props.aspectRatio}
        maxImages={this.props.maxImages}
        distance={1}
      />
      </div>
    )
  }

  render() {
    let {
      gallery,
      loading
    } = this.state


    return this._renderInputs()
  }
}

ImageEdit.defaultProps = {
  type: "photo",
  maxImages: 3
}
Gallery.defaultProps = {
  type: "photo",
  maxImages: 3
}
