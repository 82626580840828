import React from 'react'
import Modal from 'react-bootstrap/Modal'

import ImageUploader from "../common/ImageUploader";
import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import AsyncSelect from "../common/AsyncSelect";
import Calculations from "../../../utils/Calculations";
import Splink from "../../../utils/Splink";
import AuthManager from "../../../utils/AuthManager";
import ImageEdit from "../common/ImageEdit";
import PaymentPlans from "../splink/PaymentPlans";

export default class SubsplinkModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      company: AuthManager.currentUser.company,
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      subsplink: props.subsplink ? props.subsplink : {
        in_bank: false,
        payment_plans: [
          {
            type: 'one_time',
            open: false,
            vat: 0,
            reverse_fee: "no",
            currency: AuthManager.currentUser.company.settings.payment_plan.currency,
            allow_discounts: false,
            discounts: []
          }
        ]
      },
    }
  }

  _handleChange(e) {
    let subsplink = this.state.subsplink
    subsplink[e.target.name] = e.target.value
    this.setState({ subsplink, error: null })
  }

  _isFormValid(){
    let {
      subsplink
    } = this.state

    let error = null

    if(subsplink.title == null || subsplink.title.length === 0){
      error = "Please include a title"
    }else if(subsplink.slug == null || subsplink.slug.length === 0){
      error = "Please include the channel name"
    }else if(!subsplink.payment_plans[0].currency){
      error = "Please select a currency"
    }else if(subsplink.payment_plans[0].type == "recurring" && !subsplink.payment_plans[0].frequency){
      error = "Please select a frequency"
    }else if(subsplink.payment_plans[0].installments !== null && subsplink.payment_plans[0].installments < 2){
      error = "Installments must be 2 or more"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddPaymentLink(){
    if(!this._isFormValid()){
      return
    }
    if(this.state.subsplink.id){
      this._updatePaymentLink()
    }else{
      this._addPaymentLink()
    }
  }

  _addPaymentLink(){
    let {
      subsplink
    } = this.state

    this.setState({ loading: true })
    Backend.createPaymentLink(subsplink)
    .then(subsplink => {
      Notify.success("Channel Added Successfully")
      this.setState({ loading: false })
      this.props.onAdded(subsplink)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _updatePaymentLink(){
    let {
      subsplink
    } = this.state

    this.setState({ loading: true })
    Backend.updatePaymentLink(subsplink)
      .then(subsplink => {
        Notify.success("Channel Updated Successfully")
        this.setState({ loading: false })
        this.props.onAdded(subsplink)
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _setSlug = General.debounce(() => {
    let {
      subsplink,
    } = this.state

    if(!subsplink.title || subsplink.slug){
      return
    }

    return Backend.slugify(subsplink.title, 'payment_link')
      .then(response => {
        let errorSlug = false
        if(subsplink.slug && subsplink.slug != subsplink.title){
          errorSlug = true
        }
        if(!subsplink.slug){
          subsplink.slug = response.slug
        }
        this.setState({ errorSlug, subsplink, isLoading: false })
      })
      .catch(error => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }, 500)

  _checkSlug(){
    let {
      subsplink
    } = this.state

    this.setState({ isLoadingSlug: true })
    Backend.slugify(subsplink.slug, 'payment_link')
      .then(response => {
        if(response.slug !== subsplink.slug){
          this.setState({ errorSlug: true, isLoadingSlug: false })
        }
        else{
          this.setState({ errorSlug: false, isLoadingSlug: false })
        }
      })
      .catch(error => {
        Notify.error(error.message)
        this.setState({ isLoadingSlug: false })
      })
  }

  render() {
    let {
      show,
      loading,
      errorSlug,
      subsplink,
      company,
      error,
    } = this.state

    let title = subsplink.id ? "Update Channel" : "Create Channel";
    let buttonTitle = subsplink.id ? 'Update' : 'Create';

    let url = Splink.getSplinkUrl(company)

    let className = "input-group-prepend-bg-dark "

    if(subsplink.slug){
      className += errorSlug ? "input-group-prepend-bg-dark-error" : "input-group-prepend-bg-dark"
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-lg'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group">
              <span className="error-message">{ error }</span>
            </div>
          }

          <div className="form-group row">
            <label className="col-3 col-form-label">Title*</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value=""
                placeholder="ABC Ltd"
                value={subsplink.title}
                name={"title"}
                onChange={e => this._handleChange(e)}
                onBlur={e => {
                  this._setSlug()
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Channel*</label>
            <div className="col-9">
              <div className="input-group">
                <div className={`input-group-prepend ${className}`}>
                  <span className="input-group-text" style={{border:'none'}}>{url}</span>
                </div>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder="channel"
                  value={subsplink.slug}
                  disabled={!!subsplink.id}
                  name={"slug"}
                  onChange={e => this._handleChange(e)}
                  onBlur={e => {
                    this._checkSlug()
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Open or Set*</label>
            <div className="col-9">
              <div className="radio-inline"
                   onChange={e => {
                     subsplink.payment_plans[0].open = e.target.value === "on"
                     if(e.target.value === "on"){
                       subsplink.payment_plans[0].subtotal = null
                       subsplink.payment_plans[0].total = null
                       subsplink.payment_plans[0].vat = null
                     }
                     this.setState({ subsplink })
                   }}
              >
                <label className="radio">
                  <input type="radio" name="openSet" value={"on"} checked={subsplink.payment_plans[0].open}/>
                  Open
                  <span></span>
                </label>
                <label className="radio">
                  <input type="radio" name="openSet" value={"off"} checked={!subsplink.payment_plans[0].open}/>
                  Set
                  <span></span>
                </label>
              </div>
            </div>
          </div>

          <PaymentPlans
            paymentPlans={subsplink.payment_plans}
            showReverseFee={true}
            showDiscount={true}
            priceTitle="Amount"
            updatePaymentPlan={(paymentPlan, index) => {
              subsplink.payment_plans[index] = paymentPlan
              this.setState({subsplink})
            }}
          />

          <div className="form-group row">
            <label className="col-3 col-form-label">Background Image</label>
            <div className="col-9">
              <ImageEdit
                image={subsplink.background_image}
                imagePicker={true}
                hidePreview={false}
                onUpdated={image => {
                  this.setState({loading: false})
                  subsplink.background_image = image
                  this.setState({subsplink, error: null})
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Description</label>
            <div className="col-9">
              <textarea
                className="form-control form-control-solid"
                placeholder="Description"
                value={subsplink.description}
                name={"description"}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Add to list</label>
            <div className="col-9">
              <div
                className="radio-inline"
                onChange={e => {
                  subsplink.in_bank = e.target.value === "on"
                  this.setState({ subsplink })
                }}
              >
                <label className="radio">
                  <input type="radio" name="list" value={"on"} checked={subsplink.in_bank}/>
                  Yes
                  <span></span>
                </label>
                <label className="radio">
                  <input type="radio" name="list" value={"off"} checked={!subsplink.in_bank}/>
                  No
                  <span></span>
                </label>
              </div>
              <span className="form-text text-muted">add to channel list ({url}/channels)</span>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleAddPaymentLink()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
