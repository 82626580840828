import React from "react"

import moment from "moment"

import Div from "../layouts/Div"

import BankAccountNameModal from "../modal/BankAccountNameModal"

import BankAccountSelectionModal from "../modal/BankAccountSelectionModal"

import Backend from "../../../utils/Backend"
import Notify from "../../../utils/Notify"
import General from "../../../utils/General"
import AuthManager from "../../../utils/AuthManager"

export default class TokenSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      company: props.company,
      processor: props.company.settings.live ? "token" : "token_test",
    }
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _load(){
    let { company } = this.state
    let slug = company.settings.live ? "token" : "token_test"

    this.setState({ loading: true })
    Backend.getConnectData(slug)
    .then(data => {
      this.setState({
        data,
        loading: false,
      }, () => this._verifyAis())
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _getValues(status) {
    let labelClass = "";
    let label = "";

    if (status === "verified") {
      labelClass = "label-light-success";
      label = "Enabled";
    } else if (status === "onboarding") {
      labelClass = "label-light-info";
      label = "Onboarding / KYC";
    } else if (["pending", "3ds_validation", "account_selection"].indexOf(status) > -1) {
      labelClass = "label-light-warning";
      label = "Pending";
    }

    return { labelClass, label };
  }

  _getShowConnectButton(){
    let { data } = this.state
    if(!data){
      return false
    }

    if(data.ais.status === "pending"){
      return true
    }

    if(moment(data.ais.expires_at).diff(moment(), 'days') <= 31){
      return true
    }

    return false
  }

  _authenticateAis(){
    let { processor, data } = this.state

    let connectData = {
      ...data,
      action: "authenticate",
      redirect_url: window.location.href
    }

    this.setState({ loading: true })
    Backend.updateConnectData(processor, connectData)
    .then(response => {
      if(response.requires_action && response.redirect_url){
        window.location.href = response.redirect_url
      }
      else{
        Notify.error("Unexpected error while verifying account, please try again")
        this.setState({ loading: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }


  _verifyAis(){
    let { processor, data } = this.state

    const search = window.location.search
    const params = new URLSearchParams(search)
    const signature = params.get('signature')
    const requestId = params.get('request-id')
    const tokenId = params.get('tokenId')
    const error = params.get('error')
    const message = params.get('message')

    params.delete("signature")
    params.delete("request-id")
    params.delete("tokenId")
    params.delete("error")
    params.delete("message")

    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)

    if(!signature){
      return
    }

    let connectData = {
      ...data,
      signature,
      action: "verify",
      token_id: tokenId,
      request_id: requestId,
    }

    this.setState({ loading: true, tokenId, requestId, signature })
    Backend.updateConnectData(processor, connectData)
    .then(response => {

      if(response.requires_action && response.accounts_data){
        this.setState({
          loading: false,
          accounts: response.accounts_data,
          showAccountSelectionModal: true
        })
      } else {
        Notify.error("Unexpected error while verifying account, please try again")
        this.setState({ loading: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _connectAis(account){
    let {
      processor,
      data,
      tokenId,
      requestId,
      signature
    } = this.state

    let connectData = {
      ...data,
      action: "account_selection",
      token_id: tokenId,
      request_id: requestId,
      signature: signature,
      account_id: account.id
    }

    this.setState({ loading: true, showAccountSelectionModal: false })
    Backend.updateConnectData(processor, connectData)
    .then(response => {
      if(!response.requires_action){
        Notify.success("Bank Account Connected")
        this.setState({
          loading: false,
          showAccountNameModal: true
        }, () => this._load())
      }
      else {
        Notify.success("Unexpected error while selecting account, please try again")
        this.setState({ loading: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _updateAis(accountHolderName, statementDescriptor){
    let {
      processor,
      data
    } = this.state

    let connectData = {
      ...data,
      action: "edit_details",
      account_holder_name: accountHolderName,
      statement_descriptor: statementDescriptor
    }

    this.setState({ loading: true, showAccountNameModal: false })
    Backend.updateConnectData(processor, connectData)
    .then(response => {
      Notify.success("Account Details Updated!")
      this.setState({
        loading: false,
      }, () => this._load())
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }


  _renderContent(){
    let { data } = this.state

    if(!data){
      return null
    }

    let pisValues = this._getValues(data.pis.status)
    let aisValues = this._getValues(data.ais.status)

    return (
      <>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Payment Initiation:</label>
          <div className="col-lg-9 my-auto">
            <span
              className={`label label-inline label-lg ${pisValues.labelClass} btn-sm font-weight-bold`}
            >
              { pisValues.label }
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Payment Reconciliation:</label>
          <div className="col-lg-9 my-auto">
            <span
              className={`label label-inline label-lg ${aisValues.labelClass} btn-sm font-weight-bold`}
            >
              { aisValues.label }
            </span>
          </div>
        </div>

        {data.ais.status == "verified" &&
          <>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Account Holder Name:</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  className="form-control"
                  im-insert="true"
                  value={data.account_holder_name}
                  disabled
                />
              </div>
            </div>

            { data.iban &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">IBAN:</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    im-insert="true"
                    value={data.iban}
                    disabled
                  />
                </div>
              </div>
            }
            { data.account_number &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Account Number:</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    im-insert="true"
                    value={data.account_number}
                    disabled
                  />
                </div>
              </div>
            }
            { data.sort_code &&
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Sort Code:</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    im-insert="true"
                    value={data.sort_code}
                    disabled
                  />
                </div>
              </div>
            }
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Statement Descriptor:</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  className="form-control"
                  im-insert="true"
                  value={data.ais.statement_descriptor}
                  disabled
                />
              </div>
            </div>
          </>
        }
      </>
    )
  }

  render() {
    let {
      data,
      loading,
      company,
      accounts,
      showAccountNameModal,
      showAccountSelectionModal
    } = this.state

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <Div
                className="kt-wizard-v2__content"
                data-ktwizard-type="step-content"
                data-ktwizard-state="current"
                disabled={loading}
                spinner={true}
              >
                <h4 className="mb-10 font-weight-bold text-dark">Bank Account</h4>
                <div className="kt-form__section kt-form__section--first">
                  <div className="kt-wizard-v2__form">
                    { this._renderContent() }
                  </div>
                </div>
              </Div>
            </div>
            {this._getShowConnectButton() &&
              <>
                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                  <div style={{margin: '0 auto'}}>
                    <button
                      className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      onClick={e => {
                        e.preventDefault()
                        this._authenticateAis()
                      }}
                    >
                      { data?.ais.status == "verified" ? "Reconnect Bank Account" : "Connect Bank Account"}
                    </button>
                  </div>
                </div>
                { data?.ais.status != "verified" &&
                  <p className="mt-5 w-100 text-center">
                    By continuing, you agree to the {window.General.Branding.Name}'s{" "}
                    <a target="_blank" href={window.General.Branding.TermsUrl}>
                      Terms and conditions
                    </a> and the Terms of {window.General.Branding.Name}'s bank connectivity provider,{" "}
                    <a target="_blank" href="https://token.io/ais-terms-of-service">
                      Token.io.
                    </a>
                  </p>
                }
              </>
            }
            { showAccountSelectionModal &&
              <BankAccountSelectionModal
                accounts={accounts}
                show={showAccountSelectionModal}
                onSelected={account => this._connectAis(account)}
                onCancel={() => this.setState({ showAccountSelectionModal: false })}
              />
            }
            { showAccountNameModal &&
              <BankAccountNameModal
                show={showAccountNameModal}
                onConfirm={(accountHolderName, statementDescriptor) => {
                  this._updateAis(accountHolderName, statementDescriptor)
                }}
                onCancel={() => this.setState({ showAccountNameModal: false })}
              />
            }
          </form>
        </div>
      </>
    )
  }
}
