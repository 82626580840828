import React from "react"

import moment from "moment"

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"
import PaymentsTable from "./PaymentsTable"

import PillCell from "./cells/PillCell";
import BankAccountCell from "./cells/BankAccountCell";

import EngagementCard from "../common/EngagementCard";

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from "../../../utils/Currency";

import Notify from "../../../utils/Notify";

const STATUS_FILTERS = {
  name: {
    api: "status",
    display: "status"
  },
  values: [
    {
      label: "All",
      value: null
    },
    {
      label: "Pending",
      value: "pending"
    },
    {
      label: "Paid",
      value: "paid"
    },
    {
      label: "Failed",
      value: "failedd"
    },
    {
      label: "Cancelled",
      value: "canceled"
    }
  ]
}

const FILTERS = [
  STATUS_FILTERS
]

class PayoutsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loadingAccount: true,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }

    this.table = React.createRef()
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _load(){
    this.setState({ loadingAccount: true })
    Backend.getBankAccount()
    .then(bankAccount => {
      this.setState({
        bankAccount,
        loadingAccount: false,
      })
    })
    .catch(error => {
      this.setState({ loadingAccount: false })
    })
  }

  _getColumns() {
    return [
      {
        Header: 'Paid Out',
        id: 'initiated_at',
        accessor: payout => moment(payout.initiated_at).format('DD MMM YYYY')
      },
      {
        Header: 'Processed',
        id: 'gross_amount',
        Cell: rowInfo => {
          let payout = rowInfo.original
          return Currency.format(payout.currency.symbol, payout.gross_amount)
        }
      },
      {
        Header: 'Fees',
        id: 'fee',
        Cell: rowInfo => {
          let payout = rowInfo.original
          return Currency.format(payout.currency.symbol, payout.fee)
        }
      },
      {
        Header: 'Deposit',
        id: 'amount',
        Cell: rowInfo => {
          let payout = rowInfo.original
          return Currency.format(payout.currency.symbol, payout.amount)
        }
      },
      {
        Header: 'Refunds',
        id: 'refunded_amount',
        Cell: rowInfo => {
          let payout = rowInfo.original
          return Currency.format(payout.currency.symbol, payout.refunded_amount)
        }
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: rowInfo => {
          let payout = rowInfo.original
          let className = payout.status === 'paid' ? 'label-light-success' : 'label-light-danger'
          if(payout.status === 'pending'){
            className = 'label-light-primary'
          }

          return (
            <PillCell
              text={General.toTitleCase(payout.status)}
              className={className}
            />
          )
        }
      },
      {
        Header: 'Bank',
        id: 'bank_account.bank_name',
        Cell: rowInfo => {
          let payout = rowInfo.original

          return (
            <BankAccountCell
              bankAccount={payout.bank_account}
            />
          )
        }
      },
      {
        Header: 'Est. Arrival',
        id: 'expected_arrival_at',
        accessor: payout => moment(payout.expected_arrival_at).format('DD MMM YYYY')
      },
    ]
  }

  _renderPayments(payout){
    return(
      <PaymentsTable
        payout={payout}
        title={`Payments`}
        showExport={false}
      />
    )
  }

  render() {
    let {
      bankAccount,
      loadingAccount
    } = this.state

    if(loadingAccount){
      return null
    }

    const columns = this._getColumns()

    return (
      <div>
        {
          !bankAccount &&
          <EngagementCard
            title={"Connect Your Bank Account"}
            text={`
              To start receiving your money!
            `}
            buttonTitle={"Connect Bank Account"}
            onClick={() => {
              this.props.history.push("/settings/bank-account")
            }}
          />
        }
        {
          bankAccount &&
          <BaseTable
            ref={this.table}
            endpoint={`${window.Api.Payouts}?min_date=${this.state.dateFrom?.format('YYYY-MM-DD') || ''}&max_date=${this.state.dateTo?.format('YYYY-MM-DD') || ''}`}
            noDataMessage={"No payouts found"}
            title={this.props.title}
            columns={columns}
            showExport={false}
            showObjectFilter={false}
            filters={FILTERS}
            defaultSorted={[
              {
                id: "initiated_at",
                desc: true
              }
            ]}
            SubComponent={row => this._renderPayments(row.original)}
          />
        }
      </div>
    )
  }
}

export default withRouter(PayoutsTable);
