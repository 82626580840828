import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'

import print from 'print-js'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import SubsplinkModal from '../modal/SubsplinkModal'
import Currency from '../../../utils/Currency'
import AuthManager from '../../../utils/AuthManager'
import Permissions from '../../../utils/Permissions'

import DeleteCell from './cells/DeleteCell'
import ViewCell from './cells/ViewCell'
import EditCell from './cells/EditCell'
import Splink from '../../../utils/Splink'
import Notify from '../../../utils/Notify'
import ConfirmationModal from '../modal/ConfirmationModal'
import CheckCell from './cells/CheckCell'

import QRCodeModal from '../modal/QRCodeModal'
import QRCodePrintModal from '../modal/QRCodePrintModal'

export default class QRCodesTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      company: AuthManager.currentUser.company,
    }

    this.table = React.createRef()
  }

  _print(qr) {
    print(qr.image.original, 'image')
  }

  _getColumns() {
    let { permissions } = AuthManager.currentUser._role
    return [
      {
        Header: 'URL',
        accessor: 'url',
        minWidth: 300,
        className: 'text-wrap',
      },
      {
        Header: 'Call To Action',
        accessor: 'cta',
        width: 200,
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: (qrCode) =>
          qrCode.amount ? Currency.format('', qrCode.amount) : '-',
        width: 100,
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 150,
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let qrCode = rowInfo.original
          let url = qrCode.url
          return (
            <span
              style={{ overflow: 'visible', position: 'relative', width: 150 }}
            >
              {!qrCode.deleted_at && (
                <>
                  <ViewCell
                    tooltip={'View'}
                    onViewClicked={() => {
                      this.setState({ qrCode, showQRCodePrintModal: true })
                    }}
                  />
                  {Permissions.hasEditPermission(Permissions.QR_CODE) && (
                    <EditCell
                      tooltip={'Edit'}
                      onEditClicked={() => {
                        this.setState({
                          qrCode,
                          showQRCodeModal: true,
                        })
                      }}
                    />
                  )}
                </>
              )}
              {qrCode.deletable &&
                !qrCode.deleted_at &&
                Permissions.hasDeletePermission(Permissions.QR_CODE) && (
                  <DeleteCell
                    tooltip={'Archive'}
                    onDeleteClicked={() => {
                      this.setState({
                        qrCode,
                        showConfirmationModal: true,
                      })
                    }}
                  />
                )}
              {qrCode.deleted_at &&
                Permissions.hasEditPermission(Permissions.QR_CODE) && (
                  <CheckCell
                    tooltip={'Restore'}
                    onCheckClicked={() => {
                      this.setState({
                        deletedQRCode: qrCode,
                        showConfirmationModal: true,
                      })
                    }}
                  />
                )}
            </span>
          )
        },
      },
    ]
  }

  _deleteQRCode() {
    let { qrCode } = this.state

    Backend.deleteQRCode(qrCode)
      .then((qrCode) => {
        Notify.success('QR Code has been archived successfully')
        this.setState({
          qrCode: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          qrCode: null,
          showConfirmationModal: false,
        })
      })
  }

  _restoreQRCode() {
    let { deletedQRCode } = this.state

    Backend.restoreQRCode(deletedQRCode)
      .then((qrCode) => {
        Notify.success('QR Code has been restored successfully')
        this.setState({
          deletedQRCode: null,
          showConfirmationModal: false,
        })
        this.table.current.refresh()
      })
      .catch((e) => {
        Notify.error(e.message)
        this.setState({
          deletedQRCode: null,
          showConfirmationModal: false,
        })
      })
  }

  render() {
    let {
      qrCode,
      deletedQRCode,
      showQRCodeModal,
      showQRCodePrintModal,
      showConfirmationModal,
    } = this.state
    let { permissions } = AuthManager.currentUser._role
    const columns = this._getColumns()

    let message = 'Are you sure you want to hide this QR Code?'
    if (deletedQRCode) {
      message = 'Are you sure you want to restore this QR Code?'
    }

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.QRCodes}`}
          noDataMessage={'No qr codes found'}
          title={this.props.title}
          columns={columns}
          showExport={false}
          searchFilter={'active'}
          filterValue={{ objects: 'active' }}
          defaultSorted={[
            {
              id: 'id',
              desc: false,
            },
          ]}
          renderToolbar={() => {
            return (
              <>
                {Permissions.hasCreatePermission(Permissions.QR_CODE) && (
                  <a
                    className="btn btn-primary font-weight-bolder ml-4"
                    onClick={() => {
                      this.setState({ showQRCodeModal: true })
                    }}
                  >
                    <i className="flaticon2-add-1 icon-sm"></i>
                    Create A QR Code
                  </a>
                )}
              </>
            )
          }}
        />

        <ConfirmationModal
          show={showConfirmationModal}
          title={message}
          onConfirm={() => {
            if (qrCode) {
              this._deleteQRCode()
            } else if (deletedQRCode) {
              this._restoreQRCode()
            }
          }}
          onHide={() => {
            this.setState({
              qrCode: null,
              showConfirmationModal: false,
            })
          }}
        />

        <QRCodeModal
          show={showQRCodeModal}
          qrCode={qrCode}
          onUpdated={() => {
            this.table.current.refresh()
            this.setState({ qrCode: null, showQRCodeModal: false })
          }}
          onHide={() => this.setState({ qrCode: null, showQRCodeModal: false })}
        />

        <QRCodePrintModal
          show={showQRCodePrintModal}
          qrCode={qrCode}
          onPrint={() => {
            this._print(qrCode)
            this.setState({ qrCode: null, showQRCodePrintModal: false })
          }}
          onHide={() =>
            this.setState({ qrCode: null, showQRCodePrintModal: false })
          }
        />
      </div>
    )
  }
}
