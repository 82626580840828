import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ReactTableDefaults } from 'react-table'
import { toast } from 'react-toastify'

import logo from './logo.svg'
import './App.css'

import Api from './constants/Api'
import General from './constants/General'

import Backend from './utils/Backend'

import Branding from './pages/components/common/Branding';

import Intercom from './utils/Intercom'

import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'

import TagManager from 'react-gtm-module'
import Pagination from './pages/components/tables/Pagination'

import './assets/css/metronic-7.0.2/pages/login/login-1.css'
import './assets/plugins/global/plugins.bundle.css'
import './assets/plugins/custom/prismjs/prismjs.bundle.css'
import './assets/css/style.bundle.css'
import './assets/css/themes/layout/header/base/light.css'
import './assets/css/themes/layout/header/menu/light.css'
import './assets/css/themes/layout/brand/light.css'
import './assets/css/themes/layout/aside/light.css'
import './assets/css/custom.css'
import './assets/css/wizard-2.css'
import './assets/css/style.bundle-7.0.2.css'
import './assets/scss/app.scss'

import AuthApp from './pages/App'
import Login from './pages/Login'

import runtimeEnv from '@mars/heroku-js-runtime-env';

import $ from 'jquery'

const env = runtimeEnv();

Object.assign(ReactTableDefaults, {
  minRows: 0,
  PaginationComponent: Pagination,
})

toast.configure()

const tagManagerArgs = {
  gtmId: 'GTM-MXDJXNS',
}

export default class App extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount() {

    let scripts = []
    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + '/assets/plugins/global/plugins.bundle.js'
      )
    )
    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL +
          '/assets/plugins/custom/prismjs/prismjs.bundle.js'
      )
    )
    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/scripts.bundle.js')
    )
    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/custom.js')
    )

    setTimeout(() => {
      this._addScripts(scripts)
    }, 1000)

    Backend.getBranding()
    .then(branding => {
      window.General.Branding.Name = branding.platform_name
      window.General.Branding.LinkName = branding.link_name
      window.General.Branding.Colors = branding.colors

      window.General.Branding.TermsUrl      = branding.terms_url
      window.General.Branding.SupportUrl    = branding.support_url
      window.General.Branding.AddOnsUrl     = branding.add_ons_url
      window.General.Branding.NotFoundUrl   = branding.not_found_url
      window.General.Branding.ClientGTMID   = branding.client_gtm_id
      window.General.Branding.PaymentGTMID  = branding.payment_gtm_id

      if(branding.logo){
        window.General.Branding.Logo = branding.logo?.original
      }

      if(branding.banner){
        window.General.Branding.Banner = branding.banner?.original
      }

      if(branding.favicon){
        window.General.Branding.Favicon = branding.favicon?.thumbnail
      }

      window.General.Branding.Domain = branding.domain
      window.General.WebsiteDomain = branding.domain
      window.General.AssociationId = branding.association_id
      window.General.WebsiteUrl = `https://${branding.domain}`

      window.Api.PlatformPaymentMethods = branding.platform_payment_methods

      window.Api.Keys.IntercomAppId = branding.intercom_app_id
      Intercom.load()

      this.setState({ loading: false })

      if (window.General.Branding.ClientGTMID) {
        let tagManagerArgs = {
          gtmId: window.General.Branding.ClientGTMID,
        }
        TagManager.initialize(tagManagerArgs)
      }
      this._setupManifest()
    })
    .catch(() => {
      this.setState({ loading: false })
    })
  }

  _setupManifest(){
    var manifest = {
     "name": window.General.Branding.Name,
     "short_name": window.General.Branding.Name,
     "description": "Take Payment In A Variety Of Ways",
     "start_url": ".",
     "background_color": "#000000",
     "theme_color": window.General.Branding.Colors.primary,
     "icons": [
         {
           src: window.General.Branding.Favicon,
           sizes: "512x512",
           type: "image/png",
         },
       ]
     }
     const link = document.createElement("link");
     link.rel = "manifest";
     const stringManifest = JSON.stringify(manifest);
     link.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest))
     document.head.appendChild(link);
  }

  _getScript(path) {
    const script = document.createElement('script')

    script.src = path

    return script
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i]
      document.body.appendChild(script)
      await this._sleep(500)
    }
  }

  _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  render() {
    let {
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <>
        <BrowserRouter>
          <Switch>
            <UnauthenticatedRoute exact path="/account" component={Login} />
            {/*<UnauthenticatedRoute exact path="/forgot-password" component={ForgotPassword} />*/}
            {/*<UnauthenticatedRoute path="/reset-password/:email" component={ResetPassword} />*/}
            <AuthenticatedRoute path="/" component={AuthApp} />

            {/*<Route exact path="/404" component={NotFound} />*/}
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
        <Branding name="Dashboard"/>
      </>
    )
  }
}
