import React from 'react'

// import ImageEdit from "../common/ImageEdit"
import PasswordInput from '../common/PasswordInput'

import Div from '../layouts/Div'

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'
import Notify from '../../../utils/Notify'

export default class PasswordSettings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: props.loading,
      companyMember: props.companyMember,
    }

    this.newPassword = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let { companyMember, loading } = this.state

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework">
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h4 className="mb-10 font-weight-bold text-dark">
                Keep your password safe
              </h4>
              <PasswordInput
                containerClassName="form-group row"
                inputContainerClassName="col-9"
                labelClassName="col-3 col-form-label"
                label="Old Password"
                className="form-control form-control-solid"
                value={companyMember.user.current_password}
                onChange={(value, name) => {
                  let companyMember = this.state.companyMember
                  companyMember.user['current_password'] = value
                  this.props.onUpdated(companyMember)
                }}
                autoComplete="off"
              />
              <PasswordInput
                ref={this.newPassword}
                containerClassName="form-group row"
                inputContainerClassName="col-9"
                labelClassName="col-3 col-form-label"
                className="form-control form-control-solid"
                value={companyMember.user.password}
                matchValue={companyMember.user.verifyPassword}
                onChange={(value, name) => {
                  let companyMember = this.state.companyMember
                  companyMember.user[name] = value
                  this.props.onUpdated(companyMember)
                }}
                onConfirmChange={(value, name) => {
                  let companyMember = this.state.companyMember
                  companyMember.user['verifyPassword'] = value
                  this.props.onUpdated(companyMember)
                }}
                showValidationCheck
              />
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{ margin: '0 auto' }}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault()
                    if (!this.newPassword.current.isValid()) {
                      Notify.error("Your new password is not strong enough, please enter a stronger password")
                      return false
                    }
                    this.props.onSave()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
}
