import React from 'react'

import Creatable from 'react-select/creatable'
import AsyncPaginate from 'react-select-async-paginate'

import FetchHelper from '../../../utils/FetchHelper'

export default class AsyncSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      search: '',
      prevSearch: '',
      items: [],
      nextPageUrl: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      placeholder: props.placeholder,
      endpoint: props.endpoint,
      isMulti: props.isMulti ? props.isMulti : false,
      creatable: props.creatable ? props.creatable : false,
      styles: props.styles,
      filter: props.filter ? props.filter : '',
      value: props.value,
      disabled: props.disabled,
    }
  }

  _loadOptions(search, prevOptions) {
    let { endpoint, prevSearch, nextPageUrl, filter, items } = this.state

    let url = `${endpoint}?${filter + '&'}search_term=${search}`
    if(this.props.orderBy){
      url += `&order_by=${this.props.orderBy}`
    }
    let nextPage = false
    if (search === prevSearch && nextPageUrl) {
      url = nextPageUrl
      nextPage = true
    }

    return FetchHelper.get(url, this.props.validateTokens).then((response) => {
      let newOptions = response.results
      if (prevOptions.length === 0) {
        newOptions = [...this.props.getExtraOptions, ...newOptions]
      }
      newOptions = this.props.getOptions(newOptions)
      if (nextPage) {
        items = [...items, ...response.results]
      } else {
        items = response.results
      }

      this.setState({
        items,
        nextPageUrl: response.next,
        prevSearch: search,
      })
      return {
        options: newOptions,
        hasMore: response.next != null,
      }
    })
  }

  _handleChange(option) {
    if (this.state.isMulti) {
      this.setState({ value: option }, () => {
        this.props.onSelected(this.state.value)
      })
    } else if (!option) {
      this.setState({ value: option }, () => {
        this.props.onSelected(option)
      })
    } else if (option.__isNew__) {
      this.setState({ value: option }, () => {
        this.props.onCreated(option)
      })
    } else {
      this.setState({ value: option }, () => {
        this.props.onSelected(option.data)
      })
    }
  }

  render() {
    let props = { ...this.props }

    if (this.state.creatable) {
      props.SelectComponent = Creatable
    }
    return (
      <AsyncPaginate
        {...props}
        isMulti={this.props.isMulti}
        value={this.state.value}
        styles={this.state.styles}
        closeMenuOnSelect={!this.state.isMulti}
        loadOptions={this._loadOptions.bind(this)}
        debounceTimeout={300}
        onChange={(value) => {
          this._handleChange(value)
        }}
        className={`form-control h-auto border-0 form-control-solid c-selectbox ${this.props.className}`}
        isDisabled={this.state.disabled}
      />
    )
  }
}

AsyncSelect.defaultProps = {
  isMulti: false,
  getExtraOptions: [],
  validateTokens: true,
}
