import React from "react"

// import ImageEdit from "../common/ImageEdit"

import Div from "../layouts/Div"
import SmartList from "../common/SmartList";

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"
import AsyncSelect from "../common/AsyncSelect";
import General from "../../../utils/General";
import Splink from "../../../utils/Splink";
import Event from "../../../utils/Event"

export default class CustomDataCaptureSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: props.loading,
      company: props.company,
      paymentLinks: [],
      extraOption: [{id: 0, slug: props.company.slug, companyId: props.company.id}],
      paymentRequestQuestions: [],
      mode: "splinks"
    }
  }

  componentDidMount() {
    if(!AuthManager.currentUser.company.add_ons.questions){
      Event.emit('ADD_ON_ALERT', '/settings/general')
    }
    this._loadMainSplinkQuestions()
    this._loadPaymentRequestQuestions()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _loadMainSplinkQuestions(){
    Backend.getMainSplinkQuestions().then(mainQuestions => {
      this.setState({mainQuestions})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _loadPaymentRequestQuestions(){
    Backend.getQuestions("payment_request")
    .then(paymentRequestQuestions => {
      this.setState({paymentRequestQuestions})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _updateCustomDataCapture(){
    let {
      paymentLink
    } = this.state

    this._handleBackend()
    .then(paymentLink => {
      Notify.success("Custom data updated successfully")
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _handleBackend(){
    let {mode, paymentLink, paymentRequestQuestions} = this.state

    if(mode === "payment-requests"){
      return Backend.saveQuestions(paymentRequestQuestions, "payment_request")
    }
    else{
      return Backend.updatePaymentLink(paymentLink)
    }
  }

  _renderItem(item, index){
    let {
      mode,
      paymentLink,
      paymentRequestQuestions
    } = this.state

    let questions = mode === "payment-requests" ? paymentRequestQuestions : paymentLink.questions
    let helpText = questions[index].type === "text" ? "Required" : "Must Be Checked"

    return (
      <div className={"mb-7"}>
        <div className={"row mb-3"}>
          <div className={"col-3"}>
            <select
              className="form-control form-control-solid field-choice"
              value={questions[index].type}
              onChange={e => {
                if(mode == "payment-requests"){
                  paymentRequestQuestions[index].type = e.target.value
                  this.setState({ paymentRequestQuestions })
                }
                else{
                  paymentLink.questions[index].type = e.target.value
                  this.setState({ paymentLink })
                }
              }}
            >
              <option value="checkbox">Checkbox</option>
              <option value="text">Text Field</option>
            </select>
          </div>
          <div className={"col-9"}>
            <input
              type="text"
              className="form-control form-control-solid"
              value={questions[index]?.text || '' }
              disabled={questions[index]?.id != null}
              onChange={e => {
                if(mode == "payment-requests"){
                  paymentRequestQuestions[index].text = e.target.value
                  this.setState({ paymentRequestQuestions })
                }
                else{
                  paymentLink.questions[index].text = e.target.value
                  this.setState({ paymentLink })
                }
              }}
            />
          </div>
        </div>
        <label className="checkbox c-checkbox mb-0 checkbox">
          <input
            type="checkbox"
            name="agree"
            className="mr-1"
            checked={questions[index].is_required}
            onClick={e => {
              if(mode == "payment-requests"){
                paymentRequestQuestions[index].is_required = e.target.checked
                this.setState({ paymentRequestQuestions })
              }
              else{
                paymentLink.questions[index].is_required = e.target.checked
                this.setState({ paymentLink })
              }
            }}
          />
          { helpText }
          <span></span>
        </label>
      </div>
    )
  }

  _renderDelete(item, index){
    let {
      mode,
      paymentLink,
      paymentRequestQuestions
    } = this.state

    return (
      <a href="javascript:;" data-repeater-delete=""
         className="btn font-weight-bold btn-danger btn-icon"
         onClick={() => {
           if(mode == "payment-requests"){
             paymentRequestQuestions.splice(index, 1)
             this.setState({ paymentRequestQuestions })
           }
           else{
             paymentLink.questions.splice(index, 1)
             this.setState({ paymentLink })
           }
         }}
      >
        <i className="la la-remove"></i>
      </a>
    )
  }

  render() {
    let {
      data,
      mode,
      company,
      loading,
      paymentLink,
      paymentLinks,
      mainQuestions,
      paymentRequestQuestions
    } = this.state

    return (
      <>
        <div className="col">
          <form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <h4 className="mb-10 font-weight-bold text-dark">Add custom input fields to your {window.General.Branding.LinkName}</h4>

              <div className="form-group row mt-7">
                <label className="col-3 col-form-label">Type</label>
                <div className="col-9">
                  <select
                    value={mode}
                    className="form-control form-control-solid"
                    onChange={e => {
                      this.setState({ mode: e.target.value })
                    }}
                  >
                    <option value={"splinks"}>Individual {window.General.Branding.LinkName}</option>
                    <option value={"payment-requests"}>All Payment Requests</option>
                  </select>
                </div>
              </div>

              { mode === "splinks" &&
                <>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">{window.General.Branding.LinkName}</label>
                    <div className="col-9">
                      <AsyncSelect
                        endpoint={window.Api.PaymentLinks}
                        classNamePrefix={'custom-async-select'}
                        params={{ }}
                        onSelected={paymentLink => {
                          if(paymentLink.id === 0){
                            paymentLink.questions = mainQuestions
                          }
                          this.setState({paymentLink})
                        }}
                        getExtraOptions={this.state.extraOption}
                        getOptions={paymentLinks => {
                          return paymentLinks.map(paymentLink => ({
                            value: paymentLink.id,
                            label: paymentLink.id === 0 ? Splink.getSplinkUrl(paymentLink) : Splink.getPaymentLinkUrl(company, paymentLink),
                            data: paymentLink
                          }))
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {
                      paymentLink &&

                      <SmartList
                        items={paymentLink.questions}
                        itemName="Custom Input"
                        showItemName={true}
                        value={item => item.text}
                        disabled={item => item.id != null}
                        onChange={(value, item, index) => {
                          paymentLink.questions[index].text = value
                          this.setState({ paymentLink })
                        }}
                        onUpdated={questions => {
                          paymentLink.questions = questions
                          this.setState({ paymentLink })
                        }}
                        onAddItemPressed={() => {
                          paymentLink.questions.push({text: "", type: "checkbox"})
                          this.setState({ paymentLink })
                        }}
                        renderItem={(item, index) => this._renderItem(item, index)}
                        renderDelete={(item, index) => this._renderDelete(item, index)}
                      />
                    }
                  </div>
                  {
                    paymentLink &&
                    <div className="form-group row mt-7">
                      <label className="col-3 col-form-label">Collect Address</label>
                      <div className="col-9">
                        <select
                          value={paymentLink?.id === 0 ? company.settings.collect_address : paymentLink?.collect_address}
                          className="form-control form-control-solid"
                          onChange={e => {
                            if(paymentLink.id === 0){
                              company.settings.collect_address = e.target.value
                              this.props.onUpdated(company)
                            }
                            paymentLink.collect_address = e.target.value
                            this.setState({ paymentLink })
                          }}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        <span className="form-text text-muted">Choose whether or not you require your customer's address on the payment page.</span>
                      </div>
                    </div>
                  }
                </>
              }
              { mode === "payment-requests" &&
                <>
                  <SmartList
                    items={paymentRequestQuestions}
                    itemName="Custom Input"
                    showItemName={true}
                    value={item => item.text}
                    disabled={item => item.id != null}
                    onChange={(value, item, index) => {
                      paymentRequestQuestions[index].text = value
                      this.setState({ paymentRequestQuestions })
                    }}
                    onUpdated={questions => {
                      paymentRequestQuestions = questions
                      this.setState({ paymentRequestQuestions })
                    }}
                    onAddItemPressed={() => {
                      paymentRequestQuestions.push({
                        text: "",
                        type: "checkbox",
                        template: "payment_request"
                      })
                      this.setState({ paymentRequestQuestions })
                    }}
                    renderItem={(item, index) => this._renderItem(item, index)}
                    renderDelete={(item, index) => this._renderDelete(item, index)}
                  />
                </>
              }
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    this._updateCustomDataCapture()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </form>
        </div>
      </>
    )
  }
}
