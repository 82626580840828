import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {CopyToClipboard} from 'react-copy-to-clipboard';

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'
import moment from "moment";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import Notify from "../../../utils/Notify"
import AuthManager from "../../../utils/AuthManager";
import Splink from "../../../utils/Splink";
import Backend from "../../../utils/Backend";
import Currency from "../../../utils/Currency";
import Calculations from "../../../utils/Calculations";
import General from "../../../utils/General";

import { TAX } from "../../../constants/Variables";


export default class SubscriptionModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      company: AuthManager.currentUser.company,
      loading: false,
      error: null,
      nextInvoiceDueDate: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      show: props.show,
      subscription: General.clone(props.subscription),
    }
  }

  _isFormValid(){
    let {
      subscription
    } = this.state

    let paymentPlan = subscription.payment_plan

    let error = null
    if(paymentPlan.total < 100){
      error = "Total must be greater than or equal to 100 cents"
    }
    if(!subscription.next_invoice_date_at){
      error = "Please specify a 'Next Invoice Due Date'"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _update(){
    let {
      subscription,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ isLoading: true })
    Backend.updateSubscription(subscription)
    .then(subscription => {
      this.setState({ isLoading: false })
      Notify.success('Subscription has been updated successfully')
      this.props.onUpdated(subscription)
    })
    .catch(error => {
      this.setState({ isLoading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      company,
      isLoading,
      subscription,
    } = this.state


    let nextInvoiceDateAt = subscription.next_invoice_date_at

    let paymentPlan = subscription.payment_plan

    let price = paymentPlan.subtotal ? paymentPlan.subtotal / 100 : "";
    let percentage = paymentPlan.vat_percentage
      ? Math.round(paymentPlan.vat_percentage * 100) || ""
      : Math.round(
          Calculations.getPercentage(
            paymentPlan.total,
            paymentPlan.subtotal
          ) * 100
        ) / 100 || "";

    let vatEnabled = percentage > 0 || paymentPlan.enableVat;

    return (
      <Modal
        show={show}
        dialogClassName={'modal-dialog-centered modal-md'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label>Next Invoice Due Date*</label>
            <div className="input-group date">

              <div className="input-group-prepend">
                <span className="input-group-text border-0">
                  <i className="la la-calendar"></i>
                </span>
              </div>

              <Flatpickr
                className="form-control form-control-solid"
                value={nextInvoiceDateAt}
                options={{
                  enableTime: false,
                  noCalendar: false,
                  dateFormat: "Y-m-d"
                }}
                onChange={dates => {
                  nextInvoiceDateAt = moment(dates[0]).format('YYYY-MM-DDThh:mm')
                  subscription.next_invoice_date_at = nextInvoiceDateAt
                  this.setState({ subscription })
                }}
              />
            </div>
          </div>

          <div className="row no-gutters">
            <label>Amount*</label>
            <input
              className="form-control form-control-solid"
              type="number"
              value={price}
              placeholder="0.00"
              name="subtotal"
              step={"0.01"}
              onChange={(e) => {
                paymentPlan.subtotal = Math.round(e.target.value * 100);
                if (paymentPlan.vat_percentage) {
                  paymentPlan.total = Math.round(
                    parseFloat(
                      Calculations.totalIncludingVat(
                        e.target.value * 100,
                        paymentPlan.vat_percentage,
                        false
                      )
                    )
                  );
                } else {
                  paymentPlan.total = Math.round(e.target.value * 100);
                }
                paymentPlan.vat = paymentPlan.total - paymentPlan.subtotal;
                subscription.payment_plan = paymentPlan
                this.setState({ subscription })
              }}
            />
          </div>
          <div className="row no-gutters">
            <div className="col-auto mt-3">
              <span className="switch switch-outline switch-icon switch-primary">
                <label>
                  <input
                    type="checkbox"
                    id="vatSelect"
                    checked={paymentPlan.enableVat || vatEnabled}
                    onChange={(e) => {
                      let show = e.target.checked;
                      if (show) {
                        paymentPlan.enableVat = show;
                        paymentPlan.vat_percentage =
                          company.settings.vat_rate;
                        paymentPlan.vat = Math.round(
                          parseFloat(
                            Calculations.totalVat(
                              paymentPlan.subtotal,
                              company.settings.vat_rate,
                              false
                            )
                          )
                        );
                        paymentPlan.total = Math.round(
                          parseFloat(
                            Calculations.totalIncludingVat(
                              paymentPlan.subtotal,
                              company.settings.vat_rate,
                              false
                            )
                          )
                        );
                      } else {
                        paymentPlan.enableVat = show;
                        paymentPlan.vat_percentage = null;
                        paymentPlan.vat = 0;
                        paymentPlan.total = paymentPlan.subtotal;
                      }
                      subscription.payment_plan = paymentPlan
                      this.setState({ subscription })
                    }}
                  />
                  <span></span>
                </label>
              </span>
            </div>
            <label
              htmlFor="vatSelect"
              className="col col-form-label align-left pl-3 mt-3"
            >
              Enable {TAX}
            </label>
          </div>
          {vatEnabled && (
              <>
                <div className="col-3"></div>
                <div className="col-9">
                  <div className="row" id="vatOptions">
                    <div className="col-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            style={{ border: "none" }}
                          >
                            %
                          </span>
                        </div>
                        <input
                          className="form-control form-control-solid"
                          type="number"
                          min={0}
                          placeholder="0"
                          name={"vat"}
                          value={percentage}
                          onChange={(e) => {
                            let vatPercentage = parseFloat(
                              e.target.value / 100
                            ).toFixed(4);
                            paymentPlan.vat_percentage = vatPercentage;
                            paymentPlan.vat = Math.round(
                              parseFloat(
                                Calculations.totalVat(
                                  paymentPlan.subtotal,
                                  vatPercentage,
                                  false
                                )
                              )
                            );
                            paymentPlan.total = Math.round(
                              parseFloat(
                                Calculations.totalIncludingVat(
                                  paymentPlan.subtotal,
                                  vatPercentage,
                                  false
                                )
                              )
                            );
                            subscription.payment_plan = paymentPlan
                            this.setState({ subscription })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {paymentPlan.currency.symbol}
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Total"
                          value={Currency.format("", paymentPlan.total)}
                          disabled=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
          <div className="row no-gutters">
            <label>Installments</label>
            <input
              className="form-control form-control-solid"
              type="number"
              min={2}
              placeholder="Leave blank for continuous"
              name={"installments"}
              value={paymentPlan.installments}
              onChange={(e) => {
                paymentPlan.installments = e.target.value || null;
                subscription.payment_plan = paymentPlan
                this.setState({ subscription })
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button
            type="button"
            className={`btn btn-light-${this.props.theme} font-weight-bold`}
            disabled={isLoading}
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-${this.props.theme} font-weight-bold`}
            disabled={isLoading}
            onClick={() => this._update()}
          >
            Update
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

SubscriptionModal.defaultProps = {
  confirmTitleText: "Confirm",
  theme: "primary",
  dismissTitleText: "Dismiss"
}
