import React from 'react'
import Modal from 'react-bootstrap/Modal'
import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'
import moment from "moment";

import ReCAPTCHA from "react-google-recaptcha";

import PaymentPlans from "../splink/PaymentPlans";

import SmartList from "../common/SmartList";
import FileUploader from "../common/FileUploader";
import AsyncSelect from "../common/AsyncSelect";
import PhoneInput from "../common/PhoneInput"

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import Calculations from "../../../utils/Calculations";
import Currency from "../../../utils/Currency";
import Phone from "../../../utils/Phone";
import Name from "../../../utils/Name";
import Email from "../../../utils/Email";
import AuthManager from "../../../utils/AuthManager";
import Event from "../../../utils/Event"
import PaymentMethods from "../../../utils/PaymentMethods"
import CompanyProcessor from "../../../utils/CompanyProcessor";

export default class RequestPaymentModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

    this.recaptcha = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  componentDidMount(){
    Backend.getQuestions("payment_request")
    .then(paymentRequestQuestions => {
      let { paymentRequest } = this.state
      paymentRequest.questions = paymentRequestQuestions
      this.setState({paymentRequest})
    })
  }

  _processorSupportsMultipleCurrencies() {
    let { company } = this.state
    let canHandle = true
    let companyProcessor = CompanyProcessor.getDefault(company)
    return !CompanyProcessor.isSingleCurrencyProcessor(companyProcessor)
  }

  _getState(props){
    return {
      ...props,
      paymentRequest: props.paymentRequest || {
        customer: {
          send_email: true,
          send_sms: false
        },
        payment_plans: [
          {
            type: 'one_time',
            open: false,
            vat: 0,
            currency: props.company.settings.payment_plan.currency,
            discounts: [],
            allow_discounts: false,
          }
        ],
        collect_address: false,
        pay_by_bank: props.payByBank || false,
        pay_by_interac: false,
        questions: []
      },
      phone_number: Phone.getDefaultCountryCode()
    }
  }

  _handleChange(e) {
    let paymentRequest = this.state.paymentRequest
    paymentRequest[e.target.name] = e.target.value
    this.setState({ paymentRequest, error: null })
  }

  _handleCustomerChange(e) {
    let paymentRequest = this.state.paymentRequest
    paymentRequest.customer[e.target.name] = e.target.value
    this.setState({ paymentRequest, error: null })
  }

  _handlePaymentPlanChange(e) {
    let paymentRequest = this.state.paymentRequest
    paymentRequest.payment_plans[e.target.name] = e.target.value
    this.setState({ paymentRequest, error: null })
  }

  _handlePhoneUpdate(value, phoneData){
    let {
      paymentRequest
    } = this.state

    if(!phoneData || !phoneData.dialCode){
      return
    }

    paymentRequest.customer["phone_country_code"] = `+${phoneData.dialCode}`
    paymentRequest.customer["phone_number"] = value.replace(/[^0-9]+/g, '').slice(phoneData.dialCode.length)
    paymentRequest.customer['send_sms'] = paymentRequest.customer["phone_number"].length > 0

    this.setState({
      phone_number: value,
      paymentRequest
    })
  }

  _isFormValid(){
    let {
      company,
      paymentRequest
    } = this.state

    let { customer } = paymentRequest

    let error = null

    if(PaymentMethods.supportsInterac(company) && !paymentRequest.customer.last_name){
      error = "Please enter the customer's full name"
    }else if(!customer.email && (!customer.phone_country_code || !customer.phone_number)){
      error = "Please enter the customer's email or sms"
    }else if(customer.email && !Email.isValid(customer.email)){
      error = "Please enter a valid email"
    }else if(!paymentRequest.payment_plans[0].currency){
      error = "Please select the currency"
    }else if(!paymentRequest.payment_plans[0].subtotal){
      error = "Please enter the amount"
    }else if(paymentRequest.payment_plans[0].type == "recurring" && !paymentRequest.payment_plans[0].frequency){
      error = "Please select a frequency"
    }else if(paymentRequest.payment_plans[0].installments !== null && paymentRequest.payment_plans[0].installments < 2){
      error = "Installments must be 2 or more"
    }else if(!paymentRequest.due_at){
      error = "Please enter a due date"
    }else if(!paymentRequest.description){
      error = "Please enter a description"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleCreatePaymentRequest(){
    if(!this._isFormValid()){
      return
    }
    this._createPaymentRequest()
  }

  _createPaymentRequest(){
    let {
      paymentRequest
    } = this.state

    this.setState({ loading: true })

    this.recaptcha.current.executeAsync()
    .then(token => {
      paymentRequest.recaptcha = token
      return Backend.createPaymentRequest(paymentRequest)
    })
    .then(company => {
      Notify.success("Payment request created successfully")
      this.setState({ loading: false })
      this.props.onAdded(paymentRequest)
      this.recaptcha.current.reset()
    })
    .catch(error => {
      this.recaptcha.current.reset()
      this.setState({ loading: false })
      Notify.error(error?.message || error)
    })
  }

  _renderQuestionItem(item, index){
    let {
      paymentRequest,
    } = this.state

    let helpText = paymentRequest.questions[index].type === "text" ? "Required" : "Must Be Checked"

    return (
      <div className={"mb-7"}>
        <div className={"row mb-3"}>
          <div className={"col-4"}>
            <select
              className="form-control form-control-solid field-choice"
              value={paymentRequest.questions[index].type}
              onChange={e => {
                paymentRequest.questions[index].type = e.target.value
                this.setState({ paymentRequest })
              }}
            >
              <option value="checkbox">Checkbox</option>
              <option value="text">Text Field</option>
            </select>
          </div>
          <div className={"col-8"}>
            <input
              type="text"
              className="form-control form-control-solid"
              value={paymentRequest.questions[index]?.text || '' }
              disabled={paymentRequest.questions[index]?.id != null}
              onChange={e => {
                paymentRequest.questions[index].text = e.target.value
                this.setState({ paymentRequest })
              }}
            />
          </div>
        </div>
        <label className="checkbox c-checkbox mb-0 checkbox">
          <input
            type="checkbox"
            name="agree"
            className="mr-1"
            checked={paymentRequest.questions[index].is_required}
            onClick={e => {
              paymentRequest.questions[index].is_required = e.target.checked
              this.setState({ paymentRequest })
            }}
          />
          { helpText }
          <span></span>
        </label>
      </div>
    )
  }

  _renderQuestionItemDelete(item, index){
    let {
      mode,
      paymentRequest,
    } = this.state

    return (
      <a href="javascript:;" data-repeater-delete=""
         className="btn font-weight-bold btn-danger btn-icon"
         onClick={() => {
            paymentRequest.questions.splice(index, 1)
            this.setState({ paymentRequest })
         }}
      >
        <i className="la la-remove"></i>
      </a>
    )
  }

  render() {
    let {
      show,
      phone_number,
      paymentRequest,
      error,
      loading,
      company
    } = this.state

    console.log("+++", company)
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-lg'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Payment Request</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { error &&
            <div className="form-group">
              <span className="error-message">{ error }</span>
            </div>
          }

          { PaymentMethods.supportsInterac(company) &&
            <div className="form-group row">
              <label className="col-3 col-form-label">Customer Full Name*</label>
              <div className="col-9">
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder="E.g. John Smith"
                  onChange={e => {

                    let fullName = e.target.value
                    paymentRequest.customer.first_name = Name.getFirstName(fullName)
                    paymentRequest.customer.last_name = Name.getLastName(fullName)

                    this.setState({paymentRequest})
                  }}
                />
              </div>
            </div>
          }

          {/* starts, email */}
          <div className="form-group row">
            <label className="col-3 col-form-label">Email</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                name="email"
                placeholder="Email Address"
                onChange={(e) => this._handleCustomerChange(e)}
              />
              <div className="row no-gutters">
                <div className="col-auto mt-3">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <label>
                    <input
                      type="checkbox"
                      name="emailSelect"
                      checked={paymentRequest.customer.send_email}
                      onChange={e => {
                        paymentRequest.customer["send_email"] = e.target.checked
                        this.setState({paymentRequest})
                      }}
                    />
                    <span></span>
                    </label>
                  </span>
                </div>
                <label htmlFor="emailSelect" className="col col-form-label align-left pl-3 mt-3">
                  Send payment request to the above email address?
                </label>
              </div>
            </div>
          </div>
          {/* end, email */}

          {/* starts, sms */}
          <div className="form-group row">
            <label className="col-3 col-form-label">SMS</label>
            <div className="col-9">
              <PhoneInput
                value={phone_number}
                enableLongNumbers={true}
                onChange={(value, data) => this._handlePhoneUpdate(value, data)}
                inputProps={{ autocomplete: 'off', className: 'form-control form-control-solid' }}
              />
              <div className="row no-gutters">
                <div className="col-auto mt-3">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <label>
                      <input
                        type="checkbox"
                        name="smsSelect"
                        checked={paymentRequest.customer.send_sms}
                        onChange={e => {
                          paymentRequest.customer["send_sms"] = e.target.checked
                          this.setState({paymentRequest})
                        }}
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
                <label htmlFor="smsSelect" className="col col-form-label align-left pl-3 mt-3">
                  Send payment request SMS to the above number?
                </label>
              </div>
            </div>
          </div>
          {/* end, sms */}

          <PaymentPlans
            showOptional={this.props.showOptional}
            showCurrency={this._processorSupportsMultipleCurrencies()}
            priceTitle="Amount"
            paymentPlans={paymentRequest.payment_plans}
            showDiscount={true}
            updatePaymentPlan={(paymentPlan, index) => {
              paymentRequest.payment_plans[index] = paymentPlan
              this.setState({paymentRequest})
            }}
          />

          <div className="form-group row">
            <label className="col-3 col-form-label">Due Date & Time*</label>
            <div className="col-9">
              <div className="input-group date">

                <div className="input-group-prepend">
                  <span className="input-group-text border-0">
                    <i className="la la-calendar"></i>
                  </span>
                </div>

                <Flatpickr
                  className="form-control form-control-solid"
                  options={{
                    enableTime: true,
                    noCalendar: false,
                    dateFormat: "Y-m-d h:i"
                  }}
                  onChange={dates => {
                    paymentRequest['due_at'] = moment(dates[0]).format('YYYY-MM-DDThh:mm')
                    this.setState({ paymentRequest })
                  }}
                />

              </div>

            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Description*</label>
            <div className="col-9">
              <textarea
                className="form-control form-control-solid"
                placeholder="Description"
                name="description"
                onChange={(e) => this._handleChange(e)}
              />
            </div>
          </div>

          { PaymentMethods.supportsBank(company) &&
            <div className="form-group row">
              <label className="col-3 col-form-label">Pay By Bank Only</label>
              <div className="col-9">
                <div className="select-wrapper">
                  <select
                    value={paymentRequest.pay_by_bank ? "true" : "false"}
                    className="form-control form-control-solid"
                    onChange={e => {
                      paymentRequest.pay_by_bank = e.target.value == "true"
                      if(paymentRequest.pay_by_bank){
                        paymentRequest.pay_by_interac = false
                      }
                      this.setState({ paymentRequest })
                    }}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
                <span className="form-text text-muted">Choose whether or not your customer can only Pay by Bank (lower fees).</span>
              </div>
            </div>
          }

          <div className="accordion accordion-light accordion-light-borderless accordion-svg-toggle"
               id="accordionExample7">
            <div className="card">
              <div className="card-header" id="headingOne7">
                <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseOne7" aria-expanded="false">
                  <span className="svg-icon svg-icon-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                        <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"></path>
                      </g>
                    </svg>
                  </span>
                  <div className="card-label pl-4">Advanced Options</div>
                </div>
              </div>
              <div id="collapseOne7" className="collapse" data-parent="#accordionExample7">

                <div className="form-group row">
                  <label className="col-3 col-form-label">Title</label>
                  <div className="col-9">
                    <input
                      className="form-control form-control-solid"
                      type="text"
                      placeholder="Title"
                      name={"title"}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">Customer</label>
                  <div className="col-9">
                    <input
                      className="form-control form-control-solid"
                      type="text"
                      placeholder="Reference"
                      name={"reference"}
                      onChange={(e) => {
                        paymentRequest.customer.reference = e.target.value
                        this.setState({paymentRequest})
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">PDF Attachment</label>
                  <div className="col-9">
                    <FileUploader
                      endpoint={window.Api.Files}
                      params={{company_id: AuthManager.currentUser.company.id}}
                      accept={'.pdf'}
                      canUpload={AuthManager.currentUser.company.add_ons.payment_request_attachment}
                      onUploading={() => {
                        if(!AuthManager.currentUser.company.add_ons.payment_request_attachment){
                          Event.emit('ADD_ON_ALERT')
                          return
                        }
                        this.setState({loading: true})
                      }}
                      onUploaded={file => {
                        paymentRequest.file = file.id
                        this.setState({ paymentRequest, loading: false })
                      }}
                    />
                  </div>
                </div>

                { PaymentMethods.supportsInterac(company) &&
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Pay By Interac Only</label>
                    <div className="col-9">
                      <div className="select-wrapper">
                        <select
                          value={paymentRequest.pay_by_interac ? "true" : "false"}
                          className="form-control form-control-solid"
                          onChange={e => {
                            paymentRequest.pay_by_interac = e.target.value == "true"
                            if(paymentRequest.pay_by_interac){
                              paymentRequest.pay_by_bank = false
                            }
                            this.setState({ paymentRequest })
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                }

                { !paymentRequest.pay_by_interac &&
                  <div className="form-group row">
                    <label className="col-3 col-form-label">Collect Address</label>
                    <div className="col-9">
                      <div className="select-wrapper">
                        <select
                          value={paymentRequest.collect_address}
                          className="form-control form-control-solid"
                          onChange={e => {
                            paymentRequest.collect_address = e.target.value
                            this.setState({ paymentRequest })
                          }}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                      <span className="form-text text-muted">Choose whether or not you require your customer's address on the payment page.</span>
                    </div>
                  </div>
                }

                <div className="form-group row questionBox" id="questionInput" style={{border: 'none'}}>
                  <label className="col-3 col-form-label">Questions</label>
                  <div className="col-9">
                    <SmartList
                      items={paymentRequest.questions}
                      itemName="Question"
                      showItemName={true}
                      value={item => item.text}
                      disabled={item => item.id != null}
                      onChange={(value, item, index) => {
                        paymentRequest.questions[index].text = value
                        this.setState({ paymentRequest })
                      }}
                      onUpdated={questions => {
                        paymentRequest.questions = questions
                        this.setState({ paymentRequest })
                      }}
                      onAddItemPressed={() => {
                        paymentRequest.questions.push({text: "", type: "checkbox"})
                        this.setState({ paymentRequest })
                      }}
                      renderItem={(item, index) => this._renderQuestionItem(item, index)}
                      renderDelete={(item, index) => this._renderQuestionItemDelete(item, index)}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>


        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleCreatePaymentRequest()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                    />
                </g>
              </svg>
            </span>
            Create
          </button>

        </Modal.Footer>

        <ReCAPTCHA
          ref={this.recaptcha}
          sitekey={window.Api.RecaptchaSiteKey}
          size="invisible"
        />
      </Modal>
    )
  }
}
