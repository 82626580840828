import moment from 'moment'

import Currency from './Currency'

export default class Stats {
  static getTakeTotal(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    let open = Stats.getPaymentBreakDownTypeTotal(
      'open',
      paymentBreakdown,
      currency
    )
    let paymentLink = Stats.getPaymentBreakDownTypeTotal(
      'payment_link',
      paymentBreakdown,
      currency
    )

    return Currency.format(currency.symbol, open + paymentLink)
  }

  static getPaymentRequestsTotal(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    let total = Stats.getPaymentBreakDownTypeTotal(
      'payment_request',
      paymentBreakdown,
      currency
    )

    return Currency.format(currency.symbol, total)
  }

  static getRecurringTotal(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    let total = Stats.getPaymentBreakDownTypeTotal(
      'recurring',
      paymentBreakdown,
      currency
    )

    return Currency.format(currency.symbol, total)
  }

  static getShopTotal(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    let total = Stats.getPaymentBreakDownTypeTotal(
      'shop',
      paymentBreakdown,
      currency
    )

    return Currency.format(currency.symbol, total)
  }

  static getPaymentBreakDownTypeTotal(
    type,
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    let total = 0

    let splinkType = paymentBreakdown[type].find(
      (stat) => stat.currency.id === currency.id
    )
    if (splinkType) {
      total += splinkType.total
    }

    return total
  }

  static getTotalPayments(paymentBreakdown) {
    return paymentBreakdown.total_value_processed.reduce(
      (total, stat) => total + stat.count,
      0
    )
  }

  static getMonthlyLabels(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    let months = paymentBreakdown.monthly.total.find(
      (stat) => stat.currency.id === currency.id
    )

    return months?.stats.map((stat) => moment(stat.date).format('MMM')) || []
  }

  static getMonthlyTotals(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    return Stats.getMonthKey(
      'total',
      paymentBreakdown,
      (currency = window.General.DefaultCurrency)
    )
  }

  static getMonthlyFees(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    return Stats.getMonthKey(
      'fee',
      paymentBreakdown,
      (currency = window.General.DefaultCurrency)
    )
  }
  static getMonthlyCommission(
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    return Stats.getMonthKey(
      'commission',
      paymentBreakdown,
      (currency = window.General.DefaultCurrency)
    )
  }

  static getMonthKey(
    key,
    paymentBreakdown,
    currency = window.General.DefaultCurrency
  ) {
    let months = paymentBreakdown.monthly[key].find(
      (stat) => stat.currency.id === currency.id
    )

    return months?.stats.map((stat) => stat.count / 100) || []
  }

  static getRecurringDueThisWeek(
    recurring,
    currency = window.General.DefaultCurrency
  ) {
    let week = recurring?.future.next_week.find(
      (stat) => stat.currency.id === currency.id
    )

    return Currency.format(currency.symbol, week?.total)
  }

  static getAllTimeRecurring(
    recurring,
    currency = window.General.DefaultCurrency
  ) {
    let all_time = recurring?.all_time.find(
      (stat) => stat.currency.id === currency.id
    )

    return Currency.format(currency.symbol, all_time?.total)
  }

  static getAllTimeRecurringNo(
    recurring,
    currency = window.General.DefaultCurrency
  ) {
    console.log('recurring', recurring)
    console.log('currency', currency)
    let all_time = recurring?.all_time.find(
      (stat) => stat.currency.id === currency.id
    )

    console.log('all_time', all_time)

    return all_time?.count || 0
  }

  static getProcesseedStats(stats, currency = window.General.DefaultCurrency) {
    let processedStats = {
      processed_today_no: 0,
      processed_today: 0,
      processed_last_7_days_no: 0,
      processed_last_7_days: 0,
      processed_month_no: 0,
      processed_month: 0,
    }

    let month = stats.month.find((stat) => stat.currency.id === currency.id)
    if (month) {
      processedStats.processed_month = month.total
      processedStats.processed_month_no = month.count
    }

    let week = stats.week.find((stat) => stat.currency.id === currency.id)
    if (week) {
      processedStats.processed_last_7_days = week.total
      processedStats.processed_last_7_days_no = week.count
    }

    let today = stats.today.find((stat) => stat.currency.id === currency.id)
    if (today) {
      processedStats.processed_today = today.total
      processedStats.processed_today_no = today.count
    }

    return processedStats
  }
}
