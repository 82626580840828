import React, { Component } from "react";

import Div from "./components/layouts/Div";
import Select from "./components/common/CustomSelect";

import Event from '../utils/Event'
import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'
import General from '../utils/General'

const OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export default class CashDiscount extends Component {
  constructor(props) {
    super(props);

    let company = props.company
    let discount = company.settings.payment_plan.discounts[0]
    this.state = {
      company,
      discount,
      loading: false,
      selectedOption: discount ? OPTIONS[0] : OPTIONS[1],
    };
  }

  componentDidMount(){
    if (!AuthManager.currentUser.company.add_ons.discounts) {
      Event.emit('ADD_ON_ALERT', '/')
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _load(){
    this.setState({loading: true})
    Backend.getCashDiscount()
    .then(discount => {
      this.setState({
        discount,
        loading: false,
        selectedOption: discount ? OPTIONS[0] : OPTIONS[1]
      })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({
        loading: false
      })
    })
  }

  _update(paymentPlan, add){
    this.setState({loading: true})
    let action = add ? "created" : "updated"
    Backend.updatePaymentPlan(paymentPlan)
    .then(paymentPlan => {
      this.setState({
        loading: false
      })
      Notify.success(`Discount ${action}`)
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({
        loading: false
      })
    })
  }

  handleChange(option) {
    let { company, discount } = this.state
    if(option.value == true && !discount){
      discount = {
        percentage_off: "",
        conditions: [
          {
            payment_method: company.payment_methods.ach?.id,
            payment_plan: company.settings.payment_plan.id
          }
        ]
      }

    }
    this.setState({ discount, selectedOption: option });
  }

  render() {
    let { company, loading, discount, selectedOption } = this.state;

    return (
      <div className="col">
        <Div
          className="form fv-plugins-bootstrap fv-plugins-framework"
          disabled={loading}
          spinner={true}
        >
          <div
            className="pb-5"
            data-wizard-type="step-content"
            data-wizard-state="current"
          >
            <h4 className="mb-10 font-weight-bold text-dark">
              Manage Cash Discounting
            </h4>
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Enable Cash Discounting
              </label>
              <div className="col-9">
                <div className="select-wrapper">
                  <Select
                    value={selectedOption}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    name="allow_discounts"
                    placeholder="Please Select An Option"
                    onChange={(option) => this.handleChange(option)}
                    options={OPTIONS}
                  />
                  <span className="form-text text-muted">
                    Enable cash discounting by default? You can change this for
                    each individual payment request.
                  </span>
                </div>
              </div>
            </div>
            {(discount && selectedOption.value === true) && (
              <div className="form-group row">
                <label className="col-3 col-form-label">Cash Discount %</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    defaultValue={discount.percentage_off ? parseFloat(discount.percentage_off * 100).toFixed(2) : ""}
                    max={4}
                    onChange={(e) => {
                      discount.percentage_off = parseFloat(
                        e.target.value / 100
                      ).toFixed(4);

                      this.setState({
                        discount,
                      });
                    }}
                  />
                  <span className="form-text text-muted">
                    Enter the default cash discount %. This is capped at 4%.
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between border-top mt-5 pt-10">
            <div style={{ margin: "0 auto" }}>
              <button
                className="btn btn-dark font-weight-bold text-uppercase px-9 py-4"
                disabled={loading}
                onClick={() => {

                  let paymentPlan = General.clone(company.settings.payment_plan)
                  paymentPlan.allow_discounts = selectedOption.value
                  if(selectedOption.value === true){
                    paymentPlan.discounts = [discount]
                  }
                  else{
                    paymentPlan.discounts = []
                  }
                  this._update(paymentPlan, selectedOption.value);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </Div>
      </div>
    );
  }
}
