import React from 'react'

import AuthManager from '../utils/AuthManager'
import Event from '../utils/Event'

import RolesTable from './components/tables/RolesTable'

export default class Roles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (!AuthManager.currentUser.company.add_ons.roles) {
      Event.emit('ADD_ON_ALERT', '/')
    }
  }

  render() {
    return (
      <>
        <RolesTable title={'Roles'} />
      </>
    )
  }
}
