import Email from "./Email";
import moment from "moment";
import XLSX from "xlsx";

import {
  isValidPhoneNumber
} from 'libphonenumber-js'

const amountCol       = 'Amount';
const customerCol     = 'Customer';
const dueDateCol      = 'Due Date';
const emailCol        = 'Email';
const mobileCol       = 'SMS';
const recurringCol    = 'Recurring';
const descriptionCol  = 'Description';

const standardHeaders = [
  amountCol,
  customerCol,
  dueDateCol,
  emailCol,
  mobileCol,
  recurringCol,
  descriptionCol
]

export default class BulkUpload {

  static formatData(data, globalDueDate=null){
    let validFixedSplinksData = []
    let invalidFixedSplinksData = []

    let workbook = XLSX.read(data, {
      type: 'binary',
      cellDates: true,
      cellText: false,
      dateNF: 'DD"/"MM"/"YYYY'
    })

    let workSheet = workbook.Sheets[workbook.SheetNames[0]]

    let rows = XLSX.utils.sheet_to_json(workSheet, { header: 1, raw: true})

    let headers = Array.from(rows)[0]
    rows = Array.from(rows).slice(1)
    let additionalHeaders = BulkUpload.removeStandardHeaders(headers)

    rows.filter(entry => entry.length > 0).map((row, index) => {
      let amount = BulkUpload.getAmount(row[headers.indexOf(amountCol)]);
      let customer = row[headers.indexOf(customerCol)]
      let email = BulkUpload.getEmail(row[headers.indexOf(emailCol)]);
      let dueDate = BulkUpload.getDueDate(row[headers.indexOf(dueDateCol)]);
      let mobile = BulkUpload.getMobile(row[headers.indexOf(mobileCol)]);
      let recurring = BulkUpload.getRecurring(row[headers.indexOf(recurringCol)]);
      let description = row[headers.indexOf(descriptionCol)];

      let additionalData = {}
      additionalHeaders.map(additionalHeader => {
        let value = row[headers.indexOf(additionalHeader)]
        if(value && typeof value.getMonth === 'function'){
          value = BulkUpload.getTimezonSafeDate(moment(value)).format("DD/MM/YYYY");
        }
        additionalData[additionalHeader] = value
      })

      let fixedSplinkData = {
        amount: amount,
        customer: customer,
        email: email,
        mobile: mobile,
        recurring: recurring,
        description: description,
        dueDate: globalDueDate || dueDate,
      };

      fixedSplinkData = Object.assign({}, fixedSplinkData, additionalData)

      if(BulkUpload.isValid(fixedSplinkData)){
        validFixedSplinksData.push(fixedSplinkData);
      }
      else{
        invalidFixedSplinksData.push(fixedSplinkData);
      }
    })

    return [headers, validFixedSplinksData, invalidFixedSplinksData]
  }

  static removeStandardHeaders(headers){
    return headers.filter(header => {
      return standardHeaders.indexOf(header) < 0
    })
  }

  static getAmount(value){
    let amount = parseFloat(value)
    if(amount && amount > 0){
      return amount.toFixed(2)
    }
    return undefined
  }

  static getEmail(value){
    if(Email.isValid(value)){
      return value
    }
    return undefined
  }

  static getTimezonSafeDate(date){
    let convertedDate = moment(date).add(12, 'hours')
    return convertedDate
  }

  static getDueDate(date){
    if(typeof date === 'string' || date instanceof String){

      let parts = []
      if(date.indexOf('-') > -1){
        parts = date.split('-');
      }
      else{
        parts = date.split('/');
      }

      let convertedDate = new Date();
      convertedDate.setFullYear(parseInt(parts[0]), parseInt(parts[1]-1), parseInt(parts[2]))

      return BulkUpload.getDueDate(convertedDate);
    }
    else if(date){
      try{
        let timezoneSafeDate = BulkUpload.getTimezonSafeDate(date)
        return timezoneSafeDate.format('YYYY-MM-DD')
      }
      catch(error){
        return undefined;
      }
    }
    return undefined;
  }

  static getMobile(value){
    let mobile = BulkUpload.formatMobile(value);
    return mobile;
  }

  static formatMobile(value){
    if(!value){
      return undefined;
    }
    value = value.toString();

    if(value.startsWith('+')){
      return value;
    }
    if(value.startsWith('083') || value.startsWith('087') || value.startsWith('085') || value.startsWith('083') || value.startsWith('086')){
      value = value.substr(1);
    }

    if(value.startsWith('83') || value.startsWith('87') || value.startsWith('85') || value.startsWith('83') || value.startsWith('86')){
      value = '+353'+value;
    }
    if(value.startsWith('00')){
      value = '+'+value.substr(2);
    }
    // add + for US/UK/IE country codes
    if(value.startsWith('1') || value.startsWith('44') || value.startsWith('353')){
      value = '+'+value;
    }
    return value;
  }

  static getRecurring(val){
    return BulkUpload.stringToBoolean(val);
  }

  static stringToBoolean(string){
    if(string == null){
      return false
    }
    if(typeof string === "boolean"){
      return string
    }
    switch(string.toLowerCase().trim()){
      case "true": case "yes": case "1": return true;
      case "false": case "no": case "0": case null: return false;
      default: return Boolean(string);
    }
  }

  static validMobile(mobile){
    if(mobile){
      return isValidPhoneNumber(mobile);
    }
    return false
  }

  static isValid(request){
    if(request.mobile){
      if(!BulkUpload.validMobile(request.mobile)){
        return false
      }
    }

    if(
      !request.amount ||
      request.amount === "" ||
      parseFloat(request.amount) <= 0 ||
      !request.email ||
      request.email === "" ||
      !Email.isValid(request.email) ||
      !request.dueDate ||
      request.dueDate === "" ||
      !moment(request.dueDate).isValid() ||
      !request.description ||
      request.description === ""
    ){
      return false;
    }
    return true;
  }
}
