import React from 'react'

import Skeleton from 'react-loading-skeleton'
export default class PackageShimmerView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div
        className="login-aside d-flex flex-column flex-row-auto"
        style={{ backgroundColor: '#fff' }}
      >
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 pl-25 pr-25 text-center">
          <a href="#" className="mb-10" style={{height:50}}>
          </a>
          <div className="mt-5">
            <Skeleton width={'70%'} height={18}/>
            <Skeleton width={'100%'} height={18}/>
            <Skeleton width={'30%'} height={18}/>
          </div>
          <div className="mt-5">
            <Skeleton count={2} />
          </div>

        </div>
        <div className="mt-10">
          <ul className="list-unstyled">
            <li className="d-flex p-5 pl-10 pr-10">
              <span className="d-flex flex-column flex-center">
                <Skeleton circle={true} height={20} width={20} />
              </span>
              <div className="ml-10">
                <Skeleton circle={false} height={10} width={300} />
                <div className="mt-5">
                  <Skeleton
                    count={3}
                    circle={false}
                    height={10}
                    width={'100%'}
                  />
                </div>{' '}
              </div>
            </li>
            <li className="d-flex p-5 pl-10 pr-10">
              <span className="d-flex flex-column flex-center">
                <Skeleton circle={true} height={20} width={20} />
              </span>
              <div className="ml-10">
                <Skeleton circle={false} height={10} width={300} />
                <div className="mt-5">
                  <Skeleton
                    count={3}
                    circle={false}
                    height={10}
                    width={'100%'}
                  />
                </div>{' '}
              </div>
            </li>
            <li className="d-flex p-5 pl-10 pr-10">
              <span className="d-flex flex-column flex-center">
                <Skeleton circle={true} height={20} width={20} />
              </span>
              <div className="ml-10">
                <Skeleton circle={false} height={10} width={300} />
                <div className="mt-5">
                  <Skeleton
                    count={3}
                    circle={false}
                    height={10}
                    width={'100%'}
                  />
                </div>{' '}
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
