import React from 'react'

import Currency from "../../../../utils/Currency";

export default class PaymentProcessedCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      payment: props.payment,
      payout: props.payout
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      payment,
      payout
    } = this.state

    let originalProcessed = Currency.format(payment.payment_plan.currency.symbol, payment.payment_plan.total)
    let convertedProcessed = null
    if(payment.processor_payout_data && payment.processor_payout_data.currency.toUpperCase() !== payment.payment_plan.currency.code.toUpperCase()){
      convertedProcessed = Currency.format(payout.currency.symbol, payment.processor_payout_data.amount)
    }

    return (
      <div className="d-flex align-items-center">
        <div className="ml-2">
          <div className="text-dark-75 line-height-sm">{convertedProcessed || originalProcessed}</div>
          { convertedProcessed &&
            <a href="javascript:;" className="font-size-sm text-dark-50 text-hover-primary">{originalProcessed}</a>
          }
        </div>
      </div>
    )
  }
}
