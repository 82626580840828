import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import 'flatpickr/dist/flatpickr.min.css'
import Flatpickr from 'react-flatpickr'

import Div from "../layouts/Div"

import LocationInput from "../common/LocationInput"
import PhoneInput from "../common/PhoneInput"

import Person from "./Person"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import General from "../../../utils/General"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"

export default class ConnectForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      loading: true,
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    Backend.getConnectData(this.props.slug)
    .then(data => {
      if(data == null){
        data = {
          country_short: "IE",
          currency: "EUR",
          business_type: null,
          relationship: {
            location: {}
          },
          persons: [],
          company: {}
        }
      }

      this.setState({ data, loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _handleChange(e){
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleChangeCompany(e){
    let data = this.state.data
    let value = e.target.value
    if(e.target.value === "on" || e.target.value === "off" ){
      value = e.target.checked
    }
    let company = data.company || { verification: {} }

    company[e.target.name] = value

    data.company = company
    this.setState({ data, error: null })
  }

  _showIdNumber(data, person){
    return data.requirements.eventually_due.find(requirement => {
      return requirement === "individual.ssn_last_4" || requirement ==="individual.id_number"
    }) != null
  }

  handleSave(){
    let {
      data
    } = this.state

    this.setState({ loading: true })

    Backend.updateConnect(data, this.props.slug)
      .then(data => {

        this.setState({ data, loading: false })
        Notify.success("Details updated successfully")
      })
      .catch(error => {
        Notify.error(error.message)
        this.setState({ loading: false })
      })
  }

  _renderMembers(){
    let {
      data
    } = this.state

    if(data.business_type === "individual"){
      return this._renderIndividual()
    }

    if(data.business_type !== "company"){
      return null
    }

    let persons = data.persons
    return (
      <>
        <div className="row">
          <div className="col">
            { persons.map((person, index) => {
              return (
                <>
                  <Person
                    data={person}
                    typeEditable={true}
                    canDelete={true}
                    showIdNumber={this._showIdNumber(data, person)}
                    type="person"
                    title={`Person #${index + 1}`}
                    onUpdated={person => {
                      data.persons[index] = person
                      this.setState({ data })
                    }}
                    onDeletePressed={() => {
                      data.persons.splice(index, 1)
                      this.setState({ data })
                    }}
                  />
                  <div className="col-md-6" hidden/>
                </>
              )
            })
            }
          </div>
        </div>

        <div className="form-group row mb-4">
          <div className="col">
            <a
              className="btn btn-sm font-weight-bolder btn-light-primary"
              onClick={() => {
                let aa = Person.getData()
                data.persons.push(aa)
                this.setState({ data })
              }}
            >
              <i className="la la-plus"></i>Add Person
            </a>
          </div>
          <div className="col-md-3 col-lg-3"  hidden>
            <h2>Any{data.persons.length == 0 ? " " : " Other "}Directors / Execs?</h2>
          </div>
        </div>

      </>
    )
  }

  _renderIndividual(){
    let {
      data
    } = this.state
    return (
      <div className="row">
        <div className="col">
          <Person
            data={data.representative}
            type="individual"
            title="Personal Information"
            showIdNumber={this._showIdNumber(data, data.representative)}
            onUpdated={representative => {
              data.representative = representative
              this.setState({ data })
            }}
          />
        </div>
        <div className="col-md-3 col-lg-3" hidden>
          <h2>Tell us about yourself.</h2>
          <p>For security and fraud prevention we need to get a few details from you. These details will not be made public to your customers.</p>
        </div>
      </div>
    )
  }

  _renderCompanyInfo(){
    let {
      data
    } = this.state

    let company = data.company || {}


    if(data.business_type !== "company"){
      return null
    }

    let address = company.address
    let location = (address && address.line1) ? {
      ...address,
      raw: `${address.line1}, ${address.line2}, ${address.city}, ${address.state}, ${address.country}`,
      line_1: address.line1,
      line_2: address.line2,
    } : null

    return (
      <div className="row">
        <div className="col">

          <div className="kt-heading kt-heading--md">
            Company
          </div>
          <div className="kt-form__section kt-form__section--first">

            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={company.name}
                onChange={e => this._handleChangeCompany(e)}
              />
            </div>

            <div className="form-group">
              <label>Phone</label>
              <PhoneInput
                inputClass="form-control w-100"
                country={data.country_short.toLowerCase()}
                value={company.phone}
                onChange={(value, phoneData) => {
                  company.phone = "+"+value
                  data.company = company
                  this.setState({ data })
                }}
                inputProps={{ autocomplete: 'off' }}
              />
            </div>

            <div className="form-group">
              <label>Tax ID</label>
              <input
                type="text"
                name="tax_id"
                className="form-control"
                value={company.tax_id}
                onChange={e => this._handleChangeCompany(e)}
              />
            </div>

            <div className="form-group">
              <LocationInput
                className="form-control"
                requirePostalCode={true}
                allowManualInput={true}
                location={location}
                onUpdated={address => {
                  address = {
                    line1: address.address_line_1,
                    line2: address.address_line_2,
                    city: address.city,
                    state: address.state,
                    country: address.country,
                    country_short: address.country_short,
                    postal_code: address.postal_code
                  }
                  data.address = address
                  this.setState({ data })
                }}
              />
            </div>

          </div>

        </div>


        <div className="col-md-3 col-lg-3" hidden>
          <h2>Tell us about your company.</h2>
          <p>For security and fraud prevention we need to get a few details from you. These details will not be made public to your customers.</p>
        </div>

      </div>
    )
  }

  _renderCompanyRepresentative(){
    let {
      data
    } = this.state

    let company = data.company || {}

    if(data.business_type !== "company"){
      return null
    }

    return (
      <div className="row">

        <div className="col">
          <Person
            data={data.representative}
            requirements={data.requirements}
            showIdNumber={this._showIdNumber(data, data.representative)}
            type="representative"
            title="Company Representative"
            onUpdated={representative => {
              data.representative = representative
              this.setState({ data })
            }}
          />
        </div>

        <div className="col-md-3 col-lg-3" hidden>
          <h2>Main Point of Contact.</h2>
          <p>This person is the nominated contact who'll be informed of any issues and can provide further details of the company if need be,</p>
        </div>

      </div>
    )
  }

  _renderCompanyExtra(){
    let {
      data
    } = this.state

    let company = data.company || {}

    if(data.business_type !== "company"){
      return null
    }

    return (
      <div className="row mb-5">
        <div className="col">

          <div className="kt-heading kt-heading--md">
            Submission
          </div>

          <div className="form-group">


            <label>Details Provided:</label>
            <br/>

            <div className="checkbox-list">

              <label className="checkbox checkbox--brand">
                <input
                  type="checkbox"
                  name="directors_provided"
                  checked={company.directors_provided}
                  onChange={e => this._handleChangeCompany(e)}
                />
                Information on all Directors
                <span></span>
              </label>

              <label className="checkbox checkbox--brand">
                <input
                  type="checkbox"
                  name="executives_provided"
                  checked={company.executives_provided}
                  onChange={e => this._handleChangeCompany(e)}
                />
                Information on all Executives
                <span></span>
              </label>

              <label className="checkbox checkbox--brand">
                <input
                  type="checkbox"
                  name="owners_provided"
                  checked={company.owners_provided}
                  onChange={e => this._handleChangeCompany(e)}
                />
                Information on all Owners
                <span></span>
              </label>

            </div>

            <br/>


          </div>
        </div>
      </div>
    )
  }


  _renderRequirements(requirements){
    let {
      data,
    } = this.state

    if(data == null){
      return null
    }

    let requirementsDue = requirements.eventually_due.map(requirement => {
      switch(requirement){
        case "external_account":                              return "Bank Account"

        case "individual.verification.additional_document":   return "Address Verification"
        case "individual.verification.document":              return "ID Verification"
        case "individual.dob.day":                            return "Date Of Birth"
        case "individual.dob.month":                          return null
        case "individual.dob.year":                           return null
        case "individual.phone":                              return "Phone"
        case "individual.ssn_last_4":                         return "Social Security Number (Last 4 digits)"
        case "individual.id_number":                          return "Government Issued ID # (e.g. Social Security Number)"

        case "individual.address.line1":                      return "More Specific Address"
        case "individual.address.line2":                      return null
        case "individual.address.postal_code":                return null
        case "individual.address.city":                       return null
        case "individual.address.state":                      return null
        case "individual.address.country":                    return null

        case "company.name":                                  return "Company Name"
        case "company.tax_id":                                return "Company Tax ID"
        case "company.phone":                                 return "Company Phone"
        case "company.owners_provided":                       return "Information On All Owners"
        case "company.directors_provided":                    return "Information On All Directoors"
        case "company.executives_provided":                   return "Information On All Executives"

        case "representative.first_name":                     return "Representative First Name"
        case "representative.last_name":                      return "Representative Last Name"
        case "representative.dob.day":                        return "Representative Date Of Birth"
        case "representative.dob.month":                      return null
        case "representative.dob.year":                       return null
        case "representative.relationship.title":             return "Representative Title"
        case "representative.phone":                          return "Representative Phone"
        case "representative.email":                          return "Representative Email"

        case "representative.address.line1":                  return "Representative Address"
        case "representative.address.line2":                  return null
        case "representative.address.postal_code":            return null
        case "representative.address.city":                   return null
        case "representative.address.state":                  return null
        case "representative.address.country":                return null

        case "directors.first_name":                          return "Director(s) First Name"
        case "directors.last_name":                           return "Director(s) Last Name"
        case "directors.dob.day":                             return "Director(s) Date Of Birth"
        case "directors.dob.month":                           return null
        case "directors.dob.year":                            return null
        case "directors.relationship.title":                  return "Director(s) Title"
        case "directors.email":                               return "Director(s) Email"

        case "owners.first_name":                             return "Owner(s) First Name"
        case "owners.last_name":                              return "Owner(s) Last Name"
        case "owners.dob.day":                                return "Owner(s) Date Of Birth"
        case "owners.dob.month":                              return null
        case "owners.dob.year":                               return null
        case "owners.email":                                  return "Owner(s) Title"

        case "owners.address.line1":                          return "Owner(s) Address"
        case "owners.address.line2":                          return null
        case "owners.address.postal_code":                    return null
        case "owners.address.city":                           return null
        case "owners.address.state":                          return null
        case "owners.address.country":                        return null

        case "executives.first_name":                         return "Director(s) First Name"
        case "executives.last_name":                          return "Director(s) Last Name"
        case "executives.dob.day":                            return "Director(s) Date Of Birth"
        case "executives.dob.month":                          return null
        case "executives.dob.year":                           return null
        case "executives.email":                              return "Executives(s) Email"

        case "executives.address.line1":                      return "Executives(s) Address"
        case "executives.address.line2":                      return null
        case "executives.address.postal_code":                return null
        case "executives.address.city":                       return null
        case "executives.address.state":                      return null
        case "executives.address.country":                    return null

        default:                                              return General.toTitleCase(requirement)
      }
    })

    requirementsDue = requirementsDue.filter(requirement => requirement != null)

    return (
      <>
        <div className="kt-heading kt-heading--md">
          Status
        </div>
        <div className="kt-form__section kt-form__section--first">

          { requirementsDue.length === 0 &&
          <p className="splink-charges-verified">Your business is fully verified</p>
          }
          { requirementsDue.length > 0 &&
          <>
            { !data.charges_enabled &&
            <p className="splink-charges-disabled">Your {window.General.Branding.LinkName} has been disabled from accepting payments/purchases</p>
            }
            { data.charges_enabled &&
            <p className="splink-charges-enabled">Your {window.General.Branding.LinkName} is in danger of losing the ability to accept payments/purchases</p>
            }
            <p>We require the following information to verify your {window.General.Branding.LinkName}:</p>
            <ul>
              { requirementsDue.map(requirement => (
                <li>{requirement}</li>
              ))
              }
            </ul>
          </>
          }
        </div>
      </>
    )
  }

  _renderContent(){
    let {
      data
    } = this.state

    if(data == null){
      return null
    }

    return (
      <>
        { this._renderRequirements(data.requirements)}
        { data.requirements.eventually_due.length > 0 &&
        <>
          <div className="kt-heading kt-heading--md">
            Basic
          </div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">
              <div className="form-group">
                <label>Country</label>
                <div className="select-wrapper">
                  <select
                    name="country_short"
                    className="form-control kt-selectpicker"
                    defaultValue={ data.country_short}
                    onChange={e => this._handleChange(e)}
                    disabled
                  >
                    { COUNTRYES.map(country => {
                      return (
                        <option value={country.code}>{country.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label>Business Type</label>
                <div className="select-wrapper">
                  <select
                    name="business_type"
                    className="form-control kt-selectpicker"
                    defaultValue={ data.business_type}
                    onChange={e => this._handleChange(e)}
                  >
                    { !data.business_type &&
                    <option></option>
                    }

                    <option value="individual">Individual</option>
                    <option value="company">Company</option>
                  </select>
                </div>
              </div>

            </div>

            <div className="col-md-3 col-lg-3" hidden>
              <h2>Basic Info</h2>
              <p>We need a few details to help us secure your {window.General.Branding.LinkName}.</p>
            </div>

          </div>

          { this._renderCompanyInfo() }

          { this._renderCompanyRepresentative()}

          { this._renderMembers()}

          { this._renderCompanyExtra() }

          { data.business_type &&

          <div className="row">
            <div className="col-md-9">
              <a
                href=""
                className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                onClick={e => {
                  e.preventDefault()
                  this.handleSave()
                }}
              >
                Update
              </a>
            </div>
          </div>
          }
        </>
        }
      </>
    )
  }

  render(){
    let {
      show,
      data,
      error,
      loading,
    } = this.state

    return (
      <Div
        disabled={loading}
      >
        { this._renderContent() }
      </Div>
    )
  }
}


const COUNTRYES = [
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Belgium', code: 'BE'},
  //{name: 'Brazil ', code: 'BR'},
  {name: 'Canada', code: 'CA'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'Germany', code: 'DE'},
  //{name: 'Hong Kong', code: 'HK'},
  {name: 'Ireland', code: 'IE'},
  //{name: 'Japan', code: 'JP'},
  {name: 'Luxembourg', code: 'LU'},
  //{name: 'Mexico ', code: 'MX'},
  {name: 'Netherlands', code: 'NL'},
  //{name: 'New Zealand', code: 'NZ'},
  {name: 'Norway', code: 'NO'},
  //{name: 'Singapore', code: 'SG'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'Italy', code: 'IT'},
  {name: 'Portugal', code: 'PT'}
]
