import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import Name from "./Name";
import Calculations from "./Calculations";
import General from "./General";
import AsyncStorage from './AsyncStorage'

import moment from 'moment'

import parsePhoneNumber from 'libphonenumber-js'

export default class Backend {

  static getCompanyMembers(){
    return FetchHelper.get(`${window.Api.CompanyMembers}?active=true&`)
  }

  static getCurrentUser(){
    return FetchHelper.get(window.Api.User.Info)
  }

  static export(data){
    return FetchHelper.post(window.Api.Export, data);
  }

  static purchaseSubscription(company, data){
    return FetchHelper.patch(window.Api.CompanySubscriptions + '/' + company.id, data)
  }

  static getPackage(slug){
    return FetchHelper.get(window.Api.Packages + '/' + slug, false)
  }

  static slugify(name, type = ''){
    return FetchHelper.post(`${window.Api.Splinks}/slugify${type !== '' ? '?type='+type : ''}`, { name }, false, false)
  }

  static createAccount(company){
    let data = JSON.parse(JSON.stringify(company));
    return FetchHelper.post(`${window.Api.Connect}/stripe`, data);
  }

  static updateAccount(data){
    let endpoint = window.Api.CompanyMembers
    data = JSON.parse(JSON.stringify(data));

    delete data.user.address
    if(data._role){
      data._role = data._role.id
    }

    return FetchHelper.patch(`${endpoint}/${AuthManager.currentUser.user.id}`, data)
  }

  static updateCompany(company){
    let data = JSON.parse(JSON.stringify(company))
    data.description = data.description !== "" ? data.description : null
    data.contact_name = data.contact_name ? data.contact_name : null
    data.business_info = data.business_info ? data.business_info : null
    data.settings.logo = data.settings.logo ? data.settings.logo.id : null
    data.settings.logo_banner = data.settings.logo_banner ? data.settings.logo_banner.id : null
    data.settings.background = data.settings.background ? data.settings.background.id : null
    data.settings.payment_plan.currency = data.settings.payment_plan.currency ? data.settings.payment_plan.currency.id : null
    data.settings.payment_plan.frequency = data.settings.payment_plan.type === "one_time" ? null : data.settings.payment_plan.frequency
    data.settings.payment_plan.installments = data.settings.payment_plan.type === "one_time" ? null : data.settings.payment_plan.installments
    data.settings.payment_success_email_subject = data.settings.payment_success_email_subject ? data.settings.payment_success_email_subject : null
    data.settings.payment_success_email_body = data.settings.payment_success_email_body ? data.settings.payment_success_email_body : null

    delete data.settings.payment_plan.discounts

    if(data.settings.address){
      delete data.settings.address.latitude
      delete data.settings.address.longitude
    }
    return FetchHelper.patch(`${window.Api.Companies}/${company.id}`, data)
  }

  static addCompanyMember(member){
    let data = JSON.parse(JSON.stringify(member));
    return FetchHelper.post(window.Api.CompanyMembers, data);
  }

  static updateCompanyMember(member){
    let data = JSON.parse(JSON.stringify(member));

    if(data._role){
      data._role = data._role.id
    }
    return FetchHelper.patch(`${window.Api.CompanyMembers}/${member.user.id}`, data);
  }

  static uploadImage(image, type="favicon"){

    let data = new FormData()

    data.append("file", image, image.name)
    data.append("type", type)

    return FetchHelper.post(window.Api.Images, data, true)
  }

  static _parsePaymentPlans(paymentPlans){
    let parsedPaymentPlans = []
    paymentPlans.map(paymentPlan => {
      paymentPlan.currency = paymentPlan.currency ? paymentPlan.currency.id : null
      paymentPlan.frequency = paymentPlan.frequency ? paymentPlan.frequency : null
      parsedPaymentPlans.unshift(Object.assign({}, paymentPlan))
    })
    return parsedPaymentPlans
  }

  static createPaymentRequest(request){
    let data = JSON.parse(JSON.stringify(request));
    if(data.customer.phone_number === ""){
      data.customer.phone_country_code = null
      data.customer.phone_number = null
    }
    if(!data.title){
      let paymentPlan = data.payment_plans[0]
      data.title = `${Math.round(paymentPlan.total / 100.0)} ${paymentPlan.currency.code} by ${data.due_at}`
    }
    data.payment_plans = Backend._parsePaymentPlans(data.payment_plans)

    data.questions = data.questions.map(question => {
      if(question.id){
        delete question.id
      }
      return question
    })

    return FetchHelper.post(window.Api.PaymentRequests, data);
  }

  static createProduct(product){
    let data = JSON.parse(JSON.stringify(product));
    data.featured_image = data.featured_image ? data.featured_image.id : null
    data.payment_plans = Backend._parsePaymentPlans(data.payment_plans)
    data.images = data.images.map(image => image.id)
    data.description = data.description || null
    return FetchHelper.post(window.Api.Products, data);
  }

  static updateProduct(product){
    let data = JSON.parse(JSON.stringify(product));
    data.featured_image = data.featured_image ? data.featured_image.id : null
    data.payment_plans = Backend._parsePaymentPlans(data.payment_plans)
    data.images = data.images.map(image => image.id)
    data.description = data.description || null
    return FetchHelper.patch(`${window.Api.Products}/${product.id}?objects=all`, data);
  }

  static createPaymentLink(paymentLink){
    let data = JSON.parse(JSON.stringify(paymentLink));
    data.background_image = data.background_image ? data.background_image.id : null
    data.payment_plans = Backend._parsePaymentPlans(data.payment_plans)
    return FetchHelper.post(window.Api.PaymentLinks, data);
  }

  static updatePaymentLink(paymentLink){
    if(paymentLink.id === 0){
      return Backend.updateMainSplinkQuestions(paymentLink)
    }
    let data = JSON.parse(JSON.stringify(paymentLink));
    data.background_image = data.background_image ? data.background_image.id : null
    data.payment_plans = Backend._parsePaymentPlans(data.payment_plans)
    return FetchHelper.patch(`${window.Api.PaymentLinks}/${paymentLink.id}`, data);
  }

  static getPaymentRequestStats(dateFrom, dateTo){
    return FetchHelper.get(`${window.Api.Stats}?company=true&payment_requests=true&min_date=${dateFrom}&max_date=${dateTo}`)
  }

  static getPaymentStats(dateFrom, dateTo){
    return FetchHelper.get(`${window.Api.Stats}?company=true&processed=true&min_date=${dateFrom}&max_date=${dateTo}`)
  }

  static getPaymentBreakdownStats(dateFrom, dateTo){
    return FetchHelper.get(`${window.Api.Stats}?company=true&payment_breakdown=true&min_date=${dateFrom}&max_date=${dateTo}`)
  }

  static getMonthlyPaymentStats(dateFrom, dateTo){
    return FetchHelper.get(`${window.Api.Stats}?company=true&monthly_transaction=true&min_date=${dateFrom}&max_date=${dateTo}`)
  }

  static getShopStats(){
    return FetchHelper.get(`${window.Api.Stats}?company=true&shop=true`)
  }

  static getRecurringStats(dateFrom, dateTo){
    return FetchHelper.get(`${window.Api.Stats}?company=true&recurring=true&min_date=${dateFrom}&max_date=${dateTo}`)
  }

  static takePayment(payment){
    let data = JSON.parse(JSON.stringify(payment));
    return FetchHelper.post(window.Api.Payments, data);
  }

  static getPaymentAttempt(id) {
    return FetchHelper.get(`${window.Api.PaymentAttempts}/${id}`);
  }

  static _parseBulkRequests(data, requests){
    let validRequests = []
    let validRequest = {}
    requests.map(request => {
      validRequest = {customer: {data: {}}, payment_plans: [{}]}
      Object.keys(request).map(
        key => {
          validRequest.payment_plans[0].currency = data.currency ? data.currency.id : null
          validRequest.payment_plans[0].open = false
          validRequest.payment_plans[0].type = data.type
          validRequest.payment_plans[0].frequency = data.frequency
          validRequest.payment_plans[0].installments = data.installments

          validRequest.title = `${Math.round(validRequest.payment_plans[0].total / 100.0)} ${data.currency?.code || ""} by ${validRequest.due_at}`

          if(key === 'amount'){
            validRequest.payment_plans[0].subtotal = Math.round(request[key] * 100)
            validRequest.payment_plans[0].total = Math.round(request[key] * 100)
            validRequest.payment_plans[0].vat = 0
            if(data.vat_percentage > 0){
              validRequest.payment_plans[0].vat = Math.round(parseFloat(Calculations.totalVat(request[key] * 100, data.vat_percentage, false)))
              validRequest.payment_plans[0].total = Math.round(parseFloat(Calculations.totalIncludingVat(request[key] * 100, data.vat_percentage, false)))
            }
          }else if(key === 'customer'){
            validRequest.title = request[key]
            validRequest.customer.reference = request[key]
            validRequest.customer.send_email = data.send_email
            validRequest.customer.send_sms = data.send_sms
          }else if(key === 'dueDate'){
            validRequest.due_at = moment(request[key])
          }else if(key === 'email'){
            validRequest.customer.email = request[key]
          }else if(key === 'sms' || key === 'mobile'){
            const phoneNumber = parsePhoneNumber(request[key])
            if (phoneNumber) {
              validRequest.customer.phone_country_code = "+" + phoneNumber.countryCallingCode
              validRequest.customer.phone_number = phoneNumber.nationalNumber
            }
          }else if(key === 'description'){
            validRequest.description = request[key]
          }else{
            validRequest.customer.data[key] = request[key]
          }
        }
      )
      validRequests.unshift(Object.assign({}, validRequest))
    })
    return validRequests
  }

  static createBulkRequests(bulkRequest){
    let data = JSON.parse(JSON.stringify(bulkRequest));
    data.requests = Backend._parseBulkRequests(data, data.requests)
    return FetchHelper.post(window.Api.BulkUploads, {payment_requests: data.requests})
  }

  static deletePayment(payment){
    return FetchHelper.delete(`${window.Api.Payments}/${payment.id}`)
  }

  static cancelSubscription(subscription){
    let data = JSON.parse(JSON.stringify(subscription));
    return FetchHelper.delete(`${window.Api.Subscriptions}/${data.initial_payment.id}`)
  }

  static pauseSubscription(subscription){
    let data = {status: "paused"}
    return FetchHelper.patch(`${window.Api.Subscriptions}/${subscription.initial_payment.id}`, data)
  }

  static resumeSubscription(subscription, nextInvoiceDueDate){
    let data = {status: "active", next_invoice_date_at: nextInvoiceDueDate}
    return FetchHelper.patch(`${window.Api.Subscriptions}/${subscription.initial_payment.id}`, data)
  }

  static updateSubscription(subscription){
    let data = {
      status: subscription.status,
      next_invoice_date_at: subscription.next_invoice_date_at,
      total: subscription.payment_plan.total,
      subtotal: subscription.payment_plan.subtotal,
      vat: subscription.payment_plan.vat,
      installments: subscription.payment_plan.installments
    }
    return FetchHelper.patch(`${window.Api.Subscriptions}/${subscription.initial_payment.id}`, data)
  }


  static deleteChannel(channel){
    return FetchHelper.delete(`${window.Api.PaymentLinks}/${channel.id}`)
  }

  static updateChannel(channel){
    let data = JSON.parse(JSON.stringify(channel));
    data.background_image = data.background_image ? data.background_image.id : null
    data.payment_plans = Backend._parsePaymentPlans(data.payment_plans)
    return FetchHelper.patch(`${window.Api.PaymentLinks}/${channel.id}?objects=all`, data)
  }

  static deleteProduct(product){
    return FetchHelper.delete(`${window.Api.Products}/${product.id}`)
  }

  static saveBankAccount(data){
    return FetchHelper.post(window.Api.BankAccounts, data)
  }

  static getBankAccount(){
    let slug = AuthManager.currentUser.company.settings.live ? "stripe" : "stripe_test"
    return FetchHelper.get(`${window.Api.BankAccounts}?processor_slug=${slug}`)
    .then(response => {
      return response.results[0]
    })
  }

  static getPaymentLinks(){
    return FetchHelper.get(`${window.Api.PaymentLinks}?paginated=false`)
  }

  static getPaymentLink(id){
    return FetchHelper.get(`${window.Api.PaymentLinks}/${id}`)
  }

  static getMainSplinkQuestions(){
    return FetchHelper.get(`${window.Api.Questions}?main=true&paginated=false`)
  }

  static getQuestions(template){
    return FetchHelper.get(`${window.Api.Questions}?template=${template}&paginated=false`)
  }

  static saveQuestions(questions, template){
    let data = {
      template,
      questions
    }
    return FetchHelper.put(`${window.Api.Questions}`, data)
  }

  static addImage(data){
    data.type = "photo"
    let validateTokens = AuthManager.currentUser != null
    return FetchHelper.post(window.Api.Images, data, false, validateTokens)
  }

  static deleteMember(member){
    return FetchHelper.delete(`${window.Api.CompanyMembers}/${member.user.id}`)
  }

  static deletePaymentRequest(paymentRequest){
    return FetchHelper.delete(`${window.Api.PaymentRequests}/${paymentRequest.id}`)
  }

  static verifyPaymentRequest(paymentRequest){
    let data = {paid: true}
    return FetchHelper.patch(`${window.Api.PaymentRequests}/${paymentRequest.id}`, data)
  }

  static updateMainSplinkQuestions(paymentLink){
    let data = JSON.parse(JSON.stringify(paymentLink));
    let mainQuestions = {settings:{questions: data.questions, collect_address: data.collect_address}}
    return FetchHelper.patch(`${window.Api.Companies}/${data.companyId}`, mainQuestions)
  }

  static getProcessedStats(dateFrom, dateTo){
    return FetchHelper.get(`${window.Api.Stats}?company=true&processed=true&min_date=${dateFrom}&max_date=${dateTo}`)
  }

  static resendPaymentRequest(paymentRequest){
    let data = {payment_request: paymentRequest.id}
    return FetchHelper.post(`${window.Api.Reminders}`, data)
  }

  static resendPaymentReceipt(payment, email){
    let data = {payment: payment.id}
    if(email){
      data.customer_email = email
    }
    return FetchHelper.post(`${window.Api.Receipts}`, data)
  }

  static getConnectData(slug){
    return FetchHelper.get(`${window.Api.Connect}/${slug}`)
  }

  static updateConnectData(processorSlug, data){
    return FetchHelper.post(`${window.Api.Connect}/${processorSlug}`, data)
  }

  // this is stripe only
  static updateConnect(data, slug){
    data = JSON.parse(JSON.stringify(data))

    data.currency = AuthManager.currentUser.company.settings.payment_plan.currency.code

    data.representative = this._getPersonData(data.representative)
    if(data.business_type === "individual"){
      delete data.representative.id
    }

    for(var i=0; i < data.persons; i++){
      data.persons[i] = this._getPersonData(data.persons[i])
    }

    return FetchHelper.post(`${window.Api.Connect}/${slug}`, data)
  }

  static _getPersonData(data){
    let person = { ...data }
    let { verification } = person

    if(!verification){
      return data
    }

    if(verification.document){
      if(!verification.document.front_updated){
        delete person.verification.document.front
      }
      if(!verification.document.back_updated){
        delete person.verification.document.back
      }
    }
    if(verification.additional_document){
      if(!verification.additional_document.front_updated){
        delete person.verification.additional_document.front
      }
      if(!verification.additional_document.back_updated){
        delete person.verification.additional_document.back
      }
    }
    delete verification.document.front_updated
    delete verification.document.back_updated
    delete verification.additional_document.front_updated
    delete verification.additional_document.back_updated

    return person
  }

  static restoreProduct(product){
    let data = {deleted_at: null}
    return FetchHelper.patch(`${window.Api.Products}/${product.id}?objects=all`, data)
  }

  static uploadFile(file, endpoint, params){
    let data = new FormData()

    data.append("file", file, file.name)
    for(var key in params){
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static getSplink(companySlug, paymentAttemptId){
    let url = `${window.Api.Splinks}/${companySlug}`
    if(paymentAttemptId){
      url += "?payment_attempt_id=" + paymentAttemptId
    }
    return FetchHelper.get(url)
  }


  static makePayment(company, paymentPlan, paymentMethod, paymentDetails, customer, answers, description, paymentAttemptId, recaptcha){

    paymentDetails.merchant_initiated_transaction = true

    let paymentPlanData = General.clone(paymentPlan)
    paymentPlanData.currency = paymentPlanData.currency.id
    let data = {
      answers,
      customer,
      description,
      recaptcha,
      company: company.id,
      payment_plan: paymentPlanData,
      payment_method: paymentMethod.id,
      payment_details: paymentDetails,
      payment_attempt_id: paymentAttemptId
    }

    return FetchHelper.post(`${window.Api.Payments}`, data)
  }

  static reverseFeeCalc(amount, company, paymentPlan, paymentMethod){
    let url = `${window.Api.ReverseFeeCalc}?company_id=${company.id}&amount=${amount}`
    url += `&payment_plan_id=${paymentPlan.id}&payment_method_id=${paymentMethod.id}`
    return FetchHelper.get(url)
  }

  static getHash(key, type){
    let data = {
      type
    }
    let url = `${window.Api.Hashes}/${key}`
    return FetchHelper.post(url, data, false, false)
  }

  static getPaymentMethod(id){
    return FetchHelper.get(`${window.Api.PaymentMethods}/${id}`)
  }

  static getPlatformPaymentMethods(){
    return FetchHelper.get(`${window.Api.PlatformPaymentMethods}`)
  }

  static getBranding(){
    let url = window.location.hostname
    let demo = AuthManager.currentUser?.company.settings.live || false

    return FetchHelper.get(`${window.Api.Branding}?website_url=${url}&demo=${demo}`, false)
  }

  static getBanks(processorSlug, countryCode, company){
    return FetchHelper.get(`${window.Api.Connect}/${processorSlug}/banks?countries=${countryCode}&company_id=${company.id}`)
    .then(response => response.results || response.banks || [])
  }

  static connectProcessor(slug, data){
    data = JSON.parse(JSON.stringify(data))
    return FetchHelper.post(`${window.Api.Connect}/${slug}`, data)
  }

  static createQRCode(data){
    return FetchHelper.post(window.Api.QRCodes, data);
  }

  static updateQRCode(qrCode){
    return FetchHelper.patch(`${window.Api.QRCodes}/${qrCode.id}`, qrCode);
  }

  static deleteQRCode(qrCode){
    return FetchHelper.delete(`${window.Api.QRCodes}/${qrCode.id}`);
  }

  static restoreQRCode(qrCode){
    return FetchHelper.patch(`${window.Api.QRCodes}/${qrCode.id}?objects=all`, { deleted_at: null });
  }

  static getUnconnectedProcessors(company){
    return FetchHelper.get(`${window.Api.Processors}?company_id=${company.id}&connected=false&live=${company.settings.live}&paginated=false`)
  }

  static addRole(role) {
    return FetchHelper.post(window.Api.Roles, role)
  }

  static deleteRole(role) {
    return FetchHelper.delete(`${window.Api.Roles}/${role.id}`)
  }

  static updateRole(role) {
    return FetchHelper.patch(`${window.Api.Roles}/${role.id}`, role)
  }

  static getSecretKey(){
    return FetchHelper.get(`${window.Api.SecretKey}`)
  }

  static createSecretKey(){
    return FetchHelper.post(`${window.Api.SecretKey}`)
  }

  static rotateSecretKey(){
    return FetchHelper.patch(`${window.Api.SecretKey}`)
  }

  static createWebhook(data){
    return FetchHelper.post(`${window.Api.Webhooks}`, data)
  }

  static updateWebhook(webhook){
    return FetchHelper.patch(`${window.Api.Webhooks}/${webhook.id}`, webhook)
  }

  static deleteWebhook(webhook){
    return FetchHelper.delete(`${window.Api.Webhooks}/${webhook.id}`)
  }

  static getCashDiscount(){
    return FetchHelper.get(`${window.Api.Discounts}?cash_discount=true`)
    .then(response => {
      return response.results[0]
    })
  }

  static createDiscount(discount){
    return FetchHelper.post(`${window.Api.Discounts}`, discount)
  }

  static updateDiscount(discount){
    return FetchHelper.patch(`${window.Api.Discounts}/${discount.id}`, discount)
  }

  static deleteDiscount(discount){
    return FetchHelper.delete(`${window.Api.Discounts}/${discount.id}`)
  }

  static updatePaymentPlan(paymentPlan){
    let paymentPlanData = General.clone(paymentPlan)
    paymentPlanData.currency = paymentPlanData.currency.id
    return FetchHelper.patch(`${window.Api.PaymentPlans}/${paymentPlan.id}`, paymentPlanData)
  }

  static payCompanyInvoice(invoice){
    return FetchHelper.post(`${window.Api.CompanyInvoices}/${invoice.id}/payments`)
  }

  static changeCompanySubscriptionPaymentInstrument(subscription, data){
    return FetchHelper.patch(`${window.Api.CompanySubscriptions}/${subscription.company}/payment-instrument`, data)
  }

  static binLookup(value){
    return FetchHelper.get(`${window.Api.Bins}?value=${value}`)
  }
}
