import React from "react"
export default class Footer extends React.Component {

  render() {

    if (this.props.hideFooter){
      return null;
    }
    
    return (
      <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">&copy;	{new Date().getFullYear()}</span>
            <a href={window.General.WebsiteUrl} target="_blank" className="text-dark-75 text-hover-primary">{window.General.Branding.Name}</a>
          </div>
        </div>
      </div>
    )
  }
}
