import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from "moment"

import Select from '../common/CustomSelect'

import { components } from 'react-select';

import Notify from "../../../utils/Notify"
import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import CompanyProcessor from "../../../utils/CompanyProcessor"

export default class ChooseProcessorModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      company: props.company,
      processors: null,
      selectedProcessor: null
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    let { company } = this.state
    Backend.getUnconnectedProcessors(company)
    .then(processors => {

      let availableProcessors = processors.filter(processor => {
        return processor.slug != "stripe" && processor.slug != "stripe_test"
      })

      availableProcessors = availableProcessors.map(processor => {
        processor.name = processor.name.replace("Stripe Standard", "Stripe")
        return processor
      })

      this.setState({
        processors: availableProcessors,
        selectedProcessor: availableProcessors.find(processor => processor.slug.indexOf("stripe") > -1)
      })
    })
    .catch(error => {

    })
  }

  _connect(processor){
    this.setState({ loading: true })
    let data = this._getData(processor)

    Backend.connectProcessor(processor.slug, data)
    .then(data => {
      if(data.activation_link){
        window.location = data.activation_link
      }
      else{
        window.location.reload()
      }
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _getData(processor){
    if(processor.slug.indexOf("stripe") == -1){
      return null
    }

    let { user, company } = AuthManager.currentUser
    let { address, date_of_birth } = user

    let data = {
      country_short: "ie",
      currency: company.settings.payment_plan.currency.code,
      business_type: 'individual',
      persons: [],
      representative: {
        first_name: user.first_name,
        last_name: user.last_name,
        // Stripe's phone validation is more strict than Api's currently so
        // we dont want to send phone in case it's invalid
        // phone: `${user.phone_country_code}${user.phone_number}`,
        relationship: {
          director: false,
          executive: false,
          owner: false,
        },
      },
    }

    if(address){
      data.country_short = address.country_short
      data.representative.address = {
        line1: address.line_1,
        line2: address.line_2,
        city: address.city,
        state: address.state,
        country: address.country_short,
        country_short: address.country_short,
        postal_code: address.postal_code
      }

      if(date_of_birth){
        let dob = moment(date_of_birth)
        data.representative.dob = {
          year: dob.format('YYYY'),
          day: dob.format('D'),
          month: dob.format('M')
        }
      }
    }

    return data
  }

  render() {
    let {
      show,
      company,
      error,
      loading,
      processors,
      selectedProcessor
    } = this.state

    if(processors == null){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => {}}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Your Processor</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group">
            <Select
              value={selectedProcessor}
              className="async-select-paginate"
              classNamePrefix="async-select-paginate"
              name="processor"
              placeholder="Please Select A Processor"
              onChange={(option) => {
                selectedProcessor = option
                this.setState({ selectedProcessor })
              }}
              options={processors}
              menuPortalTarget={null}
              components={{ Option: IconOption, SingleValue: ValueOption }}
            />
          </div>

          <div className="form-group">
            <p>{ selectedProcessor?.description }</p>
          </div>

        </Modal.Body>
        <Modal.Footer>

          { (selectedProcessor?.slug.indexOf("stripe") == -1) &&
            <button
              type="button"
              className="btn btn-secondary font-weight-bold"
              onClick={() => window.Intercom("show")}
            >
              Contact Us
            </button>
          }
          { (selectedProcessor?.slug.indexOf("stripe") > -1)  &&
            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              onClick={() => this._connect(selectedProcessor)}
              disabled={loading}
            >
              Connect Bank Account
            </button>
          }

        </Modal.Footer>
      </Modal>
    )
  }
}

const { Option, SingleValue } = components;
const IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.logo_small?.thumbnail}
        width="20px"
        height="20px"
        style={{
          marginRight: 8
        }}
      />
      {props.data.name}
    </Option>
);

const ValueOption = (props) => (
    <SingleValue {...props}>
    <img
      src={props.data.logo_small?.thumbnail}
      width="22px"
      height="22px"
      style={{
        marginRight: 8,
      }}
    />
    { props.data.name}
    </SingleValue>
);
