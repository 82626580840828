import React from 'react'
import moment from 'moment'
import { Portal } from 'react-portal'

import StripeCustomConnectProgress from './components/kyc/StripeCustomConnectProgress'
import StripeStandardConnectProgress from './components/kyc/StripeStandardConnectProgress'
import ChooseProcessorCard from './components/kyc/ChooseProcessorCard'

import PaymentBreakdown from './components/stats/PaymentBreakdown'
import MonthlyPaymentVolume from './components/stats/MonthlyPaymentVolume'

import DateRange from './components/common/DateRange'
import StatCard from './components/common/StatCard'
import EngagementCard from './components/common/EngagementCard'

import RequestPaymentModal from './components/modal/RequestPaymentModal'
import TakePaymentModal from './components/modal/TakePaymentModal'
import ChooseProcessorModal from './components/modal/ChooseProcessorModal'

import PaymentsTable from './components/tables/PaymentsTable'
import PaymentRequestsTable from './components/tables/PaymentRequestsTable'

import EasyMode from './components/EasyMode'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'
import Stats from '../utils/Stats'
import Currency from '../utils/Currency'
import Event from '../utils/Event'
import General from '../utils/General'
import Notify from '../utils/Notify'
import CompanyProcessor from '../utils/CompanyProcessor'
import Permissions from '../utils/Permissions'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dateFrom: null,
      dateTo: null,
      createdDate: moment(AuthManager.currentUser.company.created_at),
      today: moment(),
      company: AuthManager.currentUser.company,
    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if (window.General.DefaultCurrency) {
      this._loadStats()
    }
    this._setProgress()
  }

  _setProgress() {
    this.setState({ isLoading: true })
    Backend.getCurrentUser()
      .then((response) => {
        this.setState({ company: response.company_member.company }, () => {
          this._setProgressStatus()
          this.setState({ isLoading: false })
        })
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  _setProgressStatus() {
    let { company } = this.state
    if (!company) {
      return
    }

    let companyProcessor = CompanyProcessor.getDefault(company)
    if (!companyProcessor) {
      return null
    }

    let steps = CompanyProcessor.isDefaultStripeCustomConnect(company) ? 3 : 2
    let stepsCompleted = steps

    if (
      CompanyProcessor.isDefaultStripeCustomConnect(company) &&
      !companyProcessor.bank_connected
    ) {
      stepsCompleted -= 1
    }
    if (companyProcessor.data.verification_status !== 'verified') {
      stepsCompleted -= 1
    }
    let progress = stepsCompleted * (1 / steps)
    this.setState({
      progress: progress * 100,
      processorSlug: companyProcessor.processor_slug,
    })
  }

  _shouldShowProgressCard() {
    let { company, progress } = this.state
    let companyProcessor = CompanyProcessor.getDefault(company)

    if (!Permissions.hasViewPermission(Permissions.SETTINGS)) {
      return false
    }

    if (
      !CompanyProcessor.isDefaultStripeStandardConnect(company) &&
      !CompanyProcessor.isDefaultStripeCustomConnect(company)
    ) {
      return false
    }

    if (progress < 100) {
      return true
    }

    return false
  }

  _renderChooseProcessorModal() {
    let { company, progress } = this.state
    let show =
      company.company_processors.length === 0 && company.subscription.active
    return <ChooseProcessorModal show={show} company={company} />
  }

  _loadStats() {
    let { today, createdDate } = this.state

    if (today.diff(createdDate, 'days') < 30) {
      let dateTo = moment()
      let dateFrom = moment().add(-1, 'years')

      Backend.getProcessedStats(
        dateFrom.toISOString(),
        dateTo.toISOString()
      ).then((stats) => {
        this.setState({
          stats: Stats.getProcesseedStats(stats.company.processed),
        })
      })
    }
  }

  _renderDateRange() {
    let { dateFrom, dateTo } = this.state
    if (!Permissions.hasViewPermission(Permissions.STATS)) {
      return null
    }
    return (
      <DateRange
        dateFrom={dateFrom}
        dateTo={dateTo}
        onFromDateUpdated={(dateFrom) => {
          this.setState({
            dateFrom,
          })
        }}
        onToDateUpdated={(dateTo) => {
          this.setState({
            dateTo,
          })
        }}
      />
    )
  }

  _renderStatCards() {
    let { stats } = this.state

    if (!stats) return null

    for (var key in stats) {
      // check if the property/key is defined in the object itself, not in parent
      if (stats.hasOwnProperty(key)) {
        stats[key] = Currency.format(
          window.General.DefaultCurrency.symbol,
          stats[key]
        )
      }
    }

    let processedStats = [
      {
        amount: stats.processed_today,
        title: 'Processed Today',
      },
      {
        amount: stats.processed_last_7_days,
        title: 'Processed Last 7 Days',
      },
      {
        amount: stats.processed_month,
        title: 'Processed Last 30 Days',
      },
    ]
    return processedStats.map((stat) => (
      <StatCard amount={stat.amount} title={stat.title} icon={'graph'} />
    ))
  }

  _renderStats() {
    let { dateFrom, dateTo, today, progress, company, processorSlug } =
      this.state

    let easyModeEnabled = company.add_ons.easy_mode
    return (
      <>
        { !easyModeEnabled && Permissions.hasViewPermission(Permissions.STATS) && (
            <div className={'col-xl-4'}>
              <PaymentBreakdown dateFrom={dateFrom} dateTo={dateTo} />
            </div>
        )}
        <div
          className={
            Permissions.hasViewPermission(Permissions.STATS)
              ? 'col-xl-8'
              : 'col-xl-12'
          }
        >
          {this._shouldShowProgressCard() && (
            <>
              {CompanyProcessor.isDefaultStripeCustomConnect(company) && (
                <StripeCustomConnectProgress
                  progress={progress}
                  company={company}
                />
              )}
              {CompanyProcessor.isDefaultStripeStandardConnect(company) && (
                <StripeStandardConnectProgress
                  progress={progress}
                  company={company}
                />
              )}
            </>
          )}
          {!this._shouldShowProgressCard() &&
            <>
              {
                company.add_ons.easy_mode ? (
                  <EasyMode company={company}/>
                ) : (
                  <>
                    {Permissions.hasViewPermission(Permissions.STATS) && (
                      <MonthlyPaymentVolume dateFrom={dateFrom} dateTo={dateTo} />
                    )}
                  </>
                )
              }
            </>
          }
        </div>
        { easyModeEnabled && Permissions.hasViewPermission(Permissions.STATS) && (
            <div className={'col-xl-4'}>
              <PaymentBreakdown dateFrom={dateFrom} dateTo={dateTo} />
            </div>
        )}
      </>
    )
  }

  _renderTakePaymentModal() {
    let { showTakePaymentModal } = this.state

    if (!showTakePaymentModal) return null

    return (
      <Portal node={document && document.getElementById('payment_portal')}>
        <TakePaymentModal
          show={showTakePaymentModal}
          onHide={() => this.setState({ showTakePaymentModal: false })}
        />
      </Portal>
    )
  }

  _renderRequestPaymentModal() {
    let { company, showRequestPaymentModal } = this.state

    if (!showRequestPaymentModal) return null

    return (
      <Portal node={document && document.getElementById('request_portal')}>
        <RequestPaymentModal
          show={showRequestPaymentModal}
          showOptional={false}
          company={company}
          onHide={() => this.setState({ showRequestPaymentModal: false })}
          onAdded={() => {
            this.setState({ showRequestPaymentModal: false })
          }}
        />
      </Portal>
    )
  }

  render() {
    let { company, isLoading } = this.state

    if (isLoading) {
      return null
    }

    let hasProcessed =
      (company.settings.live && company.live_payments_no) ||
      (!company.settings.live && company.demo_payments_no)
    if (!hasProcessed && company.payment_requests_no === 0 && !company.add_ons.easy_mode) {
      return (
        <>
          <EngagementCard
            title={'Welcome To Your Dashboard'}
            text={`
              Go on, take your first payment. Your dashboard will enable you to take
              payment in a variety of different ways. You will also be able to review stats,
              outstanding payments and even give a gentle nudge to remind!
            `}
            buttonTitle={'Send A Payment Request'}
            buttonTitle2={'Take Payment'}
            onClick={() => {
              this.setState({ showRequestPaymentModal: true })
            }}
            onClick2={() => {
              this.setState({ showTakePaymentModal: true })
            }}
          />
          {this._renderTakePaymentModal()}
          {this._renderRequestPaymentModal()}
          {this._renderChooseProcessorModal()}
        </>
      )
    }

    return (
      <>
        {this._renderDateRange()}
        <div className={'row'}>{this._renderStats()}</div>
        <PaymentsTable
          title={'Latest Payments Received'}
          showExport={false}
          latestResults={true}
          canShowEngagementCard={false}
          paymentsProcessed={company.live_payments_no}
        />
        <br />
        <PaymentRequestsTable
          title={'Latest Requests Sent'}
          showExport={false}
          latestResults={true}
          canShowEngagementCard={false}
          paymentRequestsSent={company.payment_requests_no}
        />
      </>
    )
  }
}
