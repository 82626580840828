import React from "react"

const image = require("../../../assets/media/icons/svg/humans/custom-10.svg")

export default class EngagementCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      title: props.title,
      text: props.text,
      buttonTitle: props.buttonTitle,
      buttonTitle2: props.buttonTitle2
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render() {
    let {
      title,
      text,
      buttonTitle,
      buttonTitle2
    } = this.state

    return (
      <div className="row">
        <div className="col-xl-12">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body d-flex p-0">
              <div className="flex-grow-1 p-10 p-lg-40 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
                   style={{
                     backgroundColor: '#1B283F',
                     backgroundPosition: 'calc(100% + 0.5rem) bottom',
                     backgroundSize: '25% auto',
                     backgroundImage: `url(${image})`,
                   }}
              >
                <h2 className="text-white pb-5 font-weight-bolder">{title}</h2>
                <p className="text-muted pb-5 font-size-h5" style={{ whiteSpace: 'pre-line' }}>
                  {text}
                </p>
                <a
                  href="#"
                  className="btn btn-danger font-weight-bold py-2 px-6 mr-3 min-w-225px"
                  onClick={() => this.props.onClick()}
                >
                  {buttonTitle}
                </a>
                {
                  buttonTitle2 &&
                  <a
                    href="#"
                    className="btn btn-primary font-weight-bold py-2 px-6 min-w-225px"
                    onClick={() => this.props.onClick2()}
                  >
                    {buttonTitle2}
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}